import { Button } from 'colabs-ui-react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Download, Loader } from 'react-feather';
import styled from 'styled-components';
import { formatBytes } from '../../utils/helper';

const StyledDownloadingDeckPopper = styled.div`
  margin: 0.5rem 0;
  .loader {
    animation: rotate 2s linear infinite;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const DownloadingDeckPopper = ({ updateCreativeDeck, deck }) => {
  const [downloadingDeckStatus, setDownloadingDeckStatus] = useState('start');
  const [downloadProgress, setDownloadProgress] = useState(0);

  const downloadCreativeDeck = async () => {
    try {
      setDownloadingDeckStatus('loading');
      // const { data } = await axiosWithAuth().post(
      //   '/cms/downloadDeck',
      //   {
      //     creative_deck_id: deck.id,
      //   },
      //   {
      //     responseType: 'blob',
      //     timeout: 50000,
      //   }
      // );
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cms/downloadDeck`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            creative_deck_id: deck.id,
          }),
        }
      );
      const reader = response.body.getReader();
      // Step 2: get total length
      const contentLength = +response.headers.get('Content-Length');
      // Step 3: read the data
      let receivedLength = 0; // received that many bytes at the moment
      const chunks = []; // array of received binary chunks (comprises the body)
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        chunks.push(value);
        receivedLength += value.length;
        setDownloadProgress((prev) => prev + value.length);
      }
      // Step 4: concatenate chunks into single Uint8Array
      const chunksAll = new Uint8Array(receivedLength); // (4.1)
      chunks.reduce((acc, chunk) => {
        chunksAll.set(chunk, acc); // (4.2)
        return acc + chunk.length;
      }, 0);
      // let position = 0;
      // for (let chunk of chunks) {
      //   chunksAll.set(chunk, position); // (4.2)
      //   position += chunk.length;
      // }
      const blob = new Blob([chunksAll], { type: 'application/zip' });
      saveAs(blob, `${deck.title}.zip`);
      await updateCreativeDeck(deck.id, {
        downloaded_at: new Date().toISOString(),
      });
      setDownloadProgress(0);
      setDownloadingDeckStatus('success');
      // const socket = io(process.env.REACT_APP_WS_URL, {
      //   withCredentials: true,
      //   extraHeaders: {
      //     Authorization:
      //       localStorage.getItem('loggedin') ??
      //       sessionStorage.getItem('loggedin'),
      //   },
      // });
      // // let fileSize = 0;
      // // let fileDownloaded = 0;
      // socket.on('creativeDeckDownload', ({ state, ...payload }) => {
      //   if (state === 'fetched') {
      //     setFilesToDownload(
      //       payload.files.map((file) => ({ name: file, zipped: false }))
      //     );
      //   }
      //   if (state === 'zipped') {
      //     setFilesToDownload((files) =>
      //       files.map(({ name, ...file }) =>
      //         name === payload.file ? { name, zipped: true } : { name, ...file }
      //       )
      //     );
      //   }
      //   if (state === 'error') {
      //     setDownloadingDeckStatus('error');
      //   }
      // });
      // const stream = ss.createStream();
      // const fileStream = streamSaver.createWriteStream(`${deck.title}.zip`);
      // const writer = fileStream.getWriter();
      // ss(socket).emit('downloadCreativeDeck', stream, {
      //   creative_deck_id: deck.id,
      // });
      // // const bufs = [];
      // stream
      //   .on('data', (data) => {
      //     // fileDownloaded += data.length;
      //     // bufs.push(data);
      //     writer.write(data);
      //   })
      //   .on('end', () => {
      //     // const buf = Buffer.concat(bufs);
      //     // const blob = new Blob([buf], { type: 'application/zip' });
      //     // saveAs(blob, `${deck.title}.zip`);
      //     writer.close();
      //     setDownloadingDeckStatus('success');
      //     if (deck.downloaded_at) return;
      //     updateCreativeDeck(deck.id, {
      //       downloaded_at: new Date().toISOString(),
      //     });
      //   })
      //   .on('error', (err) => {
      //     writer.abort();
      //     throw err;
      //   });
    } catch (err) {
      setDownloadProgress(0);
      setDownloadingDeckStatus('error');
    }
  };

  return (
    <StyledDownloadingDeckPopper>
      {/* formatBytes */}
      <Button
        variant="secondary"
        fullWidth
        text={(() => {
          if (downloadingDeckStatus !== 'loading') return 'Download';
          if (downloadProgress === 0) return 'Zipping...';
          return `Downloading ${formatBytes(downloadProgress)}`;
        })()}
        disabled={downloadingDeckStatus === 'loading'}
        icon={
          downloadingDeckStatus === 'loading' ? (
            <Loader className="loader" />
          ) : (
            <Download />
          )
        }
        onClick={downloadCreativeDeck}
      />
    </StyledDownloadingDeckPopper>
  );
};

DownloadingDeckPopper.propTypes = {};

DownloadingDeckPopper.defaultProps = {};

export default DownloadingDeckPopper;
