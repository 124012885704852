import * as Popover from '@radix-ui/react-popover';
import React, { useEffect, useState } from 'react';
import { Filter } from 'react-feather';
import styled from 'styled-components';

const StyledTrigger = styled(Popover.Trigger)`
  outline: none;
  border: none;
  background: none;
  font-size: 0.875rem;
  > .filter-icon {
    margin-left: 0.25rem;
    height: 0.75rem;
    width: 0.75rem;
    stroke-width: 1px;
    &.applied {
      fill: black;
    }
  }
`;

const StyledContent = styled(Popover.Content)`
  display: grid;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #d3d3d3;
  z-index: 1000;
  background-color: white;
  grid-template-columns: min-content min-content min-content;
  > .tag-bucket {
    padding: 0.25rem 0.75rem 0.75rem 0.75rem;
    &.Type {
    }
    &.UGC {
      border-left: 1px solid #d3d3d3;
      border-right: 1px solid #d3d3d3;
    }
    &.Trends > .tags {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &.Industry {
      grid-column: 1 / 4;
      padding-top: 0.75rem;
      border-top: 1px solid #d3d3d3;
      width: 29rem;
      > .tags {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    > .category-title {
      margin-bottom: 0.2rem;
      font-size: 16px;
    }
    > .tags {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      > .tag {
        border-radius: 9999px;
        border: 1px solid #dceaff;
        padding: 0.1rem 0.75rem;
        white-space: nowrap;
        font-size: 14px;
        width: fit-content;
        cursor: pointer;
        &.active,
        :hover {
          background-color: #dceaff;
        }
      }
    }
  }
`;

const Arrow = styled(Popover.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const TagsFilterPopover = ({ activeTags, setActiveTags }) => {
  const addTag = (tag) => {
    setActiveTags([...activeTags, tag]);
  };

  const removeTag = (tag) => {
    setActiveTags(activeTags.filter((t) => t !== tag));
  };

  return (
    <Popover.Root>
      <StyledTrigger
        className="column-title clickable"
        role="button"
        tabIndex={0}
      >
        Tags
        <Filter
          className={`filter-icon${activeTags.length > 0 ? ' applied' : ''}`}
        />
      </StyledTrigger>
      <Popover.Portal>
        <StyledContent sideOffset={5} side="right" align="start">
          {[
            { category: 'Type', tags: ['mobile', 'saas'] },
            {
              category: 'UGC',
              tags: [
                'conversation',
                'listicle',
                'review',
                'testimonial',
                'tutorial',
                'walk-through',
              ],
            },
            {
              category: 'Trends',
              tags: [
                'dances',
                'duests',
                'filters',
                'lip-sync',
                'stitches',
                'skits',
                'sounds',
                'transitions',
              ],
            },
            {
              category: 'Industry',
              tags: [
                'apparel',
                'baby/kids',
                'beauty',
                'business',
                'education',
                'electronics',
                'fintech',
                'food',
                'health',
                'home',
                'news',
                'pets',
                'services',
                'sports',
                'transportation',
                'travel',
              ],
            },
          ].map(({ category, tags }) => (
            <div className={`tag-bucket ${category}`}>
              <div className="category-title">{category}</div>
              <div className="tags">
                {tags.map((tag) => {
                  const active = activeTags.includes(tag);
                  return (
                    <div
                      className={`tag${active ? ' active' : ''}`}
                      key={tag}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => (active ? removeTag(tag) : addTag(tag))}
                      onClick={() => (active ? removeTag(tag) : addTag(tag))}
                    >
                      {tag}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
          <Arrow />
        </StyledContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default TagsFilterPopover;
