import { Button } from 'colabs-ui-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MainLayout from '../layouts/Main';

const StyledAltHome = styled.div`
  padding: 0.75rem;
  width: 100%;
  max-width: 1310px;
  display: grid;
  gap: 1rem;
  align-content: start;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  > .card {
    border: 1px solid #d3d3d3;
    border-radius: 12px;
    padding: 1rem;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.75rem;
    height: fit-content;
    width: fit-content;
    font-size: 1.25rem;
    > .thumbnail {
      width: 8rem;
      height: 8rem;
      border-radius: 12px;
      grid-row: 1 / 3;
      object-fit: cover;
    }
    > span {
      font-size: 1.25rem;
      font-weight: 500;
      margin-right: 1rem;
      color: #333;
      align-self: end;
    }
    > a {
      width: fit-content;
      height: fit-content;
    }
  }
`;

const AltHome = () => {
  const user = useSelector((state) => state.user);
  const orders = user?.orders.filter((order) =>
    order.order_items.some(
      (orderItem) => orderItem.product_name === 'video_bundle'
    )
  );

  return (
    <MainLayout centered>
      <StyledAltHome>
        {orders.map((order, idx) => (
          <div className="card">
            <video
              autoPlay
              loop
              muted
              src="https://colabs-ads.s3.amazonaws.com/3-Phone_Animation_v2.mp4"
              className="thumbnail"
            />
            <span>
              Video Bundle{orders.length > 1 ? ` #${idx + 1}` : ''} 🔥
            </span>
            <Link to={`orders/${order.id}/brief`}>
              <Button text="Open" variant="secondary" />
            </Link>
          </div>
        ))}
      </StyledAltHome>
    </MainLayout>
  );
};

export default AltHome;
