import { Textfield } from 'colabs-ui-react';
import React, { useEffect, useRef } from 'react';
import { Search } from 'react-feather';
import styled from 'styled-components';
import star from '../../assets/icons/star.svg';
import blueImage from '../../assets/images/BlueImage.png';
import useOnScreen from '../../hooks/useOnScreen';
import SortIcons from '../SortIcons';
import AllDecksMenu from './AllDecksMenu';
import DeckActionsMenu from './DeckActionsMenu';

const StyledAllDecksNavPanel = styled.div`
  display: flex;
  flex-direction: column;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
    }
    > .cui-textfield {
      flex-grow: 1;
    }
  }
  > .deck-list-row,
  > .deck-list-body > .deck-list-row {
    display: grid;
    grid-template-columns: 1fr 3.5fr 1fr 1fr 0.75fr;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    &.all {
      height: 4.75rem;
      cursor: pointer;
      :hover {
        background-color: #fafafa;
      }
    }
    &.deck {
      height: 4.75rem;
      cursor: pointer;
      &[disabled] {
        cursor: not-allowed;
        > * {
          opacity: 0.5;
        }
        :hover {
          background-color: transparent;
        }
      }
      :hover {
        background-color: #fafafa;
      }
      > .organization-logo {
        height: 3.75rem;
        width: 3.75rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .deck-name {
        font-size: 0.75rem;
      }
      > .decks-actions-menu {
        justify-self: end;
      }
    }
    &.more-decks {
      white-space: nowrap;
    }
  }
  > .deck-list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
`;

const AllDecksNavPanel = ({
  setNameQueryTextfield,
  decksFetch,
  openDeck,
  fetchAllDecks,
  setNewDeck,
  setNewReference,
}) => {
  const loadingMsg = useRef();
  const isLoadingMsgOnScreen = useOnScreen(loadingMsg, []);

  useEffect(() => {
    if (!isLoadingMsgOnScreen || decksFetch.reachedEnd) return;
    fetchAllDecks(decksFetch.page + 1);
  }, [isLoadingMsgOnScreen, decksFetch.page]);

  return (
    <StyledAllDecksNavPanel className="all-decks-nav-panel">
      <div className="panel-header">
        <span className="title">ALL DECKS</span>
        <Textfield
          icon={<Search color="#e0e0e0" />}
          placeholder="Search..."
          onChange={(e) => setNameQueryTextfield(e.target.value)}
        />
        <AllDecksMenu
          setNewDeck={setNewDeck}
          setNewReference={setNewReference}
        />
      </div>
      <div className="deck-list-row">
        {[
          { property: 'organizations.name', label: 'Org.' },
          { property: 'decks.name', label: 'Deck Name' },
          { property: 'reference_count', label: 'Media' },
          { property: 'rating_score', label: 'Avg. Rating' },
        ].map(({ property, label }) => (
          <div
            className="column-title clickable"
            onClick={() => fetchAllDecks(1, property)}
            role="button"
            tabIndex={0}
            onKeyDown={() => fetchAllDecks(1, property)}
          >
            {label}
            <SortIcons
              sorting={
                decksFetch.fetchState === 'success' &&
                decksFetch.columnSort.column === property
                  ? decksFetch.columnSort.order
                  : undefined
              }
            />
          </div>
        ))}
        <div className="column-title" />
      </div>
      <div
        className="deck-list-row all"
        onClick={() => openDeck('all')}
        role="button"
        tabIndex={0}
        onKeyDown={() => openDeck('all')}
      >
        <span>
          <img
            src="https://cdn-icons-png.flaticon.com/512/29/29302.png"
            style={{ width: '60px' }}
            alt=""
          />
        </span>
        <div className="deck-name">All References</div>
      </div>
      <div className="deck-list-body">
        {decksFetch?.data?.map((deck) => (
          <div
            className="deck-list-row deck"
            disabled={!deck.references_count}
            onClick={() => (deck.references_count ? openDeck(deck.id) : null)}
            role="button"
            tabIndex={0}
            onKeyDown={() => (deck.references_count ? openDeck(deck.id) : null)}
          >
            <img
              src={deck.organization_logo}
              alt={deck.organization_name}
              onError={(e) => {
                e.target.src = blueImage;
              }}
              className="organization-logo"
            />
            <div className="deck-name">{deck.name}</div>
            <div className="media-count">{deck.references_count}</div>
            <div className="rating">
              {deck.ratings_count ? (
                <>
                  {deck.avg_rating_score}{' '}
                  <img src={star} alt="star" className="star-icon" />
                </>
              ) : (
                '—'
              )}
              <br /> ({deck.ratings_count} rates)
            </div>
            <DeckActionsMenu
              deckId={deck.id}
              deckName={deck.name}
              deckOrganizationId={deck.organization_id}
              openDeck={openDeck}
              openDeckDisabled={!deck.references_count}
              setNewDeck={setNewDeck}
            />
          </div>
        ))}
        {!decksFetch.reachedEnd && (
          <div className="deck-list-row more-decks" ref={loadingMsg}>
            Loading more decks...
          </div>
        )}
      </div>
    </StyledAllDecksNavPanel>
  );
};

export default AllDecksNavPanel;
