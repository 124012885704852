import * as Dialog from '@radix-ui/react-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, Textfield } from 'colabs-ui-react';
import React from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0.5rem;
  height: 34px;
  background: none;
  border-radius: 0.5rem;
  border: 1px solid #d3d3d3;
  width: 100%;
  margin-bottom: 0.5rem;
  text-align: left;
  :focus,
  :active {
    outline: 1px solid #d3d3d3;
  }
`;

const DDContent = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 6rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000000000;
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
  &[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 24rem;
  padding: clamp(2rem, 5vw, 1rem);
  display: flex;
  flex-direction: column;
  > .title {
    margin-top: 0;
    font-size: 1.25rem;
    &.delete {
      margin-bottom: 0.5rem;
    }
  }
  > .description {
    margin: 0 0 1rem 0;
  }
  > .cui-textfield {
    margin-bottom: 0.5rem;
  }
  > .cui-dropdown {
    margin-bottom: 0.5rem;
    .select {
      overflow-y: scroll;
      max-height: 14rem;
    }
  }
  > .buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }
  .cui-button {
    text-transform: capitalize;
    align-self: flex-end;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
`;

const NewUserModal = ({
  newUser,
  setNewUser,
  fetchUsers,
  selectedOrganizationId,
}) => {
  const { axiosWithAuth } = useAxioswithAuth();

  const getFormUser = () => ({
    first_name: newUser.first_name?.trim() || undefined,
    last_name: newUser.last_name?.trim() || undefined,
    email: newUser.email?.toLowerCase().trim() || undefined,
    organization_role: newUser.organization_role || undefined,
    phone: newUser.phone?.trim() || undefined,
    profle_pic_url: newUser.profle_pic_url || undefined,
  });

  const createUser = async () => {
    if (!newUser.email) return;
    try {
      await axiosWithAuth().post(
        `/users/in_organization/${selectedOrganizationId}`,
        [getFormUser()]
      );
      await fetchUsers();
    } catch (e) {
      if (e.response?.status === 409) {
        alert('User already exists');
        return;
      }
      alert('Error creating user. Please contact support');
    }
  };

  const deleteUser = async () => {
    try {
      await axiosWithAuth().delete(`/users/${newUser.id}`);
      await fetchUsers();
    } catch (e) {
      alert('Error deleting user. Please contact support');
    }
  };

  const editUser = async () => {
    try {
      await axiosWithAuth().put(`/users/${newUser.id}`, getFormUser());
      await fetchUsers();
    } catch (e) {
      alert('Error updating user. Please contact support');
    }
  };

  const handleConfirm = async () => {
    await {
      create: createUser,
      edit: editUser,
    }[newUser.mode]();
    setNewUser((prev) => ({ ...prev, isModalOpen: false }));
  };

  const updateForm = (e) => {
    setNewUser((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // const updateFormDomValues = () => {
  //   const field = document.querySelector(`#deck-name-textfield`);
  //   if (!field) return;
  //   field.value = newUser.name;
  // };

  // useEffect(() => {
  //   updateFormDomValues();
  // }, [newUser.isModalOpen]);

  return (
    <Dialog.Root
      open={newUser.isModalOpen}
      onOpenChange={(open) => {
        if (open) fetchUsers();
        setNewUser((prev) => ({ ...prev, isModalOpen: open }));
      }}
    >
      <Dialog.Portal>
        <Overlay />
        {newUser.mode === 'delete' ? (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newUser.mode}`}>
              Delete "{newUser.email}"?
            </Dialog.Title>
            <Dialog.Description className="description">
              You will loose all the data associated with this user.
            </Dialog.Description>
            <div className="buttons">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() =>
                  setNewUser((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }))
                }
              />
              <Button
                text="Confirm"
                onClick={async () => {
                  await deleteUser();
                  setNewUser((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }));
                }}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        ) : (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newUser.mode}`}>
              {
                {
                  edit: 'Edit User',
                  create: 'Create New User',
                }[newUser.mode]
              }
            </Dialog.Title>
            {/* profle_pic_url: '', */}
            <span>Organization Role*</span>
            <DropdownMenu.Root>
              <Trigger>{newUser.organization_role}</Trigger>
              <DropdownMenu.Portal>
                <DDContent onClick={(e) => e.stopPropagation()}>
                  {/* viewer - can view and comment resources (creatives, scripts, copylines)
                approver - viewer + approve resources (creatives, scripts, copylines)
                admin - approver + add members, change permissions and payment info */}
                  {['viewer', 'approver', 'admin'].map((role) => (
                    <Item
                      onSelect={() => {
                        setNewUser((prev) => ({
                          ...prev,
                          organization_role: role,
                        }));
                      }}
                    >
                      {role}
                    </Item>
                  ))}
                </DDContent>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
            {[
              { name: 'email', required: true, label: 'Email*' },
              { name: 'first_name', label: 'First Name (not required)' },
              { name: 'last_name', label: 'Last Name (not required)' },
              {
                name: 'phone',
                label: 'Phone (not required)',
                format: '+1 (###) ###-####',
                inputTag: 'number',
              },
            ].map((field) => (
              <Textfield
                id="deck-name-textfield"
                {...field}
                value={newUser[field.name]}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={updateForm}
              />
            ))}
            <Button text="Confirm" onClick={handleConfirm} />
            <Close>
              <X />
            </Close>
          </Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewUserModal;
