/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { useLocalStorage } from './useLocalStorage';

export const useDarkMode = (key, intialValue) => {
  const [darkmode, setDarkmode] = useLocalStorage(key, intialValue);

  const toggleMode = (e) => {
    e.preventDefault();
    setDarkmode(!darkmode);
  };
  useEffect(() => {
    if (darkmode === false) {
      document.querySelector('body').classList.remove('dark-mode');
    } else {
      document.querySelector('body').classList.add('dark-mode');
    }
  }, [darkmode]);
  return [darkmode, toggleMode];
};
