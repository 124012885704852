/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';

const usePin = (pinRef, containerRef, containerClickable = true) => {
  const [pinIsAttached, setPinIsAttached] = useState(false);
  const [pinIsGrabbed, setPinIsGrabbed] = useState(false);

  const cleanUpPin = () => {
    if (pinRef.current) {
      pinRef.current.style.display = 'none';
    }
    setPinIsAttached(false);
  };

  const updateMousePosition = (ev) => {
    pinRef.current.style.top = `${ev.clientY}px`;
    pinRef.current.style.left = `${ev.clientX}px`;
  };

  const handleNewPinClick = (e) => {
    if (pinIsGrabbed) return;
    e.stopPropagation();
    setPinIsGrabbed(true);
  };

  const handleAdContainerClick = (e) => {
    if (!containerClickable) return;
    if (!pinIsGrabbed) {
      pinRef.current.style.position = 'fixed';
      pinRef.current.style.display = 'flex';
      pinRef.current.style.top = `${e.clientY}px`;
      pinRef.current.style.left = `${e.clientX}px`;
    }
    const rect = containerRef.current.getBoundingClientRect();
    const left = (((e.clientX - rect.left) / rect.width) * 100).toFixed(2);
    const top = (((e.clientY - rect.top) / rect.height) * 100).toFixed(2);
    if (left < 0 || left > 100 || top < 0 || top > 100) return;
    setPinIsGrabbed(false);
    setPinIsAttached(true);
    pinRef.current.style.top = `${e.clientY}px`;
    pinRef.current.style.left = `${e.clientX}px`;
  };

  useEffect(() => {
    if (!(containerRef && containerRef.current)) return undefined;
    containerRef.current.addEventListener('click', handleAdContainerClick);
    return () =>
      containerRef?.current?.removeEventListener(
        'click',
        handleAdContainerClick
      );
  });

  useEffect(() => {
    if (!(pinRef && pinRef.current)) return undefined;
    pinRef.current.addEventListener('click', handleNewPinClick);
    return () =>
      pinRef?.current?.removeEventListener('click', handleNewPinClick);
  });

  useEffect(() => {
    if (!pinIsGrabbed) return undefined;
    pinRef.current.style.position = 'fixed';
    pinRef.current.style.display = 'flex';
    window.addEventListener('mousemove', updateMousePosition);
    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, [pinIsGrabbed]);

  return { cleanUpPin, pinIsAttached, pinIsGrabbed, setPinIsGrabbed };
};

export default usePin;
