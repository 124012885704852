import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FetchData } from '../../actions';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import GridContainer from '../GridContainer';
import GridItem from '../GridItem';

const SwitchDiv = styled.div`
  && .MuiFormControlLabel-label {
    width: 100px;
  }
`;

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  console.log(classes, '<=classes');
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles(() => ({
  card: {
    width: 150,
    marginBottom: 16,
    height: 150, // Control height HERE!
    margin: 15,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  publishDiv: {
    position: 'relative',
  },
}));

export default function AdminOrgProposalList({
  proposal,
  setProposal,
  setActiveStep,
}) {
  const data = useSelector((state) => state.data);
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const dispatch = useDispatch();
  const [chosenProposal, setchosenProposal] = React.useState([]);
  const [state, setState] = React.useState(false);

  const handleChange = async (event) => {
    setState(event.target.checked);
  };

  async function handleNoProposal(e, org) {
    e.preventDefault();
    setProposal({ ...org, packages: org.packages.map((e) => JSON.parse(e)) });
  }

  async function handleNoOrg(e, org) {
    // Need to do a Get request to the right proposal as well, can't be a new proposal
    e.preventDefault();
    setProposal({
      ...proposal,
      organization_id: org.id,
      organization_name: org.name,
    });
    const response = await axiosWithAuth().get(`proposals/org/${org.id}`);
    console.log(response, '??');
    if (!response.data.length) {
      setProposal({
        ...proposal,
        organization_id: org.id,
        organization_name: org.name,
        proposal_name: '1',
      });
      if (setActiveStep) {
        setActiveStep(0);
      }
    }
    setchosenProposal(response.data);
  }

  useEffect(() => {
    if (!state) {
      dispatch(FetchData(axiosWithAuthRef.current, `/organizations/nonclient`));
    } else {
      dispatch(FetchData(axiosWithAuthRef.current, `/organizations`));
    }
    console.log(state, '<=state');
  }, [dispatch, axiosWithAuthRef, state]);

  const classes = useStyles();
  function addDefaultsrc(e) {
    e.target.src =
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/480px-No_image_available.svg.png';
  }
  return (
    <GridContainer>
      {!proposal.organization_id ? (
        <>
          <SwitchDiv className={classes.publishDiv} id="proposalOrgSwitch">
            <FormControlLabel
              style={{ position: 'absolute', top: '-30px', left: 20 }}
              control={
                <IOSSwitch
                  checked={state.published}
                  onChange={handleChange}
                  name="active_client"
                />
              }
              label="Active clients"
            />
          </SwitchDiv>
          {Array.isArray(data) &&
            data?.map((org) => {
              return (
                <GridItem
                  style={{ display: 'flex', justifyContent: 'center' }}
                  key={org.name}
                  xs={12}
                  sm={6}
                  md={3}
                  lg={4}
                  onClick={(e) => handleNoOrg(e, org)}
                >
                  <Card className={classes.card}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/480px-No_image_available.svg.png"
                        onError={addDefaultsrc}
                        alt="logo"
                        style={{
                          width: 'auto',
                          maxHeight: '80px',
                          maxWidth: '80px',
                        }}
                      />
                      <h2 style={{ fontSize: '.9rem' }}> {org.name}</h2>
                    </div>
                  </Card>
                </GridItem>
              );
            })}
        </>
      ) : null}

      {proposal.organization_id && !proposal.proposal_name ? (
        <>
          {console.log(chosenProposal, '?')}
          {chosenProposal.length
            ? chosenProposal.map((org) => {
                return (
                  <GridItem
                    key={org.name}
                    style={{ display: 'flex', justifyContent: 'center' }}
                    xs={12}
                    sm={6}
                    md={3}
                    lg={4}
                    onClick={(e) => handleNoProposal(e, org)}
                  >
                    <Card className={classes.card}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <img
                          src={
                            org.logo_url ||
                            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAllBMVEX///8Aark2icgAX7UAY7be6PMAYLXS5fKYudwAZ7gAZbfO3u76/f4AXLTt9fsGbLovgcSArddqoNHM2esAZbGlvt4AWp8pd7MfbakATIaKs9pDg8NalMsAcb2nxuO0z+cOfMMigsUAWp0AYam91eoddLhMjMjp8fgZZ6QMWZYAUZAvgL4RWpIlcasAc7oAV6V8pNJhmM2KPTNXAAAELElEQVR4nO3da3eaQBSFYYlIHLwba5oo0XptTWrj//9zxVy7ulCHwDn76NrvV13SpzPEEQErFcYYY4wxxhhjjLGLa92Q6KaGdr33MIqr5RdVW8kETXupPYpcINFgsPxhgdhOYhFfKpwvr6YGiKtQCBgMht+vrvCjOImkgMFglgrxxJXUHH0bQzhxLeb7EIL3xVpVXogdRRUhlKgjRE5UJSFwFLWEOKKaEEbUE6L2RUUhaBQ1hRiiqhAyUXWFCKKyEDBRtYX6RHWh+kTVF2qPIkCoTEQIdYkQoeq+iBFqjiJIqEhECfUmKkyoRsQJtSYqUKhERAp1JipUqDKKWKEGESxUIKKF8vsiXCg+inihNNGAUHiiWhDKjqIJoSjRhlCSaEQouC9aEcqNohmhGFFW+DOHUGqiygp/5REKEUWF881tLqLIRJUVzvINoghRUphO002uPVFkosoK57O7nMTyR1FU+EJ8esQSZYV74ubu6fetf4/bxVkJU+JwttncebcZ/qmflzAYDObz4XA29Gs2nJ+dcG/MVXh+wpxRSCGF+Cik8MvC+Oi1Ne7z4qnsy6ic99VVKGG8Wx97mWb9XRBnr5ybz75ElDBpn3ih8es1jG534PFmYlvoRqde6P51jOL+pQuDcFFpZmV9lvoLg6iTZOS8LwK0LywahRRSSCGF6b8wCjPyv22DeWHYq2XVWPkSrQvj1YHHvS+LNy98PvB4c+s5iNaFQefAPWkeLmZd6jrjrPxvTmFemE5Ul5Uv8ByEBaOQQgopPC10YZSRv9+8MB49ZFXvXMq61HUPbcj6mqbbPPFCvVPr0qXtden+MOjR2snbMe/pgf+KhvExTDfc/3akevI+Qm6a+YR7z80gv12Lsz66f/T5PHfqCWaFWlFIIYX4UEIXF834qi0Y94s2Nr2miRolbGrhdy9YiNBNS9mWFxA0hq6MTbUNj2HgVovCty9fjPx2RNSnp6j4DcxN/6XRjEIKKcQHW5cWz7YwXHaLtvU8kAFalz5knm+Yr7ofEbMu3ZayLctrmqSUbfl90Y0Rhr11u2jrvmVhEAadogWe5yrw/ZBCCvFRSOEXhXGrcFXTRzHifvF3/NrO8jt+p5RtceUNFAZRGbfj8DyHFvSXJtoW/gSceP6EHY9iSAn1opBCCvHx2zUZYTya3BRt0rW8polPnV3qk+VvuQ+e+JsvLyBqXVrGT4g3DI9h+vHpuVe0vteGgPc2OXr+rE++F6/x/ZBCCvFRSCGF+CikkEJ8FFJIIT4KKaQQH4UUUoiPQgopxEchhRTio5BCCvFRmLd2By36v2rJv39Y8bwKQq9OGWeY/ZvnmUpqRSVP0rSeqT0xHpU9hGk7Q8Rwe+pXl75Ur2pkX3StlQiwUrkeV6tZt8ZVrtWV+gX5fbVrfELjxxhjjDHGGGOMnXl/ASwK93DMPv2HAAAAAElFTkSuQmCC'
                          }
                          onError={addDefaultsrc}
                          alt="logo"
                          style={{
                            width: 'auto',
                            maxHeight: '80px',
                            maxWidth: '80px',
                          }}
                        />
                        <h2 style={{ fontSize: '.9rem' }}>
                          {' '}
                          Version {org.proposal_name}
                        </h2>
                      </div>
                    </Card>
                  </GridItem>
                );
              })
            : null}
          <div style={{ position: 'absolute', bottom: '27%' }}>
            <button
              style={{ position: 'relative', fontVariantLigatures: 'none' }}
              type="button"
              onClick={() =>
                setProposal({ ...proposal, organization_id: null })
              }
            >
              Back to Org List
            </button>
          </div>
        </>
      ) : null}
    </GridContainer>
  );
}
