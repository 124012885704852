import { Button, Card, Modal, Popper, Text } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { Bell, ChevronLeft } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import NotificationItem from './NotificationItem';

const Badge = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  > .icon {
    width: 17.05px;
    height: 18.94px;
  }
  > .badge {
    position: absolute;
    background-color: #fd4646;
    border-radius: 0.5rem;
    height: 1rem;
    width: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -0.42rem;
    top: -0.42rem;
    > .cui-text {
      font-size: 8px;
    }
  }
`;

const StyledNotificationsCard = styled(Card)`
  width: 24rem;
  border: 1px solid #d3d3d3;
  @media ${({ theme }) => theme.breakpoint.sm} {
    width: calc(100vw - 1rem);
  }
  > .notificationsHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.75rem;
    height: 3.2rem;
  }
  > .notifications {
    overflow-y: scroll;
    height: 32rem;
    @media ${({ theme }) => theme.breakpoint.sm} {
      height: calc(100vh - 3.5rem - 3.2rem - 5rem - 1rem);
    }
    &.message {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    ${({ theme }) => theme.scrollbar}
  }
`;

const StyledModal = styled(Modal)`
  top: 3.5rem !important;
  > .modalCard {
    transform: translate(-50%, 0) !important;
  }
`;

const StyledNotificationsPopper = styled(Popper)``;

const NotificationsMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState();
  const [dataFetchingStatus, setDataFetchingStatus] = useState('start');
  const user = useSelector((state) => state.user);
  const { width } = useWindowDimensions();
  const unreadNotificationsCount = notifications?.filter(
    (notification) => !notification.read_at
  )?.length;
  const { push } = useHistory();
  const { axiosWithAuth } = useAxioswithAuth();

  const markAsRead = async (ids) => {
    axiosWithAuth().put(`notifications/markAsRead`, { ids });
  };

  const fetchNotifications = async () => {
    try {
      setDataFetchingStatus('loading');
      const { data } = await axiosWithAuth().get(
        `/notifications/${user.id}/user`
      );
      setNotifications(data);
      setDataFetchingStatus('success');
    } catch (e) {
      setDataFetchingStatus('error');
    }
  };

  const markAllAsRead = async () => {
    try {
      const ids = notifications.map(({ id }) => id).flat();
      await markAsRead(ids);
    } catch (err) {
      console.log(err);
    }
    await fetchNotifications();
  };

  const handleNotificationClick = async (ids, url) => {
    try {
      await markAsRead(ids);
    } catch (err) {
      console.log(err);
    }
    push(url);
  };

  const NotificationsCard = ({ withChevron }) => {
    return (
      <StyledNotificationsCard>
        <div className="notificationsHead">
          {withChevron && (
            <Button
              icon={<ChevronLeft />}
              variant="tertiary"
              onClick={() => setIsOpen(false)}
            />
          )}
          <Text size={4} weight="medium">
            Notifications
          </Text>
          <Button
            text="Mark All as Read"
            variant="secondary"
            onClick={markAllAsRead}
          />
        </div>
        <div
          className={`notifications ${
            notifications?.length === 0 ? 'message' : ''
          } `}
        >
          {notifications?.length === 0 && (
            <Text>
              You don&apos;t have any notifications to show at the moment
            </Text>
          )}
          {notifications
            ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            ?.sort((a, b) => Boolean(a.read_at) - Boolean(b.read_at))
            ?.slice(0, 10)
            ?.map((notification) => (
              <NotificationItem
                key={notification.id[0]}
                notification={notification}
                onClick={handleNotificationClick}
              />
            ))}
        </div>
      </StyledNotificationsCard>
    );
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <Button
        id="notificationsIcon"
        variant="tertiary"
        icon={
          <Badge>
            <Bell className="icon" />
            {unreadNotificationsCount > 0 && (
              <span className="badge">
                <Text size={1} color="white">
                  {unreadNotificationsCount}
                </Text>
              </span>
            )}
          </Badge>
        }
        onClick={() => {
          if (dataFetchingStatus !== 'success') return;
          setIsOpen(!isOpen);
        }}
        size="md"
      />
      {width < 599 && (
        <StyledModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <NotificationsCard withChevron />
        </StyledModal>
      )}
      {width >= 599 && (
        <StyledNotificationsPopper
          isOpen={isOpen}
          parentId="popper"
          anchorSelector="#notificationsIcon"
          onClose={() => {
            setIsOpen(false);
          }}
          closeOnOutsideClick
          xPosition="left"
          yPosition="bottom"
        >
          <NotificationsCard />
        </StyledNotificationsPopper>
      )}
    </>
  );
};

export default NotificationsMenu;
