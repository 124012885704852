import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal, Text, Button } from 'colabs-ui-react';
import { X, Plus } from 'react-feather';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import blueImage from '../../assets/images/BlueImage.png';
import useDragAndDrop from '../../hooks/useDragAndDrop';
// import FileUploader from '../FileUploader';

const StyledUploadMediaModal = styled(Modal)`
  width: 100%;
  > .cui-card {
    max-width: 60rem;
    width: 100%;
    padding: 2rem;
    > .grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      border: 1px dashed #ccc;
      overflow: hidden;
      &.draggingAsset {
        border: 1px solid #1171ff;
      }
      @media ${({ theme }) => theme.breakpoint.lg} {
        grid-template-columns: repeat(4, 1fr);
      }
      @media ${({ theme }) => theme.breakpoint.md} {
        grid-template-columns: repeat(3, 1fr);
      }
      @media ${({ theme }) => theme.breakpoint.sm} {
        grid-template-columns: repeat(2, 1fr);
      }
      > .imageWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        padding: 2rem 0;
        > .dropZone {
          position: absolute;
          height: 100%;
          width: 100%;
          &.left {
            left: -50%;
          }
          &.right {
            right: -50%;
          }
          &.hovered {
            background: #edf5ff;
            border: 1px dashed #1171ff;
          }
        }
      }
      > .input {
        position: relative;
        align-self: center;
        height: 2rem;
        width: 2rem;
        > input {
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 100;
          opacity: 0;
        }
      }
    }
    > .dropZone {
      border: 1px dashed #ccc;
      height: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      :hover {
        cursor: pointer;
      }
      &.dragOver {
        border: 1px solid #1171ff;
        > .cui-text {
          color: #1171ff;
        }
      }
      > input {
        z-index: 100;
        opacity: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

const StyledImage = styled.div`
  height: 8rem;
  width: 8rem;
  border-radius: 0.375rem;
  border: 1px solid #d3d3d3;
  position: relative;
  z-index: 100;
  cursor: grab;
  background-color: white;
  :active {
    cursor: grabbing;
  }
  /* overflow: hidden; */
  > * {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }
  input {
    z-index: 100;
    opacity: 0;
  }
  .deleteButton {
    top: -0.75rem;
    right: -0.75rem;
    padding: 0.125rem 0 !important;
    position: absolute !important;
  }
  > .deletedSign {
    height: 100%;
    width: 100%;
    color: #f75050;
    position: absolute;
    z-index: 10;
  }
  > .cui-media {
    height: 100%;
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 0.5rem;
    &.deleted {
      filter: grayscale(0.8);
    }
  }
`;

const UploadMediaModal = ({
  isOpen,
  setIsOpen,
  addAdAsset,
  adAssets,
  deleteAdAsset,
  reorderAdAssets,
}) => {
  const {
    dragOver,
    setDragOver,
    onDragOver,
    onDragLeave,
    fileDropError,
    setFileDropError,
  } = useDragAndDrop();
  const [assetBeingDragged, setAssetBeingDragged] = useState(false);

  const handlePreview = (files) => {
    if (files && files[0]) {
      Object.values(files).map((file) => {
        if (!['video', 'image'].includes(file.type.split('/')[0])) {
          setFileDropError('Please provide an image file to upload!');
          return undefined;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          addAdAsset(e.target.result, file.name);
        };
        reader.readAsDataURL(file);
        return undefined;
      });
    }
    setDragOver(false);
  };

  return (
    <StyledUploadMediaModal
      isOpen={isOpen}
      onClose={() => setIsOpen(null)}
      withCloseButton
      closeOnOutsideClick
      withBackdrop
    >
      <Text className="title" size={4} weight="medium" gutterBottom>
        Upload Media to this Ad
      </Text>
      {adAssets?.length > 0 ? (
        <div
          className={`grid ${
            typeof assetBeingDragged === 'number' ? 'draggingAsset' : ''
          }`}
        >
          {adAssets
            ?.map((asset, index) => ({ ...asset, index }))
            ?.map(({ url, index, status }) => (
              <div className="imageWrapper">
                {['left', 'right'].map((position) => (
                  <span
                    onDragOver={(e) => {
                      if (typeof assetBeingDragged !== 'number') return;
                      e.preventDefault();
                      e.stopPropagation();
                      e.target.classList.add('hovered');
                    }}
                    onDragLeave={(e) => {
                      if (typeof assetBeingDragged !== 'number') return;
                      e.preventDefault();
                      e.stopPropagation();
                      e.target.classList.remove('hovered');
                    }}
                    onDrop={(e) => {
                      const stepMap = { left: 0, right: 1 };
                      const isSamePosition =
                        assetBeingDragged === index + stepMap[position];
                      e.target.classList.remove('hovered');
                      if (isSamePosition) return;
                      reorderAdAssets(
                        assetBeingDragged,
                        index + stepMap[position]
                      );
                    }}
                    className={`dropZone ${position}`}
                  />
                ))}
                <StyledImage
                  onDragEnd={() => setAssetBeingDragged(null)}
                  onDragStart={() => setAssetBeingDragged(index)}
                >
                  {url.match(/\.(mp4|mpeg|webm|mov)$/) != null ? (
                    <video
                      className={`cui-media ${status}`}
                      muted
                      src={`${url}#t=2`}
                    />
                  ) : (
                    <img
                      className={`cui-media ${status}`}
                      alt="media"
                      src={`${url}`}
                      onError={(e) => {
                        e.target.src = blueImage;
                      }}
                    />
                  )}
                  {status === 'deleted' ? (
                    <X className="deletedSign" />
                  ) : (
                    <Button
                      className="deleteButton"
                      icon={<X />}
                      size="sm"
                      onClick={() => deleteAdAsset(index)}
                      variant="secondary"
                    />
                  )}
                </StyledImage>
              </div>
            ))}
          <div className="input">
            <input
              multiple
              type="file"
              id="file"
              name="file"
              onChange={(e) => handlePreview(e.target.files)}
            />
            <Button variant="secondary" size="sm" icon={<Plus />} />
          </div>
        </div>
      ) : (
        <div
          htmlFor="file"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={(e) => {
            e.preventDefault();
            handlePreview(e.dataTransfer.files);
          }}
          className={`dropZone ${dragOver ? 'dragOver' : ''}`}
        >
          <Text size={5}>
            {!dragOver ? 'Select or Drop your Files here...' : 'Drop here...'}
          </Text>
          <input
            multiple
            type="file"
            id="file"
            name="file"
            onChange={(e) => handlePreview(e.target.files)}
          />
        </div>
      )}
      {/* <FileUploader /> */}
    </StyledUploadMediaModal>
  );
};

UploadMediaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  addAdAsset: PropTypes.func.isRequired,
  adAssets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deleteAdAsset: PropTypes.func.isRequired,
  reorderAdAssets: PropTypes.func.isRequired,
};

UploadMediaModal.defaultProps = {};

export default UploadMediaModal;
