import * as Dialog from '@radix-ui/react-dialog';
import { Button, Checkbox } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { ChevronLeft, X } from 'react-feather';
import styled from 'styled-components';
import CustomSlider from './Slider';

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: clamp(2rem, 5vw, 1rem);
  display: grid;
  grid-gap: 0.75rem;
  grid-template-rows: min-content auto min-content;
  width: calc(100% - 1.5rem);
  max-height: calc(100% - 1.5rem);
  height: fit-content;
  max-width: 24rem;
  overflow-y: auto;
  > .title {
    margin: 0;
    font-size: 1.25rem;
  }
  > .form {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    > .filter {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.25rem;
      > label {
        text-align: left;
      }
      > .ethnicities,
      > .genders {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }
    }
    > .actions {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;
    }
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
  width: fit-content;
  height: fit-content;
  outline: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

function getChanged(obj1, obj2) {
  const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
  return Array.from(keys).reduce((acc, key) => {
    if (obj1[key] !== obj2[key]) {
      if (
        typeof obj1[key] === 'object' &&
        obj1[key] !== null &&
        typeof obj2[key] === 'object' &&
        obj2[key] !== null
      ) {
        if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
          acc[key] = obj2[key];
        }
      } else {
        acc[key] = obj2[key];
      }
    }
    return acc;
  }, {});
}

const defaultFilterValues = {
  age: {
    min: 13,
    max: 90,
  },
  gender: '',
  ethnicity: [],
  height: {
    min: 58,
    max: 82,
  },
  weight: {
    min: 90,
    max: 350,
  },
};

const ethnicities = [
  {
    label: 'White / Caucasian',
    value: 'white',
  },
  {
    label: 'Hispanic or Latino',
    value: 'hispanic',
  },
  {
    label: 'Black or African American',
    value: 'black',
  },
  {
    label: 'Native American or Alaskan Native',
    value: 'native_american',
  },
  {
    label: 'Asian',
    value: 'asian',
  },
  {
    label: 'Pacific Islander / Native Hawaiian',
    value: 'pacific_islander',
  },
  {
    label: 'Middle Eastern or North African',
    value: 'middle_eastern',
  },
];

const genders = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Non-binary/Non-conforming',
    value: 'non_binary',
  },
];

const inchesToFeetString = (inches) =>
  `${Math.floor(inches / 12)}' ${inches % 12}"`;

const ActorFiltersModal = ({
  isOpen,
  setIsOpen,
  fetchAllActors,
  allActorsFetch,
}) => {
  const [filters, setFilters] = useState(defaultFilterValues);

  useEffect(() => {
    if (!isOpen) return;
    setFilters({ ...defaultFilterValues, ...allActorsFetch.filters });
  }, [isOpen]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
      <Dialog.Portal>
        <Overlay />
        <Content onClick={(e) => e.stopPropagation()}>
          <Dialog.Title className="title">Filters</Dialog.Title>
          <div className="form">
            <div className="filter">
              <label>Gender:</label>
              <span className="genders">
                {genders.map((gender) => (
                  <label key={gender.value}>
                    <input
                      type="radio"
                      name="gender"
                      value={gender.value}
                      checked={filters.gender === gender.value}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          gender: e.target.value,
                        }))
                      }
                    />
                    {gender.label}
                  </label>
                ))}
              </span>
            </div>
            <div className="filter">
              <label>Age:</label>
              <CustomSlider
                field="age"
                filters={filters}
                setFilters={setFilters}
                min={defaultFilterValues.age.min}
                max={defaultFilterValues.age.max}
              />
            </div>
            {/* <div className="filter">
              <label>Ethnicity:</label>
              <span className="ethnicities">
                {ethnicities.map((ethnicity) => (
                  <Checkbox
                    key={ethnicity.value}
                    label={ethnicity.label}
                    checked={filters.ethnicity?.includes(ethnicity.value)}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        ethnicity: e.target.checked
                          ? [...prev.ethnicity, ethnicity.value]
                          : filters.ethnicity.filter(
                              (el) => el !== ethnicity.value
                            ),
                      }))
                    }
                  />
                ))}
              </span>
            </div> */}
            <div className="filter">
              <label>Height:</label>
              <CustomSlider
                field="height"
                filters={filters}
                setFilters={setFilters}
                min={defaultFilterValues.height.min}
                max={defaultFilterValues.height.max}
                formatter={inchesToFeetString}
              />
            </div>
            <div className="filter">
              <label>Weight:</label>
              <CustomSlider
                field="weight"
                filters={filters}
                setFilters={setFilters}
                min={defaultFilterValues.weight.min}
                max={defaultFilterValues.weight.max}
                formatter={(lbs) => `${lbs} lb`}
              />
            </div>
            <div className="actions">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() => setIsOpen(false)}
              />
              <Button
                text="Apply"
                onClick={() => {
                  fetchAllActors(
                    null,
                    getChanged(defaultFilterValues, filters)
                  );
                  setIsOpen(false);
                }}
              />
            </div>
          </div>
          <Close>
            <X />
          </Close>
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ActorFiltersModal;
