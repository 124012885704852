export const DATA_START = 'DATA_START';
export const DATA_SUCCESS = 'DATA_SUCCESS';
export const DATA_FAILURE = 'DATA_FAILURE';
export const SET_USER = 'SET_USER';
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const DARKMODE_TOGGLE = 'DARKMODE_TOGGLE';
export const CLEAR_ORG_DATA = 'CLEAR_ORG_DATA';
export const UPDATE_DYNAMIC_REDUCER = 'UPDATE_DYNAMIC_REDUCER';
export const CLEAR_REF_TOOL_DATA = 'CLEAR_REF_TOOL_DATA';
export const UPDATE_ONBOARDING_MODAL_STATE = 'UPDATE_ONBOARDING_MODAL_STATE';

const initialState = {
  user: null,
  data: [],
  documents: [],
  executiveReports: null,
  authenticationStatus: 'start',
  onboardingModalState: {
    isOpen: false,
    currentStep: 0,
  },
  creatives: [],
  darkmode: false,
  menuOpen: true,
  refreshtkn: null,
  error: {},
  isloading: false,
  currentFile: null,
  superAdmin: [
    'jose@coefficientlabs.com',
    'sean@coefficientlabs.com',
    'bruce@coefficientlabs.com',
  ],
  refTool: {
    organizationName: '',
    adsArray: [],
    socialProfilePicture: null,
    tagFilters: [],
    orgsWithDecks: null,
    organizationsList: [],
    creativeDecks: [],
    currentCreativeDeckTags: null,
    selectedAd: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DATA_START:
      return {
        ...state,
        isloading: true,
      };
    case DATA_SUCCESS:
      return {
        ...state,
        isloading: false,
        data: action.payload || state.data,
        refreshtkn: action.refreshtkn || state.refreshtkn,
        documents: action.documents || state.documents,
        executiveReports: action.executiveReports || state.executiveReports,
        creatives: action.creatives || state.creatives,
        user: action.user || state.user,
        currentFile: action.currentFile || state.currentFile,
      };
    case DATA_FAILURE:
      return {
        ...state,
        data: action.payload || state.data,
        error: action.error || state.error,
        refreshtkn: action.refreshtkn || state.refreshtkn,
        isloading: false,
      };
    case UPDATE_ONBOARDING_MODAL_STATE:
      return {
        ...state,
        onboardingModalState: {
          ...state.onboardingModalState,
          ...action.payload,
        },
      };
    case MENU_TOGGLE:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    case DARKMODE_TOGGLE:
      return {
        ...state,
        darkmode: !state.darkmode,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        authenticationStatus: action.authenticationStatus,
      };
    case CLEAR_ORG_DATA:
      return {
        ...state,
        [action.name]: [],
      };
    case UPDATE_DYNAMIC_REDUCER:
      return {
        ...state,
        [action.name]: action.payload,
      };
    case CLEAR_REF_TOOL_DATA:
      return {
        ...state,
        refTool: {
          ...state.refTool,
          selectedAd: null,
        },
      };

    default:
      return state;
  }
};
