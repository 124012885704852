import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from 'colabs-ui-react';
import React from 'react';
import { MoreVertical } from 'react-feather';
import styled from 'styled-components';

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: none;
  background: none;
  border-radius: 0.5rem;
  width: fit-content;
  :focus,
  :active {
    outline: 1px solid #d3d3d3;
  }
`;

const Content = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 6rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
  &[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Separator = styled(DropdownMenu.Separator)`
  border-top: 1px solid #dfdfdf;
`;

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const OrderActionsMenu = ({ order, setNewOrder }) => {
  const filterNewOrderItems = (type) =>
    order.items.filter((product) => product.product_type === type);
  return (
    <>
      <DropdownMenu.Root>
        <Trigger className="decks-actions-menu">
          <Button icon={<MoreVertical />} variant="tertiary" />
        </Trigger>
        <DropdownMenu.Portal>
          <Content
            side="right"
            align="start"
            onClick={(e) => e.stopPropagation()}
          >
            <Item
              onClick={() =>
                setNewOrder({
                  mode: 'edit',
                  id: order.id,
                  errorMessage: '',
                  items: {
                    baseProducts: filterNewOrderItems('base_product').length
                      ? filterNewOrderItems('base_product')
                      : [{}],
                    addOns: filterNewOrderItems('add_on'),
                    discounts: filterNewOrderItems('discount'),
                  },
                  active: order.active,
                  isModalOpen: true,
                })
              }
            >
              Edit
            </Item>
            <Separator />
            <Separator />
            <Item
              onClick={() =>
                setNewOrder({
                  mode: 'delete',
                  id: order.id,
                  items: {
                    baseProducts: filterNewOrderItems('base_product'),
                    addOns: filterNewOrderItems('add_on'),
                    discounts: filterNewOrderItems('discount'),
                  },
                  errorMessage: '',
                  active: order.active,
                  isModalOpen: true,
                })
              }
            >
              Delete
            </Item>
            <Arrow />
          </Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default OrderActionsMenu;
