import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Card, Text } from 'colabs-ui-react';
import { ChevronRight, ChevronLeft, Grid, Maximize } from 'react-feather';
import AdDisplayContainer from './AdDisplayContainer';
import PinIcon from '../../assets/icons/PinIcon';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledAdApprovalDisplay = styled(Card)`
  width: 100%;
  height: 100%;
  min-width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  &.carousel {
    padding: 0 4rem;
    @media ${({ theme }) => theme.breakpoint.md} {
      padding: 0;
    }
  }
  > .change-asset-btn {
    margin: 0 1rem;
    z-index: 1000;
    position: absolute;
    @media ${({ theme }) => theme.breakpoint.sm} {
      margin: 0 0.25rem;
    }
    &.btn-left {
      left: 0;
    }
    &.btn-right {
      right: 0;
    }
  }
  > .filter {
    position: absolute;
    background: #ffffff66;
    height: 100%;
    width: 100%;
    backdrop-filter: brightness(2) blur(10px);
  }
  > .changeViewToggle {
    position: absolute;
    z-index: 100;
    right: 1rem;
    top: 0.5rem;
    > * {
      margin: 0.5rem 0;
    }
  }
  > .adsGrid {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    .cui-media {
      max-height: 15rem;
      margin: 0.5rem;
      max-width: 15rem;
      object-fit: contain;
    }
  }
  > .dots {
    position: absolute;
    bottom: 2.5rem;
    display: flex;
    z-index: 10;
    pointer-events: none;
    > .dot {
      margin: 0 0.5rem;
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 0.5rem;
      z-index: 11;
      background-color: #fff;
      &.selected {
        background-color: #ffa311;
      }
    }
  }
  .loadingStatus {
    z-index: 100;
    padding: 3rem 0;
    @media ${({ theme }) => theme.breakpoint.sm} {
      font-size: 1rem;
    }
  }
  .pinIcon {
    stroke: gray;
    margin-right: 0;
    height: 1.5rem !important;
    /* width: 1.25rem !important; */
    &.active {
      stroke: black;
    }
  }
`;

const AdApprovalDisplay = ({
  lastUpdate,
  ad,
  activeAssetIndex,
  cleanUpPin,
  adContainerRef,
  adPinnedNotes,
  newPinRef,
  setActiveAssetIndex,
  videoRef,
  isViewInGrid,
  setIsViewInGrid,
  internalCommentsShown,
}) => {
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [processedPins, setProcessedPins] = useState();
  const [isShowingPins, setIsShowingPins] = useState(true);
  const { width } = useWindowDimensions();

  const adPins = adPinnedNotes.map((note, index) => ({ ...note, index }));
  const assetPins = adPins.filter(
    ({ top, left, asset_id: assetId }) =>
      top &&
      left &&
      (!assetId || assetId === parseInt(ad?.assets[activeAssetIndex].id, 10))
  );

  const changeAsset = (step) => {
    cleanUpPin();
    const assets = ad?.assets;
    if (step === 1 && assets.length === activeAssetIndex + 1) {
      setActiveAssetIndex(0);
      return;
    }
    if (step === -1 && activeAssetIndex === 0) {
      setActiveAssetIndex(assets.length - 1);
      return;
    }
    setActiveAssetIndex(activeAssetIndex + step);
  };

  const handleTimeUpdate = () => {
    const updatedProcessedPins = assetPins.filter(
      (pin) =>
        videoRef.current.currentTime - 1.5 < pin.time_in_seconds &&
        videoRef.current.currentTime + 1.5 > pin.time_in_seconds
    );
    const processedPinsHaveChanged = (() => {
      if (updatedProcessedPins.length !== processedPins?.length) return true;
      const processedPinsIdsString = processedPins
        .map(({ id }) => id)
        .sort()
        .join(',');
      const updatedProcessedPinsIdsString = updatedProcessedPins
        .map(({ id }) => id)
        .sort()
        .join(',');
      return processedPinsIdsString !== updatedProcessedPinsIdsString;
    })();
    if (!processedPinsHaveChanged) return;
    setProcessedPins(updatedProcessedPins);
  };

  useEffect(() => {
    if (!ad || !adPinnedNotes) return undefined;
    if (
      ad?.assets[activeAssetIndex].url.match(/\.(mp4|mpeg|webm|mov)$/) != null
    ) {
      return setProcessedPins([]);
    }
    if (isViewInGrid && ad?.assets.length > 1) {
      return setProcessedPins(adPins);
    }
    return setProcessedPins(assetPins);
  }, [ad, adPinnedNotes, activeAssetIndex, isViewInGrid]);

  return (
    <StyledAdApprovalDisplay
      style={{ background: `url(${ad?.assets[activeAssetIndex]?.url})` }}
      className={ad?.assets?.length > 1 ? 'carousel' : ''}
    >
      <span className="filter" />
      {ad?.assets?.length > 1 && !isViewInGrid && (
        <>
          <Button
            variant="secondary"
            className="change-asset-btn btn-left"
            onClick={() => changeAsset(-1)}
            icon={<ChevronLeft />}
          />
          <Button
            variant="secondary"
            className="change-asset-btn btn-right"
            onClick={() => changeAsset(1)}
            icon={<ChevronRight />}
          />
        </>
      )}
      {width > 1279 && (
        <span className="changeViewToggle">
          {ad?.assets.length > 1 && (
            <Button
              fullWidth
              onClick={() => setIsViewInGrid((prev) => !prev)}
              icon={isViewInGrid ? <Maximize /> : <Grid />}
              variant="secondary"
            />
          )}
          <Button
            fullWidth
            onClick={() => setIsShowingPins((prev) => !prev)}
            icon={
              <PinIcon className={`pinIcon ${isShowingPins ? 'active' : ''}`} />
            }
            variant="secondary"
          />
        </span>
      )}
      {(() => {
        if (!ad)
          return (
            <Text className="loadingStatus" size={5}>
              Loading...
            </Text>
          );
        if (isViewInGrid && ad?.assets.length > 1)
          return (
            <div className="adsGrid">
              {ad?.assets.map(({ id, url }) => (
                <AdDisplayContainer
                  key={id}
                  isShowingPins={isShowingPins}
                  adContainerRef={adContainerRef}
                  processedPins={processedPins?.filter(
                    (pin) => pin.asset_id === Number(id)
                  )}
                  newPinRef={newPinRef}
                  adPinnedNotes={adPinnedNotes}
                  url={url}
                  handleTimeUpdate={handleTimeUpdate}
                  videoRef={videoRef}
                  internalCommentsShown={internalCommentsShown}
                  lastUpdate={lastUpdate}
                />
              ))}
            </div>
          );
        return (
          <AdDisplayContainer
            adContainerRef={adContainerRef}
            isShowingPins={isShowingPins}
            processedPins={processedPins}
            newPinRef={newPinRef}
            adPinnedNotes={adPinnedNotes}
            url={ad?.assets[activeAssetIndex].url}
            handleTimeUpdate={handleTimeUpdate}
            videoRef={videoRef}
            internalCommentsShown={internalCommentsShown}
            lastUpdate={lastUpdate}
            onMouseOver={() => setMouseIsOver(true)}
            onMouseOut={() => setMouseIsOver(false)}
            onFocus={() => setMouseIsOver(true)}
            onBlur={() => setMouseIsOver(false)}
          />
        );
      })()}
      {ad?.assets?.length > 1 && mouseIsOver && (
        <div className="dots">
          {ad?.assets?.map((a, i) => (
            <div
              onClick={() => setActiveAssetIndex(i)}
              key={a.id}
              onKeyDown={() => false}
              aria-label="carousel-stepper"
              role="button"
              tabIndex={0}
              className={`dot ${activeAssetIndex === i ? 'selected' : ''}`}
            />
          ))}
        </div>
      )}
    </StyledAdApprovalDisplay>
  );
};

AdApprovalDisplay.propTypes = {
  ad: PropTypes.shape().isRequired,
  activeAssetIndex: PropTypes.number.isRequired,
  adContainerRef: PropTypes.shape().isRequired,
  adPinnedNotes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  newPinRef: PropTypes.shape().isRequired,
  setActiveAssetIndex: PropTypes.func.isRequired,
  videoRef: PropTypes.shape().isRequired,
  lastUpdate: PropTypes.instanceOf(Date).isRequired,
  isViewInGrid: PropTypes.bool.isRequired,
  setIsViewInGrid: PropTypes.func.isRequired,
  internalCommentsShown: PropTypes.bool.isRequired,
};

AdApprovalDisplay.defaultProps = {};

export default AdApprovalDisplay;
