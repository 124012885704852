import { Button } from 'colabs-ui-react';
import React, { useState } from 'react';
import { ChevronLeft, Menu } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { fetchUserData } from '../../actions';
import blueImage from '../../assets/images/BlueImage.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import AvatarDropdown from './AvatarDropdown';
import NotificationsMenu from './NotificationsMenu';
import NotificationsSubscriptions from './NotificationsSubscriptions';

const StyledAppbar = styled.div`
  z-index: 9999;
  background-color: #ffffff;
  border-bottom: 1px solid #d3d3d3;
  > .banner {
    background-color: ${({ theme }) => theme.color.primary.main};
    color: #ffffff;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 0.25rem 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    > .container {
      font-size: 14px;
      width: 100%;
      max-width: 1310px;
      text-align: center;
    }
  }
  > .content {
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 1rem 0.25rem;
    @media ${({ theme }) => theme.breakpoint.sm} {
      font-size: 1rem;
    }
    > .logo {
      display: flex;
      align-items: center;
      > * {
        margin: 0 0.2rem;
      }
      > .isologo {
        height: 2.5rem;
        @media ${({ theme }) => theme.breakpoint.sm} {
          height: 1.5rem;
        }
      }
      > .logotype {
        @media ${({ theme }) => theme.breakpoint.sm} {
          display: none;
        }
        font-size: 1.25rem;
        font-family: ${({ theme }) => theme.font.main.light};
        white-space: nowrap;
        text-align: center;
      }
    }
    > .grow {
      flex-grow: 1;
    }
    > .controls {
      min-width: 10rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

const Appbar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const currentStep = useSelector(
    (state) => state.onboardingModalState.currentStep
  );
  const { axiosWithAuth } = useAxioswithAuth();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [
    isNotificationsSettingsModalOpen,
    setIsNotificationsSettingsModalOpen,
  ] = useState();
  const { width } = useWindowDimensions();

  const updateDrawerExpanded = async () => {
    try {
      await axiosWithAuth().put('/users/settings', {
        drawer_expanded:
          user?.settings.drawer_expanded === 'true' ? 'false' : 'true',
      });
      dispatch(fetchUserData());
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledAppbar className="cui-appbar">
      <NotificationsSubscriptions
        isOpen={isNotificationsSettingsModalOpen}
        setIsOpen={setIsNotificationsSettingsModalOpen}
      />
      {user?.organization?.onboarding_step < 3 && (
        <div
          className="banner"
          onClick={() =>
            dispatch({
              type: 'UPDATE_ONBOARDING_MODAL_STATE',
              payload: { isOpen: true },
            })
          }
        >
          <div className="container">
            <span>Finish setting up your account ({currentStep}/3)</span>
          </div>
        </div>
      )}
      <div className="content">
        <Button
          onClick={updateDrawerExpanded}
          icon={<Menu />}
          variant="tertiary"
        />
        <span className="logo">
          {(() => {
            if (!user) {
              return null;
            }
            if (user.organization?.type === 'agency') {
              return (
                <>
                  <img
                    alt="isologo"
                    className="isologo"
                    src={user.organization?.logo_url || blueImage}
                    onError={(e) => {
                      e.target.src = blueImage;
                    }}
                  />
                  <span className="logotype">{user.organization?.name}</span>
                </>
              );
            }
            if (user.organization?.type === 'non-agency') {
              return (
                <>
                  <img
                    alt="isologo"
                    className="isologo"
                    src={
                      user.organization?.agency_logo_url ||
                      'https://colab-platformbucket.s3.us-west-1.amazonaws.com/logos/colabs-square.png'
                    }
                    onError={(e) => {
                      e.target.src = blueImage;
                    }}
                  />
                  <span className="logotype">
                    {user.organization?.agency_name || 'COEFFICIENT LABS'}
                  </span>
                </>
              );
            }
            return (
              <>
                <img
                  alt="isologo"
                  className="isologo"
                  src="https://colab-platformbucket.s3.us-west-1.amazonaws.com/logos/colabs-square.png"
                />
                <span className="logotype">COEFFICIENT LABS</span>
              </>
            );
          })()}
        </span>
        {pathname.includes('/ad-approval/') && width > 960 && (
          <Button
            variant="tertiary"
            icon={<ChevronLeft />}
            text="Back to All Creative Decks"
            onClick={() =>
              push(`${pathname.split('/ad-approval')[0]}/ad-approval`)
            }
          />
        )}
        <span className="grow" />
        <div className="controls">
          {/* <NotificationsMenu /> */}
          <AvatarDropdown />
        </div>
      </div>
    </StyledAppbar>
  );
};

export default Appbar;
