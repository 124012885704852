import * as Dialog from '@radix-ui/react-dialog';
import { Button } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { ChevronLeft, X } from 'react-feather';
import styled from 'styled-components';

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: clamp(2rem, 5vw, 1rem);
  display: grid;
  grid-gap: 0.75rem;
  grid-template-rows: min-content auto min-content;
  width: calc(100% - 1.5rem);
  max-height: calc(100% - 1.5rem);
  height: fit-content;
  max-width: 64rem;
  text-align: center;
  overflow-y: auto;
  > .title {
    margin: 0;
    font-size: 1.25rem;
  }
  > .active-media-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    > .cui-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.5rem;
      z-index: 100;
      &.right {
        left: auto;
        right: 0.5rem;
        transform: translateY(-50%) rotate(180deg);
      }
    }
    > .active-media {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0.5rem;
    }
  }
  > .medias {
    display: flex;
    gap: 0.5rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    > * {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
      height: 4rem;
      width: 4rem;
      cursor: pointer;
      border: 3px solid transparent;
      &[data-selected='true'] {
        border: 3px solid #1171fa;
      }
      :hover {
        opacity: 0.8;
      }
    }
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
  width: fit-content;
  height: fit-content;
  outline: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

const supportedImageTypes = ['.png', '.jpg', '.jpeg', '.gif', '.webp'];
const supportedVideoTypes = ['.mp4', '.mov', '.avi', '.mkv'];

const getMediaType = (fileName) => {
  const ext = fileName
    ?.slice((((fileName || '').lastIndexOf('.') - 1) >>> 0) + 2)
    .toLowerCase();
  if (supportedImageTypes.includes(`.${ext}`)) {
    return 'image';
  }
  if (supportedVideoTypes.includes(`.${ext}`)) {
    return 'video';
  }
  return null;
};

const ActorsMediaModal = ({ actorsMediaModal, setActorsMediaModal }) => {
  const [selectedMediaIdx, setSelectedMediaIdx] = useState(0);
  const { actor } = actorsMediaModal;

  const handleCarouselBtnClicks = (direction) => {
    if (direction === 1) {
      if (selectedMediaIdx === actor?.media?.length - 1) {
        return setSelectedMediaIdx(0);
      }
      return setSelectedMediaIdx((prev) => prev + 1);
    }
    if (selectedMediaIdx === 0) {
      return setSelectedMediaIdx(actor?.media?.length - 1);
    }
    return setSelectedMediaIdx((prev) => prev - 1);
  };

  useEffect(() => {
    setSelectedMediaIdx(0);
  }, [actorsMediaModal.isOpen]);

  const currentMedia = actor?.media[selectedMediaIdx];
  const mediaType = getMediaType(currentMedia?.name);

  return (
    <Dialog.Root
      open={actorsMediaModal.isOpen}
      onOpenChange={() =>
        setActorsMediaModal((prev) => ({ ...prev, isOpen: false }))
      }
    >
      <Dialog.Portal>
        <Overlay />
        <Content onClick={(e) => e.stopPropagation()}>
          <Dialog.Title className="title">{actor?.name}</Dialog.Title>
          <div className="active-media-wrapper">
            <Button
              icon={<ChevronLeft />}
              variant="secondary"
              onClick={() => handleCarouselBtnClicks(-1)}
            />
            <Button
              className="right"
              icon={<ChevronLeft />}
              variant="secondary"
              onClick={() => handleCarouselBtnClicks(1)}
            />
            {mediaType === 'video' ? (
              <video
                className="active-media"
                src={currentMedia?.file?.url}
                controls
              />
            ) : mediaType === 'image' ? (
              <img className="active-media" src={currentMedia?.file?.url} />
            ) : null}
          </div>
          <div className="medias">
            {actor?.media?.map((media, idx) => {
              const type = getMediaType(media?.name);
              return type === 'video' ? (
                <video
                  key={idx}
                  data-selected={idx === selectedMediaIdx}
                  src={media?.file?.url}
                  onClick={() => setSelectedMediaIdx(idx)}
                />
              ) : type === 'image' ? (
                <img
                  key={idx}
                  data-selected={idx === selectedMediaIdx}
                  src={media?.file?.url}
                  onClick={() => setSelectedMediaIdx(idx)}
                />
              ) : null;
            })}
          </div>
          <Close>
            <X />
          </Close>
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ActorsMediaModal;
