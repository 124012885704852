import * as Dialog from '@radix-ui/react-dialog';
import { Button, Textfield } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 24rem;
  padding: clamp(2rem, 5vw, 1rem);
  display: flex;
  flex-direction: column;
  > .title {
    margin-top: 0;
    font-size: 1.25rem;
    &.delete {
      margin-bottom: 0.5rem;
    }
  }
  > .description {
    margin: 0 0 1rem 0;
  }
  > .cui-textfield {
    margin-bottom: 0.5rem;
  }
  > .templates-wrapper {
    margin-bottom: 0.5rem;
    > .templates {
      border: 1px solid #d3d3d3;
      border-radius: 0.5rem;
      padding: 0.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 0.75rem;
      grid-column-gap: 0.5rem;
      height: 16rem;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #dadce0;
      }
      ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
      > .template {
        > .reference {
          aspect-ratio: 1 / 1;
          width: 100%;
          border-radius: 0.5rem;
          object-fit: cover;
          border: 3px solid transparent;
          cursor: pointer;
          margin-bottom: -3px;
          &:hover {
            opacity: 0.8;
          }
          &[data-selected='true'] {
            border: 3px solid #1171fa;
            cursor: default;
            &:hover {
              opacity: 1;
            }
          }
        }
        > .name {
          margin: 0;
          display: block;
          line-height: 1.1;
          text-align: center;
        }
      }
    }
    > .note {
      display: block;
      text-align: center;
    }
  }
  > .cui-dropdown {
    margin-bottom: 0.5rem;
    .select {
      overflow-y: scroll;
      max-height: 14rem;
    }
  }
  > .buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }
  .cui-button {
    text-transform: capitalize;
    align-self: flex-end;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
`;

const NewScriptModal = ({ newScript, setNewScript, fetchScripts }) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const { orderId } = useParams();
  const [templatesFetch, setTemplatesFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  // const loadingMsg = useRef();
  // const isLoadingMsgOnScreen = useOnScreen(loadingMsg, []);
  // const [allReferencesFetch, setAllReferencesFetch] = useState({
  //   fetchState: 'start',
  //   page: 0,
  //   tagFilters: [],
  //   reachedEnd: false,
  //   columnSort: { column: 'references.created_at', order: 'desc' },
  // });

  const fetchTemplates = async () => {
    setTemplatesFetch((prev) => ({
      ...prev,
      fetchState: 'loading',
    }));
    try {
      const { data } = await axiosWithAuth().get(
        `/scripts/templates/${orderId}`
      );
      setTemplatesFetch({
        fetchState: 'success',
        data,
      });
    } catch (err) {
      setTemplatesFetch((prev) => ({
        ...prev,
        fetchState: 'error',
      }));
    }
  };

  // const fetchAllReferences = async (page, tagFilters, column) => {
  //   setAllReferencesFetch((prev) => ({
  //     ...prev,
  //     fetchState: 'loading',
  //   }));
  //   const columnSort = (() => {
  //     if (!column) return allReferencesFetch.columnSort;
  //     if (allReferencesFetch.columnSort.column !== column)
  //       return { column, order: 'desc' };
  //     if (allReferencesFetch.columnSort.order === 'desc')
  //       return { column, order: 'asc' };
  //     return { column: 'references.created_at', order: 'desc' };
  //   })();
  //   const params = {
  //     page: page || allReferencesFetch.page,
  //     columnSort,
  //     tagFilters: tagFilters || allReferencesFetch.tagFilters,
  //   };
  //   try {
  //     const { data: rawData } = await axiosWithAuth().get(
  //       `/reference-tool/references?page=${params.page}${
  //         tagFilters ? `&tag_filters=${tagFilters.join(',')}` : ''
  //       }&column_name=${params.columnSort.column}&column_sort=${
  //         params.columnSort.order
  //       }`
  //     );
  //     const newData = [
  //       ...(page > 1 && allReferencesFetch.data ? allReferencesFetch.data : []),
  //       ...rawData.map((reference) => ({
  //         id: reference.id,
  //         url: reference.url,
  //         platform: reference.platform,
  //         created_at: reference.created_at,
  //         avg_rating: Number(reference.avg_rating),
  //         tags: reference.tags.filter((e) => e.label).map((e) => e.label),
  //       })),
  //     ];
  //     setAllReferencesFetch((prev) => ({
  //       ...prev,
  //       ...params,
  //       fetchState: 'success',
  //       reachedEnd: rawData.length === 0,
  //       data: newData,
  //     }));
  //   } catch (err) {
  //     setAllReferencesFetch((prev) => ({ ...prev, fetchState: 'error' }));
  //   }
  // };

  const createScript = async () => {
    if (!newScript.name) return;
    try {
      await axiosWithAuth().post(`/scripts`, {
        name: newScript.name,
        order_id: orderId,
        template_id: newScript.templateId,
      });
      await fetchScripts();
    } catch (e) {
      console.log(e);
    }
  };

  const deleteScript = async () => {
    try {
      await axiosWithAuth().delete(`/scripts/${newScript.id}`);
      await fetchScripts();
    } catch (e) {
      console.log(e);
    }
  };

  const editScript = async () => {
    try {
      await axiosWithAuth().put(`/scripts/${newScript.id}`, {
        name: newScript.name,
        template_id: newScript.templateId,
      });
      await fetchScripts();
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirm = async () => {
    await {
      create: createScript,
      edit: editScript,
    }[newScript.mode]();
    setNewScript((prev) => ({ ...prev, isModalOpen: false }));
  };

  const updateForm = (e) => {
    setNewScript((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // useEffect(() => {
  //   if (!newScript.isModalOpen) return;
  //   fetchAllReferences(allReferencesFetch.page + 1);
  // }, [newScript.isModalOpen]);

  // useEffect(() => {
  //   if (!isLoadingMsgOnScreen) return;
  //   fetchAllReferences(allReferencesFetch.page + 1);
  // }, [isLoadingMsgOnScreen]);

  // useEffect(() => {
  //   fetchAllReferences(null, newScript.name ? [newScript.name] : null);
  // }, [newScript.name]);

  useEffect(() => {
    if (!orderId) return;
    fetchTemplates();
  }, [orderId]);

  return (
    <Dialog.Root
      open={newScript.isModalOpen}
      onOpenChange={(open) => {
        if (open) fetchScripts();
        setNewScript((prev) => ({ ...prev, isModalOpen: open }));
      }}
    >
      <Dialog.Portal>
        <Overlay />
        {newScript.mode === 'delete' ? (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newScript.mode}`}>
              Delete "{newScript.name}"?
            </Dialog.Title>
            <Dialog.Description className="description">
              You will loose all the data associated with this script.
            </Dialog.Description>
            <div className="buttons">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() =>
                  setNewScript((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }))
                }
              />
              <Button
                text="Confirm"
                onClick={async () => {
                  await deleteScript();
                  setNewScript((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }));
                }}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        ) : (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newScript.mode}`}>
              {
                {
                  edit: 'Edit Script',
                  create: 'Create New Script',
                }[newScript.mode]
              }
            </Dialog.Title>
            {[{ name: 'name', required: true, label: 'Name*' }].map((field) => (
              <Textfield
                id="deck-name-textfield"
                {...field}
                value={newScript[field.name]}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={updateForm}
              />
            ))}
            <div className="templates-wrapper">
              <span className="label">Trends</span>
              <div className="templates">
                {templatesFetch?.data?.map((template) => (
                  <div
                    className="template"
                    onClick={() =>
                      setNewScript((prev) => ({
                        ...prev,
                        templateId: template.id,
                        name: template.name,
                      }))
                    }
                  >
                    <video
                      data-id={template.id}
                      src={`${template.reference}#t=1`}
                      onError={(e) => {
                        e.target.remove();
                      }}
                      data-selected={template.id === newScript.templateId}
                      className="reference"
                    />
                    <span className="name">{template.name}</span>
                  </div>
                ))}
              </div>
            </div>
            <Button text="Confirm" onClick={handleConfirm} />
            <Close>
              <X />
            </Close>
          </Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewScriptModal;
