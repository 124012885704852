import { Button } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { ChevronLeft } from 'react-feather';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CopyPreviewTemplate from '../phoneContainer/CopyPreviewTemplate';
import PhoneMockup from '../phoneContainer/PhoneMockup';

const StyledPhoneContainer = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  @media ${({ theme }) => theme.breakpoint.sm} {
    overflow-y: auto;
  }
  > .backButton {
    margin: 0.5rem;
  }
  > .phoneMockup {
    align-self: center;
    @media ${({ theme }) => theme.breakpoint.sm} {
      align-self: auto;
    }
  }
`;

const PhoneContainer = ({
  shownPanels,
  copyDeck,
  getCopyCharLimit,
  currentPlatform,
  setCurrentPlatform,
  adTextIsExpanded,
  setAdTextIsExpanded,
  selectedCopys,
  setShownPanels,
}) => {
  const { width } = useWindowDimensions();
  return (
    <StyledPhoneContainer>
      {shownPanels.maxSlots === 1 && (
        <Button
          className="backButton"
          text="Back to Copys"
          icon={<ChevronLeft />}
          variant="secondary"
          onClick={() => setShownPanels({ maxSlots: 1, panels: [1] })}
        />
      )}
      {shownPanels.maxSlots !== 1 && <span />}
      <PhoneMockup
        isMobile={width < 599}
        width={document.documentElement.clientWidth - 1.5 * 16} // 1.5rem padding
      >
        <CopyPreviewTemplate
          creative={copyDeck?.creative_image}
          getCopyCharLimit={getCopyCharLimit}
          cta={copyDeck?.cta_text}
          currentPlatform={currentPlatform}
          setCurrentPlatform={setCurrentPlatform}
          adTextIsExpanded={adTextIsExpanded}
          setAdTextIsExpanded={setAdTextIsExpanded}
          selectedCopys={selectedCopys}
          socialProfilePicture={copyDeck?.organization_logo} // we might wanna add a new column in organization table
          socialUsername={copyDeck?.organization_name} // we might wanna add a new column in organization table
        />
      </PhoneMockup>
    </StyledPhoneContainer>
  );
};

PhoneContainer.propTypes = {};

PhoneContainer.defaultProps = {};

export default PhoneContainer;
