import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NewOrganizationModal from '../components/usersAndTeams/NewOrganizationModal';
import OrdersPanel from '../components/usersAndTeams/OrdersPanel';
import OrganizationsNavPanel from '../components/usersAndTeams/OrganizationsNavPanel';
import UsersPanel from '../components/usersAndTeams/UsersPanel';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledUsersAndOrganizations = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: 1.5fr 1fr;
  padding: 10px 0;
  grid-gap: 10px;
  width: 100%;
  max-width: 1310px;
  padding: 0.5rem;
  > :nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
`;

const UsersAndOrganizations = () => {
  const { axiosWithAuth } = useAxioswithAuth();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [newOrganization, setNewOrganization] = useState({
    mode: '', // 'create', 'edit', 'delete'
    id: null,
    name: '',
    logo_url: '',
    type: 'non-agency',
    GA_id: '', // optional
    GSheet_ID: '', // optional
    Exec_Report_Url: '', // optional
    actionState: 'start',
    isModalOpen: false,
  });
  const [organizationsFetch, setOrganizationsFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  const {
    name: organizationName,
    onboarding_step: organizationOnboardingStep,
  } =
    organizationsFetch.data.find(
      (organization) => organization.id === selectedOrganizationId
    ) || {};

  const fetchOrganizations = async () => {
    setOrganizationsFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get('/new-organizations/list');
      const organizations = data?.organizations.map((organization) => ({
        ...organization,
        baseProducts: organization.orders.map((order) => {
          return order.order_items?.find(
            (orderItem) => orderItem.product_type === 'base_product'
          )?.product_name;
        }),
      }));
      setOrganizationsFetch({
        fetchState: 'success',
        data: organizations,
      });
      if (!selectedOrganizationId && organizations.length > 0) {
        setSelectedOrganizationId(data.organizations[0].id);
      }
    } catch (err) {
      setOrganizationsFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  return (
    <MainLayout centered>
      <StyledUsersAndOrganizations>
        <NewOrganizationModal
          newOrganization={newOrganization}
          setNewOrganization={setNewOrganization}
          fetchOrganizations={fetchOrganizations}
        />
        <OrganizationsNavPanel
          organizationsFetch={organizationsFetch}
          selectedOrganizationId={selectedOrganizationId}
          setSelectedOrganizationId={setSelectedOrganizationId}
          setNewOrganization={setNewOrganization}
        />
        <UsersPanel
          organizationName={organizationName}
          selectedOrganizationId={selectedOrganizationId}
        />
        <OrdersPanel
          organizationName={organizationName}
          selectedOrganizationId={selectedOrganizationId}
          fetchOrganizations={fetchOrganizations}
          organizationOnboardingStep={organizationOnboardingStep}
        />
      </StyledUsersAndOrganizations>
    </MainLayout>
  );
};

export default UsersAndOrganizations;
