import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Eye, MessageSquare } from 'react-feather';
import { Card, Text, Button } from 'colabs-ui-react';
import blueImage from '../../assets/images/BlueImage.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledCopyHeader = styled(Card)`
  padding: 1rem;
  margin-bottom: 1rem;
  display: grid;
  background: #fafafd;
  grid-column-gap: 0.5rem;
  grid-template-columns: min-content 1fr min-content min-content;
  grid-template-rows: auto auto;
  @media ${({ theme }) => theme.breakpoint.sm} {
    padding: 0.5rem;
  }
  > .organizationLogo {
    border-radius: 0.625rem;
    width: 3rem;
    height: 3rem;
    object-fit: contain;
  }
  > .title {
    letter-spacing: −0.36px;
    align-self: center;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    @media ${({ theme }) => theme.breakpoint.md} {
      font-size: 1.2rem;
    }
    @media ${({ theme }) => theme.breakpoint.sm} {
      font-size: 1rem;
    }
  }
  > .subtitle {
    grid-column: 2 / 5;
    grid-row: 2 / 3;
    display: flex;
    align-items: center;
    > .date {
      margin-right: 1.5rem;
      @media ${({ theme }) => theme.breakpoint.sm} {
        font-size: 0.75rem;
      }
    }
    > .icon {
      margin-right: 0.25rem;
    }
  }
  > .grow {
    flex-grow: 1;
  }
  .newMessageIcon {
    grid-row: 1 / 2;
    position: relative;
    height: 1.75rem;
    width: 1.75rem;
    > svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 1rem;
      width: 1rem;
    }
    > .badge {
      background-color: #edf5ff;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 1rem;
      height: 0.75rem;
      width: 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const CopyHeader = ({
  organizationLogo,
  round,
  createdAt,
  reviewedBy,
  setShownPanels,
  shownPanels,
  commentsCount,
}) => {
  const { width } = useWindowDimensions();
  return (
    <StyledCopyHeader>
      <img
        src={organizationLogo}
        alt=""
        className="organizationLogo"
        onError={(e) => {
          e.target.src = blueImage;
        }}
      />
      <Text weight="medium" size={4} className="title">
        {round}
      </Text>
      <span className="subtitle">
        <Text className="date">
          Created on:{' '}
          {new Date(createdAt).toLocaleDateString('en-EN', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </Text>
        <Eye className="icon" size="1rem" />
        <Text className="reviewedBy">{reviewedBy?.length}</Text>
      </span>
      {!shownPanels.panels.includes(0) && (
        <Button
          variant="secondary"
          text={width > 959 ? 'Preview Selection' : undefined}
          icon={width <= 959 ? <Eye /> : undefined}
          onClick={() => {
            setShownPanels((prev) => ({
              maxSlots: prev.maxSlots,
              panels: [0, 1].slice(0, prev.maxSlots),
            }));
          }}
        />
      )}
      {!shownPanels.panels.includes(2) && (
        <Button
          variant="secondary"
          icon={
            <span className="newMessageIcon">
              <MessageSquare />
              <span className="badge">
                <Text color="blue" weight="bold" noWrap size={1}>
                  {commentsCount}
                </Text>
              </span>
            </span>
          }
          iconPos="right"
          onClick={() =>
            setShownPanels((prev) => ({
              maxSlots: prev.maxSlots,
              panels: [2, 1, 0].slice(0, prev.maxSlots),
            }))
          }
        />
      )}
    </StyledCopyHeader>
  );
};

CopyHeader.propTypes = {
  organizationLogo: PropTypes.string.isRequired,
  shownPanels: PropTypes.shape().isRequired,
  round: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  reviewedBy: PropTypes.arrayOf(PropTypes.string).isRequired,
  setShownPanels: PropTypes.func.isRequired,
  commentsCount: PropTypes.bool.isRequired,
};

CopyHeader.defaultProps = {};

export default CopyHeader;
