/* eslint-disable import/prefer-default-export */
import { useState } from 'react';

export const useLocalStorage = (key, initialvalue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialvalue;
  });
  const setValue = (value) => {
    // Save state
    setStoredValue(value);
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  return [storedValue, setValue];
};
