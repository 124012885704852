import { Button, Checkbox } from 'colabs-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { Filter, Loader, Save } from 'react-feather';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import useOnScreen from '../../hooks/useOnScreen';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import ActorFiltersModal from './ActorFiltersModal';
import ActorsMediaModal from './ActorsMediaModal';

const inchesToFeetString = (inches) =>
  `${Math.floor(inches / 12)}' ${inches % 12}"`;

const StyledAllActorsPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  overflow: auto;
  > .panel-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .grow {
      flex-grow: 1;
    }
    > .title {
      margin-right: 1rem;
      text-transform: uppercase;
    }
  }
  > .actors {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .actor {
      border-radius: 12px;
      outline: 1px solid #d3d3d3;
      display: grid;
      position: relative;
      box-sizing: border-box;
      &[data-recommended='true'] {
        outline: 3px solid #eab308;
        background-color: #fffbeb;
        > .recommended-label {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          background-color: #eab308;
          color: white;
          padding: 0.25rem 0.5rem;
          border-radius: 0.5rem;
          font-size: 0.75rem;
        }
      }
      &[data-selected='true'] {
        outline: 3px solid #007aff;
        background-color: #edf4ff;
        > .recommended-label {
          background-color: #007aff;
        }
      }
      > .cui-checkbox {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
      }
      > img {
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        /* border-bottom-left-radius: 12px; */
        /* border-bottom-right-radius: 12px; */
        aspect-ratio: 4/5;
      }
      > .info {
        padding: 0.5rem 1rem 1rem 1rem;
        > .name {
          font-size: 1.15rem;
          font-weight: 600;
        }
        > .weight-height {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
        > .media-button {
          margin-top: 0.5rem;
          &.disabled {
            cursor: initial;
            opacity: 0.5;
          }
        }
        > * {
          > .label {
            color: #888;
          }
          > .value {
          }
        }
      }
    }
  }
`;

const AllActorsPanel = ({
  setSelectedActors,
  selectedActors,
  saveActorPicks,
}) => {
  const [allActorsFetch, setAllActorsFetch] = useState({
    fetchState: 'start',
    data: [],
    nextCursor: null,
    filters: {},
    reachedEnd: false,
  });
  const user = useSelector((state) => state.user);
  const isSuperAdmin = user?.organization?.type === 'superadmin';
  const [actorsMediaModal, setActorsMediaModal] = useState({
    isOpen: false,
    actor: null,
  });
  const [savingRecommendationsStatus, setSavingRecommendationsStatus] =
    useState('start');
  const { orderId } = useParams();

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const { axiosWithAuth } = useAxioswithAuth();
  const loadingMsg = useRef();
  const isLoadingMsgOnScreen = useOnScreen(loadingMsg, []);

  const fetchAllActors = async (
    nextCursor = null,
    filters = allActorsFetch.filters
  ) => {
    setAllActorsFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get(`/actors/${orderId}`, {
        params: { next_cursor: nextCursor, filters, order_id: orderId },
      });
      const newData = [
        ...(nextCursor === null ? [] : allActorsFetch.data),
        ...data.actors,
      ];
      setAllActorsFetch({
        fetchState: 'success',
        data: newData,
        nextCursor: data.next_cursor,
        filters,
        reachedEnd: !data.next_cursor,
      });
    } catch (err) {
      setAllActorsFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  useEffect(() => {
    if (!isLoadingMsgOnScreen || allActorsFetch.reachedEnd) return;
    fetchAllActors(allActorsFetch.nextCursor);
  }, [isLoadingMsgOnScreen]);

  return (
    <StyledAllActorsPanel>
      <ActorsMediaModal
        setActorsMediaModal={setActorsMediaModal}
        actorsMediaModal={actorsMediaModal}
      />
      <ActorFiltersModal
        isOpen={isFiltersModalOpen}
        setIsOpen={setIsFiltersModalOpen}
        fetchAllActors={fetchAllActors}
        allActorsFetch={allActorsFetch}
      />
      <div className="panel-header">
        <span className="title">All Actors</span>
        <div className="grow" />
        {isSuperAdmin && (
          <Button
            icon={
              savingRecommendationsStatus === 'loading' ? <Loader /> : <Save />
            }
            text="Save as Recommended"
            disabled={
              savingRecommendationsStatus === 'loading' ||
              selectedActors.length === 0
            }
            onClick={async () => {
              setSavingRecommendationsStatus('loading');
              await saveActorPicks('recommended');
              await fetchAllActors(null, {});
              setSelectedActors([]);
              setSavingRecommendationsStatus('success');
            }}
            variant="secondary"
          />
        )}
        <Button
          icon={<Filter />}
          text={`Filters${
            Object.keys(allActorsFetch.filters).length > 0
              ? ` (${Object.keys(allActorsFetch.filters).length} applied)`
              : ''
          }`}
          onClick={() => setIsFiltersModalOpen(true)}
          variant="secondary"
        />
      </div>
      <div className="actors">
        {allActorsFetch.data.map((actor) => (
          <div
            className="actor"
            key={actor.name}
            data-recommended={actor.recommended}
            data-selected={selectedActors.includes(actor.name)}
          >
            {actor.recommended && (
              <span className="recommended-label">Hand picked for you</span>
            )}
            <Checkbox
              checked={selectedActors.includes(actor.name)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedActors((prev) => [...prev, actor.name]);
                } else {
                  setSelectedActors((prev) =>
                    prev.filter((name) => name !== actor.name)
                  );
                }
              }}
            />
            <img
              src={actor.profile_pic || blueImage}
              onError={(e) => {
                e.target.src = blueImage;
              }}
              alt={`${actor.name}`}
            />
            <div className="info">
              <div className="name">{actor.name}</div>
              <div className="weight-height">
                <span>
                  {actor.height ? inchesToFeetString(actor.height) : '—'}
                </span>
                <span>{actor.weight ? `${actor.weight}lbs` : '—'}</span>
              </div>
              <div>
                <span className="label">Gender:</span>{' '}
                <span className="value">
                  {actor.gender ? actor.gender : '—'}
                </span>
              </div>
              <div>
                <span className="label">Ethnicity:</span>{' '}
                <span className="value">
                  {actor.ethnicity ? actor.ethnicity : '—'}
                </span>
              </div>
              <div>
                <span className="label">Age Range:</span>{' '}
                <span className="value">
                  {actor.age ? `${actor.age - 5} - ${actor.age + 5}` : '—'}
                </span>
              </div>
              <Button
                text="Watch Reel"
                variant="secondary"
                className="media-button"
                size="sm"
                disabled={!actor.media || actor.media?.length === 0}
                onClick={() => setActorsMediaModal({ isOpen: true, actor })}
              />
            </div>
          </div>
        ))}
        {!allActorsFetch.reachedEnd && (
          <div className="more-actors" ref={loadingMsg} />
        )}
        {allActorsFetch.fetchState === 'error' && (
          <div className="error">Error fetching actors</div>
        )}
        {allActorsFetch.fetchState === 'loading' && (
          <div className="loading">Loading actors...</div>
        )}
        {allActorsFetch.fetchState === 'success' &&
          allActorsFetch.data.length === 0 && (
            <div className="no-actors">
              No actors found with the applied filters
            </div>
          )}
      </div>
    </StyledAllActorsPanel>
  );
};

export default AllActorsPanel;
