import { Button, Popper } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Edit2, Folder, Trash2 } from 'react-feather';
import styled from 'styled-components';

const StyledAdCreatorMenu = styled(Popper)`
  .popperCard {
    padding: 0.25rem 0.75rem;
    border: 1px solid #d3d3d3;
    z-index: 10;
    > * {
      margin: 0.5rem 0;
    }
  }
`;

const AdCreatorMenu = ({
  selectedAdIndex,
  setSelectedAdIndex,
  setUploadMediaSelectedAd,
  setAds,
}) => {
  return (
    <StyledAdCreatorMenu
      isOpen={typeof selectedAdIndex === 'number'}
      parentId="popper"
      anchorSelector={`#deckCreatorForm .creative${selectedAdIndex}`}
      withArrow
      yPosition="bottom"
      xPosition="right"
      onClose={() => setSelectedAdIndex(null)}
      closeOnOutsideClick
    >
      <Button icon={<Folder />} variant="secondary" fullWidth text="Move to" />
      <Button
        icon={<Edit2 />}
        variant="secondary"
        fullWidth
        text="Edit"
        onClick={() => {
          setUploadMediaSelectedAd(selectedAdIndex);
          setSelectedAdIndex(null);
        }}
      />
      <Button
        icon={<Trash2 />}
        onClick={() => {
          setAds((prev) => {
            const isAdNew = prev[selectedAdIndex].status === 'new';
            if (isAdNew) {
              return prev.filter((_, i) => i !== selectedAdIndex);
            }
            return prev.map((ad, i) =>
              i === selectedAdIndex ? { ...ad, status: 'deleted' } : ad
            );
          });
          setSelectedAdIndex(null);
        }}
        variant="secondary"
        fullWidth
        text="Delete"
      />
    </StyledAdCreatorMenu>
  );
};

AdCreatorMenu.propTypes = {};

AdCreatorMenu.defaultProps = {};

export default AdCreatorMenu;
