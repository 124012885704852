import * as Dialog from '@radix-ui/react-dialog';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Check, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import OnboardingStep1 from './OnboardingStep1';
import OnboardingStep2 from './OnboardingStep2';
import OnboardingStep3 from './OnboardingStep3';
import OnboardingStep4 from './OnboardingStep4';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100vh;
  max-width: 46rem;
  padding: clamp(2rem, 5vw, 1rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .close-btn {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    > :hover {
      opacity: 0.5;
    }
    > svg {
      opacity: 0.87;
      stroke-width: 1.5px;
      height: 1.25rem;
      width: 1.25rem;
    }
  }
  > .brief-stepper {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
    align-items: center;
    height: 3.25rem;
    padding: 0 0.75rem;
    gap: 1rem;
    width: 100%;
    > .step {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
      &.done {
        > .number {
          background-color: #1171fa;
        }
      }
      &.active {
        opacity: 1;
        > .number {
          background-color: #1171fa;
        }
        > .label {
          color: #1171fa;
        }
      }
      > .number {
        height: 1.375rem;
        width: 1.375rem;
        border-radius: 100rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #888;
        color: white;
        > .check-icon {
          height: 1rem;
          width: 1rem;
        }
      }
    }
    > .line {
      border-bottom: 1px solid #d3d3d3;
      min-width: 1rem;
      flex-grow: 1;
    }
  }
  > .step {
    max-width: 600px;
    width: 100%;
    &.step-1 {
      display: grid;
      grid-template-columns: 16rem 1fr 1fr;
      grid-gap: 1rem;
      > .profilePic {
        grid-row: 1 / 6;
        grid-column: 1 / 2;
        > .avatar {
          width: 100%;
          object-fit: cover;
        }
      }
      .email,
      .position,
      .location,
      .phone {
        grid-column: 2 / 4;
      }
      > .editing-actions {
        grid-column: 1 / 4;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000;
  padding: 0 0.5rem;
`;

const OnboardingModal = () => {
  const { isOpen, currentStep } = useSelector(
    (state) => state.onboardingModalState
  );
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { axiosWithAuth } = useAxioswithAuth();
  const [clientSecret, setClientSecret] = useState('');

  const setOnboardingModalState = (payload) =>
    dispatch({
      type: 'UPDATE_ONBOARDING_MODAL_STATE',
      payload,
    });

  const createSetupIntent = async () => {
    try {
      const { data } = await axiosWithAuth().post('/payments/setup_intents', {
        organization_id: user.organization_id,
      });
      setClientSecret(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!user) return;
    setOnboardingModalState({
      currentStep: user?.organization?.onboarding_step,
    });
  }, [user]);

  useEffect(() => {
    if (currentStep !== 2) return;
    createSetupIntent();
  }, [currentStep]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.body.style.pointerEvents = 'auto';
      }, 1000);
    }
    if (!isOpen && currentStep === 3) {
      window.location.reload();
    }
  }, [isOpen]);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Overlay />
        <Content onClick={(e) => e.stopPropagation()}>
          <button
            type="button"
            className="close-btn"
            onClick={() => setOnboardingModalState({ isOpen: false })}
          >
            <X />
          </button>
          <div className="brief-stepper">
            {[
              'Update Profile Info',
              'Invite Your Team',
              'Add Payment Method',
            ].map((item, index) => (
              <>
                <div
                  key={item}
                  className={`step${(() => {
                    if (currentStep === index) return ' active';
                    if (currentStep > index) return ' done';
                    return '';
                  })()}`}
                >
                  <span className="number">
                    {index < currentStep ? (
                      <Check className="check-icon" />
                    ) : (
                      index + 1
                    )}
                  </span>
                  <span className="label">{item}</span>
                </div>
                {index !== 2 && <div className="line" />}
              </>
            ))}
          </div>
          {
            [
              <OnboardingStep1
                setCurrentStep={(e) =>
                  setOnboardingModalState({ currentStep: e })
                }
                currentStep={currentStep}
              />,
              <OnboardingStep2
                setCurrentStep={(e) =>
                  setOnboardingModalState({ currentStep: e })
                }
                currentStep={currentStep}
              />,
              clientSecret ? (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                    appearance: {
                      hideIcon: false,
                      theme: 'stripe',
                      letiables: {
                        colorPrimary: '#1171ff',
                        colorBackground: 'white',
                        colorText: '#000000',
                      },
                    },
                    loader: 'never',
                    paymentMethodOrder: [
                      'us_bank_account',
                      'card',
                      // 'acss_debit',
                      // 'bancontact',
                      // 'card_present',
                      // 'cashapp',
                      // 'ideal',
                      // 'link',
                      'sepa_debit',
                      // 'sofort',
                    ],
                  }}
                >
                  <OnboardingStep3
                    setCurrentStep={(e) =>
                      setOnboardingModalState({ currentStep: e })
                    }
                    currentStep={currentStep}
                  />
                </Elements>
              ) : (
                <div>Loading...</div>
              ),
              <OnboardingStep4
                setIsOpen={(e) => setOnboardingModalState({ isOpen: e })}
              />,
            ][currentStep]
          }
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default OnboardingModal;
