import { Button, Textfield } from 'colabs-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import S3 from 'react-aws-s3';
import { Loader, Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import defaultAvatar from '../assets/images/default-avatar.png';
import SettingsLayout from '../layouts/Settings';
import { api, axiosUsingAuth } from '../utils/useAxioswithAuth';

const snakeToTitle = (str) =>
  str.replace(/_/g, ' ').replace(/^./, (s) => s.toUpperCase());

const StyledProfilePage = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  > .panel {
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    > .panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      min-height: 3.25rem;
      padding: 0 0.75rem;
      gap: 0.5rem;
      > .title {
        margin-right: 1rem;
      }
      > .cui-textfield {
        flex-grow: 1;
      }
    }
    > .profile-info {
      display: grid;
      grid-template-columns: 16rem 1fr 1fr;
      grid-gap: 1rem;
      padding: 12px;
      > .profilePic {
        grid-row: 1 / 5;
        grid-column: 1 / 2;
        /* height: 75%; */
        width: 100%;
        aspect-ratio: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        justify-self: center;
        position: relative;
        overflow: hidden;
        > .image {
          height: 100%;
        }
        > .fileUpload {
          position: absolute;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          font-size: 1rem;
          bottom: 0;
          height: 100%;
          width: 100%;
          text-align: center;
          /* color: ${({ theme }) => theme.color.primary.main}; */
        }
        input[type='file'] {
          opacity: 0;
          cursor: pointer;
          height: 100%;
          width: 100%;
          z-index: 2;
        }
      }
      > .editing-actions {
        grid-column: 2 / 4;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
    > .description {
      display: block;
      padding: 8px 12px;
    }
    > .password-change {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem;
      padding: 12px;
      > .editing-actions {
        grid-column: 2 / 4;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
  > .panel-item {
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
  }
`;

const ProfilePage = () => {
  const user = useSelector((state) => state.user);
  const profilePicInput = useRef();
  const newImage = useRef();
  const oldImage = useRef();

  const uniqueId = Math.random();
  const [imageUploadState, setImageUploadState] = useState('start');
  const [profileUpdateFetch, setProfileUpdateFetch] = useState({
    hasChanges: false,
    fetchState: 'start',
  });
  const [profileInfo, setProfileInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    position: '',
    location: '',
    phone: '',
  });

  const handlePreview = async ({ target }) => {
    if (target.files && target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        newImage.current.src = e.target.result;
        newImage.current.hidden = false;
        oldImage.current.hidden = true;
      };
      reader.readAsDataURL(target.files[0]);
      try {
        setImageUploadState('loading');
        const ReactS3Client = new S3({
          bucketName: 'colab-platformbucket',
          dirName: user.first_name
            ? `clients/${user.organization.name.replace(
                ' ',
                '-'
              )}/${user.first_name.replace(' ', '-')}-${user.id}/profile_pic`
            : `clients/${user.organization.name.replace(' ', '-')}/${
                user.id
              }/profile_pic`,
          region: 'us-west-1',
          accessKeyId: process.env.REACT_APP_AWS_TOKEN_KEYID,
          secretAccessKey: process.env.REACT_APP_AWS_TOKEN_KEYSECRET,
        });
        const res = await ReactS3Client.uploadFile(
          profilePicInput.current.files[0],
          profilePicInput.current.files[0].name.replace(' ', '-')
        );
        if (!res.status === 204) throw new Error('Error uploading file');
        setProfileInfo((prev) => ({ ...prev, profile_pic_url: res.location }));
        setImageUploadState('success');
      } catch (err) {
        setImageUploadState('error');
      }
    }
  };

  const handleProfileInfoChange = (e) => {
    if (!profileUpdateFetch.hasChanges) {
      setProfileUpdateFetch({
        fetchState: 'start',
        hasChanges: true,
      });
    }
    setProfileInfo({
      ...profileInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleProfileInfoFormSubmit = async (e) => {
    e.preventDefault();
    setProfileUpdateFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      await axiosUsingAuth().put(`/users/${user.id}`, profileInfo);
      setProfileUpdateFetch({ hasChanges: false, fetchState: 'success' });
    } catch (err) {
      setProfileUpdateFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  const fillTextfields = () =>
    setProfileInfo((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
        acc[key] = user[key] || '';
        return acc;
      }, {}),
    }));

  async function sendPwResetInstructions(e) {
    e.preventDefault();
    try {
      await api.post('/auth/reset-password', {
        email: user.email.toLowerCase().trim(),
      });
      alert('Password reset instructions sent to your email');
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    if (!user) return;
    fillTextfields();
  }, [user]);

  return (
    <SettingsLayout>
      <StyledProfilePage>
        <div className="panel">
          <div className="panel-header">
            <span className="title">PROFILE</span>
          </div>
          <form onSubmit={handleProfileInfoFormSubmit} className="profile-info">
            <div className="profilePic">
              <img ref={newImage} hidden className="image" alt="profile" />
              <img
                ref={oldImage}
                className="image"
                src={user?.profile_pic_url || defaultAvatar}
                alt="profile"
              />
              <label htmlFor={uniqueId} className="fileUpload">
                <input
                  type="file"
                  id={uniqueId}
                  ref={profilePicInput}
                  onChange={handlePreview}
                />
              </label>
            </div>
            {[
              { name: 'first_name', placeholder: 'Jackson' },
              { name: 'last_name', placeholder: 'Doe' },
              { name: 'email' },
              { name: 'position', placeholder: 'Chief Marketing Officer' },
              { name: 'location', placeholder: 'Los Angeles' },
              { name: 'phone', placeholder: '(555) 555-1234' },
            ].map((item) => (
              <Textfield
                onChange={handleProfileInfoChange}
                name={item.name}
                label={snakeToTitle(item.name)}
                value={profileInfo[item.name]}
                className={item.name}
                disabled={item.name === 'email'}
                placeholder={
                  item.name === 'email' ? user.email : item.placeholder
                }
              />
            ))}
            <div className="editing-actions">
              <Button
                variant="secondary"
                text="Cancel"
                disabled={!profileUpdateFetch.hasChanges}
                onClick={() => {
                  fillTextfields();
                  setProfileUpdateFetch((prev) => ({
                    ...prev,
                    hasChanges: false,
                  }));
                }}
              />
              <Button
                text={
                  profileUpdateFetch.fetchState === 'success' ? 'Saved' : 'Save'
                }
                type="submit"
                icon={
                  profileUpdateFetch.fetchState === 'loading' ? (
                    <Loader />
                  ) : null
                }
                disabled={
                  !profileUpdateFetch.hasChanges ||
                  ['loading'].includes(profileUpdateFetch.fetchState)
                }
              />
            </div>
          </form>
        </div>
        <div className="panel-item">
          <span className="title">RESET PASSWORD</span>
          <span className="grow" />
          <Button
            onClick={sendPwResetInstructions}
            variant="secondary"
            text="Send email with instructions"
          />
        </div>
      </StyledProfilePage>
    </SettingsLayout>
  );
};

export default ProfilePage;
