import React from 'react';
import styled from 'styled-components';

const StyledSortIcons = styled.span`
  padding: 0;
  margin-left: 0.35rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > .sort-icon {
    scale: 1.25;
  }
  > .sort-icon.down {
    rotate: 0deg;
  }
  > .sort-icon.up {
    rotate: 180deg;
  }
  :not(.asc):not(.desc) {
    gap: 0.05rem;
    > .sort-icon {
      scale: 0.75;
    }
  }
  &.asc > .sort-icon.up {
    display: none;
  }
  &.desc > .sort-icon.down {
    display: none;
  }
`;

const SortIcons = ({ sorting = null }) => {
  return (
    <StyledSortIcons className={`sorting ${sorting}`}>
      {['up', 'down'].map((direction) => (
        <svg
          width="6"
          height="5"
          className={`sort-icon ${direction}`}
          viewBox="0 0 6 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 5L0.401924 0.5L5.59808 0.499999L3 5Z" fill="black" />
        </svg>
      ))}
    </StyledSortIcons>
  );
};

export default SortIcons;
