import React from 'react';
import styled from 'styled-components';
import star from '../../assets/icons/star.svg';
import blueImage from '../../assets/images/BlueImage.png';
import DeckActionsMenu from './DeckActionsMenu';

const StyledLatestDecksPanel = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  margin-left: 1rem;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 0 0.75rem;
    gap: 0.5rem;
    min-height: 3.25rem;
  }
  > .latest-rated-decks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 1rem;
    > .deck-card {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      border: 1px solid #d3d3d3;
      cursor: pointer;
      :hover {
        background-color: #f5f5f5;
      }
      > .deck-card-upper {
        border-bottom: 1px solid #d3d3d3;
        padding: 0.75rem;
        > .deck-card-header {
          display: flex;
          justify-content: space-between;
          gap: 0.5rem;
          margin-bottom: 0.5rem;
          align-items: center;
          > .organization-logo {
            height: 3.75rem;
            width: 3.75rem;
            border: 1px solid #d3d3d3;
            border-radius: 0.5rem;
            overflow: hidden;
            object-fit: cover;
          }
          > .deck-card-name {
            flex-grow: 1;
          }
          > .decks-actions-menu {
            align-self: flex-start;
          }
        }
        > .avg-rating {
          margin-bottom: 0.25rem;
        }
        > .rating-date {
        }
      }
      > .deck-card-lower {
        padding: 0.75rem;
        > .tags-title {
          margin-bottom: 0.25rem;
        }
        > .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 0.25rem;
          > .tag {
            border-radius: 9999px;
            padding: 0.1rem 0.75rem;
            font-size: 14px;
            background-color: #dceaff;
          }
        }
      }
    }
  }
`;

const LatestDecksPanel = ({ latestDecksFetch, openDeck, setNewDeck }) => {
  return (
    <StyledLatestDecksPanel className="latest-decks">
      <div className="panel-header">
        <span className="title">LATEST RATED DECKS</span>
      </div>
      <div className="latest-rated-decks">
        {latestDecksFetch.fetchState === 'success' &&
          latestDecksFetch.data.map((deck) => (
            <div
              className="deck-card"
              onClick={() => openDeck(deck.id)}
              role="button"
              tabIndex={0}
              onKeyDown={() => openDeck(deck.id)}
            >
              <div className="deck-card-upper">
                <div className="deck-card-header">
                  <img
                    src={deck.organization_logo}
                    alt={deck.organization_name}
                    onError={(e) => {
                      e.target.src = blueImage;
                    }}
                    className="organization-logo"
                  />
                  <div className="deck-card-name">{deck.name}</div>
                  <DeckActionsMenu
                    deckId={deck.id}
                    deckName={deck.name}
                    deckOrganizationId={deck.organization_id}
                    openDeck={openDeck}
                    setNewDeck={setNewDeck}
                  />
                </div>
                <div className="avg-rating">
                  Avg. Rating:{' '}
                  {deck.avg_rating_score ? (
                    <>
                      {deck.avg_rating_score}{' '}
                      <img src={star} alt="star" className="star-icon" />
                    </>
                  ) : (
                    '—'
                  )}{' '}
                  ({deck.ratings_count}/{deck.references_count} references
                  rated)
                </div>
                <div className="rating-date">
                  Rated On:{' '}
                  {deck.rated_on
                    ? new Date(deck.rated_on).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : '—'}
                </div>
              </div>
              <div className="deck-card-lower">
                <div className="tags-title">Top Rated Tags</div>
                <div className="tags">
                  {deck.tags.map((tag) => (
                    <div className="tag">{tag}</div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </StyledLatestDecksPanel>
  );
};

export default LatestDecksPanel;
