import * as Popover from '@radix-ui/react-popover';
import React from 'react';
import styled from 'styled-components';
import TagsCard from './TagsCard';

const StyledTrigger = styled(Popover.Trigger)`
  border-radius: 9999px;
  padding: 0.1rem 0.75rem;
  font-size: 14px;
  background-color: #dceaff;
  outline: none;
  border: none;
  cursor: pointer;
  :hover {
    background-color: #cfdbff;
  }
`;

const StyledContent = styled(Popover.Content)`
  z-index: 1000;
`;

const Arrow = styled(Popover.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const ReferenceTagsPopover = ({ activeTags, setActiveTags, referenceId }) => {
  return (
    <Popover.Root>
      <StyledTrigger>+</StyledTrigger>
      <Popover.Portal>
        <StyledContent sideOffset={5} side="right" align="start">
          <TagsCard
            activeTags={activeTags}
            setActiveTags={setActiveTags}
            referenceId={referenceId}
          />
          <Arrow />
        </StyledContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default ReferenceTagsPopover;
