/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { fetchUserData } from '../actions';

const PrivateRoute = ({ children, component, ...routeProps }) => {
  const { authenticationStatus } = useSelector((state) => state);
  const dispatch = useDispatch();

  const Component = children || component;

  useEffect(() => {
    if (authenticationStatus === 'start') {
      dispatch(fetchUserData());
    }
  }, [dispatch, authenticationStatus]);

  if (authenticationStatus === 'loading' || authenticationStatus === 'start') {
    return <div />;
  }

  return (
    <Route
      {...routeProps}
      render={(props) => {
        return authenticationStatus === 'success' ? (
          <Component {...props} {...routeProps} />
        ) : (
          <Redirect to={`/login${window.location.pathname}`} />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.func,
  component: PropTypes.func,
};

PrivateRoute.defaultProps = {
  children: null,
  component: null,
};

export default PrivateRoute;
