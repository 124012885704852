import { Button, Card, Chip, Media, Popper, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Edit2, Send } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { approvalStatus } from '../../staticData';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import DownloadingDeckPopper from './DownloadingDeckPopper';

const phiNumber = 1.618;

const StyledPopper = styled(Popper)`
  .popperCard {
    padding: 0.25rem 0.75rem;
    border: 1px solid #d3d3d3;
    z-index: 10;
    @media ${({ theme }) => theme.breakpoint.sm} {
      transform: translateX(-50%);
      top: calc(100% + 2rem) !important;
      left: initial !important;
      right: initial !important;
      bottom: initial !important;
    }
    > .cui-button {
      margin: 0.5rem 0;
    }
  }
`;

const StyledDeckPreviewCard = styled(Card)`
  border-radius: 0.375rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  background: white;
  @media ${({ theme }) => theme.breakpoint.lg} {
    grid-template-columns: 1fr;
  }
  .preview {
    overflow: hidden;
    border-radius: 0.375rem;
    border: 1px solid #d3d3d3;
    width: 100%;
    height: 26rem;
    @media ${({ theme }) => theme.breakpoint.lg} {
      height: 22rem;
    }
    @media ${({ theme }) => theme.breakpoint.md} {
      height: 18rem;
    }
    @media ${({ theme }) => theme.breakpoint.md} {
      height: 12rem;
    }
    > * {
      width: 100%;
      height: 100%;
    }
    > .fibonacci {
      display: grid;
      grid-template-rows: ${(phiNumber / (phiNumber * phiNumber)) * 100}% ${(1 /
          (phiNumber * phiNumber)) *
        100}%; // using golden ratios
      grid-template-columns: ${(phiNumber / (phiNumber * phiNumber)) * 100}% ${(1 /
          (phiNumber * phiNumber)) *
        100}%; // using golden ratios
      > .cui-media {
        height: 100%;
        width: 100%;
        object-position: left center;
        object-fit: cover;
        :nth-child(1) {
          grid-column: 1 / 2;
          grid-row: 1 / 3;
        }
      }
    }
    > .singleAsset {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-position: center center;
      background-size: cover;
      > .filter {
        position: absolute;
        background: #ffffff66;
        height: 100%;
        width: 100%;
        backdrop-filter: brightness(2) blur(10px);
      }
      > .cui-media {
        z-index: 10;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  > .data {
    @media ${({ theme }) => theme.breakpoint.lg} {
      grid-row: 1/2;
    }
    > .titleCard {
      padding: 1rem;
      margin-bottom: 1rem;
      background-color: #f8f8fa;
      display: grid;
      grid-template-columns: min-content auto min-content;
      grid-template-rows: min-content min-content;
      grid-column-gap: 1rem;
      align-items: center;
      @media ${({ theme }) => theme.breakpoint.md} {
        grid-column-gap: 0.5rem;
      }
      > .organizationPic {
        grid-row: 1 / 3;
        height: 3.5rem;
        width: 3.5rem;
        border-radius: 0.375rem;
        object-fit: contain;
        align-self: start;
      }
      > .title {
        grid-column: 2 / 3;
        display: flex;
        align-items: center;
        @media ${({ theme }) => theme.breakpoint.xl} {
          font-size: 1.25rem;
        }
        @media ${({ theme }) => theme.breakpoint.sm} {
          font-size: 1rem;
        }
      }
      > .updateDate {
        display: flex;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        align-items: center;
        margin-top: 0.1rem;
        @media ${({ theme }) => theme.breakpoint.lg} {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      > .cui-text {
        white-space: pre-line;
      }
    }
    > .actions {
      display: flex;
      margin-top: 2rem;
      > .cui-button {
        font-variant-ligatures: none;
        margin-right: 1rem;
      }
    }
  }
`;

const DeckPreviewCard = ({
  deck,
  type,
  setDeckToEditId,
  fetchCreativeDecks,
  creativeDeckPage,
}) => {
  const { push } = useHistory();
  const location = useLocation();
  const [copyingToGoogleDriveStatus, setCopyingToGoogleDriveStatus] =
    useState('start');
  const { axiosWithAuth } = useAxioswithAuth();
  const { width } = useWindowDimensions();
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const approvalState = approvalStatus.find(
    ({ text }) => text === deck?.approval_state
  );
  const axiosWithAuthRef = useRef(axiosWithAuth);

  const sendToGoogleDrive = async (deckId) => {
    try {
      setCopyingToGoogleDriveStatus('loading');
      await axiosWithAuthRef.current().post(`/cms/sendToGoogleDrive/${deckId}`);
      setCopyingToGoogleDriveStatus('success');
    } catch (err) {
      setCopyingToGoogleDriveStatus('error');
    }
  };

  const updateCreativeDeck = async (id, payload) => {
    try {
      const { data } = await axiosWithAuthRef
        .current()
        .put(`/cms/updateCreativeDeck/${id}`, {
          creative_deck: { ...payload, has_changes: true },
        });
      // perf: should only replace the deck that was updated, not fetch everything again
      await fetchCreativeDecks(creativeDeckPage, 'loading', true);
      // const index = creativeDecks.findIndex((deck) => deck.id === id);
      // setCreativeDecks((prevState) => [
      //   ...prevState.slice(0, index),
      //   data,
      //   ...prevState.slice(index + 1),
      // ]);
      // changeDeckPreview(data, 'creative');
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   if (['start', 'success'].includes(downloadingDeckStatus)) return;
  //   setIsFilesModalOpen(true);
  // }, [downloadingDeckStatus]);

  useEffect(() => {
    if (isActionsOpen || copyingToGoogleDriveStatus !== 'success') return;
    setCopyingToGoogleDriveStatus('start');
  }, [isActionsOpen, copyingToGoogleDriveStatus]);

  return (
    <StyledDeckPreviewCard
      factor={(() => {
        if (width < 959) return 0.75;
        if (width < 1279) return 1;
        return 1.25;
      })()}
    >
      <div className="preview">
        {(() => {
          if (!deck) {
            return <div />;
          }
          if (deck?.assets?.length >= 3) {
            return (
              <div className="fibonacci">
                {deck.assets.slice(0, 3).map(({ url, alt }) => (
                  <Media
                    onError={(e) => {
                      e.target.src = blueImage;
                    }}
                    src={url}
                    alt={alt}
                    key={url}
                    srcSuffix="#t=2"
                  />
                ))}
              </div>
            );
          }
          return (
            <div
              className="singleAsset"
              style={{
                backgroundImage: `url(${deck.assets[0]?.url})`,
              }}
            >
              <span className="filter" />
              <Media
                onError={(e) => {
                  e.target.src = blueImage;
                }}
                src={deck.assets[0]?.url}
                alt={deck.assets[0]?.alt}
                key={deck.assets[0]?.url}
                srcSuffix="#t=2"
              />
            </div>
          );
        })()}
      </div>
      <div className="data">
        <Card className="titleCard">
          <Media
            className="organizationPic"
            src={`${deck?.organization_logo}`}
            alt=""
            onError={(e) => {
              e.target.src = blueImage;
            }}
          />
          <Text size={5} weight="medium" className="title">
            {deck?.title}
          </Text>
          <Text weight="light" className="updateDate">
            Updated:{' '}
            {deck?.updated_at
              ? new Date(deck?.updated_at).toLocaleDateString('en-EN', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                })
              : ''}
          </Text>
          {width < 599 ? (
            approvalState?.icon
          ) : (
            <Chip
              label={approvalState?.text}
              icon={approvalState?.icon}
              color={approvalState?.chipColor}
              variant="primary"
            />
          )}
          {/* <span
            className="approvalState"
            style={{
              backgroundColor: approvalStatus.find(
                ({ text }) => text === deck?.approval_state
              )?.color,
            }}
          >
            <Text size={2} weight="medium" color="#fff" noWrap>
              {deck.approval_state}
            </Text>
          </span> */}
          {/* <div className="reviewsAndApprovals">
          {[
            { label: 'Reviewed By', property: 'reviewed_by' },
            { label: 'Approved By', property: 'approved_by' },
          ].map(({ label, property }) => (
            <div className="">
              <Text size={3} weight="bold">
                {label}
              </Text>
              <div className="stackedAvatars">
                {deck?.ads[activeAdIndex][property].length ===
                0 ? (
                  <Text>None</Text>
                ) : (
                  deck?.ads[activeAdIndex][property]
                    .slice(
                      0,
                      deck?.ads[activeAdIndex][property].length <=
                        4
                        ? 4
                        : 3
                    )
                    .map((avatar, index) => (
                      <StyledAvatar
                        index={index}
                        onMouseEnter={(e) => {
                          setOverlayValue(avatar.email);
                          setAvatarRef(e.target);
                        }}
                        onMouseLeave={() => {
                          setOverlayValue('');
                          setAvatarRef(null);
                        }}
                      >
                        <Avatar picture={avatar.profile_pic_url} alt="" />
                      </StyledAvatar>
                    ))
                )}
                {deck?.ads[activeAdIndex][property].length >
                  4 && (
                  <StyledAvatar index={3}>
                    <div className="more">
                      <Text>
                        +
                        {deck?.ads[activeAdIndex][property]
                          .length - 3}
                      </Text>
                    </div>
                  </StyledAvatar>
                )}
              </div>
            </div>
          ))}
        </div> */}
        </Card>
        {deck?.description &&
          deck.description.split('\n').map((p) => (
            <Text key={p} gutterBottom>
              {p}
            </Text>
          ))}
        <div className="actions">
          <Button
            text={`${
              deck?.approval_state === 'Pending Approval' ? 'Review' : 'Open'
            } Deck`}
            variant="primary"
            size="md"
            onClick={() => push(`${location.pathname}/${type}/${deck?.id}`)}
          />
          {/* {user?.organization?.id === 1 &&
            type === 'creative' &&
            !deck.downloaded_at && (
              <Button
                variant="secondary"
                text={
                  deck.is_ready_for_launch
                    ? 'Set as Not Ready for Launch'
                    : 'Set as Ready for Launch'
                }
                size="md"
                onClick={() =>
                  updateCreativeDeck({
                    is_ready_for_launch: !deck.is_ready_for_launch,
                  })
                }
              />
            )} */}
          {/* {user?.organization?.id === 1 &&
            type === 'creative' &&
            deck.is_ready_for_launch && (
              <Button
                variant="secondary"
                text={
                  downloadingDeckStatus === 'loading'
                    ? 'Downloading...'
                    : 'Download'
                }
                disabled={downloadingDeckStatus === 'loading'}
                icon={
                  downloadingDeckStatus === 'loading' ? (
                    <Loader />
                  ) : (
                    <Download />
                  )
                }
                size="md"
                onClick={async () => {
                  await downloadCreativeDeck();
                  await updateCreativeDeck({
                    downloaded_at: new Date().toISOString(),
                  });
                }}
              />
            )} */}
          {user?.organization?.id === 1 && type === 'creative' && (
            <>
              <Button
                id="actions"
                text="Actions"
                variant="secondary"
                size="md"
                onClick={() => setIsActionsOpen(true)}
              />
              <StyledPopper
                isOpen={isActionsOpen}
                parentId="popper"
                anchorSelector="#actions"
                yPosition="bottom"
                id="newPopperAAA"
                xPosition="right"
                onClose={() => setIsActionsOpen(false)}
                closeOnOutsideClick
              >
                {deck.ready_for_launch_since && (
                  <>
                    <DownloadingDeckPopper
                      updateCreativeDeck={updateCreativeDeck}
                      deck={deck}
                    />
                    <Button
                      icon={<Send />}
                      text={(() => {
                        if (copyingToGoogleDriveStatus === 'loading') {
                          return 'Copying to Google Drive...';
                        }
                        if (copyingToGoogleDriveStatus === 'success') {
                          return 'Finished Copying!';
                        }
                        if (copyingToGoogleDriveStatus === 'error') {
                          return 'Error Copying';
                        }
                        return 'Copy to Google Drive';
                      })()}
                      variant="secondary"
                      fullWidth
                      disabled={['success', 'loading'].includes(
                        copyingToGoogleDriveStatus
                      )}
                      onClick={() => sendToGoogleDrive(deck.id)}
                    />
                  </>
                )}
                {!deck.downloaded_at && (
                  <Button
                    variant="secondary"
                    fullWidth
                    text={
                      deck.ready_for_launch_since
                        ? 'Set as Not Ready for Launch'
                        : 'Set as Ready for Launch'
                    }
                    onClick={() =>
                      updateCreativeDeck(deck.id, {
                        ready_for_launch_since: deck.ready_for_launch_since
                          ? null
                          : new Date().toISOString(),
                      })
                    }
                  />
                )}
                {
                  <Button
                    icon={<Edit2 />}
                    text="Edit Deck"
                    fullWidth
                    variant="secondary"
                    onClick={() => {
                      setDeckToEditId(deck.id);
                      setIsActionsOpen(false);
                    }}
                  />
                }
              </StyledPopper>
            </>
          )}
        </div>
      </div>
    </StyledDeckPreviewCard>
  );
};

DeckPreviewCard.propTypes = {
  deck: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
  setDeckToEditId: PropTypes.number.isRequired,
};

export default DeckPreviewCard;
