import { Button } from 'colabs-ui-react';
import React, { useEffect, useMemo, useState } from 'react';
// import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import ActorsBottomBarMessage from '../components/brief/ActorsBottomBarMessage';
import AllActorsPanel from '../components/brief/AllActorsPanel';
import BriefBars from '../components/brief/BriefBars';
import CompletePanel from '../components/brief/CompletePanel';
import FinishedMeetingModal from '../components/brief/FinishedMeetingModal';
import QuestionsMainPanel from '../components/brief/QuestionsMainPanel';
import ScriptMainPanel from '../components/brief/ScriptMainPanel';
import ScriptsBottomBarMessage from '../components/brief/ScriptsBottomBarMessage';
import ScriptsNavPanel from '../components/brief/ScriptsNavPanel';
import TemplatesPanel from '../components/brief/TemplatesPanel';
import TrendsBottomBarMessage from '../components/brief/TrendsBottomBarMessage';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const Brief = () => {
  const { axiosWithAuth } = useAxioswithAuth();
  const { push } = useHistory();
  const { orderId, briefStep } = useParams();
  // const user = useSelector((state) => state.user);
  const [brief, setBrief] = useState({});
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [meetingModalType, setMeetingModalType] = useState(false);
  const [selectedActors, setSelectedActors] = useState([]);
  const [trendsCollapsed, setTrendsCollapsed] = useState(false);
  const briefStepOptions = [
    'start-booked',
    'trends',
    'scripts',
    'actors',
    'complete',
  ];
  const briefStepIdx = briefStepOptions.indexOf(briefStep);

  const getBriefStatus = async () => {
    try {
      const { data } = await axiosWithAuth().get(`/orders/${orderId}/status`);
      const currentStep = Number(data.status[0]);
      if (!briefStep) {
        push(
          `${window.location.pathname}/${briefStepOptions[currentStep - 1]}`
        );
      }
      const isWrongStep = !briefStepOptions.includes(briefStep);
      const isUnauthorizedStep =
        currentStep !== briefStep &&
        !(
          ['complete', 'actors'].includes(currentStep) &&
          ['scripts', 'actors'].includes(briefStep)
        );
      if (isWrongStep || isUnauthorizedStep) {
        push(
          window.location.pathname.replace(
            briefStep,
            briefStepOptions[currentStep - 1]
          )
        );
      }
      setBrief(data);
    } catch (error) {
      alert('Error getting brief status');
    }
  };

  // trends state
  const [allTemplatesFetch, setAllTemplatesFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  const [selectedTemplatesIds, setSelectedTemplatesIds] = useState([]);
  const [activeTemplateIdx, setActiveTemplateIdx] = useState(0);

  // scripts state
  const [isEditing, setIsEditing] = useState(false);
  const [activeScriptId, setActiveScriptId] = useState();
  const [scriptsFetch, setScriptsFetch] = useState({
    fetchState: 'start',
    data: [],
    page: 0,
    columnSort: { column: 'decks.name', order: 'asc' },
  });

  const fetchScripts = async () => {
    setScriptsFetch(({ data }) => ({ fetchState: 'loading', data }));
    try {
      const { data } = await axiosWithAuth().get(`/scripts/${orderId}`);
      setScriptsFetch({ fetchState: 'success', data });
      if (
        data.every(({ status }) => ['approved', 'completed'].includes(status))
      ) {
        setNextBtnDisabled(false);
      }
    } catch (e) {
      setScriptsFetch(({ data }) => ({ fetchState: 'error', data }));
    }
  };

  const generateScripts = async () => {
    setMeetingModalType('REVIEW_MEETING');
    setNextBtnDisabled(true);
    try {
      await axiosWithAuth().post('/scripts/generate', {
        order_id: orderId,
        template_ids: selectedTemplatesIds,
      });
      console.log('success');
    } catch (err) {
      console.log(err);
    }
  };

  const saveActorPicks = async (status) => {
    try {
      setNextBtnDisabled(true);
      await axiosWithAuth().post('/actors/picks', {
        order_id: orderId,
        actor_names: selectedActors,
        status,
      });
      if (status === 'final') {
        push(window.location.pathname.replace(briefStep, 'complete'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!selectedActors.length) return;
    setNextBtnDisabled(false);
  }, [selectedActors]);

  useEffect(() => {
    if (!orderId) return;
    getBriefStatus();
  }, [orderId]);

  if (!brief.status) return null;

  return (
    <MainLayout centered>
      <FinishedMeetingModal
        meetingModalType={meetingModalType}
        setMeetingModalType={setMeetingModalType}
      />
      {briefStep === 'start-booked' && (
        <BriefBars
          currentStep={briefStepIdx}
          briefOrganizationName={brief.organization_name}
          nextBtnProps={{
            disabled: nextBtnDisabled,
            onClick: () =>
              push(window.location.pathname.replace(briefStep, 'trends')),
          }}
        >
          <QuestionsMainPanel setNextBtnDisabled={setNextBtnDisabled} />
        </BriefBars>
      )}
      {briefStep === 'trends' && (
        <BriefBars
          briefOrganizationName={brief.organization_name}
          mainWrapperProps={{
            style: {
              gridTemplateColumns: trendsCollapsed ? '1fr 2fr' : '2fr 1fr',
            },
          }}
          bottomBarMessage={
            <TrendsBottomBarMessage
              selectedTemplatesIds={selectedTemplatesIds}
            />
          }
          currentStep={briefStepIdx}
          nextBtnProps={{
            disabled: nextBtnDisabled,
            onClick: generateScripts,
          }}
        >
          <TemplatesPanel
            allTemplatesFetch={allTemplatesFetch}
            setAllTemplatesFetch={setAllTemplatesFetch}
            setTrendsCollapsed={setTrendsCollapsed}
            trendsCollapsed={trendsCollapsed}
            selectedTemplatesIds={selectedTemplatesIds}
            setSelectedTemplatesIds={setSelectedTemplatesIds}
            activeTemplateIdx={activeTemplateIdx}
            setNextBtnDisabled={setNextBtnDisabled}
            setActiveTemplateIdx={setActiveTemplateIdx}
          />
          <video
            alt="template video"
            className="templates-reference"
            src={allTemplatesFetch.data[activeTemplateIdx]?.reference}
            controls
          />
        </BriefBars>
      )}
      {briefStep === 'scripts' && (
        <BriefBars
          briefOrganizationName={brief.organization_name}
          mainWrapperProps={{ style: { gridTemplateColumns: '1fr 3fr' } }}
          currentStep={briefStepIdx}
          nextBtnProps={{
            disabled: nextBtnDisabled,
            onClick: () => {
              push(window.location.pathname.replace(briefStep, 'actors'));
              setNextBtnDisabled(true);
            },
          }}
          bottomBarMessage={
            <>
              <ScriptsBottomBarMessage scriptsFetch={scriptsFetch} />
              <Button
                onClick={() => setMeetingModalType('APPROVAL_MEETING')}
                variant="secondary"
                text="Schedule a Meeting"
              />
            </>
          }
        >
          <ScriptsNavPanel
            scriptsFetch={scriptsFetch}
            activeScriptId={activeScriptId}
            setActiveScriptId={setActiveScriptId}
            fetchScripts={fetchScripts}
            setNextBtnDisabled={setNextBtnDisabled}
            isEditing={isEditing}
          />
          <ScriptMainPanel
            currentScript={scriptsFetch.data.find(
              ({ id }) => activeScriptId === id
            )}
            fetchScripts={fetchScripts}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </BriefBars>
      )}
      {briefStep === 'actors' && (
        <BriefBars
          briefOrganizationName={brief.organization_name}
          currentStep={briefStepIdx}
          nextBtnProps={{
            disabled: nextBtnDisabled,
            onClick: () => saveActorPicks('final'),
          }}
          bottomBarMessage={
            <ActorsBottomBarMessage selectedActors={selectedActors} />
          }
        >
          <AllActorsPanel
            setSelectedActors={setSelectedActors}
            selectedActors={selectedActors}
            saveActorPicks={saveActorPicks}
          />
        </BriefBars>
      )}
      {briefStep === 'complete' && (
        <BriefBars
          briefOrganizationName={brief.organization_name}
          currentStep={briefStepIdx}
          nextBtnProps={{ disabled: true }}
        >
          <CompletePanel />
        </BriefBars>
      )}
    </MainLayout>
  );
};

export default Brief;
