import * as Popover from '@radix-ui/react-popover';
import { Button, Divider, Text } from 'colabs-ui-react';
import React, { useState } from 'react';
import { Clock, MessageSquare, X } from 'react-feather';
import styled from 'styled-components';
import PinIcon from '../../assets/icons/PinIcon';
import CommentTextfield from '../copys/CommentTextField';
import CopyComment from '../copys/CopyComment';
import Pin from '../Pin';

const StyledTrigger = styled(Popover.Trigger)`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const StyledCommentsPanel = styled(Popover.Content)`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #d3d3d3;
  min-height: 20rem;
  max-height: calc(100vh - 12rem);
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  z-index: 1;
  width: 100vw;
  max-width: 395px;
  > .commentsBody {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.scrollbar}
    > .noCommentsMsg {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  > .commentTextfield {
    padding: 1rem;
  }
  .cui-divider {
    border: none !important;
    border-top: 1px solid transparent !important;
    background: #e0e0e0 !important;
  }
`;

const CommentsPanel = ({
  commentsCount,
  fetchComments,
  commentsFetch,
  editComment,
  likeComment,
  deleteComment,
  postComment,
  sendDisabled,
  textfieldPlaceholder,
  pinIsAttached,
  cleanUpPin,
  pins,
}) => {
  const [isAttachedPinHovered, setIsAttachedPinHovered] = useState(false);

  const handleOpenChange = async (open) => {
    if (!open) return;
    await fetchComments();
  };

  return (
    <Popover.Root onOpenChange={handleOpenChange}>
      <StyledTrigger>
        <Button
          variant="secondary"
          text={`Comments (${commentsCount || 0})`}
          icon={<MessageSquare />}
        />
      </StyledTrigger>
      <StyledCommentsPanel
        sideOffset={5}
        className="commentsWrapper"
        align="end"
      >
        <div className="commentsBody">
          {commentsFetch.status === 'loading' && (
            <div className="noCommentsMsg">
              <Text>Loading Comments...</Text>
            </div>
          )}
          {commentsFetch.status === 'error' && (
            <div className="noCommentsMsg">
              <Text>Failed to Load Comments</Text>
            </div>
          )}
          {commentsFetch.status === 'success' &&
            commentsFetch.data?.length === 0 && (
              <div className="noCommentsMsg">
                <Text>No Comments in Current Script</Text>
              </div>
            )}
          {commentsFetch.status === 'success' &&
            commentsFetch.data?.length > 0 &&
            commentsFetch.data?.map((comment) => (
              <CopyComment
                key={comment.id}
                editComment={editComment}
                comment={comment}
                likeComment={likeComment}
                deleteComment={deleteComment}
                onTextfieldCancel={() => {}}
                postComment={postComment}
                // commentAttachment={(() => {
                //   const pinIndex = pins?.findIndex((pin) => {
                //     return pin.comment_id === comment.id;
                //   });
                //   if (pinIndex < 0) return undefined;
                //   return (
                //     <Pin
                //       number={`${pinIndex + 1}${` — ${new Date(
                //         pins[pinIndex]?.time_in_seconds * 1000
                //       )
                //         .toUTCString()
                //         .split(' ')[4]
                //         .substring(3)}`}`}
                //       onClick={() => {
                //         if (
                //           creativeMediaEl?.tagName !== 'VIDEO' ||
                //           !pins[pinIndex]?.time_in_seconds
                //         )
                //           return;
                //         creativeMediaEl.currentTime =
                //           pins[pinIndex].time_in_seconds;
                //       }}
                //       className={`${
                //         pins[pinIndex]?.time_in_seconds ? 'timestamp' : ''
                //       }${(() => {
                //         if (pins[pinIndex]?.is_internal) return ' internal';
                //         if (pins[pinIndex]?.user_organization_id === 1)
                //           return ' colabs';
                //         return '';
                //       })()}`}
                //     />
                //   );
                // })()}
              />
            ))}
        </div>
        <Divider />
        <div className="commentTextfield">
          <CommentTextfield
            postComment={postComment}
            sendDisabled={sendDisabled}
            commentAttachments={
              pinIsAttached ? (
                <Pin
                  number={
                    isAttachedPinHovered ? (
                      <X size="0.9rem" color="#1171FA" />
                    ) : (
                      <Text size={1} color="blue">
                        {(pins?.length || 0) + 1}
                      </Text>
                    )
                  }
                  onMouseEnter={() => setIsAttachedPinHovered(true)}
                  onMouseLeave={() => setIsAttachedPinHovered(false)}
                  onClick={cleanUpPin}
                />
              ) : (
                ''
              )
            }
            placeholder={textfieldPlaceholder}
          />
        </div>
      </StyledCommentsPanel>
    </Popover.Root>
  );
};

export default CommentsPanel;
