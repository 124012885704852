import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Appbar from '../components/mainLayout/Appbar';
import Drawer from '../components/mainLayout/Drawer';
import NavBar from '../components/mainLayout/NavBar';
import OnboardingModal from '../components/paymentSetup/OnboardingModal';
import { useDarkMode } from '../hooks/useDarkMode';
import useWindowDimensions from '../hooks/useWindowDimensions';

const StyledMainLayout = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  @media ${({ theme }) => theme.breakpoint.sm} {
    display: flex;
    flex-direction: column;
  }
  > .cui-appbar {
    grid-column: 1 / 3;
    @media ${({ theme }) => theme.breakpoint.sm} {
      position: fixed;
      top: 0;
      width: 100vw;
    }
  }
  > main {
    background: #f5f5f5;
    overflow: auto;
    @media ${({ theme }) => theme.breakpoint.sm} {
      margin-top: 3rem;
    }
    ${({ centered }) =>
      centered ? 'display: flex; justify-content: center' : ''}
  }
  > .navBarSpacing {
    height: 5rem;
  }
`;

const MainLayout = ({ children, centered }) => {
  const [darkMode, toggleMode] = useDarkMode('dark', false);
  const onboardingStep = useSelector(
    (state) => state.user?.organization?.onboarding_step
  );
  const { width } = useWindowDimensions();
  return (
    <StyledMainLayout centered={centered}>
      {onboardingStep < 3 && <OnboardingModal />}
      <Appbar darkMode={darkMode} toggleMode={toggleMode} />
      {width > 600 ? (
        <Drawer darkMode={darkMode} toggleMode={toggleMode} />
      ) : (
        <NavBar />
      )}
      <main>{children}</main>
      {width < 600 && <div className="navBarSpacing" />}
    </StyledMainLayout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  centered: PropTypes.bool,
};

MainLayout.defaultProps = {
  children: null,
  centered: false,
};

export default MainLayout;
