import React, { useState } from 'react';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import MediaControls from '../phoneContainer/MediaControls';

const StyledRatingCard = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  > .rating-wrapper {
    display: flex;
    align-items: center;
    &.disabled {
      opacity: 0.5;
      position: relative;
      :hover {
        opacity: 1;
        > .disabled-hover-message {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    > .disabled-hover-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      background-color: #ffffffcc;
      z-index: 1000;
      display: none;
    }
    > .title {
      font-size: 16px;
    }
    > .options {
      display: flex;
      > .option {
        cursor: pointer;
        height: 1.75rem;
        width: 2rem;
        &.hovered,
        &.current {
          > .circle {
            background-color: #1171fa;
            color: white;
          }
        }
        > .circle {
          background-color: #e6e6e6;
          height: 100%;
          width: 1.75rem;
          margin: 0 auto;
          transition: background-color 0.2s ease;
          border-radius: 9999px;
          white-space: nowrap;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

const RatingCard = ({
  mediaType,
  creativeMediaEl,
  reference,
  deckId,
  setActiveDeckFetch,
  ratingCardDisabled,
}) => {
  const [ratingHovered, setRatingHovered] = useState(0);
  const { axiosWithAuth } = useAxioswithAuth();

  const handleRatingClick = async (score) => {
    try {
      await axiosWithAuth().post('/reference-tool/ratings', {
        deck_id: deckId,
        reference_id: reference.id,
        score,
      });
      setActiveDeckFetch((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          references: prev.data.references.map((ref) => {
            if (ref.id === reference.id) {
              return {
                ...ref,
                rating_score: score,
              };
            }
            return ref;
          }),
        },
      }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledRatingCard>
      {(mediaType === 'VIDEO' || creativeMediaEl?.tagName === 'VIDEO') && (
        <MediaControls creativeMediaEl={creativeMediaEl} />
      )}
      <div className={`rating-wrapper${ratingCardDisabled ? ' disabled' : ''}`}>
        <div className="disabled-hover-message">
          You can only rate references within a deck.
        </div>
        <span className="title">Rating:</span>
        <span>👎</span>
        {}
        <div className="options">
          {[...Array(10)].map((_, i) => (
            <div
              onMouseEnter={() => setRatingHovered(i + 1)}
              onMouseLeave={() => setRatingHovered(0)}
              className={`option${ratingHovered > i ? ` hovered` : ''}${
                reference?.rating_score > i ? ` current` : ''
              }`}
              key={i}
              onClick={() => handleRatingClick(i + 1)}
              role="button"
              tabIndex={0}
              onKeyDown={() => handleRatingClick(i + 1)}
            >
              <div className="circle">{i + 1}</div>
            </div>
          ))}
          <span>👍</span>
        </div>
      </div>
    </StyledRatingCard>
  );
};

export default RatingCard;
