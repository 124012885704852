import AWS from 'aws-sdk';
import { Button, Textfield } from 'colabs-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import defaultAvatar from '../../assets/images/default-avatar.png';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const camelToTitle = (str) =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());

const snakeToTitle = (str) =>
  str.replace(/_/g, ' ').replace(/^./, (s) => s.toUpperCase());

const StyledOnboardingStep1 = styled.form`
  display: grid;
  grid-template-columns: 16rem 1fr 1fr;
  grid-gap: 1rem;
  > .profilePic {
    grid-row: 1 / 6;
    grid-column: 1 / 2;
    height: 75%;
    width: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    justify-self: center;
    position: relative;
    overflow: hidden;
    > .image {
      height: 100%;
    }
    > .fileUpload {
      position: absolute;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 1rem;
      bottom: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      /* color: ${({ theme }) => theme.color.primary.main}; */
    }
    input[type='file'] {
      opacity: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: 2;
    }
  }
  .email,
  .position,
  .location,
  .phone {
    grid-column: 2 / 4;
  }
  > .editing-actions {
    grid-column: 1 / 4;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
`;

const OnboardingStep1 = ({ setCurrentStep, currentStep }) => {
  const user = useSelector((state) => state.user);
  const profilePicInput = useRef();
  const newImage = useRef();
  const oldImage = useRef();

  const uniqueId = Math.random();
  const [imageUploadState, setImageUploadState] = useState('start');
  const [profileInfo, setProfileInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    position: '',
    location: '',
    phone: '',
  });
  const { axiosWithAuth } = useAxioswithAuth();

  const handlePreview = async ({ target }) => {
    if (target.files && target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        newImage.current.src = e.target.result;
        newImage.current.hidden = false;
        oldImage.current.hidden = true;
      };
      reader.readAsDataURL(target.files[0]);
      try {
        setImageUploadState('loading');
        const file = profilePicInput.current.files[0];
        const fileType = file.type || 'application/octet-stream';
        const s3 = new AWS.S3();
        const params = {
          Bucket: 'colab-platformbucket',
          Key: user.first_name
            ? `clients/${user.organization.name.replace(
                ' ',
                '-'
              )}/${user.first_name.replace(' ', '-')}-${user.id}/profile_pic`
            : `clients/${user.organization.name.replace(' ', '-')}/${
                user.id
              }/profile_pic`,
          Body: file,
          ACL: 'public-read',
          ContentType: fileType,
        };
        const upload = s3.upload(params).promise();
        const res = await upload;
        if (!res.status === 204) throw new Error('Error uploading file');
        setProfileInfo((prev) => ({ ...prev, profile_pic_url: res.location }));
        setImageUploadState('success');
      } catch (err) {
        setImageUploadState('error');
      }
    }
  };

  const handleProfileInfoChange = (e) => {
    setProfileInfo({
      ...profileInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSkip = async (e) => {
    e.preventDefault();
    try {
      await axiosWithAuth().put(`/organizations/${user.organization_id}`, {
        onboarding_step: 1,
      });
    } catch (err) {
      // alert('Error updating profile. Please try again later.');
    }
    setCurrentStep(currentStep + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosWithAuth().put(`/users/${user.id}`, profileInfo);
      await axiosWithAuth().put(`/organizations/${user.organization_id}`, {
        onboarding_step: 1,
      });
    } catch (err) {
      // alert('Error updating profile. Please try again later.');
    }
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (!user) return;
    setProfileInfo((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
        acc[key] = user[key] || '';
        return acc;
      }, {}),
    }));
  }, [user]);

  return (
    <StyledOnboardingStep1 className="step step-1" onSubmit={handleSubmit}>
      <div className="profilePic">
        <img ref={newImage} hidden className="image" alt="Profile Picture" />
        <img
          ref={oldImage}
          className="image"
          src={user?.profile_pic_url || defaultAvatar}
          alt="Profile Picture"
        />
        <label htmlFor={uniqueId} className="fileUpload">
          <input
            type="file"
            id={uniqueId}
            ref={profilePicInput}
            onChange={handlePreview}
          />
        </label>
      </div>
      {[
        { name: 'first_name', placeholder: 'Jackson' },
        { name: 'last_name', placeholder: 'Doe' },
        { name: 'email' },
        { name: 'position', placeholder: 'Chief Marketing Officer' },
        { name: 'location', placeholder: 'Los Angeles' },
        { name: 'phone', placeholder: '(555) 555-1234' },
      ].map((item) => (
        <Textfield
          onChange={handleProfileInfoChange}
          name={item.name}
          label={snakeToTitle(item.name)}
          value={profileInfo[item.name]}
          className={item.name}
          disabled={item.name === 'email'}
          placeholder={item.name === 'email' ? user.email : item.placeholder}
        />
      ))}
      <div className="editing-actions">
        <Button text="Save" type="submit" />
        <Button variant="secondary" text="Skip" onClick={handleSkip} />
      </div>
    </StyledOnboardingStep1>
  );
};

export default OnboardingStep1;
