import React from 'react';

export default function PinIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.099 32.099"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path id="Path_585" data-name="Path 585" d="M0,0H32.1V32.1H0Z" />
        </clipPath>
      </defs>
      <g
        id="_754-pin-attachement-outline"
        data-name="754-pin-attachement-outline"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_583"
          data-name="Path 583"
          d="M158.628,304.413l-4.113,6.782"
          transform="translate(-144.596 -284.871)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_584"
          data-name="Path 584"
          d="M143.554,106.87l-11.052-6.7.291-.481a3.867,3.867,0,0,1,5.311-1.3l4.439,2.692a3.867,3.867,0,0,1,1.3,5.311Zm-5.8-8.685,2.757-4.546,5.133,3.112-2.757,4.546m-2.376-7.659-.345-3.9,9.1,5.516-3.618,1.5"
          transform="translate(-123.996 -83.977)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
