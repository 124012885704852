import { Button } from 'colabs-ui-react';
import { diffWords } from 'diff';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import CommentsPanel from './CommentsPopover';
import ScriptSection from './ScriptSection';

const StyledScriptMainPanel = styled.div`
  background-color: white;
  border: 1px solid #d3d3d3;
  width: 100%;
  border-radius: 12px;
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  overflow: hidden;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .info {
      margin-right: 1rem;
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      > .title {
        text-transform: uppercase;
      }
      > .version {
        color: #1171fa;
      }
      > .date {
        color: #1171fa;
      }
      > .mode {
        border: 1px solid #d3d3d3;
        padding: 2px 8px;
        border-radius: 9999px;
      }
    }
    > .button-group {
      display: flex;
      border: 1px solid #d3d3d3;
      align-items: center;
      border-radius: 8px;
      position: relative;
      &[data-disabled='true'] {
        > .cui-button {
          pointer-events: none;
          opacity: 0.5;
        }
        &:hover {
          > .disabled-message {
            display: flex;
          }
        }
      }
      &[data-activated='true'] {
        border: #1171ff 2px solid;
        background-color: #edf4ff;
      }
      > .disabled-message {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }
  > .panel-body {
    display: grid;
    grid-template-columns: 625px 1fr;
    overflow: hidden;
    padding: 12px;
    grid-gap: 12px;
    > .script {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      overflow: hidden;
      > .sections {
        display: grid;
        flex-grow: 1;
        align-content: start;
        height: 100%;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 6px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #dadce0;
        }
        ::-webkit-scrollbar-track {
          background-color: #f5f5f5;
        }
        > .empty-state {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 2rem 0;
        }
      }
      > .editing-actions {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-self: end;
        > .grow {
          flex-grow: 1;
        }
      }
      > .main-actions {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-gap: 9px;
        position: relative;
        &[data-disabled='true'] {
          > .cui-button {
            pointer-events: none;
            opacity: 0.5;
          }
          &:hover {
            > .disabled-message {
              display: flex;
            }
          }
        }
        > .disabled-message {
          height: 100%;
          width: 100%;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          display: none;
          align-items: center;
          justify-content: center;
          text-align: center;
          line-height: 1;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.8);
        }
        > .cui-button {
          height: 3rem;
          &.approve-button.primary {
            background: #106ef7;
          }
        }
      }
    }
    > .reference-wrapper {
      overflow: hidden;
      > .reference {
        margin-bottom: -4px;
        object-fit: contain;
        object-position: center;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        border: 1px solid #d3d3d3;
      }
    }
  }
`;

const ScriptMainPanel = ({
  currentScript,
  fetchScripts,
  isEditing,
  setIsEditing,
}) => {
  const user = useSelector((state) => state.user);
  const isApprover = ['admin', 'approver'].includes(user?.organization_role);
  const isColabsMember = user?.organization?.id === 1;
  const [changeIndex, setChangeIndex] = useState(0);
  const [newSections, setNewSections] = useState([]);
  const [changeGroupsFetch, setChangeGroupsFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  const [commentsFetch, setCommentsFetch] = useState({
    data: [],
    status: 'start',
  });
  const sectionsRef = useRef();
  const sectionsToShow = [
    ...(currentScript?.sections || []),
    ...newSections.map((_, idx) => ({ ..._, idx })),
  ];
  const changeGroupsCount = Number(currentScript?.change_groups_count);
  const currentMode = (() => {
    if (isEditing) return 'Editing';
    if (!changeIndex) return 'View';
    return 'History';
  })();
  const { axiosWithAuth } = useAxioswithAuth();

  const handleApprovalChange = async (action) => {
    try {
      await axiosWithAuth().put(`/scripts/${currentScript.id}/approval`, {
        action,
      });
      fetchScripts();
    } catch (error) {
      alert('Error changing approval status. Please contact suport.');
    }
  };

  const fetchComments = async () => {
    setCommentsFetch((prev) => ({ ...prev, status: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get(
        `/comments/script/${currentScript?.id}`
      );
      setCommentsFetch((prev) => ({ ...prev, data, status: 'success' }));
    } catch (err) {
      setCommentsFetch((prev) => ({ ...prev, status: 'error' }));
    }
  };

  const likeComment = async (commentId) => {
    try {
      await axiosWithAuth().put(`/comments/like/${commentId}`);
    } catch (e) {
      alert('Error liking comment. Please contact suport.');
    }
    await fetchComments();
  };

  const postComment = async (content, parent) => {
    try {
      await axiosWithAuth().post('/comments', {
        content,
        parent_id: parent?.id,
        related_id: currentScript?.id,
        is_internal: true,
        related_table: 'scripts',
      });
    } catch (e) {
      alert('Error saving comment. Please contact suport.');
    }
    if (currentScript?.status === 'pending' && !isColabsMember && isApprover) {
      await handleApprovalChange('review');
    }
    await fetchComments();
  };

  const editComment = async (commentId, newContent) => {
    try {
      await axiosWithAuth().put(`/comments/${commentId}`, {
        content: newContent,
      });
    } catch (e) {
      alert('Error saving comment. Please contact suport.');
    }
    await fetchComments();
  };

  const deleteComment = async (commentId) => {
    try {
      await axiosWithAuth().delete(`/comments/${commentId}`);
    } catch (e) {
      alert('Error deleting comment. Please contact suport.');
    }
    await fetchComments();
  };

  const handleSave = async () => {
    const sections = document.querySelectorAll('.script-section');
    const payload = [];
    try {
      sections.forEach((section) => {
        const sectionName = section.querySelector('.section-name');
        const sectionContent = section.querySelector('.section-content');
        const { id } = section.dataset;
        const { deleted } = section.dataset;
        const { name } = sectionName.dataset;
        const newName = sectionName.innerText;
        const { content } = sectionContent.dataset;
        const newContent = sectionContent.innerText;
        if (!id) {
          payload.push({
            op: 'add',
            name: newName,
            content: newContent,
          });
          return;
        }
        if (deleted === 'true') {
          payload.push({
            id,
            op: 'delete',
          });
          return;
        }
        const hasDifferentName = name !== newName;
        const contentChanges = diffWords(content, newContent)
          .reduce((acc, curr, idx) => {
            return [
              ...acc,
              {
                added: curr.added,
                removed: curr.removed,
                value: curr.value,
                position: (() => {
                  if (idx === 0) return 0;
                  const prev = acc[idx - 1];
                  if (prev.removed) return prev.position;
                  return prev.position + prev.value.length;
                })(),
              },
            ];
          }, [])
          .filter((change) => change.added || change.removed);
        if (!contentChanges.length && !hasDifferentName) return;
        payload.push({
          op: 'edit',
          id,
          contentChanges,
          content: contentChanges.length ? newContent : undefined,
          name: hasDifferentName ? newName : undefined,
        });
        sectionContent.innerHTML = newContent;
        sectionName.innerHTML = newName;
      });
      await axiosWithAuth().put(`/scripts/${currentScript.id}`, {
        script_sections: payload,
      });
      setNewSections([]);
      await fetchScripts();
      setIsEditing(false);
    } catch (error) {
      alert('Error saving changes');
    }
    if (currentScript?.status === 'reviewed' && isColabsMember) {
      await handleApprovalChange('update');
    }
  };

  const fetchScriptChanges = async () => {
    setChangeGroupsFetch((prev) => ({
      ...prev,
      fetchState: 'loading',
    }));
    try {
      const { data } = await axiosWithAuth().get(
        `/scripts/${currentScript.id}/changes`
      );
      setChangeGroupsFetch((prev) => ({
        ...prev,
        fetchState: 'success',
        data,
      }));
    } catch (error) {
      setChangeGroupsFetch((prev) => ({
        ...prev,
        fetchState: 'error',
      }));
    }
  };

  const handleEditScript = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    const sections = document.querySelectorAll('.section-content');
    sections.forEach((section) => {
      const { content } = section.dataset;
      section.innerHTML = content;
    });
    if (newSections.length) {
      setNewSections([]);
    }
    setIsEditing(false);
  };

  const handleAddNewSection = async () => {
    setNewSections((prev) => [...prev, { content: 'Content', name: 'Title' }]);
  };

  const scrollToChange = () => {
    const changeSpans = document.querySelectorAll('.script-section-change');
    if (!changeSpans.length) return;
    changeSpans[changeSpans.length - 1].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useEffect(() => {
    if (changeIndex === 0) return;
    if (isEditing) {
      handleCancel();
    }
    if (changeGroupsFetch.fetchState !== 'success') {
      fetchScriptChanges();
    }
  }, [changeIndex]);

  useEffect(() => {
    if (!newSections.length) return;
    sectionsRef.current.scrollTo({
      top: sectionsRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [newSections]);

  useEffect(() => {
    setChangeIndex(0);
    setChangeGroupsFetch({
      fetchState: 'start',
      data: [],
    });
    setIsEditing(false);
  }, [currentScript]);

  return (
    <StyledScriptMainPanel>
      <Prompt
        when={isEditing}
        message="You're still editing, unsaved changes will be lost. Continue?"
      />
      <div className="panel-header">
        <span className="info">
          <span className="title">
            {currentScript?.name} (
            {(() => {
              try {
                if (!sectionsToShow.length) return 0;
                if (sectionsToShow.length === 1) return 1;
                return sectionsToShow.length - 1;
              } catch (e) {
                return '';
              }
            })()}{' '}
            VIDEOS){' '}
          </span>
          {changeGroupsCount > 0 && (
            <>
              <span className="version">
                v{changeGroupsCount + 1 - changeIndex}
              </span>
              <span className="date">
                {Boolean(changeIndex) &&
                  changeGroupsFetch.fetchState === 'success' &&
                  ` (${new Date(
                    changeGroupsFetch.data[changeIndex - 1]?.created_at
                  ).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })})`}
              </span>
            </>
          )}
          <span className="mode">{currentMode} Mode</span>
        </span>
        {changeGroupsCount > 0 && (
          <span
            className="button-group"
            data-disabled={isEditing}
            data-activated={changeIndex > 0}
          >
            <Button
              variant="tertiary"
              icon={<ChevronLeft />}
              disabled={changeIndex >= changeGroupsCount}
              onClick={() => setChangeIndex((prev) => prev + 1)}
            />
            <Button
              variant="tertiary"
              icon={<ChevronRight />}
              disabled={changeIndex === 0}
              onClick={() => setChangeIndex((prev) => prev - 1)}
            />
            <Button
              variant="tertiary"
              text="Latest"
              disabled={!changeIndex}
              onClick={() => setChangeIndex(0)}
            />
            <span className="disabled-message">
              Save or cancel <br />
              changes to enable
            </span>
          </span>
        )}
        <CommentsPanel
          commentsCount={currentScript?.comments_count}
          commentsFetch={commentsFetch}
          fetchComments={fetchComments}
          editComment={editComment}
          likeComment={likeComment}
          deleteComment={deleteComment}
          postComment={postComment}
          isCommentPanelOpen
          textfieldPlaceholder="Leave a comment"
          noHeader
          // sendDisabled={}
          // pinIsAttached={}
          // setPinIsGrabbed={}
          // cleanUpPin={}
          // pins={}
          // creativeMediaEl={}
        />
      </div>
      <div className="panel-body">
        <div className="script">
          <div className="sections" ref={sectionsRef}>
            {!sectionsToShow.length && (
              <div className="empty-state">No Sections</div>
            )}
            {sectionsToShow.map((section) => (
              <ScriptSection
                section={section}
                isEditing={isEditing}
                changeIndex={changeIndex}
                changeGroupsFetch={changeGroupsFetch}
                deleteNewSection={() => {
                  setNewSections((prev) =>
                    prev.filter((_, i) => i !== section.idx)
                  );
                }}
                scrollToChange={scrollToChange}
              />
            ))}
          </div>
          {isEditing && (
            <div className="editing-actions">
              {isColabsMember && (
                <Button
                  variant="secondary"
                  text="Add New Section"
                  onClick={handleAddNewSection}
                />
              )}
              <div className="grow" />
              <Button
                variant="secondary"
                text="Cancel"
                onClick={handleCancel}
              />
              <Button text="Save" onClick={handleSave} />
            </div>
          )}
          {!isEditing && (
            <div className="main-actions" data-disabled={changeIndex > 0}>
              <span className="disabled-message">
                You're on v{changeGroupsCount + 1 - changeIndex}. Go to the
                latest version to edit or change approval.
              </span>
              <Button
                variant="secondary"
                text="Edit Script"
                fullWidth
                onClick={handleEditScript}
              />
              <Button
                className={`approve-button${
                  currentScript?.status !== 'approved' ? ' primary' : ''
                }`}
                variant={
                  currentScript?.status !== 'approved' ? 'primary' : 'secondary'
                }
                text={
                  currentScript?.status !== 'approved'
                    ? 'Approve Script'
                    : 'Remove Script Approval'
                }
                fullWidth
                onClick={() =>
                  handleApprovalChange(
                    currentScript?.status !== 'approved'
                      ? 'approve'
                      : 'un-approve'
                  )
                }
              />
            </div>
          )}
        </div>
        <div className="reference-wrapper">
          <video
            className={`cui-media reference`}
            alt=""
            controls
            src={`${currentScript?.reference}#t=2`}
          />
        </div>
      </div>
    </StyledScriptMainPanel>
  );
};

export default ScriptMainPanel;
