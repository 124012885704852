import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Overlay } from 'react-overlays';

const Tooltip = styled('div')`
  position: absolute;
  ${({ theme }) => theme.elevation.md}
  z-index: 1000000;
`;

const Arrow = styled('div')`
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;
  &::before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    background-color: ${({ color }) => color};
    width: 10px;
    height: 10px;
    top: 0;
    left: 0;
  }
  ${({ placement }) =>
    ({
      left: 'right: -4px;',
      right: 'left: -4px;',
      top: 'bottom: -4px;',
      bottom: 'top: -4px;',
    }[placement])}
`;

function CuiOverlay({
  triggerRef,
  containerRef,
  placement,
  show,
  children,
  arrowColor,
}) {
  return (
    <Overlay
      show={show}
      rootClose
      offset={[0, 10]}
      onHide={() => {}}
      placement={placement}
      container={containerRef}
      target={triggerRef}
    >
      {({ props, arrowProps, placement }) => (
        <Tooltip {...props} placement={placement}>
          <Arrow {...arrowProps} placement={placement} color={arrowColor} />
          {children}
        </Tooltip>
      )}
    </Overlay>
  );
}

CuiOverlay.propTypes = {
  triggerRef: PropTypes.shape(),
  containerRef: PropTypes.shape(),
  placement: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.shape().isRequired,
  arrowStyle: PropTypes.shape(),
};

CuiOverlay.defaultProps = {
  containerRef: null,
  triggerRef: null,
  arrowStyle: {},
};

export default CuiOverlay;
