import { Button, Media, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-overlays';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ApprovedIcon from '../../assets/icons/Approved';
import AwaitingRevisionsIcon from '../../assets/icons/AwaitingRevisions';
import { approvalStatus } from '../../staticData';
import { getDisplayName } from '../../utils/helper';

const StyledModal = styled(Modal)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.25rem;
  padding: 3.5rem 0;
  width: 42rem;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 10001;
  :focus {
    outline: none;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const StyledOutcomeMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > .lotties {
    height: 12rem;
    width: 12rem;
    margin-bottom: 2rem;
  }
  > .thumbnail {
    position: relative;
    margin-bottom: 2rem;
    > .avatar {
      height: 9.375rem;
      width: 9.375rem;
      border-radius: 5rem;
      object-fit: cover;
      border: 1px solid #d3d3d3;
      > svg {
        height: 5rem;
        width: 5rem;
      }
    }
    > .approvalState {
      position: absolute;
      display: flex;
      align-items: center;
      padding: 0.25rem 1.25rem;
      border-radius: 2rem;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      @media ${({ theme }) => theme.breakpoint.lg} {
        margin: 0 0 1rem 0;
      }
    }
  }
  > .ads {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: space-around;
    width: 100%;
    background: #f6f7f7;
    padding: 2rem 4rem;
    > .ad {
      position: relative;
      > .cui-media {
        height: 6rem;
        width: 6rem;
        object-fit: cover;
        background-color: white;
        border-radius: 0.3rem;
      }
      > .adApprovalIcon {
        position: absolute;
        bottom: -1rem;
        right: -1rem;
        height: 2.75rem;
        width: 2.75rem;
      }
    }
  }
  > .cui-text {
    margin-bottom: 2.875rem;
    padding: 0 4.25rem;
  }
`;

const FeedbackModal = ({ isOpen, setIsOpen, creativeDeck }) => {
  const user = useSelector((state) => state.user);
  const { push } = useHistory();

  const closeModal = () => {
    setIsOpen(false);
    push('/adApproval');
  };

  return (
    <StyledModal
      show={isOpen}
      renderBackdrop={(rest) => (
        <Backdrop {...rest} onClick={() => closeModal()} />
      )}
      aria-labelledby="modal-label"
    >
      <StyledOutcomeMessage>
        <Text size={6} weight="medium" gutterBottom>
          Thank You, {!user ? null : getDisplayName(user, 16)}
        </Text>
        <div className="thumbnail">
          <img
            src={
              user.profile_pic_url ||
              'https://gravatar.com/avatar/d559c0b948620a119ac87908bdca93d0?s=400&d=mp&r=x'
            }
            onError={(e) => {
              e.target.src =
                'https://gravatar.com/avatar/d559c0b948620a119ac87908bdca93d0?s=400&d=mp&r=x';
            }}
            alt="avatar"
            className="avatar"
          />
          <span
            className="approvalState"
            style={{
              backgroundColor: approvalStatus.find(
                ({ text }) => text === creativeDeck?.approval_state
              )?.color,
            }}
          >
            <Text size={2} weight="medium" color="white" noWrap>
              {creativeDeck?.approval_state}
            </Text>
          </span>
        </div>

        <div className="ads">
          {creativeDeck?.ads?.slice(0, 3).map((ad) => (
            <div className="ad" key={ad.id}>
              <Media src={ad?.assets[0].url} srcSuffix="#t=2" />
              {ad.approval_state === 'Approved' ? (
                <ApprovedIcon className="adApprovalIcon" />
              ) : (
                <AwaitingRevisionsIcon className="adApprovalIcon" />
              )}
            </div>
          ))}
        </div>
        <Text weight="medium" gutterBottom>
          {creativeDeck?.approval_state === 'Approved'
            ? 'Thank you for approving all creatives in the deck! You’re all set and ready to go — sit back and relax as we go ahead and launch this deck!'
            : 'Thank you for your feedback! We will make revisions based on your comments and suggestions and get back to you ASAP!'}
        </Text>
        <Button
          onClick={closeModal}
          text="Back to Creative Decks"
          variant="secondary"
          size="md"
        />
      </StyledOutcomeMessage>
    </StyledModal>
  );
};

FeedbackModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  creativeDeck: PropTypes.shape().isRequired,
};

export default FeedbackModal;
