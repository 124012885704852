import { Button, Card, Popper, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import styled from 'styled-components';
import BlueImage from '../../assets/images/BlueImage.png';
import BubbleCircle from '../../assets/images/BubbleCircle.png';
import CircleBackground from '../../assets/images/CircleBackground.png';
import ThumbsUp from '../../assets/images/ThumbsUp.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledPopper = styled(Popper)`
  pointer-events: none;
  .popperCard {
    padding: 0.25rem 0.75rem;
    background: #ffffffcc;
    backdrop-filter: blur(10px);
    z-index: 10;
    > * {
      margin: 0.5rem 0;
    }
  }
`;

const StyledAdApprovalStatus = styled.div`
  padding: 0.25rem 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .container {
    display: flex;
    width: 100%;
    height: 3rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 0.675rem 1.25rem;
    transition: 0.5s ease-in-out all;
    margin-bottom: 0.5rem;
    .cui-text {
      z-index: 3;
      margin: 0 1rem;
    }
    > img {
      display: none;
    }
    > .avatars {
      position: relative;
      display: flex;
      > .avatar {
        z-index: 5;
        margin: 0 0.25rem;
        object-fit: cover;
        border-radius: 100rem;
        width: 2rem;
        height: 2rem;
        > .cui-avatar {
          border: 2px solid white;
        }
        > .more {
          height: 2rem;
          width: 2rem;
          background: #e0e0e0;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      > .cui-text {
        z-index: 5;
      }
    }
    > :last-child {
      display: none;
    }
  }
  &.open > .container {
    height: 25rem;
    flex-direction: column;
    position: relative;
    > img {
      display: initial;
    }
    .cui-text {
      z-index: 3;
      margin: 0;
    }
    > .topLeft {
      position: absolute;
      height: 37%;
      top: 1rem;
      left: 1rem;
      z-index: 2;
    }
    > .bottomRight {
      position: absolute;
      height: 30%;
      bottom: 4.5rem;
      right: 1rem;
      z-index: 2;
    }
    > .center {
      position: absolute;
      height: 40%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    > .avatars {
      margin: 0.75rem 0;
      > .avatar {
        width: 3rem;
        height: 3rem;
      }
    }
    > .cui-text:nth-child(3) {
      opacity: 1;
      position: initial;
    }
    > :last-child {
      display: initial;
      white-space: pre-line;
      position: absolute;
      width: calc(100% - 2.5rem);
      left: 50%;
      bottom: 2.5rem;
      transform: translateX(-50%);
    }
  }

  @keyframes growOut {
    0% {
      transform: scale(1, 0.5);
      opacity: 0;
    }
    80% {
      opacity: 0.5;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
`;

const AdApprovalStatus = ({ approvers, comments, userId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [overlayValue, setOverlayValue] = useState();
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if ((comments?.length === 0) === isOpen) return;
    setIsOpen(!isOpen);
  }, [comments]);

  return (
    <StyledAdApprovalStatus className={height > 799 && isOpen ? 'open' : ''}>
      <StyledPopper
        isOpen={overlayValue}
        parentId="popper"
        anchorSelector={`#approver-${overlayValue?.id}`}
        yPosition="bottom"
        xPosition="left"
        onClose={() => setOverlayValue(null)}
      >
        {overlayValue?.email}
      </StyledPopper>
      <Card className="container" elevation={4}>
        <img className="topLeft" alt="" src={BlueImage} />
        <img className="bottomRight" alt="" src={ThumbsUp} />
        <img
          className="center"
          alt=""
          src={approvers?.length > 0 ? CircleBackground : BubbleCircle}
        />
        {(approvers?.length > 0 || comments?.length > 0) && (
          <Text size={4} weight="medium" color="blue">
            Approved By
          </Text>
        )}
        {(() => {
          if (approvers?.length > 0) {
            return (
              <div className="avatars">
                {approvers
                  .slice(0, approvers?.length <= 4 ? 4 : 3)
                  .map((approver) => (
                    <img
                      key={approver.id}
                      className="avatar"
                      id={`approver-${approver.id}`}
                      onMouseEnter={() => setOverlayValue(approver)}
                      onMouseLeave={() => setOverlayValue(null)}
                      src={
                        approver?.profile_pic_url ||
                        'https://esri-es.github.io/arcgis-experts/assets/imgs/no_avatar.jpg'
                      }
                      // size={isOpen ? 'lg' : 'md'}
                      onError={(e) => {
                        e.target.src =
                          'https://esri-es.github.io/arcgis-experts/assets/imgs/no_avatar.jpg';
                      }}
                      alt=""
                    />
                  ))}
                {approvers?.length > 4 && (
                  <div className="avatar">
                    <div className="more">
                      <Text>+{approvers?.length - 3}</Text>
                    </div>
                  </div>
                )}
              </div>
            );
          }
          if (comments?.length > 0) {
            return <Text noWrap>No one yet</Text>;
          }
          if (comments?.length === 0) {
            return (
              <Text noWrap color="blue">
                No comments yet
              </Text>
            );
          }
          return '';
        })()}
        <Text size={3} color="blue" align="center">
          {(() => {
            if (approvers?.length === 0) {
              return 'Approve this creative or \n leave your feedback below!';
            }
            if (approvers?.length > 0) {
              return `This creative has been approved by ${
                approvers?.some(({ id }) => id === userId) ? 'you' : 'your team'
              }! \n You can still leave notes below.`;
            }
            return '';
          })()}
        </Text>
      </Card>
      {approvers?.length > 0 && comments?.length > 0 && width > 1279 && (
        <Button
          variant="tertiary"
          icon={isOpen ? <ChevronUp /> : <ChevronDown />}
          onClick={() => setIsOpen(!isOpen)}
        />
      )}
    </StyledAdApprovalStatus>
  );
};

AdApprovalStatus.propTypes = {
  approvers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape()),
  userId: PropTypes.number.isRequired,
};

AdApprovalStatus.defaultProps = {
  comments: [],
};

export default AdApprovalStatus;
