/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import Alert from '../presentational/Alert';
import AdminOrgProposalList from './AdminOrgProposalList';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CreateNonClientOrg({
  cb,
  setProposal,
  proposal,
  org,
  setorg,
  handleNext,
}) {
  const [chooseProposal, setChooseProposal] = useState(false);
  const [existingOrg, setExistingOrg] = useState(false);
  const classes = useStyles();
  const [alertProps, setAlertProps] = useState();
  const { axiosWithAuth } = useAxioswithAuth();

  React.useEffect(() => {
    if (proposal.organization_id) {
      setChooseProposal(true);
    }
  }, [proposal.organization_id]);

  function handleChange(e) {
    setorg({ ...org, name: e.target.value });
  }

  function handleChangeProposal(e) {
    setProposal({ ...proposal, [e.target.name]: e.target.value });
    cb(true);
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const ax = await axiosWithAuth().post(
        `${process.env.REACT_APP_API_URL}/auth/registerOrg`,
        org
      );
      setChooseProposal(true);
      setProposal({
        ...proposal,
        organization_name: ax.data.org.name,
        organization_id: ax.data.org.id,
      });
    } catch (error) {
      if (
        error.response.data.error.includes('Organization already registered')
      ) {
        return setAlertProps({
          message:
            'Organization already registered, please go to next steps by clicking on step name',
          id: Math.random(),
          severity: 'error',
        });
      }
      console.error(error.response);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      // const ax = await axiosWithAuth().post(
      //   `${process.env.REACT_APP_API_URL}/auth/registerOrg`,
      //   org
      // );
      cb(true);
      handleNext();
      // setProposal({
      //   ...proposal,
      //   proposal_name: ax.data.org.name,
      // });
    } catch (error) {
      if (
        error.response.data.error.includes('Organization already registered')
      ) {
        return setAlertProps({
          message:
            'Organization already registered, please go to next steps by clicking on step name',
          id: Math.random(),
          severity: 'error',
        });
      }
      console.error(error.response);
    }
  }

  function handleNoOrg(e, organization) {
    e.preventDefault();
    setProposal({
      ...proposal,
      organization_id: organization.id,
      organization_name: organization.name,
    });
  }
  return (
    <>
      <Alert
        text={alertProps?.message}
        open={alertProps?.id}
        severity={alertProps?.severity}
        style={{ left: '40%' }}
      />

      {!existingOrg || proposal.organization_id ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          {!chooseProposal ? (
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Non Client Organization
              </Typography>
              <form className={classes.form} onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="Org"
                      label="Organization Name"
                      name="name"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Create
                  </Button>
                  <Button type="button" onClick={() => setExistingOrg(true)}>
                    Choose Existing Org
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography component="h1" variant="h5">
                Organization: &nbsp;
                {proposal.organization_name}
              </Typography>
            </div>
          )}

          <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="Org"
                    label="Version"
                    value={proposal.proposal_name}
                    name="proposal_name"
                    disabled={!chooseProposal}
                    onChange={handleChangeProposal}
                  />
                  <br />
                  <br />
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="Org"
                    label="Client Name"
                    name="client_name"
                    value={proposal.client_name}
                    disabled={!chooseProposal}
                    onChange={handleChangeProposal}
                  />
                  <br />
                  <br />
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="Org"
                    label="Client Title"
                    name="client_title"
                    value={proposal.client_title}
                    disabled={!chooseProposal}
                    onChange={handleChangeProposal}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      ) : (
        <AdminOrgProposalList
          handleNoOrg={handleNoOrg}
          proposal={proposal}
          setProposal={setProposal}
        />
      )}
    </>
  );
}
