import { Button } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import blueImage from '../assets/images/BlueImage.png';
import SortIcons from '../components/SortIcons';
import MemberActionsMenu from '../components/team/MemberActionsMenu';
import NewMemberModal from '../components/team/NewMemberModal';
import SettingsLayout from '../layouts/Settings';
import { getDisplayName } from '../utils/helper';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledTeamPage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  max-height: 100%;
  overflow: hidden;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
      text-transform: uppercase;
    }
    > .onboarding-step {
      border: 1px solid #d3d3d3;
      border-radius: 9999px;
      padding: 0.25rem 0.5rem;
      width: fit-content;
      &.green {
        border: 1px solid #44dc7a;
        color: #44dc7a;
        /* background-color: #c7ffdb; */
      }
      &.red {
        border: 1px solid #f55050;
        color: #f55050;
        /* background-color: #ffdbdb; */
      }
      &.orange {
        border: 1px solid #ffa311;
        color: #ffa311;
        /* background-color: #ffe9c7; */
      }
    }
    > .grow {
      flex-grow: 1;
    }
  }
  > .list-row,
  > .list-body > .list-row {
    display: grid;
    grid-template-columns: 0.75fr 1.5fr 1fr 2fr 1fr 0.5fr;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    .message-icon {
      stroke-width: 1px;
      height: 1.25rem;
      width: 1.25rem;
    }
    &.member {
      font-size: 14px;
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      :hover {
        background-color: #f5f5f5;
      }
      &[data-active='true'] {
        border: 3px solid #1171fa;
        border-radius: 0.5rem;
        background-color: #edf4ff;
      }
      &[disabled] {
        cursor: not-allowed;
        > * {
          opacity: 0.5;
        }
        :hover {
          background-color: transparent;
        }
      }
      > .profile-pic {
        height: 3.75rem;
        width: 3.75rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .name {
        text-transform: lowercase;
      }
      > .active {
        padding-left: 0.5rem;
        svg {
          stroke-width: 1px;
          height: 1rem;
          width: 1rem;
        }
      }
      > .decks-actions-menu {
        justify-self: end;
      }
    }
  }
  > .list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .no-items {
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

const TeamPage = () => {
  const [membersFetch, setMembersFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  const user = useSelector((state) => state.user);
  const [newMember, setNewMember] = useState({
    mode: '', // 'create', 'delete'
    id: null,
    name: '',
    actionState: 'start',
    isModalOpen: false,
  });
  const { axiosWithAuth } = useAxioswithAuth();

  // const [columnSort, setColumnSort] = useState({
  //   column: 'name',
  //   order: 'asc',
  // });

  // const updateColumnSort = (column) => {
  //   if (columnSort.column === column) {
  //     setColumnSort({
  //       column,
  //       order: columnSort.order === 'asc' ? 'desc' : 'asc',
  //     });
  //   } else {
  //     setColumnSort({
  //       column,
  //       order: 'asc',
  //     });
  //   }
  // };

  const fetchMembers = async () => {
    setMembersFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get(
        `/users/in_organization/${user.organization_id}`
      );
      setMembersFetch({ fetchState: 'success', data });
    } catch (error) {
      setMembersFetch({ fetchState: 'error', data: [] });
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <SettingsLayout>
      <StyledTeamPage className="members-nav-panel">
        <NewMemberModal
          newMember={newMember}
          setNewMember={setNewMember}
          fetchMembers={fetchMembers}
        />
        <div className="panel-header">
          <span className="title">MEMBERS</span>
          <span className="grow" />
          <Button
            className="stand-alone-btn"
            onClick={() =>
              setNewMember({
                mode: 'create',
                id: null,
                items: {
                  baseProducts: [{}],
                  addOns: [],
                  discounts: [],
                },
                active: false,
                actionState: 'start',
                errorMessage: '',
                isModalOpen: true,
              })
            }
            variant="secondary"
            icon={<Plus />}
          />
        </div>
        <div className="list-row">
          <div className="column-title" />
          {[
            { property: '', label: 'Name' },
            { property: '', label: 'Role' },
            { property: '', label: 'Email' },
            { property: '', label: 'Last Log In' },
          ].map(({ property, label }) => (
            <div
              className="column-title"
              // onClick={() => updateColumnSort(property)}
              // role="button"
              // tabIndex={0}
              // onKeyDown={() => updateColumnSort(property)}
            >
              {label}
              {/* <SortIcons
              sorting={
                membersFetch.fetchState === 'success' &&
                columnSort.column === property
                  ? columnSort.order
                  : undefined
              }
            /> */}
            </div>
          ))}
          <div className="column-title" />
        </div>
        <div className="list-body">
          {membersFetch.fetchState === 'success' &&
            !membersFetch.data.length && (
              <div className="no-items">No members yet</div>
            )}
          {membersFetch?.data?.map((member) => (
            <div
              className="list-row member"
              // disabled={!member.references_count}
            >
              <img
                className="profile-pic"
                src={member.profile_pic_url || blueImage}
                onError={(e) => {
                  e.target.src = blueImage;
                }}
                alt={member.name}
              />
              <div className="name">{getDisplayName(member)}</div>
              <div className="role">{member.organization_role}</div>
              <div className="email">{member.email}</div>
              <div className="last_login">
                {new Date(member.last_login).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </div>
              <MemberActionsMenu member={member} setNewMember={setNewMember} />
            </div>
          ))}
        </div>
      </StyledTeamPage>
    </SettingsLayout>
  );
};

export default TeamPage;
