import React, { useEffect, useState } from 'react';
import { Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { camelToTitle } from '../../utils/helper';

const StyledScriptSection = styled.div`
  margin-bottom: 12px;
  &[data-deleted='true'] {
    display: none;
  }
  &[data-editing='true'] {
    > .header {
      > .section-name {
        cursor: text;
        background: #edf4ff;
        &:focus {
          outline: none;
          animation: pulse 1s infinite;
        }
      }
    }
    > .section-content {
      display: block;
      background: #edf4ff;
      border-left: 4px solid #1171ff;
      padding: 4px 12px 4px 8px;
      &:focus {
        outline: none;
        animation: pulse 1s infinite;
      }
      @keyframes pulse {
        0% {
          background-color: #edf4ff;
        }
        50% {
          background-color: #d6e4ff;
        }
        100% {
          background-color: #edf4ff;
        }
      }
    }
    > .header {
      color: #1171ff;
    }
  }
  > .header {
    color: #888;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    > .section-name {
      font-family: 'TTNormsMedium';
      color: #106ef7;
      margin-right: 8px;
      padding-bottom: -2px;
    }
    > .delete-btn {
      height: 16px;
      width: 16px;
      color: #f55050;
      border-radius: 9999px;
      cursor: pointer;
      :hover {
        opacity: 0.8;
        background: #ffdbdb;
      }
    }
  }
  > .section-content {
    background: #fff;
    padding: 4px 24px 4px 0;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.005em;
    transition: all 0.2s ease-in-out;
    font-family: 'CourierNew';
    > * {
      font-family: 'CourierNew';
    }
    > .added {
      background: #c7ffdb;
      border-radius: 2px;
      color: #144224;
    }
    > .removed {
      background: #ffdbdb;
      border-radius: 4px;
      color: #5b1d1d;
      text-decoration: line-through;
    }
  }
`;

const ScriptSection = ({
  section,
  isEditing,
  deleteNewSection,
  changeGroupsFetch,
  changeIndex,
  scrollToChange,
}) => {
  const [contentShown, setContentShown] = useState(section.content);
  const user = useSelector((state) => state.user);
  const isColabsMember = user?.organization?.id === 1;
  const [deleted, setDeleted] = useState(false);

  const getChangeGroups = (changeIndex, section, changeGroupsFetch) => {
    const relevantChangeGroups = changeGroupsFetch.data
      .slice(0, changeIndex)
      .map(
        ({ sections }) =>
          sections.find(({ id }) => section.id === id)?.changes || []
      );
    return [
      relevantChangeGroups.slice(0, relevantChangeGroups.length - 1),
      relevantChangeGroups[relevantChangeGroups.length - 1],
    ];
  };

  const applyChanges = (changeGroups, section) => {
    return changeGroups.reduce((acc, changes) => {
      return changes.reduce((acc2, change, index) => {
        const nextString = acc?.slice(
          change.position + (change.added ? change.value.length : 0),
          changes[index + 1]?.position
        );
        const newString = `${acc2}${
          change.added ? '' : change.value
        }${nextString}`;
        return newString;
      }, acc.slice(0, changes[0]?.position));
    }, section.content);
  };

  const formatChanges = (changeGroup, contentString) => {
    return changeGroup
      .reduce(
        (acc, change, index) => {
          const nextString = contentString?.slice(
            change.position + (change.added ? change.value.length : 0),
            changeGroup[index + 1]?.position
          );
          const changeClass = change.added ? 'added' : 'removed';
          const changeSpan = `<span class="script-section-change ${changeClass}">${change.value}</span>`;
          const newArr = [...acc, changeSpan, nextString];
          return newArr;
        },
        [contentString.slice(0, changeGroup[0]?.position)]
      )
      .join('');
  };

  const updateContentShown = () => {
    // todo: add redundancy sort to ensure that the changes within groups
    // are in the correct order (right now, we're trusting the BE):
    //    1) position ASC
    //    2) removed ASC
    const [recentChangeGroups, activeChangeGroup] = getChangeGroups(
      changeIndex,
      section,
      changeGroupsFetch
    );
    const contentString = applyChanges(recentChangeGroups, section);
    const contentWithChanges = formatChanges(activeChangeGroup, contentString);
    setContentShown(contentWithChanges);
    // if (section.id === 1) {
    //   console.log(`
    //       here
    //       recentChangeGroups: ${JSON.stringify(recentChangeGroups)}
    //       activeChangeGroup: ${JSON.stringify(activeChangeGroup)}
    //       contentString: ${contentString}
    //       activeChangeGroup: ${JSON.stringify(activeChangeGroup)}
    //       contentWithChanges: ${contentWithChanges}
    //     `);
    // }
  };

  useEffect(() => {
    if (changeGroupsFetch.fetchState !== 'success' || !changeIndex) {
      setContentShown(section.content);
      return;
    }
    updateContentShown();
  }, [section.content, changeGroupsFetch, changeIndex]);

  useEffect(() => {
    scrollToChange();
  }, [contentShown]);

  useEffect(() => {
    if (!isEditing) {
      setDeleted(false);
    }
  }, [isEditing]);

  return (
    <StyledScriptSection
      data-editing={isEditing}
      data-deleted={deleted}
      data-id={section.id}
      className="script-section"
    >
      <div className="header" data-revisions-on={changeIndex > 0}>
        <span
          contentEditable={isEditing}
          data-name={section.name}
          className="section-name"
        >
          {camelToTitle(section.name)}
        </span>
        {isEditing && isColabsMember && (
          <Trash2
            onClick={() =>
              typeof section.idx === 'number'
                ? deleteNewSection()
                : setDeleted(true)
            }
            className="delete-btn"
          />
        )}
      </div>
      <div
        contentEditable={isEditing}
        style={{ whiteSpace: 'pre-wrap' }}
        className="section-content"
        data-content={section.content}
        dangerouslySetInnerHTML={{ __html: contentShown }}
      />
    </StyledScriptSection>
  );
};

export default ScriptSection;
