import * as Dialog from '@radix-ui/react-dialog';
import AWS from 'aws-sdk';
import { Button, Checkbox, Textfield } from 'colabs-ui-react';
import React, { useRef } from 'react';
import { Edit2, X } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 24rem;
  padding: clamp(2rem, 5vw, 1rem);
  display: flex;
  flex-direction: column;
  > .title {
    margin-top: 0;
    font-size: 1.25rem;
    &.delete {
      margin-bottom: 0.5rem;
    }
  }
  > .description {
    margin: 0 0 1rem 0;
  }
  > .logo-upload {
    height: 8rem;
    width: 8rem;
    margin-bottom: 1rem;
    align-self: center;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
      border: 1px solid #d3d3d3;
    }
    > .edit {
      position: absolute;
      bottom: -0.5rem;
      right: -0.5rem;
      background-color: #fff;
      border: 1px solid #d3d3d3;
      border-radius: 9999px;
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
      }
      > input {
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
      > svg {
        height: 1.25rem;
        width: 1.25rem;
        stroke-width: 1px;
        cursor: pointer;
      }
    }
  }
  > .cui-textfield {
    margin-bottom: 0.5rem;
  }
  > .cui-dropdown {
    margin-bottom: 0.5rem;
    .select {
      overflow-y: scroll;
      max-height: 14rem;
    }
  }
  > .buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    &.right {
      justify-content: flex-end;
    }
  }
  .cui-button {
    text-transform: capitalize;
    align-self: flex-end;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
`;

const NewOrganizationModal = ({
  newOrganization,
  setNewOrganization,
  fetchOrganizations,
}) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const logoImgRef = useRef(null);
  const fileInputRef = useRef(null);
  const user = useSelector((state) => state.user);
  const isSuperAdmin = user?.organization?.type === 'superadmin';

  const handlePreview = ({ target }) => {
    // check if file is an image
    if (!target.files[0].type.includes('image')) {
      alert('Please upload an image file');
      return;
    }
    if (target.files && target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        logoImgRef.current.src = e.target.result;
      };
      reader.readAsDataURL(target.files[0]);
    }
  };

  const uploadLogo = async () => {
    try {
      if (!fileInputRef.current.files[0]) return '';
      AWS.config.update({
        region: 'us-east-1',
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        }),
      });
      await AWS.config.credentials.getPromise();
      const file = fileInputRef.current.files[0];
      const fileName = file.name.replace(' ', '-');
      const fileType = file.type || 'application/octet-stream';
      const s3 = new AWS.S3();
      const params = {
        Bucket: 'colab-platformbucket',
        Key: `clients/${
          newOrganization.name.replace(' ', '-') || 'logo'
        }/${fileName}`,
        Body: file,
        ACL: 'public-read',
        ContentType: fileType,
      };
      const upload = s3.upload(params).promise();
      const res = await upload;
      if (!res || !res.Location) throw new Error('Error uploading file');
      return res.Location;
    } catch (err) {
      alert('Error uploading logo. Please contact support');
      return '';
    }
  };

  const createOrganization = async (logoUrl) => {
    if (!newOrganization.name) return;
    try {
      await axiosWithAuth().post('/new-organizations', {
        name: newOrganization.name || undefined,
        GA_id: newOrganization.GA_id || undefined,
        type: newOrganization.type || undefined,
        GSheet_ID: newOrganization.GSheet_ID || undefined,
        Exec_Report_Url: newOrganization.Exec_Report_Url || undefined,
        logo_url: logoUrl || undefined,
      });
      await fetchOrganizations();
    } catch (e) {
      if (
        e.response?.status === 409 &&
        e.response?.data?.message === 'you already have this client'
      ) {
        alert(`You already have ${newOrganization.name} as a client`);
        return;
      }
      if (
        e.response?.status === 409 &&
        e.response?.data?.message === 'this organization already exists' &&
        user?.organization?.type === 'agency'
      ) {
        alert(
          `This organization already has an account. If you're their agency, please contact support to get access.`
        );
        return;
      }
      alert('Error creating organization. Please contact support');
    }
  };

  const deleteOrganization = async () => {
    try {
      await axiosWithAuth().delete(`/new-organizations/${newOrganization.id}`);
      await fetchOrganizations();
    } catch (e) {
      alert('Error deleting organization. Please contact support');
    }
  };

  const updateOrganization = async (logoUrl) => {
    try {
      await axiosWithAuth().put(`/new-organizations/${newOrganization.id}`, {
        name: newOrganization.name || undefined,
        type: newOrganization.type || undefined,
        GA_id: newOrganization.GA_id || undefined,
        GSheet_ID: newOrganization.GSheet_ID || undefined,
        Exec_Report_Url: newOrganization.Exec_Report_Url || undefined,
        logo_url: logoUrl || undefined,
      });
      await fetchOrganizations();
    } catch (e) {
      alert('Error updating organization. Please contact support');
    }
  };

  const handleConfirm = async () => {
    if (!newOrganization.name) {
      alert('Please enter a name');
      return;
    }
    const logoUrl = await uploadLogo();
    console.log('here', logoUrl);
    await {
      create: () => createOrganization(logoUrl),
      edit: () => updateOrganization(logoUrl),
    }[newOrganization.mode]();
    setNewOrganization((prev) => ({ ...prev, isModalOpen: false }));
  };

  const updateForm = (e) => {
    setNewOrganization((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // const updateFormDomValues = () => {
  //   const field = document.querySelector(`#deck-name-textfield`);
  //   if (!field) return;
  //   field.value = newOrganization.name;
  // };

  // useEffect(() => {
  //   updateFormDomValues();
  // }, [newOrganization.isModalOpen]);

  return (
    <Dialog.Root
      open={newOrganization.isModalOpen}
      onOpenChange={(open) => {
        if (open) fetchOrganizations();
        setNewOrganization((prev) => ({ ...prev, isModalOpen: open }));
      }}
    >
      <Dialog.Portal>
        <Overlay />
        {newOrganization.mode === 'delete' ? (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newOrganization.mode}`}>
              <span className="avoidwrap">Are you sure you want to delete</span>{' '}
              <span className="avoidwrap">"{newOrganization.name}"?</span>
            </Dialog.Title>
            <Dialog.Description className="description">
              You will loose all the data associated with this organization.
            </Dialog.Description>
            <div className="buttons">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() =>
                  setNewOrganization((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }))
                }
              />
              <Button
                text="Confirm"
                onClick={async () => {
                  await deleteOrganization();
                  setNewOrganization((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }));
                }}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        ) : (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newOrganization.mode}`}>
              {
                {
                  edit: 'Edit Organization',
                  create: 'Create New Organization',
                }[newOrganization.mode]
              }
            </Dialog.Title>
            <div className="logo-upload">
              <img
                src={newOrganization.logo_url || blueImage}
                alt="logo"
                ref={logoImgRef}
                onError={(e) => {
                  e.target.src = blueImage;
                }}
              />
              <span className="edit">
                <Edit2 />
                <input
                  type="file"
                  onChange={handlePreview}
                  ref={fileInputRef}
                />
              </span>
            </div>
            {[
              { name: 'name', required: true, label: 'Name*' },
              { name: 'GA_id', label: 'GA ID (not required)' },
              { name: 'GSheet_ID', label: 'Google Sheet ID (not required)' },
              {
                name: 'Exec_Report_Url',
                label: 'Reports URL (not required)',
              },
            ].map((field) => (
              <Textfield
                id="deck-name-textfield"
                label={field.label}
                name={field.name}
                value={newOrganization[field.name]}
                required={field.required}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={updateForm}
              />
            ))}
            {isSuperAdmin && (
              <Checkbox
                label="Agency"
                checked={newOrganization.type === 'agency'}
                onChange={(e) =>
                  setNewOrganization((prev) => ({
                    ...prev,
                    type: e.target.checked ? 'agency' : 'non-agency',
                  }))
                }
              />
            )}
            <div className="buttons right">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() => {
                  setNewOrganization((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }));
                }}
              />
              <Button text="Confirm" onClick={handleConfirm} />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewOrganizationModal;
