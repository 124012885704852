import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import AllDecksNavPanel from '../components/referenceTool/AllDecksNavPanel';
import AllReferencesNavPanel from '../components/referenceTool/AllReferencesNavPanel';
import LatestDecksPanel from '../components/referenceTool/LatestDecksPanel';
import NewDeckModal from '../components/referenceTool/NewDeckModal';
import NewReferenceModal from '../components/referenceTool/NewReferenceModal';
import ReferenceDeckNavPanel from '../components/referenceTool/ReferenceDeckNavPanel';
import ReferenceView from '../components/referenceTool/ReferenceView';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

function sortAndRemoveDuplicates(arr) {
  const frequency = arr.reduce((acc, val) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {});
  const frequencyArray = Object.entries(frequency).map(([item, count]) => ({
    item,
    count,
  }));
  const uniqueArray = frequencyArray
    .sort((a, b) => b.count - a.count)
    .map((obj) => obj.item);
  return uniqueArray;
}

const StyledReferenceTool = styled.div`
  flex: 1 1 auto;
  padding: 0.75rem;
  width: 100%;
  max-width: 100rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  &.deck-opened {
    .all-decks-nav-panel {
      left: -100% !important;
      /* transition: left 0.3s ease-in-out; */
    }
    .deck-nav-panel {
      left: 0 !important;
      /* transition: left 0.3s ease-in-out; */
    }
  }
  &.all-opened {
    .all-decks-nav-panel {
      left: -100% !important;
      /* transition: left 0.3s ease-in-out; */
    }
    .references-nav-panel {
      left: 0 !important;
      /* transition: left 0.3s ease-in-out; */
    }
  }
  > .nav-panel {
    z-index: 100;
    background-color: white;
    width: 32rem;
    border-radius: 0.75rem;
    position: relative;
    display: flex;
    overflow: hidden;
    /* transition: all 0.3s ease-in-out; */
    > .all-decks-nav-panel {
      left: 0;
      width: 100%;
      position: absolute;
      /* transition: all 0.3s ease-in-out; */
      height: 100%;
    }
    > .deck-nav-panel,
    > .references-nav-panel {
      /* transition: all 0.3s ease-in-out; */
      position: absolute;
      width: 100%;
      left: 100%;
      height: 100%;
    }
  }
  &.deck-opened,
  &.all-opened {
    > .latest-decks {
      display: none;
    }
    > .reference-view {
      display: flex !important;
    }
  }

  > .reference-view {
    display: none;
  }
`;

const ReferenceTool = () => {
  const { push } = useHistory();
  const { axiosWithAuth } = useAxioswithAuth();
  const { deckId } = useParams();
  const [nameQueryTextfield, setNameQueryTextfield] = useState();
  const [activeRefIdx, setActiveRefIdx] = useState(0);
  const [newDeck, setNewDeck] = useState({
    mode: '', // 'create', 'edit', 'delete', 'duplicate'
    id: null,
    name: '',
    organization_id: null,
    starter_deck_id: null,
    starter_deck_name: '',
    actionState: 'start',
    isModalOpen: false,
  });
  const [newReference, setNewReference] = useState({
    mode: '',
    id: null,
    deck_id: null,
    url: '',
    actionState: 'start',
    isModalOpen: false,
  });
  const [activeDeckFetch, setActiveDeckFetch] = useState({
    fetchState: 'start',
  });
  const [decksFetch, setDecksFetch] = useState({
    fetchState: 'start',
    page: 0,
    reachedEnd: false,
    columnSort: { column: 'decks.name', order: 'asc' },
  });
  const [allReferencesFetch, setAllReferencesFetch] = useState({
    fetchState: 'start',
    page: 0,
    tagFilters: [],
    reachedEnd: false,
    columnSort: { column: 'references.created_at', order: 'desc' },
  });
  const [latestDecksFetch, setLatestDecksFetch] = useState({
    fetchState: 'start',
  });

  const fetchAllDecks = async (page, column) => {
    setDecksFetch((prev) => ({
      ...prev,
      fetchState: 'loading',
    }));
    const columnSort = (() => {
      if (!column) return decksFetch.columnSort;
      if (decksFetch.columnSort.column !== column)
        return { column, order: 'asc' };
      if (decksFetch.columnSort.order === 'asc')
        return { column, order: 'desc' };
      return { column: 'decks.name', order: 'asc' };
    })();
    const params = {
      page: page || decksFetch.page,
      nameQuery: nameQueryTextfield || '',
      columnSort,
    };
    try {
      const { data: rawData } = await axiosWithAuth().get(
        `/reference-tool/decks?page=${params.page}&name_query=${params.nameQuery}&column_name=${params.columnSort.column}&column_sort=${params.columnSort.order}`
      );
      const newData = [
        ...(page > 1 && decksFetch.data ? decksFetch.data : []),
        ...rawData.map((deck) => ({
          ...deck,
          avg_rating_score: Number(deck.rating_score),
          ratings_count: Number(deck.rating_count),
          references_count: Number(deck.reference_count),
        })),
      ];
      setDecksFetch((prev) => ({
        ...prev,
        ...params,
        fetchState: 'success',
        data: newData,
        reachedEnd: rawData.length === 0,
      }));
    } catch (err) {
      setDecksFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  const fetchDeck = async () => {
    if (!deckId) return;
    setActiveDeckFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data: rawData } = await axiosWithAuth().get(
        `/reference-tool/decks/${deckId}`
      );
      const data = {
        ...rawData[0], // should pass an object, not an array of 1 element
        references: rawData[0].references.map((reference) => ({
          id: reference.id,
          url: reference.url,
          created_at: reference.created_at, // BUG: it's returning null
          rating_score: Number(reference.latest_rating), // changing name
          tags: reference.tags.filter((e) => e), // didn't have tags but still had 1 item: tags=[null]
        })),
      };
      setActiveDeckFetch({ fetchState: 'success', data });
    } catch (err) {
      setActiveDeckFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  const reorderReferenceWithinDeck = async (
    referenceIdx,
    localDeckId,
    newReferences
  ) => {
    try {
      const referenceId = newReferences[referenceIdx].id;
      await axiosWithAuth().put(`/reference-tool/reference-deck`, {
        reference_id: referenceId,
        deck_id: localDeckId,
        sort_order: referenceIdx + 1,
      });
      setActiveDeckFetch((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          references: newReferences.map((reference, idx) => ({
            ...reference,
            sort_order: idx + 1,
          })),
        },
      }));
      setActiveRefIdx(referenceIdx);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLatestDecks = async () => {
    setLatestDecksFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data: rawData } = await axiosWithAuth().get(
        `/reference-tool/latestdecks`
      );
      const data = rawData.slice(0, 6).map((deck) => {
        const ratedReferences = deck.references.filter(
          (ref) => ref.latest_rating
        );
        return {
          ...deck,
          avg_rating_score:
            ratedReferences.length &&
            ratedReferences.reduce((acc, ref) => acc + ref.latest_rating, 0) /
              ratedReferences.length,
          ratings_count: ratedReferences.length,
          references_count: deck.references.length,
          tags: sortAndRemoveDuplicates(
            ratedReferences
              .map((reference) => reference.tags)
              .flat()
              .filter((e) => e)
          ),
          rated_on: ratedReferences
            .map((ref) => ref.latest_rating_updated_at)
            .sort()[0], // should be "created_at", not "updated_at"
        };
      });
      setLatestDecksFetch({ fetchState: 'success', data });
    } catch (error) {
      setLatestDecksFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  const fetchAllReferences = async (page, tagFilters, column) => {
    setAllReferencesFetch((prev) => ({
      ...prev,
      fetchState: 'loading',
    }));
    const columnSort = (() => {
      if (!column) return allReferencesFetch.columnSort;
      if (allReferencesFetch.columnSort.column !== column)
        return { column, order: 'desc' };
      if (allReferencesFetch.columnSort.order === 'desc')
        return { column, order: 'asc' };
      return { column: 'references.created_at', order: 'desc' };
    })();
    const params = {
      page: page || allReferencesFetch.page,
      columnSort,
      tagFilters: tagFilters || allReferencesFetch.tagFilters,
    };
    try {
      const { data: rawData } = await axiosWithAuth().get(
        `/reference-tool/references?page=${params.page}${
          tagFilters ? `&tag_filters=${tagFilters.join(',')}` : ''
        }&column_name=${params.columnSort.column}&column_sort=${
          params.columnSort.order
        }`
      );
      const newData = [
        ...(page > 1 && allReferencesFetch.data ? allReferencesFetch.data : []),
        ...rawData.map((reference) => ({
          id: reference.id,
          url: reference.url,
          platform: reference.platform,
          created_at: reference.created_at,
          avg_rating: Number(reference.avg_rating),
          tags: reference.tags.filter((e) => e.label).map((e) => e.label),
        })),
      ];
      setAllReferencesFetch((prev) => ({
        ...prev,
        ...params,
        fetchState: 'success',
        reachedEnd: rawData.length === 0,
        data: newData,
      }));
    } catch (err) {
      setAllReferencesFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  const openDeck = (id) => push(`/ops/reference-tool/${id}`);

  useEffect(() => {
    (async () => {
      setActiveRefIdx(0);
      if (!deckId) {
        await fetchLatestDecks();
        return;
      }
      if (deckId === 'all') {
        return;
      }
      await fetchDeck();
    })();
  }, [deckId]);

  useEffect(() => {
    if (nameQueryTextfield === undefined) return () => undefined;
    const delayDebounceFn = setTimeout(async () => {
      await fetchAllDecks(1);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [nameQueryTextfield]);

  return (
    <MainLayout centered>
      <NewDeckModal
        newDeck={newDeck}
        fetchDecks={async () => {
          await fetchAllDecks();
          await fetchLatestDecks();
        }}
        setNewDeck={setNewDeck}
      />
      <NewReferenceModal
        newReference={newReference}
        setNewReference={setNewReference}
        fetchAllReferences={fetchAllReferences}
        fetchDeck={fetchDeck}
      />
      <StyledReferenceTool
        className={(() => {
          if (!deckId) return '';
          if (deckId === 'all') return 'all-opened';
          return 'deck-opened';
        })()}
      >
        <div className="nav-panel">
          <AllDecksNavPanel
            setNameQueryTextfield={setNameQueryTextfield}
            decksFetch={decksFetch}
            openDeck={openDeck}
            fetchAllDecks={fetchAllDecks}
            setNewDeck={setNewDeck}
            setNewReference={setNewReference}
          />
          <ReferenceDeckNavPanel
            activeDeckFetch={activeDeckFetch}
            activeRefIdx={activeRefIdx}
            setActiveRefIdx={setActiveRefIdx}
            setNewDeck={setNewDeck}
            setActiveDeckFetch={setActiveDeckFetch}
            setNewReference={setNewReference}
            reorderReferenceWithinDeck={reorderReferenceWithinDeck}
          />
          <AllReferencesNavPanel
            allReferencesFetch={allReferencesFetch}
            activeRefIdx={activeRefIdx}
            setActiveRefIdx={setActiveRefIdx}
            fetchAllReferences={fetchAllReferences}
            setAllReferencesFetch={setAllReferencesFetch}
            setNewDeck={setNewDeck}
            setNewReference={setNewReference}
          />
        </div>
        <LatestDecksPanel
          latestDecksFetch={latestDecksFetch}
          openDeck={openDeck}
          setNewDeck={setNewDeck}
        />
        <ReferenceView
          pinsEnabled
          changeAsset={() => {}}
          currentPlatform="tiktok"
          setCurrentPlatform={() => {}}
          setActiveDeckFetch={setActiveDeckFetch}
          mediaType="video"
          {...(() => {
            if (deckId === 'all' && allReferencesFetch?.data?.length > 0) {
              return {
                creative: allReferencesFetch.data[activeRefIdx].url,
                tags: allReferencesFetch.data[activeRefIdx].tags,
                reference: allReferencesFetch.data[activeRefIdx],
                ratingCardDisabled: true,
                setActiveTags: (newTags) => {
                  setAllReferencesFetch((prev) => ({
                    ...prev,
                    data: prev.data.map((e) => {
                      if (e.id === allReferencesFetch.data[activeRefIdx].id) {
                        return {
                          ...e,
                          tags: newTags,
                        };
                      }
                      return e;
                    }),
                  }));
                },
              };
            }
            if (
              typeof Number(deckId) === 'number' &&
              activeDeckFetch.fetchState === 'success'
            ) {
              return {
                creative: activeDeckFetch.data.references[activeRefIdx].url,
                socialProfilePicture: activeDeckFetch.data.organization_logo,
                socialUsername: activeDeckFetch.data.organization_name,
                tags: activeDeckFetch.data.references[activeRefIdx].tags,
                reference: activeDeckFetch.data.references[activeRefIdx],
                deckId: activeDeckFetch.data.id,
                setActiveTags: (newTags) => {
                  setActiveDeckFetch((prev) => ({
                    ...prev,
                    data: {
                      ...prev.data,
                      references: prev.data.references.map((e) => {
                        if (
                          e.id ===
                          activeDeckFetch.data.references[activeRefIdx].id
                        ) {
                          return {
                            ...e,
                            tags: newTags,
                          };
                        }
                        return e;
                      }),
                    },
                  }));
                },
              };
            }
            return {
              creative: null,
            };
          })()}
        />
      </StyledReferenceTool>
    </MainLayout>
  );
};

export default ReferenceTool;
