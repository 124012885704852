import { Button } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import NotesTextField from './NotesTextField';

const StyledAdApprovalMainActions = styled.div`
  margin: 1rem;
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid #d3d3d3;
  &.internal {
    border: 2px solid #ffa311;
  }
  > .approvalButtons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.625rem;
    flex-direction: column;
    > .approvalMainButton {
      grid-column: 1/3;
    }
    > .commentsMainButton {
      grid-column: 1/2;
      @media ${({ theme }) => theme.breakpoint.sm} {
        grid-column: 1/3;
      }
    }
    > .navMainButton {
      grid-column: 2/3;
      @media ${({ theme }) => theme.breakpoint.sm} {
        display: none;
      }
    }
  }
  &:not(.approver) {
    > .approvalButtons {
      > .approvalMainButton {
        display: none;
      }
      > .commentsMainButton {
        grid-column: 1/3;
      }
      > .navMainButton {
        grid-column: 1/3;
      }
    }
  }
`;

const AdApprovalMainActions = ({
  setCommentsWithTextfields,
  pinIsAttached,
  pinIsGrabbed,
  cleanUpPin,
  postComment,
  setPinIsGrabbed,
  newPinnedNoteIndex,
  creativeDeck,
  activeAdIndex,
  isLoadingApprovalState,
  currentMainAction,
  updateAdApproval,
  changeAd,
  backToCreativeDecksHandler,
  videoRef,
  internalCommentsShown,
  dropPinDisabled,
  dataFetchingStatus,
  setActiveTab,
  activeTab,
}) => {
  const { width } = useWindowDimensions();
  const [isLeavingRootComment, setIsLeavingRootComment] = useState(false);
  const user = useSelector((state) => state.user);
  const showRootTextfield =
    (width < 1279 && activeTab === 1) ||
    internalCommentsShown ||
    isLeavingRootComment;

  useEffect(() => {
    if (!isLeavingRootComment) return;
    setIsLeavingRootComment(false);
  }, [activeAdIndex]);

  return (
    <StyledAdApprovalMainActions
      className={`${user.permissions.includes('approveAds') ? 'approver' : ''}${
        internalCommentsShown ? ' internal' : ''
      }`}
    >
      {showRootTextfield && (
        <NotesTextField
          pinIsAttached={pinIsAttached}
          pinIsGrabbed={pinIsGrabbed}
          avatar={user?.profile_pic_url}
          cleanUpPin={cleanUpPin}
          newPinnedNoteIndex={newPinnedNoteIndex}
          handleCancelButton={() => {
            setIsLeavingRootComment(false);
            setCommentsWithTextfields((a) => a.filter((e) => e !== 'root'));
            cleanUpPin();
          }}
          placeholder="Leave a new comment here"
          postComment={(content) => postComment(content, null)}
          videoRef={videoRef}
          dropPinDisabled={dropPinDisabled}
          setPinIsGrabbed={setPinIsGrabbed}
          noAnnotationTools={width <= 1279}
        />
      )}
      {!showRootTextfield && (
        <div className="approvalButtons">
          {creativeDeck?.ads[activeAdIndex]?.approved_by
            .map(({ id }) => id)
            .includes(user.id) && (
            <Button
              fullWidth
              className="approvalMainButton"
              text={
                isLoadingApprovalState ? 'Loading...' : 'Undo Creative Approval'
              }
              variant={
                currentMainAction === 'approve' ? 'primary' : 'secondary'
              }
              size="md"
              disabled={
                dataFetchingStatus !== 'success' || isLoadingApprovalState
              }
              onClick={() => updateAdApproval('remove')}
            />
          )}
          {!creativeDeck?.ads[activeAdIndex]?.approved_by
            .map(({ id }) => id)
            .includes(user.id) && (
            <Button
              fullWidth
              className="approvalMainButton"
              text={isLoadingApprovalState ? 'Loading...' : 'Approve Creative'}
              variant={
                currentMainAction === 'approve' ? 'primary' : 'secondary'
              }
              size="md"
              disabled={
                dataFetchingStatus !== 'success' || isLoadingApprovalState
              }
              onClick={() => updateAdApproval('add')}
            />
          )}
          {width > 1279 && (
            <Button
              fullWidth
              text="Leave Feedback"
              variant="secondary"
              className="commentsMainButton"
              size="md"
              disabled={dataFetchingStatus !== 'success'}
              onClick={() => {
                setCommentsWithTextfields((a) => [...a, 'root']);
                setIsLeavingRootComment(true);
              }}
            />
          )}
          {width <= 1279 && (
            <Button
              fullWidth
              text="View Comments"
              variant="secondary"
              className="commentsMainButton"
              size="md"
              disabled={dataFetchingStatus !== 'success'}
              onClick={() => setActiveTab(1)}
            />
          )}
          {creativeDeck?.ads?.length === activeAdIndex + 1 && (
            <Button
              fullWidth
              disabled={dataFetchingStatus !== 'success'}
              text="Back to All Decks"
              variant={currentMainAction === 'nextAd' ? 'primary' : 'secondary'}
              size="md"
              className="navMainButton"
              onClick={() => backToCreativeDecksHandler()}
            />
          )}
          {creativeDeck?.ads?.length !== activeAdIndex + 1 && (
            <Button
              fullWidth
              className="navMainButton"
              disabled={dataFetchingStatus !== 'success'}
              text="Next Creative"
              variant={currentMainAction === 'nextAd' ? 'primary' : 'secondary'}
              size="md"
              onClick={() => changeAd(1)}
            />
          )}
        </div>
      )}
    </StyledAdApprovalMainActions>
  );
};

AdApprovalMainActions.propTypes = {
  newPinnedNoteIndex: PropTypes.number.isRequired,
  pinIsAttached: PropTypes.bool.isRequired,
  setCommentsWithTextfields: PropTypes.func.isRequired,
  pinIsGrabbed: PropTypes.bool.isRequired,
  cleanUpPin: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  setPinIsGrabbed: PropTypes.func.isRequired,
  creativeDeck: PropTypes.shape().isRequired,
  activeAdIndex: PropTypes.number.isRequired,
  isLoadingApprovalState: PropTypes.bool,
  currentMainAction: PropTypes.string.isRequired,
  updateAdApproval: PropTypes.func.isRequired,
  changeAd: PropTypes.func.isRequired,
  backToCreativeDecksHandler: PropTypes.func.isRequired,
  videoRef: PropTypes.shape().isRequired,
  dropPinDisabled: PropTypes.bool.isRequired,
  internalCommentsShown: PropTypes.bool.isRequired,
  dataFetchingStatus: PropTypes.string.isRequired,
};

AdApprovalMainActions.defaultProps = {
  isLoadingApprovalState: false,
};

export default AdApprovalMainActions;
