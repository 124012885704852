import { Button, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Pause, Play, Volume2, VolumeX } from 'react-feather';
import styled from 'styled-components/macro';

const StyledMediaControls = styled.div`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: min-content min-content auto min-content;
  z-index: 1000;
  width: 100%;
  align-items: center;
  border-radius: 0.675rem;
  .progressBar {
    position: relative;
    height: 1rem;
    cursor: pointer;
    > * {
      position: absolute;
      top: 33%;
      height: 33%;
      &.duration {
        width: 100%;
        background-color: #bdbdbd;
      }
      &.progress {
        background-color: #1171fa;
      }
    }
  }
`;

const MediaControls = ({ creativeMediaEl }) => {
  const [videoCurrentTime, setVideoCurrentTime] = useState();
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const videoProgress = `${
    typeof creativeMediaEl?.duration === 'number'
      ? Math.floor((videoCurrentTime / creativeMediaEl?.duration) * 100)
      : 0
  }%`;

  const togglePlay = () => {
    if (isPlaying) {
      creativeMediaEl.pause();
    } else {
      creativeMediaEl.play();
    }
  };

  const handleMute = () => {
    const newValue = !creativeMediaEl.muted;
    setIsMuted(newValue);
    creativeMediaEl.muted = newValue;
  };

  const handleVideoChange = (e) => {
    if (e.type === 'playing') {
      setIsPlaying(true);
    }
    if (['pause', 'ended'].includes(e.type)) {
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = (e) => {
    setVideoCurrentTime(creativeMediaEl.currentTime);
  };

  const handleProgressBarClick = (e) => {
    if (!creativeMediaEl.duration) return;
    const { x, width } = e.target.getBoundingClientRect();
    const clickPercent = (e.clientX - x) / width;
    creativeMediaEl.currentTime = creativeMediaEl.duration * clickPercent;
  };

  useEffect(() => {
    if (!creativeMediaEl) return undefined;
    creativeMediaEl.addEventListener('timeupdate', handleTimeUpdate);
    ['playing', 'pause', 'ended'].map((type) =>
      creativeMediaEl?.addEventListener(type, handleVideoChange)
    );
    return () => {
      const a = creativeMediaEl.removeEventListener(
        'timeupdate',
        handleTimeUpdate
      );
      const b = ['playing', 'pause', 'ended'].map((type) =>
        creativeMediaEl?.removeEventListener(type, handleVideoChange)
      );
    };
  }, [creativeMediaEl]);

  return (
    <StyledMediaControls>
      <Button
        variant="secondary"
        onClick={togglePlay}
        icon={isPlaying ? <Pause /> : <Play />}
        className="pause video-button"
      />
      <Button
        variant="secondary"
        onClick={handleMute}
        icon={isMuted ? <VolumeX /> : <Volume2 />}
        className="pause video-button"
      />
      <div className="progressBar" onClick={handleProgressBarClick}>
        <span className="duration" />
        <span className="progress" style={{ width: videoProgress }} />
      </div>
      <Text>
        {(() => {
          if (!videoCurrentTime) return '00:00';
          const n = new Date(0, 0);
          n.setSeconds(+videoCurrentTime);
          return n.toTimeString().slice(3, 8);
        })()}
      </Text>
    </StyledMediaControls>
  );
};

MediaControls.propTypes = {
  creativeMediaEl: PropTypes.shape.isRequired,
};

MediaControls.defaultProps = {};

export default MediaControls;
