import React, { useEffect } from 'react';
import { Check } from 'react-feather';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import WizardStep1 from '../components/ugcBundleWizard/WizardStep1';
import WizardStep2 from '../components/ugcBundleWizard/WizardStep2';
import WizardStep3 from '../components/ugcBundleWizard/WizardStep3';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledUGCBundleWizard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1310px;
  padding: 0 0.5rem;
  > .brief-stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.25rem;
    padding: 0.5rem 0.75rem;
    max-width: 36rem;
    gap: 1rem;
    width: 100%;
    > .step {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
      &.done {
        > .number {
          background-color: #1171fa;
        }
      }
      &.active {
        opacity: 1;
        > .number {
          background-color: #1171fa;
        }
        > .label {
          color: #1171fa;
        }
      }
      > .number {
        height: 1.375rem;
        width: 1.375rem;
        border-radius: 100rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #888;
        color: white;
        > .check-icon {
          height: 1rem;
          width: 1rem;
        }
      }
    }
    > .line {
      border-bottom: 1px solid #d3d3d3;
      min-width: 1rem;
      flex-grow: 1;
    }
  }
  > .step-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1rem;
    border: 1px solid #d3d3d3;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const steps = [
  { label: 'Add a Client', status: null },
  { label: 'Choose a Bundle', status: '0-not-started' },
  { label: 'Schedule Creative Brief', status: '1-start' },
];

const UGCBundleWizard = () => {
  const { organizationId, orderId } = useParams();
  const { push } = useHistory();
  const [organizationFetch, setOrganizationFetch] = React.useState({
    data: {},
    status: 'start',
  });
  const { axiosWithAuth } = useAxioswithAuth();

  const currentStepIdx = (() => {
    if (organizationId && orderId) return 2;
    if (organizationId && !orderId) return 1;
    return 0;
  })();

  const fetchOrganization = async () => {
    setOrganizationFetch((prev) => ({ ...prev, status: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get(
        `/new-organizations/${organizationId}`
      );
      if (orderId) {
        const order = data.orders.find(({ id }) => id === Number(orderId));
        if (!order) throw new Error('Order not found');
      }
      setOrganizationFetch((prev) => ({ ...prev, data, status: 'success' }));
    } catch (error) {
      alert('Error with the current link. Please contact support');
      push(`/home`);
    }
  };

  useEffect(() => {
    if (organizationId) {
      fetchOrganization();
      return;
    }
    setOrganizationFetch((prev) => ({ ...prev, status: 'success' }));
  }, [organizationId, orderId]);

  return (
    <MainLayout centered>
      <StyledUGCBundleWizard>
        <div className="brief-stepper">
          {steps.map((step, idx) => (
            <>
              <div
                key={step.label}
                className={`step ${(() => {
                  if (currentStepIdx === idx) return 'active';
                  if (currentStepIdx > idx) return 'done';
                  return '';
                })()}`}
              >
                <span className="number">
                  {idx < currentStepIdx ? (
                    <Check className="check-icon" />
                  ) : (
                    idx + 1
                  )}
                </span>
                <span className="label">{step.label}</span>
              </div>
              {idx !== 2 && <div className="line" />}
            </>
          ))}
        </div>
        <div className="step-container">
          {organizationFetch.status === 'success' && currentStepIdx === 0 && (
            <WizardStep1 />
          )}
          {organizationFetch.status === 'success' && currentStepIdx === 1 && (
            <WizardStep2 organizationName={organizationFetch?.data?.name} />
          )}
          {organizationFetch.status === 'success' && currentStepIdx === 2 && (
            <WizardStep3 organizationName={organizationFetch?.data?.name} />
          )}
        </div>
      </StyledUGCBundleWizard>
    </MainLayout>
  );
};

export default UGCBundleWizard;
