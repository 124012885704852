import { Button, Checkbox } from 'colabs-ui-react';
import React, { useEffect } from 'react';
import { ChevronLeft } from 'react-feather';
import { useParams } from 'react-router';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledTemplatesPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  overflow: auto;
  &[data-collapsed='true'] {
    > .panel-header {
      > .cui-button {
        transform: rotate(180deg);
      }
    }
    > .templates {
      grid-template-columns: 1fr;
      > .template {
        > video {
          aspect-ratio: 1 / 1;
        }
      }
    }
  }
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
      text-transform: uppercase;
    }
  }
  > .templates {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.75rem;
    padding: 0.75rem;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .template {
      border-radius: 8px;
      height: 100%;
      width: 100%;
      outline: 1px solid #d3d3d3;
      display: grid;
      grid-template-columns: min-content auto;
      position: relative;
      cursor: pointer;
      background-color: white;
      &:not([data-active='true']):hover {
        background-color: #f5f5f5;
      }
      &[data-active='true'] {
        outline: 3px solid #1171fa;
        padding: 0;
        background-color: #edf4ff;
      }
      > .thumbnail {
        height: 100%;
        width: 115px;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      > .info {
        padding: 0.75rem;
        > .name {
          font-size: 16px;
          padding-bottom: 4px;
          display: block;
          line-height: 1.1;
          font-family: ${({ theme }) => theme.font.main.medium};
        }
        > .description {
          font-size: 14px;
          margin: 0;
        }
      }
      > .cui-checkbox {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
      }
    }
  }
`;

const TemplatesPanel = ({
  allTemplatesFetch,
  setAllTemplatesFetch,
  selectedTemplatesIds,
  setSelectedTemplatesIds,
  activeTemplateIdx,
  setActiveTemplateIdx,
  setNextBtnDisabled,
  setTrendsCollapsed,
  trendsCollapsed,
}) => {
  const { orderId } = useParams();
  const { axiosWithAuth } = useAxioswithAuth();

  const fetchAllTemplates = async () => {
    setAllTemplatesFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get(
        `/scripts/templates/${orderId}`
      );
      setAllTemplatesFetch({ fetchState: 'success', data });
    } catch (err) {
      setAllTemplatesFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  const handleCheckboxChange = (templateId) => {
    if (selectedTemplatesIds.includes(templateId)) {
      setSelectedTemplatesIds((prev) => prev.filter((id) => id !== templateId));
    } else {
      setSelectedTemplatesIds((prev) => [...prev, templateId]);
    }
  };

  useEffect(() => {
    if (!orderId) return;
    fetchAllTemplates();
  }, [orderId]);

  useEffect(() => {
    if (selectedTemplatesIds.length >= 1) {
      setNextBtnDisabled(false);
    } else {
      setNextBtnDisabled(true);
    }
  }, [selectedTemplatesIds]);

  return (
    <StyledTemplatesPanel data-collapsed={trendsCollapsed}>
      <div className="panel-header">
        <span className="title">
          All Trends ({allTemplatesFetch.data.length})
        </span>
        <Button
          onClick={() => setTrendsCollapsed((prev) => !prev)}
          variant="secondary"
          icon={<ChevronLeft />}
        />
      </div>
      <div className="templates">
        {allTemplatesFetch.fetchState === 'loading' && <div>Loading...</div>}
        {allTemplatesFetch.fetchState === 'error' && (
          <div>Error fetching all templates</div>
        )}
        {allTemplatesFetch.data.map((template, idx) => (
          <div
            className="template"
            key={template.id}
            data-active={activeTemplateIdx === idx ? 'true' : 'false'}
            onClick={() => setActiveTemplateIdx(idx)}
          >
            <img
              src={template.thumbnail || blueImage}
              onError={(e) => {
                e.target.src = blueImage;
              }}
              className="thumbnail"
              alt={`${template.name}-thumbnail`}
            />
            <Checkbox
              checked={selectedTemplatesIds.includes(template.id)}
              className="checkbox"
              onChange={() => handleCheckboxChange(template.id)}
            />
            <span className="info">
              <span className="name">
                {idx + 1}. {template.name}
              </span>
              <p className="description">{template.description}</p>
            </span>
          </div>
        ))}
      </div>
    </StyledTemplatesPanel>
  );
};

export default TemplatesPanel;
