/* eslint-disable react/prop-types */
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { v4 as uuidv4 } from 'uuid';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import AdminOrgProposalList from './AdminOrgProposalList';
import Badge from '../presentational/Badge';

export const ContainerDiv = styled.div`
    fieldset{
      margin-bottom:15px;
      margin-right:10px;
    }

  .cbcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }
  .race {
    width: 20%;
    margin: 0 auto;
    @media (max-width: 800px) {
      width: 80%;
    }
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 6px;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .cbcontainer:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .cbcontainer input:checked ~ .checkmark {
    background-color: #23a2b8;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .cbcontainer input:checked ~ .checkmark:after {
    /* display: block; */
  }
  .cbcontainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  form {
    margin: 2%;
    input[type='submit'] {
      font-size: 100%;
      padding: 8px;
      background-color: #3cb371;
      color: white;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
        border: 1px solid #98fb98;
        color: #98fb98;
      }
      &:active {
        background-color: #2e8b57;
        outline: none;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .flavors {
    @media (max-width: 800px) {
      width: 45%;
      display: flex;
      align-items: center;
      margin: 2%;
    }
  }

  form > div {
    margin-top: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    width: 80%;
    border: 1px solid #f5f5f5;
    @media (max-width: 800px) {
      width: 100%;
    }
    > div:first-child {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #3cb371;
      display: flex;
      justify-content: center;
      h3 {
        text-decoration: none;
        color: white;
        font-size: 180%;
        @media (max-width: 800px) {
          text-align: center;
        }
      }
    }
    > div:last-child {
      padding: 10px;
    }
    fieldset {
      input {
        background-color: #f5f5f5;
      }
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
}
`;

export default function CreateProposalInputs({
  setProposal,
  proposal,
  setActiveStep,
}) {
  const fundingRoundChoices = [
    'Pre-Seed',
    'Seed',
    'Series-A',
    'Series-B',
    'Series-C+',
    'Other',
  ];
  const socialPackageChoices = [
    'Facebook',
    'Instagram',
    'Google Ads',
    'Linkedin',
    `Youtube`,
    `Bing`,
    `TikTok`,
    'Twitter',
    'Reddit',
    'Pinterest',
    'Snapchat',
  ];
  // add OTher

  const packageChoices = [
    { name: 'Set-Up Pod' },
    { name: 'Full Pod' },
    // 'Content Pod',
    { name: 'Website' },
    { name: 'Landing Page' },
    { name: 'Video' },
    { name: 'Email Marketing' },
  ];

  const mediaGoalsChoices = [
    'Leads',
    'Marketing Qualified Leads',
    'Sales Qualified Leads',
    'Customers',
    'ROAS',
    // '# of Videos',
    'Full Website',
  ];

  function handleChange(e) {
    e.preventDefault();
    setProposal({ ...proposal, [e.target.name]: e.target.value });
  }
  const findIndx = proposal.packages.findIndex((p) => p.name === 'Website');
  const findLpIndx = proposal.packages.findIndex(
    (p) => p.name === 'Landing Page'
  );

  function handlePackageChange(event) {
    setProposal((e) => {
      let findindex = null;
      console.log(
        '=>',
        event,
        proposal,
        proposal.packages.some((e) => e.name.includes('Landing Page'))
      );
      if (proposal.packages.some((e) => e.name.includes('Landing Page'))) {
        findindex = proposal.packages.findIndex(
          (p) => p.name === 'Landing Page'
        );
      } else {
        findindex = proposal.packages.findIndex((p) => p.name === 'Website');
      }
      proposal.packages[findindex] = {
        name: proposal.packages.some((e) => e.name.includes('Landing Page'))
          ? 'Landing Page'
          : 'Website',
        number: event,
      };
      return {
        ...proposal,
        packages: [...proposal.packages],
      };
    });
  }

  const genBadge = (array, item) => {
    const index = array.findIndex((e) => e.name === item);
    console.log(array, item, index, 'TESTBADGEFUN');
    if (index !== -1) return <Badge text={index + 1} />;
    return null;
  };

  return (
    <>
      {console.log(proposal, '??')}
      {proposal.organization_id && proposal.proposal_name ? (
        <Container component="main">
          <CssBaseline />
          <ContainerDiv>
            <Typography component="h1" variant="h5">
              Choose your Proposal Inputs
            </Typography>
            <br />
            <form
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '0',
              }}
            >
              <label htmlFor="phase-select">
                Phase:{' '}
                <select
                  name="phase"
                  id="phase-select"
                  onChange={handleChange}
                  value={proposal.phase}
                >
                  <option value="">--Please choose an option--</option>
                  <option value="1">Phase 1</option>
                  <option value="2">Phase 2</option>
                  <option value="3">Phase 3</option>
                  <option value="4">Phase 4</option>
                </select>
              </label>
              <br />
              <label htmlFor="media_link_href">
                Media Link Url: &nbsp;
                <input
                  id="media_link_href"
                  value={proposal.media_plan_link}
                  onChange={(e) =>
                    setProposal({
                      ...proposal,
                      media_plan_link: e.target.value,
                    })
                  }
                />
              </label>
              <p>Operating Channels:</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {socialPackageChoices.map((ele) => (
                  <fieldset>
                    <label className="cbcontainer">
                      {ele} &nbsp;
                      <input
                        checked={!!proposal.social_platforms.includes(ele)}
                        type="checkbox"
                        key={uuidv4()}
                        name="social_platforms"
                        required
                        value={ele}
                        onChange={(event) =>
                          proposal.social_platforms.includes(ele)
                            ? setProposal({
                                ...proposal,
                                social_platforms: proposal.social_platforms.filter(
                                  (e) => e !== ele
                                ),
                              })
                            : setProposal({
                                ...proposal,
                                social_platforms: [
                                  ...proposal.social_platforms,
                                  event.target.value,
                                ],
                              })
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </fieldset>
                ))}
              </div>
              <br />
              <p>Goal of the Media Plan:</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {mediaGoalsChoices.map((ele) => (
                  <fieldset>
                    <label className="cbcontainer">
                      {ele} &nbsp;
                      <input
                        checked={!!proposal.mediaGoals.includes(ele)}
                        type="checkbox"
                        id={uuidv4()}
                        name="mediaGoals"
                        required
                        value={ele}
                        onChange={(e) =>
                          proposal.mediaGoals.includes(ele)
                            ? setProposal({
                                ...proposal,
                                mediaGoals: proposal.mediaGoals.filter(
                                  (e) => e !== ele
                                ),
                              })
                            : setProposal({
                                ...proposal,
                                mediaGoals: [
                                  ...proposal.mediaGoals,
                                  e.target.value,
                                ],
                              })
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </fieldset>
                ))}
              </div>
              <br />
              <p>Funding Round:</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {fundingRoundChoices.map((ele) => (
                  <fieldset>
                    <label className="cbcontainer">
                      {ele} &nbsp;
                      <input
                        checked={!!proposal.funding_round.includes(ele)}
                        type="checkbox"
                        id={uuidv4()}
                        name="funding_round"
                        required
                        value={ele}
                        onChange={(e) =>
                          proposal.funding_round.includes(ele)
                            ? setProposal({
                                ...proposal,
                                funding_round: proposal.funding_round.filter(
                                  (e) => e !== ele
                                ),
                              })
                            : setProposal({
                                ...proposal,
                                funding_round: [
                                  ...proposal.funding_round,
                                  e.target.value,
                                ],
                              })
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </fieldset>
                ))}
              </div>
              <br />
              <p>Services:</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {packageChoices.map((ele, indx) => (
                  <fieldset>
                    {console.log(proposal.packages, ele, 'TESTBADGE')}
                    <label className="cbcontainer">
                      {ele.name} &nbsp; {genBadge(proposal.packages, ele.name)}
                      <input
                        checked={
                          !!proposal.packages
                            .map((e) => e.name)
                            .includes(ele.name)
                        }
                        type="checkbox"
                        id={uuidv4()}
                        name="packages"
                        required
                        value={ele.name}
                        onChange={(event) =>
                          proposal.packages
                            .map((e) => e.name)
                            .includes(ele.name)
                            ? setProposal({
                                ...proposal,
                                packages: proposal.packages.filter(
                                  (e) => e.name !== ele.name
                                ),
                              })
                            : setProposal({
                                ...proposal,
                                packages: [
                                  ...proposal.packages,
                                  { name: event.target.value },
                                ],
                              })
                        }
                      />
                      <span className="checkmark" />
                    </label>
                    {proposal.packages.length &&
                    ele.name === 'Landing Page' &&
                    proposal.packages.some((e) =>
                      e.name.includes('Landing Page')
                    ) ? (
                      <>
                        <label
                          style={{
                            width: '50%',
                            display: `flex`,
                            flexDirection: `column`,
                            margin: `0 auto`,
                          }}
                          htmlFor="numOfPages"
                        >
                          # of pages <br />
                          <input
                            id="numOfPages"
                            type="number"
                            name="number"
                            value={proposal.packages[findLpIndx]?.number}
                            onChange={(e) =>
                              handlePackageChange(e.target.value)
                            }
                          />
                        </label>
                      </>
                    ) : null}
                    {proposal.packages.length &&
                    ele.name === 'Website' &&
                    proposal.packages.some((e) =>
                      e.name.includes('Website')
                    ) ? (
                      <>
                        <label
                          style={{
                            width: '50%',
                            display: `flex`,
                            flexDirection: `column`,
                            margin: `0 auto`,
                          }}
                          htmlFor="numOfPages"
                        >
                          # of pages <br />
                          <input
                            id="numOfPages"
                            type="number"
                            name="number"
                            value={proposal.packages[findIndx]?.number}
                            onChange={(e) =>
                              handlePackageChange(e.target.value)
                            }
                          />
                        </label>
                      </>
                    ) : null}
                  </fieldset>
                ))}
              </div>
              <br />
            </form>
          </ContainerDiv>
        </Container>
      ) : (
        <AdminOrgProposalList
          proposal={proposal}
          setProposal={setProposal}
          setActiveStep={setActiveStep}
        />
      )}
    </>
  );
}
