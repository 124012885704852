import React from 'react';
import styled from 'styled-components';

const StyledBottomBarMessage = styled.div`
  display: flex;
  gap: 0.5rem;
  .chip {
    border-radius: 9999px;
    padding: 0.25rem 0.75rem;
    border: 1px solid #d3d3d3;
    &.green {
      border: 1px solid #44dc7a;
      color: #44dc7a;
      background-color: #c7ffdb;
    }
    &.red {
      border: 1px solid #f55050;
      color: #f55050;
      background-color: #ffdbdb;
    }
    &.orange {
      border: 1px solid #ffa311;
      color: #ffa311;
      background-color: #ffe9c7;
    }
  }
`;

const BottomBarMessage = ({ selectedActors }) => {
  return (
    <StyledBottomBarMessage>
      <div className={`chip ${selectedActors.length >= 1 ? 'green' : 'red'}`}>
        {selectedActors.length}{' '}
        {selectedActors.length > 1 ? 'Actors Selected:' : ''}{' '}
        {selectedActors.length === 1 ? 'Actor Selected:' : ''}{' '}
        {selectedActors.length === 0 ? 'Actors Selected' : ''}{' '}
      </div>
      {selectedActors.map((actor) => (
        <div className="chip" key={actor}>
          {actor}{' '}
        </div>
      ))}
    </StyledBottomBarMessage>
  );
};

export default BottomBarMessage;
