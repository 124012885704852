import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StripeCheckoutPage = () => {
  const { axiosWithAuth } = useAxioswithAuth();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user && user.organization.id === 28) {
      (async () => {
        try {
          const redirectURL = await axiosWithAuth().post(
            `/payments/${user.organization.id}/${user.organization.stripe_customer_id}/stripe-checkout`
          );
          console.log(redirectURL, '<= url');
          window.location.href = redirectURL.data.url;
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      window.location.href = '/';
    }
  }, [user]);

  return (
    <div style={{ marginLeft: '40px' }}>
      <p>loading ...</p>
    </div>
  );
};

export default StripeCheckoutPage;
