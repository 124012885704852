import { Card, Divider } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import AdApprovalDetailsCard from './AdApprovalDetailsCard';
import AdApprovalMainActions from './AdApprovalMainActions';
import AdApprovalPanelHeader from './AdApprovalPanelHeader';
import AdApprovalStatus from './AdApprovalStatus';
import CreativeCommentsPanel from './CreativeCommentsPanel';

const StyledAdsCommentsPanel = styled(Card)`
  grid-row: 1/3;
  grid-column: 2/3;
  height: 100%;
  width: 28rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 2px solid #ffffffff;
  &.internal {
    border: 2px solid #ffa311;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 100%;
    grid-column: auto;
    grid-row: auto;
    justify-self: center;
  }
`;

const AdsCommentsPanel = ({
  internalCommentsShown,
  currentAd,
  activeAssetIndex,
  shownAdPinnedNotes,
  adContainerRef,
  videoRef,
  fetchCreativeDeck,
  setIsModalOpen,
  creativeDeck,
  activeAdIndex,
  setActiveAssetIndex,
  pushAdId,
  cleanUpPin,
  setCommentsWithTextfields,
  setCurrentMainAction,
  dataFetchingStatus,
  activeTab,
  setInternalCommentsShown,
  setActiveTab,
  pinIsAttached,
  pinIsGrabbed,
  setPinIsGrabbed,
  isViewInGrid,
  currentMainAction,
  changeAd,
  setAdPinnedNotes,
  setInternalAdPinnedNotes,
}) => {
  const [isLoadingApprovalState, setIsLoadingApprovalState] = useState();
  const [commentsFetchingStatus, setCommentsFetchingStatus] = useState('start');
  const [comments, setComments] = useState(null);
  const [internalComments, setInternalComments] = useState(null);
  const { adId } = useParams();
  const shownComments = internalCommentsShown ? internalComments : comments;
  const currentAsset = currentAd?.assets[activeAssetIndex];
  const newPinnedNoteIndex = shownAdPinnedNotes?.length + 1;
  const user = useSelector((state) => state.user);
  const { width } = useWindowDimensions();
  const { push } = useHistory();
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);

  const getPinData = () => {
    const newPin = document.querySelector('#newPin');
    if (!newPin || ['none', ''].includes(newPin.style.display)) {
      return undefined;
    }
    const rect = adContainerRef.current.getBoundingClientRect();
    const left = (
      ((newPin.style.left.slice(0, -2) - rect.left) / rect.width) *
      100
    ).toFixed(2);
    const top = (
      ((newPin.style.top.slice(0, -2) - rect.top) / rect.height) *
      100
    ).toFixed(2);
    const asset_id = currentAsset.id;
    const time_in_seconds = videoRef?.current?.currentTime || null;
    return { left, top, asset_id, time_in_seconds };
  };

  const fetchComments = useCallback(async () => {
    try {
      setCommentsFetchingStatus('loading');
      const { data } = await axiosWithAuthRef
        .current()
        .post('/cdcomments/comments', {
          ad_id: adId,
        });
      setComments(data.comments);
      setInternalComments(data.internal_comments);
      setAdPinnedNotes(data.ad_pinned_notes);
      setInternalAdPinnedNotes(data.internal_ad_pinned_notes);
      setCommentsFetchingStatus('success');
    } catch (err) {
      setCommentsFetchingStatus('error');
    }
  }, [axiosWithAuthRef, adId]);

  const likeComment = async (commentId) => {
    try {
      await axiosWithAuth().post('/cdcomments/likeComment', {
        comment_id: commentId,
      });
    } catch (e) {
      // setRequestState('error');
    }
    await fetchCreativeDeck(); //change for just fetchComments, test later
  };

  function backToCreativeDecksHandler() {
    if (user?.permissions.includes('approveAds')) return setIsModalOpen(true);
    return push('/adApproval');
  }

  const updateAdApproval = async (operator) => {
    setIsLoadingApprovalState(true);
    try {
      await axiosWithAuth().post('/cms/approveAd', {
        ad_id: adId,
      });
    } catch (e) {
      // setRequestState('error');
    }
    await fetchCreativeDeck();
    setIsLoadingApprovalState(false);
    if (operator !== 'add') return;
    if (creativeDeck.ads.length === activeAdIndex + 1) {
      setIsModalOpen(true);
      return;
    }
    setActiveAssetIndex(0);
    pushAdId(activeAdIndex + 1);
  };

  const postComment = async (content, parent) => {
    try {
      await axiosWithAuth().post('/cdcomments/createComment', {
        content,
        userId: user?.id,
        ad: adId,
        is_internal: internalCommentsShown,
        parent,
        pin: getPinData(),
      });
    } catch (e) {
      // console.log(e);
    }
    cleanUpPin();
    setCommentsWithTextfields((prev) =>
      prev.filter((comment) => comment !== parent)
    );
    setCurrentMainAction('nextAd');
    fetchComments();
  };

  const editComment = async (commentId, newContent) => {
    try {
      await axiosWithAuth().post('/cdcomments/editComment', {
        id: commentId,
        content: newContent,
      });
    } catch (e) {
      // console.log(e);
    }
    fetchComments();
  };

  const deleteComment = async (commentId) => {
    try {
      await axiosWithAuth().post('/cdcomments/deleteComment', {
        id: commentId,
      });
    } catch (e) {
      // console.log(e);
    }
    fetchComments();
  };

  useEffect(() => {
    if (!creativeDeck);
    fetchComments();
  }, [creativeDeck, activeAdIndex, fetchComments]);

  return (
    <StyledAdsCommentsPanel
      className={`commentsPanel${internalCommentsShown ? ' internal' : ''}`}
    >
      {dataFetchingStatus === 'success' && (
        <AdApprovalDetailsCard
          activeAdIndex={activeAdIndex}
          creativeDeck={creativeDeck}
        />
      )}
      {(width > 1279 || activeTab === 1) && <Divider />}
      {(width > 1279 || activeTab === 1) && (
        <AdApprovalPanelHeader
          internalCommentsShown={internalCommentsShown}
          setInternalCommentsShown={setInternalCommentsShown}
          setActiveTab={setActiveTab}
        />
      )}
      {(width > 1279 || activeTab === 1) && (
        <AdApprovalStatus
          comments={shownComments}
          userId={user?.id}
          approvers={currentAd?.approved_by}
        />
      )}
      {(width > 1279 || activeTab === 1) && (
        <CreativeCommentsPanel
          commentsFetchingStatus={commentsFetchingStatus}
          shownComments={shownComments}
          editComment={editComment}
          pinIsAttached={pinIsAttached}
          pinIsGrabbed={pinIsGrabbed}
          shownAdPinnedNotes={shownAdPinnedNotes}
          internalCommentsShown={internalCommentsShown}
          likeComment={likeComment}
          creativeDeck={creativeDeck}
          deleteComment={deleteComment}
          postComment={postComment}
          user={user}
          setActiveAssetIndex={setActiveAssetIndex}
          cleanUpPin={cleanUpPin}
          setPinIsGrabbed={setPinIsGrabbed}
          newPinnedNoteIndex={newPinnedNoteIndex}
          setCommentsWithTextfields={setCommentsWithTextfields}
          videoRef={videoRef}
          isViewInGrid={isViewInGrid}
          activeAdIndex={activeAdIndex}
        />
      )}
      <Divider />
      <AdApprovalMainActions
        dataFetchingStatus={dataFetchingStatus}
        setCommentsWithTextfields={setCommentsWithTextfields}
        pinIsAttached={pinIsAttached}
        pinIsGrabbed={pinIsGrabbed}
        newPinnedNoteIndex={newPinnedNoteIndex}
        adPinnedNotes={shownAdPinnedNotes}
        user={user}
        cleanUpPin={cleanUpPin}
        postComment={postComment}
        internalCommentsShown={internalCommentsShown}
        setPinIsGrabbed={setPinIsGrabbed}
        creativeDeck={creativeDeck}
        activeAdIndex={activeAdIndex}
        isLoadingApprovalState={isLoadingApprovalState}
        currentMainAction={currentMainAction}
        updateAdApproval={updateAdApproval}
        changeAd={changeAd}
        backToCreativeDecksHandler={backToCreativeDecksHandler}
        videoRef={videoRef}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        dropPinDisabled={isViewInGrid && currentAd.assets?.length > 1}
      />
    </StyledAdsCommentsPanel>
  );
};

AdsCommentsPanel.propTypes = {};

AdsCommentsPanel.defaultProps = {};

export default AdsCommentsPanel;
