import { Media, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import blueImage from '../../../assets/images/BlueImage.png';
import linkedinBottom from '../../../assets/images/linkedin_Bottom.png';
import linkedinReactions from '../../../assets/images/linkedin_Reactions.png';
import linkedinTop from '../../../assets/images/linkedin_Top.png';
import Pin from '../../Pin';
// import Image from '../presentational/Image';

const StyledLinkedin = styled.div`
  background-color: #c9ccd1;
  width: 100%;
  height: 100%;
  > .appTop,
  > .appBottom {
    background: #fafafa;
    object-fit: contain;
    width: 100%;
    margin-bottom: -0.4rem;
  }
  > .appTop {
    z-index: 10;
  }
  > .viewport {
    padding-top: 0.4rem;
    height: 82.4%;
    overflow-y: auto;
    ${({ theme }) => theme.scrollbar}
  }
  > .appBottom {
    position: absolute;
    bottom: 0;
    z-index: 10;
  }
  .cui-text {
    font-size: 0.8rem;
    line-height: 16px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    &.medium {
      font-weight: 500;
    }
  }
  .reactions {
    background: #fafafa;
    object-fit: contain;
    width: 100%;
    margin-bottom: -0.4rem;
  }
  .postHeader {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    padding: 0.5rem;
    background-color: white;
    > .avatar {
      height: 2.25rem;
      width: 2.25rem;
      object-fit: contain;
    }
  }
  .postText {
    background-color: white;
    padding: 0 0.5rem 0.5rem 0.5rem;
    > .cui-text {
      white-space: pre-wrap;
    }
  }
  /* > .creativeContainer {
    display: inline-block;
    position: relative;
    width: 100%;
    overflow-y: hidden;
    margin-bottom: -0.4rem;
    &:after {
      content: '';
      display: block;
      margin-top: 100%;
    }
    > .creative {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  } */
  .creativeWrapper {
    position: relative;
    width: 100%;
    max-height: 21rem;
    overflow: hidden;
    > .pin {
      position: absolute;
    }
    > .creative {
      width: 100%;
      height: 100%;
      // try to get rid of hardcoded max-height
      object-fit: contain;
      margin-bottom: -0.4rem;
    }
  }
  .headline {
    background: #eff1f3;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .cta {
      /* background: #0084ff; */
      padding: 0.3rem 1rem;
      border-radius: 0.5rem;
      border: 1px solid #d4d6d7;
      width: fit-content;
    }
  }
`;

const Linkedin = ({
  selectedCopys,
  cta,
  creative,
  socialProfilePicture,
  socialUsername,
  adTextIsExpanded,
  setAdTextIsExpanded,
  trimCopy,
  isLongerThanLimit,
  pins,
  isInteractive,
  pinsEnabled,
  newPinIndex,
}) => {
  return (
    <StyledLinkedin>
      <img className="appTop" alt="" src={linkedinTop} />
      <div className="viewport">
        <div>
          <div className="postHeader">
            <Media
              onError={(e) => {
                e.target.src = blueImage;
              }}
              alt=""
              className="avatar"
              src={socialProfilePicture || blueImage}
            />
            <span>
              <Text weight="medium">{socialUsername}</Text>
              <Text style={{ color: '#888C92' }}>Promoted</Text>
            </span>
          </div>
          <div className="postText">
            <Text tag="span">
              {adTextIsExpanded
                ? selectedCopys?.adText?.content
                : trimCopy(selectedCopys?.adText, 'expand')}
              {isLongerThanLimit(selectedCopys?.adText, 'expand') &&
              !adTextIsExpanded ? (
                <>
                  ...{' '}
                  <Text
                    onClick={() => setAdTextIsExpanded(true)}
                    style={{ cursor: 'pointer' }}
                    tag="span"
                    style={{ color: '#888C92' }}
                    noWrap
                  >
                    more
                  </Text>
                </>
              ) : (
                ''
              )}
            </Text>
          </div>
          <div id="creativeWrapper" className="creativeWrapper">
            {pins?.map((pin) => (
              <Pin
                style={{ top: `${pin.top}%`, left: `${pin.left}%` }}
                className={(() => {
                  if (pin.is_internal) return 'internal';
                  if (pin.user_organization_id === 1) return 'colabs';
                  return '';
                })()}
                number={pin?.number}
              />
            ))}
            {pinsEnabled && (
              <Pin className="newPin" number={newPinIndex} id="newPin" />
            )}
            <Media
              onError={(e) => {
                e.target.src = blueImage;
              }}
              videoProps={
                isInteractive
                  ? {
                      autoPlay: true,
                      loop: true,
                    }
                  : {}
              }
              alt=""
              id="creativeMedia"
              className="creative"
              src={creative}
            />
          </div>
          <div className="headline">
            <Text>{selectedCopys?.headline?.content}</Text>
            <div className="cta">
              <Text weight="medium" noWrap>
                {cta?.substring(0, 18)}
              </Text>
            </div>
          </div>
          <Media
            onError={(e) => {
              e.target.src = blueImage;
            }}
            src={linkedinReactions}
            className="reactions"
            alt=""
          />
        </div>
      </div>
      <img alt="" className="appBottom" src={linkedinBottom} />
    </StyledLinkedin>
  );
};

Linkedin.propTypes = {
  selectedCopys: PropTypes.shape().isRequired,
  cta: PropTypes.string.isRequired,
  creative: PropTypes.string.isRequired,
  socialProfilePicture: PropTypes.string.isRequired,
  socialUsername: PropTypes.string.isRequired,
  adTextIsExpanded: PropTypes.bool.isRequired,
  setAdTextIsExpanded: PropTypes.func.isRequired,
  trimCopy: PropTypes.func.isRequired,
  isLongerThanLimit: PropTypes.func.isRequired,
};

Linkedin.defaultProps = {};

export default Linkedin;
