import { Button, Modal, Text, Textfield } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import S3 from 'react-aws-s3';
import { Plus } from 'react-feather';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import SwitchFieldset from '../presentational/SwitchFieldset';
import AdCreatorMenu from './AdCreatorMenu';
import CreativeForEdit from './CreativeForEdit';
import OrganizationDropdown from './OrganizationDropdown';
import UploadMediaModal from './UploadMediaModal';

const StyledDeckCreatorModal = styled(Modal)`
  width: 100%;
  > .cui-card {
    max-width: 40rem;
    width: 100%;
    padding: 2rem;
    max-height: 90vh;
    overflow-y: auto;
    @media ${({ theme }) => theme.breakpoint.sm} {
      padding: 1rem;
    }
    > .title {
      margin-bottom: 1rem;
    }
    > .form {
      display: grid;
      grid-template-columns: 2.8fr 0.7fr 1.5fr;
      grid-gap: 1rem;
      @media ${({ theme }) => theme.breakpoint.sm} {
        grid-template-columns: 1fr;
      }
      > .description {
        grid-column: 1 / 4;
        @media ${({ theme }) => theme.breakpoint.sm} {
          grid-column: auto;
        }
      }
      > .actions {
        grid-column: 1 / 4;
        justify-self: end;
        display: flex;
        width: 100%;
        align-items: center;
        @media ${({ theme }) => theme.breakpoint.sm} {
          grid-column: auto;
        }
        > * {
          margin-left: 1rem;
        }
        > .switch {
          margin-left: 0;
          flex-grow: 1;
        }
        > .grow {
          flex-grow: 1;
        }
      }
    }
  }
  .cui-dropdown {
    > .select {
      max-height: 20rem;
      overflow-y: auto;
    }
  }
  .creativesWrapper {
    grid-column: 1 / 4;
    width: 100%;
    margin-bottom: 1rem;
    @media ${({ theme }) => theme.breakpoint.sm} {
      grid-column: auto;
    }
    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    > .creativesContainer {
      border: 1px solid #d6d6d6;
      border-radius: 0.625rem;
      padding: 1rem;
      > .creatives {
        max-height: 16rem;
        overflow-y: auto;
        ${({ theme }) => theme.scrollbar}
      }
      .creativesActions {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

const DeckCreatorModal = ({ isOpen, setIsOpen, deck, fetchDecks }) => {
  const [selectedAdIndex, setSelectedAdIndex] = useState();
  const [sendingState, setSendingState] = useState('start');
  const [uploadMediaSelectedAd, setUploadMediaSelectedAd] = useState(false);
  const { axiosWithAuth } = useAxioswithAuth();
  const [ads, setAds] = useState(deck?.ads || []);
  const [form, setForm] = useState({
    has_changes: false,
    title: deck?.title || '',
    description: deck?.description || '',
    round: deck?.round || '',
    organization_id: deck?.OrganizationId || '',
    published_at: deck?.published_at || null,
  });

  const ReactS3Client = new S3({
    bucketName: 'colabs-ads',
    s3Url: 'https://colabs-ads.s3.amazonaws.com',
    region: 'us-east-1',
    accessKeyId: process.env.REACT_APP_AWS_TOKEN_KEYID,
    secretAccessKey: process.env.REACT_APP_AWS_TOKEN_KEYSECRET,
  });

  const handleTexfieldChange = (e) => {
    e.preventDefault();
    setForm({ ...form, has_changes: true, [e.target.name]: e.target.value });
  };

  function dataURLtoFile(dataUrl, fileName) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  const toggleAdInShowcase = (index) => {
    const newAds = [...ads];
    newAds[index].status =
      newAds[index].status === 'updatedInShowcase'
        ? undefined
        : 'updatedInShowcase';
    newAds[index].in_ad_showcase = !newAds[index].in_ad_showcase;
    setAds(newAds);
  };

  const deleteAdAsset = (index) =>
    setAds((prev) => {
      const prevAd = prev[uploadMediaSelectedAd];
      const isNewAsset = prevAd?.assets[index].status === 'new';
      const updatedAd = {
        ...prevAd,
        assets: isNewAsset
          ? [
              ...prevAd.assets.slice(0, index),
              ...prevAd.assets.slice(index + 1),
            ]
          : [
              ...prevAd.assets.slice(0, index),
              { ...prevAd.assets[index], status: 'deleted' },
              ...prevAd.assets.slice(index + 1),
            ],
      };
      const newAds = [
        ...prev.slice(0, uploadMediaSelectedAd),
        updatedAd,
        ...prev.slice(uploadMediaSelectedAd + 1),
      ];
      return newAds;
    });

  const reorderAdAssets = (from, to) => {
    setAds((prev) => {
      const prevAd = prev[uploadMediaSelectedAd];
      const fromIsHigher = from > to;
      const updatedAd = {
        ...prevAd,
        status: 'assetsReordered',
        assets: fromIsHigher
          ? [
              ...prevAd.assets.slice(0, to),
              { ...prevAd.assets[from] },
              ...prevAd.assets.slice(to, from),
              ...prevAd.assets.slice(from + 1),
            ]
          : [
              ...prevAd.assets.slice(0, from),
              ...prevAd.assets.slice(from + 1, to),
              { ...prevAd.assets[from] },
              ...prevAd.assets.slice(to),
            ],
      };
      const newAds = [
        ...prev.slice(0, uploadMediaSelectedAd),
        updatedAd,
        ...prev.slice(uploadMediaSelectedAd + 1),
      ];
      return newAds;
    });
  };

  const addAdAsset = (imageDataUrl, fileName) =>
    setAds((prev) => {
      const updatedAd = {
        ...prev[uploadMediaSelectedAd],
        assets: [
          ...(prev[uploadMediaSelectedAd]?.assets?.length > 0
            ? prev[uploadMediaSelectedAd]?.assets
            : []),
          { url: imageDataUrl, status: 'new', fileName },
        ],
      };
      const newAds = [
        ...prev.slice(0, uploadMediaSelectedAd),
        updatedAd,
        ...prev.slice(uploadMediaSelectedAd + 1),
      ];
      return newAds;
    });

  const uploadAssetAndGetUrl = async (asset) => {
    if (asset.status !== 'new') return asset;
    try {
      const file = dataURLtoFile(asset.url, asset?.fileName);
      const fileName = `${new Date().getTime()}-${asset?.fileName.replace(
        /[^a-zA-Z0-9-]/gi,
        ''
      )}`;
      const { location } = await ReactS3Client.uploadFile(file, fileName);
      return {
        ...asset,
        fileName,
        url: location,
        status: 'uploaded',
      };
    } catch (err) {
      console.error('Could not upload file to S3 ', asset.fileName, err);
      return { ...asset, url: '', status: 'errorUploading' };
    }
  };

  const reshapeAd = async (ad) => ({
    id: ad.id || undefined,
    status: ad.status || undefined,
    in_ad_showcase: ad.in_ad_showcase,
    assets:
      ad?.assets?.length > 0
        ? await Promise.all(ad?.assets?.map(uploadAssetAndGetUrl))
        : [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendingState('loading');
    const filteredAds = ads.filter(
      (ad) =>
        ['deleted', 'assetsReordered', 'updatedInShowcase'].includes(
          ad.status
        ) ||
        (ad.status === 'new' && ad?.assets?.length > 0) ||
        ad?.assets?.some((asset) => asset.status)
    );
    const updatedAds = await Promise.all(filteredAds?.map(reshapeAd));
    try {
      const body = { creative_deck: form, ads: updatedAds };
      if (deck) {
        await axiosWithAuth().put(`/cms/updateCreativeDeck/${deck.id}`, body);
      } else {
        await axiosWithAuth().post('/cms/createCreativeDeck', body);
      }
      setIsOpen(false);
      await fetchDecks();
      setSendingState('success');
    } catch (err) {
      setSendingState('error');
    }
  };

  const updateFormDomValues = () =>
    Object.keys(form).map((key) => {
      const field = document.querySelector(`#deckCreatorForm *[name="${key}"]`);
      if (!field) return undefined;
      field.value = deck[key];
      return undefined;
    });

  useEffect(() => {
    if (!deck) return;
    updateFormDomValues();
  }, [deck]);

  return (
    <>
      <StyledDeckCreatorModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        withBackdrop
        withCloseButton
      >
        <AdCreatorMenu
          selectedAdIndex={selectedAdIndex}
          setSelectedAdIndex={setSelectedAdIndex}
          setUploadMediaSelectedAd={setUploadMediaSelectedAd}
          setAds={setAds}
        />
        <Text className="title" size={4} weight="medium">
          {deck ? 'Edit Creative Deck' : 'Create New Creative Deck'}
        </Text>
        <div className="form" id="deckCreatorForm">
          <Textfield
            className="title"
            name="title"
            label="Title:"
            onChange={handleTexfieldChange}
          />
          <Textfield
            className="round"
            name="round"
            label="Round:"
            onChange={handleTexfieldChange}
          />
          <div className="organization">
            <Text size={2}>Organization:</Text>
            <OrganizationDropdown
              isOpen={isOpen}
              setForm={setForm}
              form={form}
            />
          </div>
          <Textfield
            className="description"
            name="description"
            label="Description:"
            inputTag="textarea"
            rows={5}
            onChange={handleTexfieldChange}
          />
          <div className="creativesWrapper">
            <Text size={2}>Creatives:</Text>
            <div className="creativesContainer">
              <div className="creatives">
                {ads.map((ad, index) => (
                  <CreativeForEdit
                    ad={ad}
                    index={index}
                    toggleAdInShowcase={toggleAdInShowcase}
                    setUploadMediaSelectedAd={setUploadMediaSelectedAd}
                    setSelectedAdIndex={setSelectedAdIndex}
                  />
                ))}
              </div>
              <div className="creativesActions">
                <Button
                  icon={<Plus />}
                  variant="secondary"
                  onClick={() => setAds((prev) => [...prev, { status: 'new' }])}
                />
              </div>
            </div>
          </div>
          <div className="actions">
            <SwitchFieldset
              text="Published"
              checked={Boolean(form.published_at)}
              onChange={() =>
                setForm((e) => ({
                  ...e,
                  has_changes: true,
                  published_at: form.published_at ? null : new Date(),
                }))
              }
            />
            <span className="grow" />
            <Button
              text="Cancel"
              variant="secondary"
              onClick={() => setIsOpen(false)}
            />
            <Button
              text={(() => {
                if (sendingState === 'loading') return 'Loading...';
                if (sendingState === 'error') return 'Error Occured';
                if (!deck) return 'Create';
                return 'Save & Close';
              })()}
              onClick={handleSubmit}
              disabled={
                sendingState === 'loading' ||
                (!form.has_changes &&
                  !ads.some(
                    ({ status, assets }) =>
                      status || assets?.some((asset) => asset?.status)
                  ))
              }
            />
          </div>
        </div>
      </StyledDeckCreatorModal>
      <UploadMediaModal
        isOpen={typeof uploadMediaSelectedAd === 'number'}
        setIsOpen={setUploadMediaSelectedAd}
        adAssets={ads[uploadMediaSelectedAd]?.assets}
        deleteAdAsset={deleteAdAsset}
        reorderAdAssets={reorderAdAssets}
        addAdAsset={addAdAsset}
      />
    </>
  );
};

DeckCreatorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  deck: PropTypes.shape(),
};

DeckCreatorModal.defaultProps = {
  deck: null,
};

export default DeckCreatorModal;
