/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../web.worker.js';
import { useAsyncCallback } from 'react-async-hook';
import * as Comlink from 'comlink';

export const useExportCallback = (
  changeCount,
  proposal,
  pdfState,
  pdfCurrentStateKey,
  fileUploadtoS3
) =>
  useAsyncCallback(async () => {
    const worker = new Worker();
    const pdfWorker = Comlink.wrap(worker);

    pdfWorker.onProgress(Comlink.proxy((info) => console.log(info, '<=Info')));

    const pdfBlob = await pdfWorker.generateAll(
      proposal,
      pdfState,
      pdfCurrentStateKey
    );
    const fileName = `${proposal.organization_name.replace(
      / +/g,
      '-'
    )}_CoEfficient-Labs${proposal.phase ? `-Phase-${proposal.phase}` : ''}-SOW`;
    const file = new File(
      [pdfBlob],
      `${proposal.organization_name.replace(/ +/g, '-')}_CoEfficient-Labs${
        proposal.phase ? `-Phase-${proposal.phase}` : ''
      }-SOW${changeCount > 0 ? `-${changeCount}` : ''}`,
      { type: 'application/pdf' }
    );

    fileUploadtoS3(file);

    return { pdfBlob, fileName };
  });
