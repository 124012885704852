import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import AllDecksList from '../components/decks/AllDecksList';
import CreateNewDeckPopper from '../components/decks/CreateNewDeckPopper';
import DeckCreatorModal from '../components/decks/DeckCreatorModal';
import DeckPreviewCard from '../components/decks/DeckPreviewCard';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledDecksPage = styled.div`
  flex: 1 1 auto;
  padding: 0.75rem;
  width: 100%;
  max-width: 100rem;
  .reviewDeck {
    text-decoration: none;
    @media ${({ theme }) => theme.breakpoint.sm} {
      display: none;
    }
  }
  > .previewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  > .cui-divider {
    margin-bottom: 0.75rem;
  }
`;

const DecksPage = () => {
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const user = useSelector((state) => state.user);
  const [selectedDeck, setSelectedDeck] = useState();
  const [organizations, setOrganizations] = useState();
  const [newDeckMenuIsOpen, setNewDeckMenuIsOpen] = useState();
  const [creativeDeckPage, setCreativeDeckPage] = useState(0);
  const [isDeckCreatorModalOpen, setIsDeckCreatorModalOpen] = useState(false);
  const [deckToEditId, setDeckToEditId] = useState();
  const [creativeDecks, setCreativeDecks] = useState([]);
  const [rawCopyDecks, setRawCopyDecks] = useState();
  const [copyDecks, setCopyDecks] = useState();
  const creativesLoadingMsgRef = useRef();
  const [creativesFetchingStatus, setCreativesFetchingStatus] =
    useState('start');
  const [copysFetchingStatus, setCopysFetchingStatus] = useState('start');
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const creativeDecksPerPage = 10;
  const hasReachedEndOfList =
    creativeDecks[0]?.total_count <
    (creativeDeckPage + 1) * creativeDecksPerPage;

  const changeDeckPreview = (deck, type) => {
    const reshapedDeck = (() => {
      if (type === 'creative')
        return {
          ...deck,
          assets: deck?.ads
            .map(({ assets }) =>
              assets.map(({ url, alt }, i) => ({ url, alt, i }))
            )
            .flat()
            .sort((a, b) => a.i - b.i),
          title: `${deck.title} • Round ${deck.round}`,
          organization_logo: deck?.organization?.organization_logo,
        };
      if (type === 'copy')
        return {
          title: deck.round,
          updated_at: deck.created_at,
          description: deck.description,
          id: deck.id,
          assets: [{ url: deck.creative_image }],
          approval_state: deck.approval_state,
          organization_logo: deck.organization_logo,
        };
      return null;
    })();
    setSelectedDeck({ type, deck: reshapedDeck });
  };

  const fetchOrganizations = async () => {
    try {
      const { data } = await axiosWithAuthRef.current().get('/organizations');
      setOrganizations(
        data.sort(
          (a, b) =>
            +b.active_client - +a.active_client || a.name.localeCompare(b.name)
        )
      );
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCreativeDecks = async (
    page,
    loadingStatus = 'loading',
    replace = false
  ) => {
    setCreativesFetchingStatus(loadingStatus);
    try {
      const creativeDecksRes = await axiosWithAuth().get('/cms/creativeDecks', {
        params: {
          page,
          organzation_ids: filteredOrganizations,
        },
      });
      setCreativeDecks((prev) =>
        replace ? creativeDecksRes?.data : [...prev, ...creativeDecksRes?.data]
      );
      setCreativesFetchingStatus('success');
    } catch (err) {
      setCreativesFetchingStatus('error');
    }
  };

  const fetchCopyDecks = async () => {
    setCopysFetchingStatus('loading');
    try {
      const copyDecksRes = await axiosWithAuth().get(
        `/copyDeck/list/${user?.organization?.id}`
      );
      setRawCopyDecks(copyDecksRes?.data);
      setCopysFetchingStatus('success');
    } catch (err) {
      setCopysFetchingStatus('error');
    }
  };

  const selectDeckToPreview = () => {
    if (selectedDeck) return;
    if (
      creativeDecks &&
      creativeDecks?.length > 0 &&
      creativeDecks?.filter(
        (deck) => deck?.organization?.organization_active_client
      )?.length > 0
    ) {
      const index = creativeDecks.findIndex(
        (deck) => deck?.organization?.organization_active_client
      );
      changeDeckPreview(creativeDecks[index], 'creative');
      return;
    }
    if (copyDecks && copyDecks?.length > 0) {
      changeDeckPreview(copyDecks[0], 'copy');
    }
  };

  const updateCopyDecks = () => {
    if (!rawCopyDecks) return;
    if (!filteredOrganizations?.length) {
      setCopyDecks(rawCopyDecks);
      return;
    }
    setCopyDecks(
      rawCopyDecks.filter((deck) =>
        filteredOrganizations.includes(deck?.organization_id)
      )
    );
  };

  useEffect(() => {
    setCreativeDeckPage(0);
    fetchCreativeDecks(0, 'loading', true);
  }, [filteredOrganizations]);

  useEffect(() => {
    updateCopyDecks();
  }, [filteredOrganizations, rawCopyDecks]);

  useEffect(() => {
    fetchOrganizations();
    fetchCopyDecks();
  }, []);

  useEffect(() => {
    selectDeckToPreview();
  }, [creativeDecks, rawCopyDecks]);

  return (
    <MainLayout centered>
      <StyledDecksPage>
        {isDeckCreatorModalOpen && (
          <DeckCreatorModal
            isOpen={isDeckCreatorModalOpen}
            setIsOpen={(e) => {
              setIsDeckCreatorModalOpen(e);
              setDeckToEditId(null);
            }}
            fetchDecks={async () => {
              await fetchCreativeDecks();
              await fetchCopyDecks();
            }}
            deck={creativeDecks.find(({ id }) => id === deckToEditId)}
          />
        )}
        <CreateNewDeckPopper
          newDeckMenuIsOpen={newDeckMenuIsOpen}
          setNewDeckMenuIsOpen={setNewDeckMenuIsOpen}
          setIsDeckCreatorModalOpen={setIsDeckCreatorModalOpen}
        />
        <DeckPreviewCard
          type={selectedDeck?.type}
          deck={selectedDeck?.deck}
          setDeckToEditId={(e) => {
            setDeckToEditId(e);
            setIsDeckCreatorModalOpen(true);
          }}
          fetchCreativeDecks={fetchCreativeDecks}
          creativeDeckPage={creativeDeckPage}
        />
        <AllDecksList
          organizations={organizations}
          filteredOrganizations={filteredOrganizations}
          setFilteredOrganizations={setFilteredOrganizations}
          setNewDeckMenuIsOpen={setNewDeckMenuIsOpen}
          creativesFetchingStatus={creativesFetchingStatus}
          creativeDecks={creativeDecks}
          changeDeckPreview={changeDeckPreview}
          selectedDeck={selectedDeck}
          hasReachedEndOfList={hasReachedEndOfList}
          creativesLoadingMsgRef={creativesLoadingMsgRef}
          copysFetchingStatus={copysFetchingStatus}
          copyDecks={copyDecks}
          fetchCreativeDecks={fetchCreativeDecks}
          creativeDeckPage={creativeDeckPage}
          setCreativeDeckPage={setCreativeDeckPage}
        />
      </StyledDecksPage>
    </MainLayout>
  );
};

export default DecksPage;
