import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider, Text } from 'colabs-ui-react';
import LinearGradient from '../presentational/LinearGradient';

const StyledSprintGraph = styled.div`
  > .section {
    padding: 1rem;
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    > .summary {
      display: flex;
      justify-content: space-around;
      > .metric {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    > .progressBar {
      background: #e0e0e0;
      width: 100%;
      height: 0.5rem;
      overflow: hidden;
      border-radius: 0.25rem;
      > .progress {
        height: 100%;
        /* background: url(#budgetProgressBar); */
        background: #4d27c2;
      }
    }
    &.dates {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      > .date {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

const SprintGraph = ({ week, mainDates }) => {
  return (
    <StyledSprintGraph>
      <LinearGradient
        color1="#1171FF"
        color2="#61E3FF"
        id="budgetProgressBar"
      />
      <div className="section">
        <div className="header">
          <Text size={3}>Sprint at a Glance</Text>
          <Text size={3}>SPRINT 1</Text>
        </div>
        <div className="summary">
          <div className="metric">
            <Text size={5} weight="medium" style={{ color: '#9a75fa' }}>
              Round {week || 0}
            </Text>
            <Text size={1} style={{ color: '#b0b0b0' }}>
              THIS WEEK
            </Text>
          </div>
          <div className="metric">
            <Text size={5} weight="medium">
              {week ? `${((week * 100) / 12).toFixed(0)}%` : '—'}
            </Text>
            <Text size={1} style={{ color: '#b0b0b0' }}>
              COMPLETED
            </Text>
          </div>
        </div>
      </div>
      <Divider />
      <div className="graph section">
        <Text gutterBottom weight="medium" size={2}>
          Sprint Progress
        </Text>
        <div className="progressBar">
          <div
            className="progress"
            style={{ width: `${week ? (week * 100) / 12 : '0'}%` }}
          />
        </div>
      </div>
      <Divider />
      <div className="dates section">
        <div className="date">
          <Text weight="medium" size={2}>
            Start of Sprint
          </Text>
          <Text weight="medium" size={2} style={{ color: '#9a75fa' }}>
            {mainDates?.startOfSprint || '—'}
          </Text>
        </div>
        <div className="date">
          <Text weight="medium" size={2}>
            End of Sprint
          </Text>
          <Text weight="medium" size={2} style={{ color: '#9a75fa' }}>
            {mainDates?.endOfSprint || '—'}
          </Text>
        </div>
        <div className="date">
          <Text weight="medium" size={2}>
            Renewal Day
          </Text>
          <Text weight="medium" size={2} style={{ color: '#9a75fa' }}>
            {mainDates?.renewalDay || '—'}
          </Text>
        </div>
        <div className="date">
          <Text weight="medium" size={2}>
            Start of Next Sprint
          </Text>
          <Text weight="medium" size={2} style={{ color: '#9a75fa' }}>
            {mainDates?.startOfNextSprint || '—'}
          </Text>
        </div>
      </div>
    </StyledSprintGraph>
  );
};

SprintGraph.propTypes = {
  week: PropTypes.number,
  mainDates: PropTypes.shape(),
};

SprintGraph.defaultProps = {
  week: null,
  mainDates: null,
};

export default SprintGraph;
