import Axios from 'axios';

require('dotenv').config();

const url = process.env.REACT_APP_API_URL;

Axios.defaults.withCredentials = true;
export const useAxioswithAuth = () => {
  const axiosWithAuth = () => {
    return Axios.create({
      baseURL: url,
    });
  };
  return { axiosWithAuth };
};
export const axiosUsingAuth = () => {
  return Axios.create({
    baseURL: url,
  });
};

export const api = Axios.create({
  baseURL: url,
});
