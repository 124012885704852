import React from 'react';

export default function AwaitingRevisionsIcon(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="22" cy="22" r="22" fill="white" />
      <path
        d="M21.9999 38.6666C31.2047 38.6666 38.6666 31.2047 38.6666 21.9999C38.6666 12.7952 31.2047 5.33325 21.9999 5.33325C12.7952 5.33325 5.33325 12.7952 5.33325 21.9999C5.33325 31.2047 12.7952 38.6666 21.9999 38.6666Z"
        stroke="#FB4D9D"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 12L22 23"
        stroke="#FB4D9D"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 30.6667H22.0167"
        stroke="#FB4D9D"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
