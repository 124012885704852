import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledStartLayout = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  > .backdrop {
    background-color: #f8fafc;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100vh;
    @media ${({ theme }) => theme.breakpoint.md} {
      width: 100%;
    }
    > .content {
      padding: 1rem;
      max-width: 24rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
    }
  }
  > .video-wrapper {
    position: relative;
    height: 100vh;
    width: 60%;
    z-index: 2;
    @media ${({ theme }) => theme.breakpoint.md} {
      display: none;
    }
    > video {
      position: absolute;
      inset: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

const StartLayout = ({ children }) => {
  return (
    <StyledStartLayout>
      <div className="backdrop">
        <div className="content">{children}</div>
      </div>
      <div className="video-wrapper">
        <video
          src="https://client-lp-assets.s3.amazonaws.com/Colabs_Splash+A_V2.mp4"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>
    </StyledStartLayout>
  );
};

StartLayout.propTypes = {
  children: PropTypes.element,
};

StartLayout.defaultProps = {
  children: null,
};

export default StartLayout;
