import React from 'react';
import styled from 'styled-components';

const StyledBottomBarMessage = styled.div`
  border-radius: 9999px;
  padding: 0.25rem 0.75rem;
  &.green {
    border: 1px solid #44dc7a;
    color: #44dc7a;
    background-color: #c7ffdb;
  }
  &.red {
    border: 1px solid #f55050;
    color: #f55050;
    background-color: #ffdbdb;
  }
  &.orange {
    border: 1px solid #ffa311;
    color: #ffa311;
    background-color: #ffe9c7;
  }
`;

const BottomBarMessage = ({ scriptsFetch }) => {
  if (scriptsFetch.fetchState !== 'success') return <div />;
  if (scriptsFetch.data.length === 0)
    return <StyledBottomBarMessage>No Scripts Found</StyledBottomBarMessage>;
  const getScriptsCountByStatus = (status) =>
    scriptsFetch.data.filter((script) => script.status === status).length;

  const pendingScriptsCount = getScriptsCountByStatus('pending');
  const reviewedScriptsCount = getScriptsCountByStatus('reviewed');

  if (pendingScriptsCount === 0)
    return (
      <StyledBottomBarMessage
        className={reviewedScriptsCount ? 'orange' : 'green'}
      >
        All Scripts Approved/Reviewed
      </StyledBottomBarMessage>
    );
  if (pendingScriptsCount === 1)
    return (
      <StyledBottomBarMessage className="red">
        1 Script Pending Review: Approve It or Leave a Comment
      </StyledBottomBarMessage>
    );
  return (
    <StyledBottomBarMessage className="red">
      {pendingScriptsCount} Scripts Pending Review: Approve Them or Leave a
      Comment
    </StyledBottomBarMessage>
  );
};

export default BottomBarMessage;
