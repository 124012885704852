import { Dropdown } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledOrganizationDropdown = styled(Dropdown)``;

const OrganizationDropdown = ({ setForm, form, isOpen }) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const [organizations, setOrganizations] = useState();
  const [fetchOrganizationsStatus, setFetchOrganizationsStatus] = useState(
    'start'
  );

  const fetchOrganizations = async () => {
    try {
      setFetchOrganizationsStatus('loading');
      const { data } = await axiosWithAuth().get('/organizations');
      setOrganizations(
        data.sort(
          (a, b) =>
            +b.active_client - +a.active_client || a.name.localeCompare(b.name)
        )
      );
      setFetchOrganizationsStatus('success');
    } catch (e) {
      setFetchOrganizationsStatus('error');
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    fetchOrganizations();
  }, [isOpen]);

  return (
    <StyledOrganizationDropdown
      fullWidth
      size="md"
      toggle={
        <div>
          {(() => {
            if (['loading', 'start'].includes(fetchOrganizationsStatus)) {
              return 'Loading...';
            }
            if (fetchOrganizationsStatus === 'error') {
              return 'Error';
            }
            if (organizations?.length > 0) {
              return (
                organizations?.find(({ id }) => id === form?.organization_id)
                  ?.name || 'None Selected'
              );
            }
            return 'No Organizations in the System';
          })()}
        </div>
      }
      items={
        organizations
          ? organizations.map((organization) => (
              <div
                role="button"
                onKeyDown={() => false}
                tabIndex={0}
                onClick={() =>
                  setForm((prev) => ({
                    ...prev,
                    has_changes: true,
                    organization_id: organization.id,
                  }))
                }
                className="option"
              >
                {organization?.name}
              </div>
            ))
          : []
      }
    />
  );
};

OrganizationDropdown.propTypes = {};

OrganizationDropdown.defaultProps = {};

export default OrganizationDropdown;
