import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from 'colabs-ui-react';
import React from 'react';
import { MoreVertical } from 'react-feather';
import styled from 'styled-components';

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: none;
  background: none;
  border-radius: 0.5rem;
  width: fit-content;
  :focus,
  :active {
    outline: 1px solid #d3d3d3;
  }
  > .want-this {
    width: 100%;
  }
`;

const Content = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 6rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
  &[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Separator = styled(DropdownMenu.Separator)`
  border-top: 1px solid #dfdfdf;
`;

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const OrganizationActionsMenu = ({ organization, setNewOrganization }) => {
  return (
    <>
      <DropdownMenu.Root>
        <Trigger className="decks-actions-menu">
          <Button icon={<MoreVertical />} variant="tertiary" />
        </Trigger>
        <DropdownMenu.Portal>
          <Content
            side="right"
            align="start"
            onClick={(e) => e.stopPropagation()}
          >
            <Item
              onClick={() =>
                setNewOrganization({
                  mode: 'edit',
                  id: organization.id,
                  name: organization.name,
                  type: organization.type,
                  GA_id: organization.GA_id,
                  GSheet_ID: organization.GSheet_ID,
                  Exec_Report_Url: organization.Exec_Report_Url,
                  logo_url: organization.logo_url,
                  isModalOpen: true,
                  actionState: 'start',
                })
              }
            >
              Edit
            </Item>
            <Separator />
            <Separator />
            <Item
              onClick={() =>
                setNewOrganization({
                  mode: 'delete',
                  id: organization.id,
                  name: organization.name,
                  type: organization.type,
                  GA_id: organization.GA_id,
                  GSheet_ID: organization.GSheet_ID,
                  Exec_Report_Url: organization.Exec_Report_Url,
                  logo_url: organization.logo_url,
                  isModalOpen: true,
                  actionState: 'start',
                })
              }
            >
              Delete
            </Item>
            <Arrow />
          </Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default OrganizationActionsMenu;
