import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'colabs-ui-react';

const StyledAdApprovalDecksFetchingStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-right: 2rem;
`;

const AdApprovalDecksFetchingStatus = ({
  status,
  name,
  decks,
  iconComponent,
  hasReachedEndOfList = true,
  creativesLoadingMsgRef,
}) => {
  const Icon = iconComponent;
  if (status === 'start') return null;
  if (status === 'loading') {
    return (
      <StyledAdApprovalDecksFetchingStatus>
        <Text size={4}>Loading...</Text>
      </StyledAdApprovalDecksFetchingStatus>
    );
  }
  if (status === 'error') {
    return (
      <StyledAdApprovalDecksFetchingStatus>
        <Text size={4}>Error</Text>
      </StyledAdApprovalDecksFetchingStatus>
    );
  }
  if (status === 'success') {
    if (decks?.length === 0) {
      return (
        <StyledAdApprovalDecksFetchingStatus>
          <Icon size="4rem" strokeWidth="1" style={{ marginRight: '1.5rem' }} />
          <Text size={4}>
            You don&apos;t have any {name} Decks to show right now
          </Text>
        </StyledAdApprovalDecksFetchingStatus>
      );
    }
    if (!hasReachedEndOfList) {
      return (
        <StyledAdApprovalDecksFetchingStatus
          ref={creativesLoadingMsgRef}
          id="loadingMessage"
        >
          <Text size={4}>Loading...</Text>
        </StyledAdApprovalDecksFetchingStatus>
      );
    }
  }
  return null;
};

AdApprovalDecksFetchingStatus.propTypes = {};

AdApprovalDecksFetchingStatus.defaultProps = {};

export default AdApprovalDecksFetchingStatus;
