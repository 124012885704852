/* eslint-disable react/prop-types */
import { Card, Chip, Popper, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Eye } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { approvalStatus } from '../../staticData';

const StyledPopper = styled(Popper)`
  pointer-events: none;
  .popperCard {
    padding: 0.25rem 0.75rem;
    background: #ffffffcc;
    backdrop-filter: blur(10px);
    z-index: 10;
    > * {
      margin: 0.5rem 0;
    }
  }
`;

const StyledAdApprovalDetailsCard = styled.div`
  padding: 0.5rem 0.75rem;
  grid-row: 1/2;
  grid-column: 2/3;
  > .cui-card {
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > .reviewedBy {
      display: flex;
      align-items: center;
      width: min-content;
      svg {
        margin-right: 0.5rem;
      }
    }
  }
`;

const AdApprovalDetailsCard = ({ creativeDeck, activeAdIndex }) => {
  const user = useSelector((state) => state.user);
  const [reviewedByHovered, setReviewedByHovered] = useState(false);
  return (
    <StyledAdApprovalDetailsCard className="detailsCard">
      <Card>
        <StyledPopper
          isOpen={reviewedByHovered}
          parentId="popper"
          anchorSelector="#reviewedBy"
          withArrow
          yPosition="bottom"
          xPosition="left"
          onClose={() => setReviewedByHovered(false)}
          closeOnOutsideClick
        >
          <>
            <Text weight="medium" size={2}>
              Reviewed by:
            </Text>
            {[
              ...(activeAdIndex
                ? creativeDeck?.ads[activeAdIndex]?.reviewed_by
                    .map(({ email }) => email)
                    .filter((email) => email !== user.email)
                : []),
              user.email,
            ].map((email) => (
              <Text size={2}>{email}</Text>
            ))}
          </>
        </StyledPopper>
        <Text size={3} weight="medium" style={{ color: '#373A3E' }} noWrap>
          {new Date(creativeDeck?.updated_at).toLocaleDateString('en-EN', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
          })}
        </Text>
        <span
          className="reviewedBy"
          id="reviewedBy"
          onMouseEnter={() => setReviewedByHovered(true)}
          onMouseLeave={() => setReviewedByHovered(false)}
        >
          <Eye color="#373A3E" />
          <Text style={{ color: '#373A3E' }}>
            {creativeDeck?.ads[activeAdIndex]?.reviewed_by.filter(
              ({ email }) => email !== user.email
            ).length + 1}
          </Text>
        </span>
        <Chip
          color={
            approvalStatus.find(
              ({ text }) =>
                text === creativeDeck?.ads[activeAdIndex]?.approval_state
            )?.chipColor
          }
          label={creativeDeck?.ads[activeAdIndex]?.approval_state}
        />
      </Card>
    </StyledAdApprovalDetailsCard>
  );
};

AdApprovalDetailsCard.propTypes = {};

AdApprovalDetailsCard.defaultProps = {};

export default AdApprovalDetailsCard;
