import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FileText, Image } from 'react-feather';
import { Button, Popper } from 'colabs-ui-react';

const StyledCreateNewDeckPopper = styled(Popper)`
  .popperCard {
    padding: 0.25rem 0.75rem;
    background: #ffffffcc;
    backdrop-filter: blur(10px);
    z-index: 10;
    > * {
      margin: 0.5rem 0;
    }
  }
`;

const CreateNewDeckPopper = ({
  newDeckMenuIsOpen,
  setNewDeckMenuIsOpen,
  setIsDeckCreatorModalOpen,
}) => {
  const { push } = useHistory();
  return (
    <StyledCreateNewDeckPopper
      isOpen={newDeckMenuIsOpen}
      parentId="popper"
      anchorSelector="#newDeckMenuBtn"
      yPosition="bottom"
      xPosition="left"
      closeOnOutsideClick
      onClose={() => setNewDeckMenuIsOpen(false)}
    >
      <Button
        onClick={() => {
          setNewDeckMenuIsOpen(false);
          setIsDeckCreatorModalOpen(true);
        }}
        text="Creative Deck"
        icon={<Image />}
        fullWidth
        variant="secondary"
      />
      <Button
        onClick={() => {
          setNewDeckMenuIsOpen(false);
          push('adApproval/createcopydeck');
        }}
        text="Copy Deck"
        fullWidth
        icon={<FileText />}
        variant="secondary"
      />
    </StyledCreateNewDeckPopper>
  );
};

CreateNewDeckPopper.propTypes = {};

CreateNewDeckPopper.defaultProps = {};

export default CreateNewDeckPopper;
