import { Card, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FetchData } from '../../actions/index';
import MainLayout from '../../layouts/Main';
import { CLEAR_ORG_DATA } from '../../reducers/index';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledPage = styled.div`
  max-width: 100rem;
  width: 100%;
  padding: 0.75rem;
  > .grid {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    justify-items: center;
    grid-template-columns: repeat(4, 1fr);
    @media ${({ theme }) => theme.breakpoint.lg} {
      grid-template-columns: repeat(3, 1fr);
    }
    @media ${({ theme }) => theme.breakpoint.md} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media ${({ theme }) => theme.breakpoint.sm} {
      grid-row-gap: 1rem;
    }
    > a {
      text-decoration: none;
      width: 16rem;
      @media ${({ theme }) => theme.breakpoint.md} {
        width: 14rem;
      }
      @media ${({ theme }) => theme.breakpoint.sm} {
        width: 100%;
      }
      > .org-card {
        overflow: hidden;
        height: 100%;
        > .org-logo {
          object-fit: contain;
          width: 100%;
          height: 16rem;
          @media ${({ theme }) => theme.breakpoint.md} {
            height: 14rem;
          }
          @media ${({ theme }) => theme.breakpoint.sm} {
            height: 12rem;
            object-fit: cover;
          }
        }
        > .org-name {
          padding: 0.5rem 1rem 0.75rem 1rem;
          color: #000;
          @media ${({ theme }) => theme.breakpoint.sm} {
            padding: 0.25rem 1rem 0.5rem 1rem;
          }
        }
      }
    }
  }
`;

const AdminPageOrgGrid = ({ resource }) => {
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);

  useEffect(() => {
    if (user) {
      dispatch(FetchData(axiosWithAuthRef.current, `/organizations`));
    }
  }, [user, dispatch, axiosWithAuthRef]);

  useEffect(() => {
    if (user) {
      dispatch({
        type: CLEAR_ORG_DATA,
        name: resource === 'reports' ? 'executiveReports' : resource,
      });
    }
  }, [user, dispatch, resource]);

  return (
    <MainLayout centered>
      <StyledPage>
        <div className="grid">
          {Array.isArray(data) &&
            data
              ?.slice(0)
              ?.filter((organization) => organization.active_client === true)
              .map((org) => (
                <Link
                  to={{ pathname: `/orders/${org.id}/${resource}` }}
                  onClick={() =>
                    dispatch({
                      type: CLEAR_ORG_DATA,
                      name:
                        resource === 'reports' ? 'executiveReports' : resource,
                    })
                  }
                  key={org.name}
                >
                  <Card className="org-card">
                    <img
                      src={org.logo_url}
                      alt=""
                      className="org-logo"
                      onError={(e) => {
                        e.target.src =
                          'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/480px-No_image_available.svg.png';
                      }}
                    />
                    <Text size={4} className="org-name">
                      {org.name}
                    </Text>
                  </Card>
                </Link>
              ))}
        </div>
      </StyledPage>
    </MainLayout>
  );
};

AdminPageOrgGrid.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default AdminPageOrgGrid;
