import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card, Dropdown, Text } from 'colabs-ui-react';
import { ChevronRight } from 'react-feather';
import { camelToSentenceCase } from '../../utils/helper';

const StyledBreadcrumbs = styled(Card)`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #fafafd;
  > * {
    margin-right: 1.5rem !important;
  }
  .platformIcon {
    width: 4rem;
    object-fit: contain;
    flex-grow: 1;
  }
  .toggle {
    border: 0 !important;
    background-color: transparent !important;
    :hover {
      background-color: white !important;
    }
  }
  .option {
    display: flex;
    &:hover {
      background-color: #edf5ff !important;
      * {
        color: #000 !important;
      }
    }
  }
`;

const Breadcrumbs = ({
  handleDropdownClick,
  setCurrentPlatform,
  currentPlatform,
  copys,
  currentType,
  setCurrentType,
}) => {
  return (
    <StyledBreadcrumbs>
      {[
        <Dropdown
          align="left"
          toggle={
            currentPlatform && (
              <img
                className="platformIcon"
                alt=""
                src={require(`../../assets/images/socialLogos/${currentPlatform}.png`)}
              />
            )
            // camelToSentenceCase(currentPlatform)
          }
          items={Object.keys(copys).map((platform) => (
            <div
              onKeyDown={() => false}
              role="button"
              tabIndex={0}
              className="option"
              onClick={() =>
                handleDropdownClick(setCurrentPlatform, platform, platform)
              }
            >
              {platform && (
                <img
                  className="platformIcon"
                  alt=""
                  src={require(`../../assets/images/socialLogos/${platform}.png`)}
                />
              )}
              {/* {camelToSentenceCase(platform)} */}
            </div>
          ))}
        />,
        <Dropdown
          align="left"
          toggle={<Text>{camelToSentenceCase(currentType)}</Text>}
          items={Object.keys(copys[currentPlatform]).map((type) => (
            <div
              onKeyDown={() => false}
              role="button"
              tabIndex={0}
              onClick={() =>
                handleDropdownClick(
                  setCurrentType,
                  type,
                  `${currentPlatform}-${type}`
                )
              }
            >
              {camelToSentenceCase(type)}
            </div>
          ))}
        />,
      ].map((breadcrumb, index, breadcrumbs) => (
        <Fragment key={index}>
          {breadcrumb}{' '}
          {index < breadcrumbs.length - 1 && <ChevronRight size="1rem" />}
        </Fragment>
      ))}
    </StyledBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  handleDropdownClick: PropTypes.func.isRequired,
  setCurrentPlatform: PropTypes.func.isRequired,
  currentPlatform: PropTypes.string.isRequired,
  copys: PropTypes.shape().isRequired,
  currentType: PropTypes.string.isRequired,
  setCurrentType: PropTypes.func.isRequired,
};

Breadcrumbs.defaultProps = {};

export default Breadcrumbs;
