import { Button, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { AlertCircle, MoreVertical, X } from 'react-feather';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';

const StyledCreativeForEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  grid-gap: 0.75rem;
  position: relative;
  &.deleted {
    > .assets {
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
  > .assets {
    display: flex;
    > .asset {
      margin-right: 0.5rem;
      height: 3rem;
      width: 3rem;
      position: relative;
      > .deletedSign {
        height: 100%;
        width: 100%;
        color: #f75050;
        position: absolute;
        z-index: 10;
      }
      > .cui-media {
        height: 100%;
        position: absolute;
        width: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 0.5rem;
        &.deleted {
          filter: grayscale(0.8);
        }
      }
    }
  }
  > .dash {
    position: absolute;
    width: 90%;
    left: 1%;
    height: 3px;
    z-index: 100;
    background-color: #f75050;
    top: 50%;
  }
  > .switch {
    white-space: nowrap;
    border-radius: 8px;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.inShowcase {
      border: 2px solid #d4e7ff;
      > .cui-text {
        color: #1171fa;
      }
      &.updated {
        /* border: 1px solid #1171fa; */
        background-color: #edf5ff;
        /* > .cui-text {
          color: #1171fa;
        } */
      }
    }
    &:not(.inShowcase) {
      border: 2px solid #cccccc;
      > .cui-text {
        color: #666666;
      }
      &.updated {
        /* border: 1px solid #434343; */
        background-color: #f7f7f7;
        /* > .cui-text {
          color: black;
        } */
      }
    }
    > .alert-icon {
      margin-left: 0.25rem;
      color: #ffa311;
    }
  }
`;

const CreativeForEdit = ({
  ad,
  index,
  setUploadMediaSelectedAd,
  setSelectedAdIndex,
  toggleAdInShowcase,
}) => {
  return (
    <StyledCreativeForEdit className={`creative ${ad.status}`}>
      <Text size={2}>{index + 1}.</Text>
      {ad.status === 'deleted' && <span className="dash" />}
      {ad?.assets && (
        <div className="assets">
          {ad.assets.slice(0, 6).map(({ url, status }) => (
            <div className="asset">
              {status === 'deleted' && <X className="deletedSign" />}
              {url.match(/\.(mp4|mpeg|webm|mov)$/) != null ? (
                <video
                  className={`cui-media ${status}`}
                  muted
                  src={`${url}#t=2`}
                />
              ) : (
                <img
                  className={`cui-media ${status}`}
                  alt="media"
                  src={`${url}`}
                  onError={(e) => {
                    e.target.src = blueImage;
                  }}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {!ad?.assets && (
        <Button
          text="Upload Media"
          variant="secondary"
          onClick={() => setUploadMediaSelectedAd(index)}
        />
      )}
      <span className="grow" />
      <div
        className={`switch${
          ad.status === 'updatedInShowcase' ? ' updated' : ''
        }${ad.in_ad_showcase ? ' inShowcase' : ''}`}
        onClick={() => toggleAdInShowcase(index)}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <Text size={2} className="text">
          {ad.in_ad_showcase ? 'In Showcase' : 'Private'}
        </Text>
        {ad.status === 'updatedInShowcase' && (
          <AlertCircle size={12} className="alert-icon" />
        )}
      </div>
      {/* <SwitchFieldset
        text="In Showcase"
        checked={Boolean(ad.in_ad_showcase)}
        className="switch"
        // onChange={() =>
        //   setForm((e) => ({
        //     ...e,
        //     has_changes: true,
        //     published_at: form.published_at ? null : new Date(),
        //   }))
        // }
      /> */}
      <Button
        icon={<MoreVertical />}
        onClick={() => setSelectedAdIndex(index)}
        className={`creative${index}`}
        variant="secondary"
      />
    </StyledCreativeForEdit>
  );
};

CreativeForEdit.propTypes = {};

CreativeForEdit.defaultProps = {};

export default CreativeForEdit;
