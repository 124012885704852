import { Button, Card, Modal, Text, Textfield } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Edit2, Plus, Save } from 'react-feather';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FetchData, PostData } from '../../actions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledReportsBar = styled(Card)`
  display: grid;
  grid-template-columns: minmax(3rem, 1fr) repeat(3, min-content);
  grid-gap: 0.5rem;
  padding: 0.5rem;
  align-items: end;
  margin-bottom: 1rem;
  > .cui-textfield {
    > .container {
      > .cui-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        overflow-wrap: break-word;
        word-break: break-all;
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  width: 100%;
  > .cui-card {
    width: 100%;
    max-width: 28rem;
    padding: 1rem;
    display: grid;
    grid-gap: 0.5rem;
    > .cui-button {
      justify-self: end;
    }
  }
`;

const ReportsBar = ({
  organizationId,
  orgExecutiveReportUrl,
  setOrgExecutiveReportUrl,
}) => {
  const [editingUrl, setEditingUrl] = useState(false);
  const { width } = useWindowDimensions();
  const [isCreatingNewReport, setIsCreatingNewReport] = useState(false);
  const dispatch = useDispatch();
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const [uploadExecReport, setUploadExecReport] = useState({
    name: '',
    file_url: '',
    type: 'executiveReport',
    organization_id: organizationId,
  });

  function getIdFromUrl(url) {
    if (url.match(/[-\w]{25,}/)) {
      return url.match(/[-\w]{25,}/)[0];
    }
    return null;
  }

  const handleUrlUpdate = async (e) => {
    e.preventDefault();
    if (
      !getIdFromUrl(orgExecutiveReportUrl) ||
      !orgExecutiveReportUrl.includes('presentation')
    ) {
      alert('Must be a valid google slides url');
      return;
    }
    try {
      await axiosWithAuth().put(`/organizations/${organizationId}`, {
        Exec_Report_Url: orgExecutiveReportUrl,
      });
      dispatch(
        FetchData(axiosWithAuthRef.current, `/organizations/${organizationId}`)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const createNewReport = async (e) => {
    e.preventDefault();
    if (!orgExecutiveReportUrl.length) {
      alert('Need to have URL before you can create Executive Reports');
      return;
    }
    try {
      dispatch(
        PostData(
          axiosWithAuth,
          `${process.env.REACT_APP_API_URL}/documents/${organizationId}`,
          uploadExecReport,
          'executiveReports'
        )
      );
      dispatch(
        FetchData(
          axiosWithAuthRef.current,
          `/documents/org/${organizationId}/executivereports`,
          'executiveReports'
        )
      );
      setUploadExecReport({
        name: '',
        file_url: '',
        type: 'executiveReport',
        organization_id: organizationId,
      });
    } catch (error) {
      console.log(error);
    }
    setIsCreatingNewReport(false);
  };

  return (
    <StyledReportsBar>
      <StyledModal
        isOpen={isCreatingNewReport}
        onClose={() => setIsCreatingNewReport(false)}
        withBackdrop
        withCloseButton
        closeOnOutsideClick
      >
        <Text weight="medium" size={4}>
          Create New Report from Editable
        </Text>
        <Textfield
          label="Title:"
          placeholder="Phase X Round XX"
          onChange={(e) => {
            e.preventDefault();
            setUploadExecReport({
              ...uploadExecReport,
              [e.target.name]: e.target.value,
            });
          }}
          name="name"
          value={uploadExecReport.name}
        />
        <Button text="Save" variant="primary" onClick={createNewReport} />
      </StyledModal>
      <Textfield
        id="execUploadUrl"
        name="orgExecutiveReportUrl"
        disabled={!editingUrl}
        label="Editable Report URL:"
        placeholder={orgExecutiveReportUrl}
        onChange={(e) => {
          e.preventDefault();
          setOrgExecutiveReportUrl(e.target.value);
        }}
        value={orgExecutiveReportUrl}
      />
      {!editingUrl && (
        <Button
          variant="secondary"
          icon={<Edit2 />}
          text={width > 599 ? 'Update URL' : undefined}
          onClick={() => setEditingUrl(true)}
        />
      )}
      {editingUrl && (
        <>
          <Button
            text="Cancel"
            variant="secondary"
            onClick={() => setEditingUrl(false)}
          />
          <Button
            text={width > 599 ? 'Save' : undefined}
            icon={<Save />}
            variant="primary"
            onClick={(e) => {
              handleUrlUpdate(e);
              setEditingUrl(false);
            }}
          />
        </>
      )}
      <Button
        text={width > 599 ? 'Create New Report' : undefined}
        icon={<Plus />}
        onClick={() => setIsCreatingNewReport(true)}
        variant="secondary"
      />
    </StyledReportsBar>
  );
};

ReportsBar.propTypes = {
  organizationId: PropTypes.number.isRequired,
  orgExecutiveReportUrl: PropTypes.string.isRequired,
  setOrgExecutiveReportUrl: PropTypes.string.isRequired,
};

ReportsBar.defaultProps = {};

export default ReportsBar;
