import axios from 'axios';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledQuestionsPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  overflow: auto;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
      text-transform: uppercase;
    }
  }
  > .table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .status {
      padding: 0.75rem;
    }
    > .section {
      width: 100%;
      margin-bottom: 1.5rem;
      > .header {
        text-transform: uppercase;
        color: #666;
        border-bottom: 1px solid #d3d3d3;
        font-size: 16px;
        padding: 1rem 0.75rem 0.5rem 0.75rem;
        margin: 0;
      }
      > .rows {
        > .row {
          display: grid;
          grid-template-columns: 10rem auto;
          grid-gap: 0.75rem;
          align-items: center;
          border-bottom: 1px solid #d3d3d3;
          padding: 0.5rem 0.75rem;
          > .question {
            text-align: right;
            font-size: 16px;
            color: #666;
          }
          > .answer {
            text-align: left;
            font-size: 16px;
          }
        }
      }
    }
  }
`;

const QuestionsPanel = ({ brief }) => {
  const [typeformFetch, setTypeformFetch] = useState({
    fetchState: 'start',
    data: [],
    errorMessage: '',
  });
  const { axiosWithAuth } = useAxioswithAuth();

  const fetchTypeform = async (source) => {
    setTypeformFetch(({ data }) => ({ fetchState: 'loading', data }));
    try {
      if (brief.status === '1-start') {
        throw new Error(`Brief still in Questions step`);
      }
      const { data } = await axiosWithAuth().get(`/typeform/${brief?.id}`, {
        cancelToken: source.token,
      });
      setTypeformFetch({ fetchState: 'success', data });
    } catch (e) {
      if (axios.isCancel(e)) return;
      setTypeformFetch(({ data }) => ({
        fetchState: 'error',
        data,
        errorMessage:
          e.message === 'Brief still in Questions step'
            ? e.message
            : 'Questionnaire Not Found.',
      }));
    }
  };

  useEffect(() => {
    if (!brief?.id) return;
    const source = axios.CancelToken.source();
    fetchTypeform(source);
    return () => source.cancel('Query cancelled due to brief change.');
  }, [brief?.id]);

  return (
    <StyledQuestionsPanel>
      <div className="panel-header">
        <span className="title">
          {brief?.organization_name} · Questionnaire
        </span>
      </div>
      <div className="table">
        {typeformFetch?.fetchState === 'error' && (
          <div className="status">{typeformFetch?.errorMessage}</div>
        )}
        {typeformFetch?.fetchState === 'loading' && (
          <div className="status">Loading...</div>
        )}
        {typeformFetch?.fetchState === 'success' &&
          typeformFetch?.data.map((section) => (
            <div className="section">
              <h1 className="header">{section.name}</h1>
              <div className="rows">
                {Object.keys(section.questions).map((question) => (
                  <div className="row">
                    <span className="question">
                      {question.replaceAll('-', ' ')}
                    </span>
                    <span className="answer">
                      {section.questions[question]}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </StyledQuestionsPanel>
  );
};

export default QuestionsPanel;
