import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button } from 'colabs-ui-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StripeIcon from '../../assets/icons/Stripe';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledSetupPaymentMethod = styled.form`
  > .payment-message {
    color: #f55050;
    margin-top: 1rem;
    @media (max-width: 1120px) {
      text-align: center;
    }
  }
  > .fee-message {
    color: #1171fa;
    text-align: center;
    margin-top: 0.5rem;
  }
  > .active-orders-container {
    margin-top: 1rem;
    > .title {
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
    > .active-orders {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      border: 1px solid #d3d3d3;
      border-radius: 12px;
      padding: 0.75rem;
      > .active-order {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:first-child) {
          border-top: 1px solid #d3d3d3;
          padding-top: 0.75rem;
        }
        > .product-name {
          font-size: 0.875rem;
          text-transform: uppercase;
        }
        > .payment-date {
          font-size: 0.875rem;
          font-weight: 400;
          color: #6e6e6e;
          text-align: right;
        }
      }
    }
  }
  > .actions {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .secured {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      > .icon {
        height: 1.5rem;
      }
    }
    > .add-method-btn {
    }
  }
`;

const SetupPaymentMethod = ({ closeModal }) => {
  const [message, setMessage] = useState('');
  const [paymentElementReady, setPaymentElementReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const { axiosWithAuth } = useAxioswithAuth();
  const user = useSelector((state) => state.user);
  const elements = useElements();

  // const handleReady = async () => {
  //   const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  //   await delay(1000);
  //   setStep(2);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    try {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
        },
        redirect: 'if_required',
      });
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message);
        } else {
          setMessage('An unexpected error occurred.');
        }
        setIsLoading(false);
        return;
      }
      await axiosWithAuth().put(`/organizations/${user.organization_id}`, {
        onboarding_step: 3,
      });
      alert('Payment method added successfully.');
      window.location.reload();
      setIsLoading(false);
    } catch (err) {
      setMessage('An unexpected error occurred.');
      setIsLoading(false);
    }
  };

  return (
    <StyledSetupPaymentMethod className="step step-3" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        onReady={() => setPaymentElementReady(true)}
      />
      <div className="fee-message">+2.9% transaction fee for CARD payments</div>
      {/* {message && <div className="payment-message">{message}</div>} */}
      <div className="actions">
        <span className="secured">
          <span>SECURED WITH:</span>
          <StripeIcon className="icon" />
        </span>
        <Button
          disabled={isLoading || !stripe || !elements || !paymentElementReady}
          className="add-method-btn"
          type="submit"
          text="Add Payment Method"
        />
      </div>
    </StyledSetupPaymentMethod>
  );
};

export default SetupPaymentMethod;
