import { Media } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import blueImage from '../../../assets/images/BlueImage.png';
import Pin from '../../Pin';

const StyledNoPlatform = styled.div`
  background: #c9ccd1;
  background-color: #c9ccd1;
  width: 100%;
  height: 100%;
  .creativeWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    > .pin {
      position: absolute;
    }
    .creative {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      object-position: center center;
    }
  }
`;

const NoPlatform = ({
  creative,
  isInteractive,
  pins,
  pinsEnabled,
  newPinIndex,
}) => {
  return (
    <StyledNoPlatform>
      <div id="creativeWrapper" className="creativeWrapper">
        {pins?.map((pin) => (
          <Pin
            style={{ top: `${pin.top}%`, left: `${pin.left}%` }}
            className={(() => {
              if (pin.is_internal) return 'internal';
              if (pin.user_organization_id === 1) return 'colabs';
              return '';
            })()}
            number={pin?.number}
          />
        ))}
        {pinsEnabled && (
          <Pin className="newPin" number={newPinIndex} id="newPin" />
        )}
        <Media
          onError={(e) => {
            e.target.src = blueImage;
          }}
          videoProps={
            isInteractive
              ? {
                  autoPlay: true,
                  loop: true,
                }
              : {}
          }
          alt=""
          id="creativeMedia"
          className="creative"
          src={creative}
        />
      </div>
    </StyledNoPlatform>
  );
};

NoPlatform.propTypes = {
  creative: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool.isRequired,
  creativeRef: PropTypes.shape(),
};

NoPlatform.defaultProps = {};

export default NoPlatform;
