import { Media } from 'colabs-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import star from '../../assets/icons/star.svg';
import useOnScreen from '../../hooks/useOnScreen';
import SortIcons from '../SortIcons';
import AllDecksMenu from './AllDecksMenu';
import AllReferencesActionsMenu from './AllReferencesActionsMenu';
import ReferenceTagsPopover from './ReferenceTagsPopover';
import TagsFilterPopover from './TagsFilterPopover';

const StyledAllReferencesNavPanel = styled.div`
  display: flex;
  flex-direction: column;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .back-btn {
      color: #1171fa;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 0.5rem;
      padding: 0.25rem 0.5rem 0.25rem 0;
      :hover {
        background-color: #fafafa;
      }
      > svg {
        width: 1rem;
      }
    }
    > .title {
      margin-right: 1rem;
      flex-grow: 1;
    }
  }
  > .reference-list-row,
  > .reference-list-body > .reference-list-row {
    display: grid;
    grid-template-columns: 1.25fr 3.25fr 1fr 1fr 0.75fr;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    &.reference {
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      :hover {
        background-color: #f5f5f5;
      }
      &.active {
        border: 3px solid #1171fa;
        border-radius: 0.5rem;
        background-color: #edf4ff;
        cursor: initial;
      }
      > .thumbnail {
        height: 5rem;
        width: 5rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .reference-name {
        font-size: 0.75rem;
      }
      > .references-actions-menu {
        justify-self: end;
      }
      > .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
        > .tag {
          border-radius: 9999px;
          padding: 0.1rem 0.75rem;
          font-size: 14px;
          background-color: #dceaff;
        }
      }
    }
    &.more-references {
      white-space: nowrap;
    }
    &.no-references {
      grid-template-columns: 1fr;
    }
  }
  > .reference-list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
`;

const AllReferencesNavPanel = ({
  allReferencesFetch,
  fetchAllReferences,
  activeRefIdx,
  setActiveRefIdx,
  setNewDeck,
  setNewReference,
  setAllReferencesFetch,
}) => {
  const { push } = useHistory();
  const loadingMsg = useRef();
  const isLoadingMsgOnScreen = useOnScreen(loadingMsg, []);

  useEffect(() => {
    if (!isLoadingMsgOnScreen) return;
    fetchAllReferences(allReferencesFetch.page + 1);
  }, [isLoadingMsgOnScreen]);

  return (
    <StyledAllReferencesNavPanel className="references-nav-panel">
      <div className="panel-header">
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => push('/ops/reference-tool')}
          onClick={() => push('/ops/reference-tool')}
          className="back-btn"
        >
          <ChevronLeft strokeWidth={1} />
          Decks
        </div>
        <span className="title">ALL REFERENCES</span>
        <AllDecksMenu
          setNewDeck={setNewDeck}
          setNewReference={setNewReference}
        />
      </div>
      <div className="reference-list-row">
        {[
          { property: '', label: 'Thumbnail' },
          { property: 'tags', label: 'Tags' },
          { property: 'avg_rating', label: 'Avg. Rating' },
          { property: 'references.created_at', label: 'Date' },
        ].map(({ property, label }) => {
          if (!property) return <div className="column-title">{label}</div>;
          if (property === 'tags') {
            return (
              <TagsFilterPopover
                activeTags={allReferencesFetch.tagFilters}
                setActiveTags={(newTags) => fetchAllReferences(null, newTags)}
              />
            );
          }
          return (
            <div
              className="column-title clickable"
              onClick={() => property && fetchAllReferences(1, null, property)}
              role="button"
              tabIndex={0}
              onKeyDown={() =>
                property && fetchAllReferences(1, null, property)
              }
            >
              {label}
              {property && (
                <SortIcons
                  sorting={
                    allReferencesFetch.columnSort.column === property
                      ? allReferencesFetch.columnSort.order
                      : undefined
                  }
                />
              )}
            </div>
          );
        })}
        <div className="column-title" />
      </div>
      <div className="reference-list-body">
        {allReferencesFetch?.data?.map((reference, idx) => (
          <div
            className={`reference-list-row reference${
              activeRefIdx === idx ? ' active' : ''
            }`}
            key={reference.id}
            onClick={() => setActiveRefIdx(idx)}
            role="button"
            tabIndex={0}
            onKeyDown={() => setActiveRefIdx(idx)}
          >
            <Media className="thumbnail" src={reference.url} srcSuffix="#t=2" />
            <div className="tags">
              {reference.tags.map((tag) => (
                <div className="tag">{tag}</div>
              ))}
              <ReferenceTagsPopover
                activeTags={reference.tags}
                referenceId={reference.id}
                setActiveTags={(newTags) =>
                  setAllReferencesFetch((prev) => ({
                    ...prev,
                    data: prev.data.map((ref) => {
                      if (ref.id === reference.id) {
                        return {
                          ...ref,
                          tags: newTags,
                        };
                      }
                      return ref;
                    }),
                  }))
                }
              />
            </div>
            <div className="rating">
              {reference.avg_rating ? (
                <>
                  {reference.avg_rating}{' '}
                  <img src={star} alt="star" className="star-icon" />
                </>
              ) : (
                '—'
              )}
            </div>
            <div className="date">
              {new Date(reference.created_at).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })}
            </div>
            <AllReferencesActionsMenu
              referenceId={reference.id}
              viewReference={() => setActiveRefIdx(idx)}
              setNewReference={setNewReference}
            />
          </div>
        ))}
        {allReferencesFetch?.data?.length === 0 && (
          <div className="reference-list-row no-references">
            No references found. Try changing your filters.
          </div>
        )}
        {!allReferencesFetch.reachedEnd && (
          <div className="reference-list-row more-references" ref={loadingMsg}>
            Loading more references...
          </div>
        )}
      </div>
    </StyledAllReferencesNavPanel>
  );
};

export default AllReferencesNavPanel;
