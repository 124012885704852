import { Button } from 'colabs-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import styled from 'styled-components/macro';
import usePin from '../../hooks/usePin';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import CopyPreviewTemplate from '../phoneContainer/CopyPreviewTemplate';
import PhoneMockup from '../phoneContainer/PhoneMockup';
import RatingCard from './RatingCard';
import CommentsPanel from './RefToolCommentsPanel';
import TagsCard from './TagsCard';

const StyledPhoneView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  > .circle {
    background: linear-gradient(135deg, #1574f5, #55d2ff);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 33rem;
    width: 66rem;
    z-index: 0;
    border-radius: 17rem;
  }
  > .phoneMockup {
    z-index: 1;
    margin: 0 0.5rem;
  }
  > .reference-panels {
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    gap: 0.5rem;
  }
`;

const PhoneView = ({
  creative,
  changeAsset,
  socialProfilePicture,
  socialUsername,
  currentPlatform,
  setCurrentPlatform,
  multipleAssets,
  pinsEnabled,
  mediaType,
  tags,
  reference,
  setActiveDeckFetch,
  deckId,
  setActiveTags,
  ratingCardDisabled,
}) => {
  const [processedPins, setProcessedPins] = useState([]);
  const [creativeMediaEl, setCreativeMediaEl] = useState({});
  const { axiosWithAuth } = useAxioswithAuth();
  const [commentsFetch, setCommentsFetch] = useState({
    data: [],
    status: 'start',
  });
  const newPin = { current: document.querySelector('#newPin') };
  const [pins, setPins] = useState();
  const { cleanUpPin, pinIsAttached, pinIsGrabbed, setPinIsGrabbed } = usePin();
  // newPin,
  // creativeWrapper,
  // activePanel === 1 // commentsWithTextfieldsValidation
  const insertIndexToArrOfObjects = (arr, key, displacement = 0) =>
    arr?.map((item, i) => ({ ...item, [key]: i + displacement }));

  // const getPinData = () => {
  //   if (['none', ''].includes(newPin.current.style.display)) {
  //     return undefined;
  //   }
  //   const rect = creativeWrapper.current.getBoundingClientRect();
  //   const left = (
  //     ((newPin.current.style.left.slice(0, -2) - rect.left) / rect.width) *
  //     100
  //   ).toFixed(2);
  //   const top = (
  //     ((newPin.current.style.top.slice(0, -2) - rect.top) / rect.height) *
  //     100
  //   ).toFixed(2);
  //   const asset_id = adsArray[activeAdIndex]?.assets[activeAssetIndex].id;
  //   const time_in_seconds = creativeMediaEl?.currentTime || null;
  //   return { left, top, asset_id, time_in_seconds };
  // };

  const fetchComments = async () => {
    setCommentsFetch((prev) => ({ ...prev, status: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get(
        `/comments/reference/${reference.id}`
      );
      // setPins([...data.ad_pinned_notes, ...data.internal_ad_pinned_notes]);
      // setInternalComments(data.internal_comments);
      // setAdPinnedNotes(data.ad_pinned_notes);
      // setInternalAdPinnedNotes(data.internal_ad_pinned_notes);
      setCommentsFetch((prev) => ({ ...prev, data, status: 'success' }));
    } catch (err) {
      setCommentsFetch((prev) => ({ ...prev, status: 'error' }));
    }
  };

  const likeComment = async (commentId) => {
    try {
      await axiosWithAuth().put(`/comments/like/${commentId}`);
    } catch (e) {
      // setRequestState('error');
    }
    await fetchComments();
  };

  const postComment = async (content, parent) => {
    try {
      await axiosWithAuth().post('/comments', {
        content,
        parent_id: parent?.id,
        related_id: reference?.id,
        is_internal: true,
        related_table: 'references',
      });
    } catch (e) {
      console.log(e);
    }
    // cleanUpPin();
    await fetchComments();
  };

  const editComment = async (commentId, newContent) => {
    try {
      await axiosWithAuth().put(`/comments/${commentId}`, {
        content: newContent,
      });
    } catch (e) {
      // console.log(e);
    }
    await fetchComments();
  };

  const deleteComment = async (commentId) => {
    try {
      await axiosWithAuth().delete(`/comments/${commentId}`);
    } catch (e) {
      // console.log(e);
    }
    await fetchComments();
  };

  const handleTimeUpdate = () => {
    const updatedProcessedPins = insertIndexToArrOfObjects(
      pins,
      'number',
      1
    )?.filter((pin) => {
      const tolerance = 1.5;
      const beforeMinTime =
        creativeMediaEl.currentTime - tolerance < pin.time_in_seconds;
      const afterMaxTime =
        creativeMediaEl.currentTime + tolerance > pin.time_in_seconds;
      return beforeMinTime && afterMaxTime;
    });
    const processedPinsHaveChanged = (() => {
      if (updatedProcessedPins?.length !== processedPins?.length) return true;
      const processedPinsIdsString = processedPins
        ?.map((pin) => pin.id)
        ?.sort()
        ?.join(',');
      const updatedProcessedPinsIdsString = updatedProcessedPins
        ?.map((pin) => pin.id)
        ?.sort()
        ?.join(',');
      return processedPinsIdsString !== updatedProcessedPinsIdsString;
    })();
    if (!processedPinsHaveChanged) return;
    setProcessedPins(updatedProcessedPins);
  };

  const handleProcessedPins = () => {
    if (creativeMediaEl?.tagName === 'VIDEO') {
      creativeMediaEl.addEventListener('timeupdate', handleTimeUpdate);
    }
    if (creativeMediaEl?.tagName === 'IMG') {
      setProcessedPins(insertIndexToArrOfObjects(pins, 'number', 1));
    }
  };

  const cleanUpProcessedPins = () => {
    if (creativeMediaEl?.tagName === 'VIDEO') {
      creativeMediaEl.removeEventListener('timeupdate', handleTimeUpdate);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCreativeMediaEl(document.querySelector('#creativeMedia'));
    }, 2000);
  }, [creative]);

  useEffect(() => {
    handleProcessedPins();
    return cleanUpProcessedPins;
  }, [creativeMediaEl, pins]);

  useEffect(() => {
    if (!reference) return;
    fetchComments();
  }, [reference]);

  return (
    <StyledPhoneView className="reference-view">
      <div className="circle" />
      <div>
        <PhoneMockup>
          <CopyPreviewTemplate
            isInteractive
            creative={creative}
            trimCopy={() => {}}
            isLongerThanLimit={() => false}
            cta="Buy Now"
            currentPlatform={currentPlatform}
            setCurrentPlatform={setCurrentPlatform}
            adTextIsExpanded="true"
            pins={processedPins}
            pinsEnabled={pinsEnabled}
            newPinIndex={pins?.length + 1}
            // adTextIsExpanded={adTextIsExpanded}
            // setAdTextIsExpanded={setAdTextIsExpanded}
            // selectedCopys={selectedCopys}
            socialProfilePicture={socialProfilePicture} // we might wanna add a new column in organization table
            socialUsername={socialUsername} // we might wanna add a new column in organization table
          />
          {multipleAssets && (
            <>
              <Button
                variant="secondary"
                onClick={() => changeAsset(-1)}
                icon={<ChevronLeft />}
                className="prev change-asset-button"
              />
              <Button
                variant="secondary"
                onClick={() => changeAsset(1)}
                icon={<ChevronRight />}
                className="next change-asset-button"
              />
            </>
          )}
        </PhoneMockup>
      </div>
      <div className="reference-panels">
        <RatingCard
          mediaType={mediaType}
          creativeMediaEl={creativeMediaEl}
          reference={reference}
          setActiveDeckFetch={setActiveDeckFetch}
          deckId={deckId}
          ratingCardDisabled={ratingCardDisabled}
        />
        <TagsCard
          activeTags={tags}
          referenceId={reference?.id}
          setActiveTags={setActiveTags}
        />
        <CommentsPanel
          isCommentPanelOpen
          comments={commentsFetch.data}
          editComment={editComment}
          likeComment={likeComment}
          deleteComment={deleteComment}
          postComment={postComment}
          creativeMediaEl={creativeMediaEl}
          textfieldPlaceholder="Leave a comment"
          noHeader
          pinIsAttached={pinIsAttached}
          setPinIsGrabbed={setPinIsGrabbed}
          cleanUpPin={cleanUpPin}
          pins={pins}
          // changeCopyToComment={changeCopyToComment}
        />
      </div>
    </StyledPhoneView>
  );
};

PhoneView.propTypes = {};

PhoneView.defaultProps = {};

export default PhoneView;
