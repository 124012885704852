import React from 'react';
import {
  AlertCircle,
  ArrowDownCircle,
  ArrowUpCircle,
  CheckCircle,
  Clock,
  Eye,
  Home,
  Image,
  PieChart,
  Send,
  Tool,
  Users,
} from 'react-feather';
export const approvalStatus = [
  {
    text: 'Pending Approval',
    color: '#ffa311',
    chipColor: 'orange',
    icon: <AlertCircle color="#ffa311" />,
  },
  {
    text: 'Approved',
    color: '#44dc7a',
    chipColor: 'green',
    icon: <CheckCircle color="#44dc7a" />,
  },
  {
    text: 'Awaiting Revisions',
    color: '#FB4D9D',
    chipColor: 'purple',
    icon: <Clock color="#FB4D9D" />,
  },
  {
    text: 'Reviewed',
    color: '#44dc7a',
    chipColor: 'green',
    icon: <Eye color="#44dc7a" />,
  },
  {
    text: 'Ready for Launch',
    color: '#44dc7a',
    chipColor: 'green',
    icon: <Send color="#44dc7a" />,
  },
  {
    text: 'Downloaded',
    color: '#44dc7a',
    chipColor: 'green',
    icon: <ArrowDownCircle color="#44dc7a" />,
  },
  {
    text: 'Not Published',
    color: '#000',
    chipColor: 'gray',
    icon: <ArrowUpCircle color="#000" />,
  },
];

export const mainSections = [
  {
    path: '/home',
    icon: <Home />,
    name: 'Overview',
    fullName: 'Overview',
    checkPermission: () => true,
  },
  // {
  //   path: '/organization',
  //   icon: <Users />,
  //   name: 'Organization',
  //   fullName: 'My Organization',
  //   checkPermission: () => true,
  // },
  {
    path: '/reports',
    icon: <PieChart />,
    name: 'Reports',
    fullName: 'Executive Reports',
    checkPermission: () => true,
  },
  {
    path: '/adapproval',
    icon: <Image />,
    name: 'Ad Approval',
    fullName: 'Ad Approval - BETA',
    checkPermission: () => true,
  },
  {
    path: '/colabs-tools',
    icon: <Tool />,
    name: 'CoLabs',
    fullName: 'CoLabs Tools',
    checkPermission: (user) => user?.organization_id === 1,
  },
];

export const copyCharLimits = {
  facebook: {
    adText: {
      expand: 148, // unsure
    },
    headline: {
      max: null,
    },
  },
  instagram: {
    adText: {
      expand: 92, // unsure
    },
    headline: {
      max: null,
    },
  },
  linkedin: {
    adText: {
      max: 600,
      expand: 92, // unsure
    },
    headline: {
      max: 200,
    },
  },
  youtube: {
    headline: 10,
  },
  tiktok: {
    description: 10,
  },
  google: {
    headline1: {
      max: 30,
    },
    headline2: {
      max: 30,
    },
    headline3: {
      max: 30,
    },
    description: {
      max: 90,
    },
    description2: {
      max: 90,
    },
  },
};

export const toolboxList = [
  {
    title: 'VC Map',
    bgColor: 'rgba(0, 0, 0, 0.4)',
    handleClick: () =>
      window.open('https://vc-map.coefficientlabs.com/start', '_blank'),
    bgImage:
      'https://vc-map-images.s3-us-west-1.amazonaws.com/mapCaptures/la+capture.webp',
    description:
      'Get free access by signing in below, and start exploring the top VC firms in the world.',
  },
  {
    title: 'Demo Day Podcast',
    bgColor: 'rgba(0, 0, 0, 0.4)',
    handleClick: () =>
      window.open('https://demoday.coefficientlabs.com/', '_blank'),
    bgImage:
      'https://is2-ssl.mzstatic.com/image/thumb/Podcasts113/v4/86/8a/31/868a3182-e873-a1c6-151d-fed00efa3273/mza_5637533125676907897.jpg/1200x630wp.png',
    description:
      'Demo Day is the podcast for founders, venture capitalists, and anyone who wants to learn more about the top accelerator, incubator, and VC programs across the country. Come hear the top venture capitalists and founders in the game discuss what it takes to go from seed to exit.',
  },
  {
    title: 'Website',
    bgColor: 'rgba(0, 0, 0, 0.4)',
    handleClick: () => window.open('https://coefficientlabs.com/', '_blank'),
    bgImage:
      'https://colab-platformbucket.s3-us-west-1.amazonaws.com/websiteCapture.jpeg',
    description:
      'Our team acts as an extension of your growth team. With weekly sprints and  performance-based pricing, we are aligned & motivated to hit your goals in less than 90 days.',
  },
];
