import { Button, Textfield } from 'colabs-ui-react';
import React, { useEffect, useRef } from 'react';
import { Search } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import useOnScreen from '../../hooks/useOnScreen';
import SortIcons from '../SortIcons';
import BriefActionsMenu from './BriefActionsMenu';

const StyledAllBriefsNavPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  overflow: auto;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
    }
    > .cui-textfield {
      flex-grow: 1;
    }
  }
  > .brief-list-row,
  > .brief-list-body > .brief-list-row {
    display: grid;
    grid-template-columns: 0.75fr 1.5fr 2fr 1fr 1fr 0.5fr;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    &.brief {
      font-size: 14px;
      cursor: pointer;
      border: 3px solid transparent;
      padding-bottom: calc(0.5rem + 2px);
      border-bottom: 1px solid #e0e0e0;
      :hover {
        background-color: #f5f5f5;
      }
      &[disabled] {
        cursor: not-allowed;
        > * {
          opacity: 0.5;
        }
        :hover {
          background-color: transparent;
        }
      }
      &[data-active='true'] {
        border: 3px solid #1171fa;
        padding-bottom: 0.5rem;
        border-radius: 0.5rem;
        background-color: #edf4ff;
      }
      > .organization-logo {
        height: 3.75rem;
        width: 3.75rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .status {
        .circle {
          display: inline-block;
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 9999px;
          margin: 0 2px;
          &.red {
            background-color: hsla(0, 89%, 64%, 1);
          }
          &.orange {
            background-color: hsla(37, 100%, 53%, 1);
          }
        }
      }
      > .decks-actions-menu {
        justify-self: end;
      }
    }
    &.more-briefs {
      white-space: nowrap;
    }
  }
  > .brief-list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
`;

const AllBriefsNavPanel = ({
  setNameQueryTextfield,
  briefsFetch,
  fetchAllBriefs,
  activeBriefIdx,
  setActiveBriefIdx,
  // setNewDeck,
}) => {
  const loadingMsg = useRef();
  const isLoadingMsgOnScreen = useOnScreen(loadingMsg, []);
  // const [newBrief, setNewBrief] = useState(null);

  useEffect(() => {
    if (!isLoadingMsgOnScreen || briefsFetch.reachedEnd) return;
    fetchAllBriefs(briefsFetch.page + 1);
  }, [isLoadingMsgOnScreen, briefsFetch.page]);

  return (
    <StyledAllBriefsNavPanel className="all-briefs-nav-panel">
      <div className="panel-header">
        <span className="title">ALL BRIEFS</span>
        <Textfield
          icon={<Search color="#e0e0e0" />}
          placeholder="Search..."
          onChange={(e) => setNameQueryTextfield(e.target.value)}
        />
      </div>
      <div className="brief-list-row">
        <div className="column-title" />
        {[
          { property: 'organization_name', label: 'Organization' },
          { property: 'status', label: 'Current Step' },
          { property: 'created_at', label: 'Created At' },
        ].map(({ property, label }) => (
          <div
            className="column-title clickable"
            onClick={() => fetchAllBriefs(1, property)}
            role="button"
            tabIndex={0}
            onKeyDown={() => fetchAllBriefs(1, property)}
          >
            {label}
            <SortIcons
              sorting={
                briefsFetch.fetchState === 'success' &&
                briefsFetch.columnSort.column === property
                  ? briefsFetch.columnSort.order
                  : undefined
              }
            />
          </div>
        ))}
        <div className="column-title" />
      </div>
      <div className="brief-list-body">
        {briefsFetch?.data?.map((brief, idx) => (
          <div
            className="brief-list-row brief"
            // disabled={!brief.references_count}
            data-order-id={brief.id}
            data-active={briefsFetch.data[activeBriefIdx].id === brief.id}
            onClick={() => setActiveBriefIdx(idx)}
            role="button"
            tabIndex={0}
            onKeyDown={() => setActiveBriefIdx(idx)}
          >
            <img
              src={brief.organization_logo || blueImage}
              alt={brief.organization_name}
              onError={(e) => {
                e.target.src = blueImage;
              }}
              className="organization-logo"
            />
            <div>{brief.organization_name}</div>
            <div className="status">
              {
                {
                  '1-start': '1. Questions',
                  '1.5-booked': '1. Questions',
                  '2-trends': '2. Trends',
                  '3-scripts-pending': (
                    <span>
                      3. Scripts{' '}
                      <span className="avoidwrap">
                        (<span className="circle red" />
                        Pending)
                      </span>
                    </span>
                  ),
                  '3-scripts-reviewed': (
                    <span>
                      3. Scripts{' '}
                      <span className="avoidwrap">
                        (<span className="circle orange" />
                        Reviewed)
                      </span>
                    </span>
                  ),
                  '4-actors': '4. Actors',
                  '5-complete': '5. Complete',
                }[brief.status]
              }
            </div>
            <div className="last-update">
              {new Date(brief.created_at).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </div>
            <div className="brief-actions-menu">
              <Link to={`/orders/${brief.id}/brief`}>
                <Button text="Open" variant="secondary" />
              </Link>
            </div>
            <BriefActionsMenu
              // setNewBrief={setNewBrief}
              brief={brief}
            />
          </div>
        ))}
        {!briefsFetch.reachedEnd && (
          <div className="brief-list-row more-briefs" ref={loadingMsg}>
            Loading more briefs...
          </div>
        )}
      </div>
    </StyledAllBriefsNavPanel>
  );
};

export default AllBriefsNavPanel;
