/* eslint-disable no-param-reassign */
import { Button, Divider, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { CornerDownRight, MoreVertical, ThumbsUp } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CommentMenu from '../copys/CommentMenu';
import NotesTextField from './NotesTextField';

const StyledComment = styled.div`
  padding: 0.75rem 1rem;
  > .comment {
    > .head {
      display: flex;
      position: relative;
      margin-bottom: 0.25rem;
      align-items: center;
      > .new {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 0.25rem;
        background-color: #1171fa;
        margin-left: 1rem;
      }
      > .avatar {
        margin-right: 0.5rem;
        height: 1.75rem;
        width: 1.75rem;
        border-radius: 100rem;
        object-fit: cover;
      }
      > :nth-child(2) {
        margin-right: 1rem;
      }
      > .grow {
        flex-grow: 1;
      }
      > .menuContainer {
        position: relative;
      }
    }
    .pin {
      width: 1.5rem;
      height: 1.5rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.25rem;
      backdrop-filter: blur(10px);
      z-index: 100;
      cursor: pointer;
      transform: translate(-50%, -50%);
      background: #ae41e8;
      transform: translate(0, 0);
      :hover {
        background: #ecc7ff;
      }
      &.timestamp {
        width: auto;
        padding: 0 0.5rem;
      }
      &.colabs {
        background: #1171ff;
        :hover {
          background: #edf5ff;
        }
      }
      &.internal {
        background: #ffa311;
        :hover {
          background: #ffe9c7;
        }
      }
      &.old {
        background: gray;
        :hover {
          background: lightgray;
        }
      }
    }
    .content {
      white-space: pre-wrap;
    }
    > .actions {
      margin-top: 0.5rem;
      display: flex;
      > .cui-button {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        cursor: pointer;
        color: gray;
        > .icon {
          stroke: gray;
          margin-right: 0.1rem;
          height: 1rem;
          &.active {
            /* stroke: #1372fa; */
            stroke: red;
            border: 1px solid red;
          }
        }
      }
    }
  }
`;

const Comment = ({
  comment,
  postComment,
  deleteComment,
  cleanUpPin,
  avatar,
  pinIsAttached,
  pinIsGrabbed,
  newPinnedNoteIndex,
  editComment,
  setPinIsGrabbed,
  likeComment,
  adPinnedNotes,
  parentCommentUser,
  setCommentsWithTextfields,
  videoRef,
  assets,
  genStyledCommentWithTimestamps,
  setActiveAssetIndex,
  adLastUpdate,
  dropPinDisabled,
  internalCommentsShown,
}) => {
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const menuContainerRef = useRef();

  const pinIndex = comment.ad_pinned_note_id
    ? adPinnedNotes.findIndex(
        (adPinnedNote) => adPinnedNote.id === comment.ad_pinned_note_id
      ) + 1
    : null;

  const handlePinClick = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime =
        adPinnedNotes[pinIndex - 1].time_in_seconds;
      return undefined;
    }
    const newActiveAssetIndex = assets.findIndex(
      ({ id }) => Number(id) === adPinnedNotes[pinIndex - 1].asset_id
    );
    if (newActiveAssetIndex === -1) {
      return alert('Pin belongs to an old asset no longer available');
    }
    return setActiveAssetIndex(newActiveAssetIndex);
  };

  return (
    <>
      <StyledComment
        style={{
          paddingLeft: `${
            comment.depth > 1 ? `${comment.depth * 2.5 - 2.5}rem` : '1rem'
          }`,
        }}
      >
        {isEditing ? (
          <NotesTextField
            noAnnotationTools
            defaultContent={comment.content}
            pinIsAttached={pinIsAttached}
            pinIsGrabbed={pinIsGrabbed}
            avatar={avatar}
            cleanUpPin={cleanUpPin}
            newPinnedNoteIndex={newPinnedNoteIndex}
            handleCancelButton={() => setIsEditing(false)}
            placeholder="Leave a comment here..."
            postComment={(content) => {
              editComment(comment?.id, content);
              setIsEditing(false);
            }}
            videoRef={videoRef}
            setPinIsGrabbed={setPinIsGrabbed}
          />
        ) : (
          <>
            <CommentMenu
              isOpen={isMenuOpen}
              setIsOpen={setIsMenuOpen}
              deleteComment={deleteComment}
              setIsEditing={setIsEditing}
              elementRef={menuContainerRef}
              commentId={comment?.id}
            />
            <div className="comment">
              <span className="head">
                <img
                  src={
                    comment.user?.profile_pic_url ||
                    'https://gravatar.com/avatar/d559c0b948620a119ac87908bdca93d0?s=400&d=mp&r=x'
                  }
                  className="avatar"
                  alt="avatar"
                  onError={(e) => {
                    e.target.src =
                      'https://gravatar.com/avatar/d559c0b948620a119ac87908bdca93d0?s=400&d=mp&r=x';
                  }}
                />
                <Text weight="medium">
                  {comment.user?.first_name} {comment.user?.last_name}
                </Text>
                <Text color="gray" size={2}>
                  {new Date(comment.created_at).toLocaleDateString('en-EN', {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </Text>
                {adLastUpdate < new Date(comment.created_at) && (
                  <span className="new" />
                )}
                <div className="grow" />
                {comment.userId === user.id &&
                  comment?.comment_state !== 'deleted' && (
                    <div className="menuContainer" ref={menuContainerRef}>
                      <Button
                        variant="tertiary"
                        size="sm"
                        onClick={() => setIsMenuOpen(true)}
                        icon={<MoreVertical />}
                      />
                    </div>
                  )}
              </span>
              {comment?.comment_state !== 'deleted' ? (
                <Text tag="span">
                  <>
                    {pinIndex && (
                      <span
                        onKeyDown={() => false}
                        role="button"
                        aria-label="pin"
                        tabIndex={0}
                        className={`pin ${
                          videoRef?.current ? 'timestamp' : ''
                        } ${
                          comment?.user?.organization_id === 1 ? 'colabs' : ''
                        } ${internalCommentsShown ? 'internal' : ''} ${
                          adLastUpdate > new Date(comment.created_at)
                            ? 'old'
                            : ''
                        }`}
                        onClick={handlePinClick}
                      >
                        <Text size={1} color="white" noWrap>
                          {pinIndex}
                          {videoRef?.current
                            ? ` — ${new Date(
                                adPinnedNotes[pinIndex - 1].time_in_seconds *
                                  1000
                              )
                                .toUTCString()
                                .split(' ')[4]
                                .substring(3)}`
                            : ''}
                        </Text>
                      </span>
                    )}{' '}
                    {parentCommentUser && (
                      <Text tag="span" color="blue" weight="medium">
                        @{parentCommentUser}{' '}
                      </Text>
                    )}
                    <Text tag="span" className="content">
                      {genStyledCommentWithTimestamps(
                        comment.content,
                        comment.created_at
                      )}
                    </Text>
                    {comment?.comment_state === 'edited' && (
                      <Text tag="span" style={{ color: '#888' }}>
                        (Edited)
                      </Text>
                    )}
                  </>
                </Text>
              ) : (
                <Text weight="italic" color="gray">
                  [This comment has been deleted]
                </Text>
              )}
              {comment?.comment_state !== 'deleted' && (
                <span className="actions">
                  <Button
                    variant="tertiary"
                    onClick={() => likeComment(comment?.id)}
                    icon={
                      <ThumbsUp
                        className={`icon ${
                          comment?.liked_by?.includes(user.id) ? 'active' : ''
                        }`}
                        color={
                          comment?.liked_by?.includes(user.id) ? '#1372FA' : ''
                        }
                      />
                    }
                    text={`${comment.liked_by ? comment.liked_by.length : 0}`}
                  />
                  <Button
                    variant="tertiary"
                    icon={<CornerDownRight className="icon" />}
                    text="Reply"
                    onClick={() => {
                      setCommentsWithTextfields((a) => [...a, comment?.id]);
                      setIsReplying(true);
                    }}
                  />
                </span>
              )}
            </div>
          </>
        )}
      </StyledComment>
      <Divider />
      {isReplying && (
        <>
          <div
            className="section"
            style={{ padding: '1rem', paddingLeft: `${comment.depth * 2}rem` }}
          >
            <NotesTextField
              commentToReply={comment}
              pinIsAttached={pinIsAttached}
              pinIsGrabbed={pinIsGrabbed}
              avatar={avatar}
              cleanUpPin={cleanUpPin}
              newPinnedNoteIndex={newPinnedNoteIndex}
              handleCancelButton={() => {
                setCommentsWithTextfields((a) =>
                  a.filter((e) => e !== comment?.id)
                );
                cleanUpPin();
                setIsReplying(false);
              }}
              placeholder="Write a reply..."
              postComment={(content) => {
                postComment(content, comment?.id, comment?.copy_id);
                setIsReplying(false);
              }}
              videoRef={videoRef}
              dropPinDisabled={dropPinDisabled}
              setPinIsGrabbed={setPinIsGrabbed}
            />
          </div>
          <Divider />
        </>
      )}
      {comment.replies?.map((reply) => (
        <Comment
          key={reply.id}
          parentCommentUser={`${comment.user?.first_name} ${comment.user?.last_name}`}
          comment={reply}
          pinIsAttached={pinIsAttached}
          adLastUpdate={adLastUpdate}
          assets={assets}
          setActiveAssetIndex={setActiveAssetIndex}
          postComment={postComment}
          isReplying={isReplying}
          genStyledCommentWithTimestamps={genStyledCommentWithTimestamps}
          newPinnedNoteIndex={newPinnedNoteIndex}
          setPinIsGrabbed={setPinIsGrabbed}
          cleanUpPin={cleanUpPin}
          setIsReplying={setIsReplying}
          adPinnedNotes={adPinnedNotes}
          deleteComment={deleteComment}
          internalCommentsShown={internalCommentsShown}
          editComment={editComment}
          videoRef={videoRef}
          avatar={avatar}
          dropPinDisabled={dropPinDisabled}
          likeComment={likeComment}
          setCommentsWithTextfields={setCommentsWithTextfields}
        />
      ))}
    </>
  );
};

Comment.propTypes = {
  videoRef: PropTypes.shape().isRequired,
  comment: PropTypes.shape().isRequired,
  postComment: PropTypes.func.isRequired,
  adLastUpdate: PropTypes.instanceOf(Date).isRequired,
  deleteComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  dropPinDisabled: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  adPinnedNotes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cleanUpPin: PropTypes.func.isRequired,
  parentCommentUser: PropTypes.string,
  newPinnedNoteIndex: PropTypes.number.isRequired,
  pinIsAttached: PropTypes.bool,
  pinIsGrabbed: PropTypes.bool,
  setPinIsGrabbed: PropTypes.func.isRequired,
  setCommentsWithTextfields: PropTypes.func.isRequired,
  likeComment: PropTypes.func.isRequired,
  genStyledCommentWithTimestamps: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setActiveAssetIndex: PropTypes.func.isRequired,
  internalCommentsShown: PropTypes.bool.isRequired,
};

Comment.defaultProps = {
  parentCommentUser: null,
  pinIsAttached: false,
  pinIsGrabbed: false,
};

export default Comment;
