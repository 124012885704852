/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import S3 from 'react-aws-s3';
import { useDispatch } from 'react-redux';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';
import { PostData } from '../actions';

export const useMultipleFileUploader = (
  s3UploadURL,
  dbPostURL,
  dbOrgID,
  fileTypes,
  cb,
  getLinks
) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const dispatch = useDispatch();
  const { axiosWithAuth } = useAxioswithAuth();

  // specify upload params and url for your files
  const getUploadParams = () => {
    return { url: `${process.env.REACT_APP_API_URL}` };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files, allFiles) => {
    const links = [];

    const promises = allFiles.map(async (f) => {
      const { file } = f;
      const newFileName = f.file.name.replace(/[^A-Z0-9]+/gi, '-');
      const config = {
        bucketName: 'colab-platformbucket',
        dirName:
          process.env.NODE_ENV === 'development'
            ? `development/${s3UploadURL}`
            : s3UploadURL,
        region: 'us-west-1',
        accessKeyId: process.env.REACT_APP_AWS_TOKEN_KEYID,
        secretAccessKey: process.env.REACT_APP_AWS_TOKEN_KEYSECRET,
      };
      setloadingSpinner(true);

      const ReactS3Client = new S3(config);

      try {
        await ReactS3Client.uploadFile(file, newFileName).then(async (d) => {
          if (d.status === 204) {
            dispatch(
              PostData(
                axiosWithAuth,
                dbPostURL,
                {
                  name: newFileName,
                  type: newFileName.includes('SOW') ? 'SOW' : fileTypes,
                  is_sensitive: !!fileTypes.includes('sensitive'),
                  organization_id: dbOrgID,
                  file_url: d.location,
                },
                'documents'
              )
            );

            links.push(d.location);
          } else {
            console.log('fail');
          }
        });
        return 'success';
      } catch (error) {
        console.error(error.response);
        return ' error';
      }
    });

    await Promise.all(promises)
      .then((response) => {
        console.log(response, 'yes');
        if (getLinks) {
          getLinks(links);
        }
        return allFiles.forEach((f) => f.remove());
      })
      .catch((error) => {
        setFileUploaded('error');
        console.log(`Error in executing ${error}`);
      });
    setFileUploaded(true);
    setloadingSpinner(false);
    if (cb) {
      cb();
    }
  };

  return {
    getUploadParams,
    handleChangeStatus,
    handleSubmit,
    fileUploaded,
    loadingSpinner,
  };
};
