import { Button, Card, Divider, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { ChevronLeft } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CoEfficientLabsIcon from '../../assets/icons/CoefficientLabs';
import { scrollIntoView } from '../../utils/helper';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import Pin from '../Pin';
import CommentTextfield from './CommentTextField';
import CopyComment from './CopyComment';

const StyledCommentsPanel = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 1rem 0.5rem 1rem 0.5rem;
  > .commentsPanel {
    transition: all 0.2s ease-in-out;
    width: 0;
    opacity: 0;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 2px solid #ffffffff;
    opacity: 1;
    width: 100%;
    &.internal {
      border: 2px solid #ffa311;
      > .commentsHeader {
        color: #ffa311;
        > .internalCommentsToggle {
          .icon {
            fill: #ffa311;
          }
        }
      }
      .cui-textfield {
        .container {
          border: 2px solid #ffa311;
        }
      }
    }
    /* &.open {
      width: 28rem;
      opacity: 1;
    } */
    > .commentsHeader {
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      > .title {
        flex-grow: 1;
      }
      > .internalCommentsToggle {
        .icon {
          fill: #2c81fb;
        }
      }
    }
    > .commentsBody {
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      &.message {
        padding: 1rem;
        justify-content: center;
      }
      ${({ theme }) => theme.scrollbar}
    }
    > .commentTextfield {
      padding: 1rem;
    }
  }
`;

const CommentsPanel = ({
  isCommentPanelOpen,
  comments,
  copyToComment,
  fetchComments,
  changeCopyToComment,
  commentAttachment,
  sendDisabled,
  textfieldPlaceholder,
  internalComments,
  internalCommentsShown,
  setShownPanels,
  shownPanels,
  setInternalCommentsShown,
}) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);

  const postComment = async (content, parentComment) => {
    try {
      await axiosWithAuthRef.current().post('/comments', {
        content,
        parent_id: parentComment?.id,
        related_table: 'copy',
        related_id: copyToComment?.id || parentComment?.copy_id,
        is_internal: internalCommentsShown,
      });
    } catch (e) {
      console.log(e);
    }
    await fetchComments();
  };

  const editComment = async (id, content) => {
    try {
      await axiosWithAuth().put(`/comments/${id}`, { content });
    } catch (e) {
      console.log(e);
    }
    await fetchComments();
  };

  const likeComment = async (id) => {
    try {
      await axiosWithAuth().put(`/comments/like/${id}`);
    } catch (e) {
      console.log(e);
    }
    await fetchComments();
  };

  const deleteComment = async (id) => {
    try {
      await axiosWithAuth().delete(`/comments/${id}`);
    } catch (e) {
      console.log(e);
    }
    await fetchComments();
  };

  const userData = useSelector(({ user }) => user);
  const shownComments = [
    ...((internalCommentsShown ? internalComments : comments) || []),
  ];
  return (
    <StyledCommentsPanel className="commentsWrapper">
      <Card
        elevation={5}
        className={`commentsPanel ${isCommentPanelOpen ? 'open ' : ''}${
          internalCommentsShown ? 'internal' : ''
        }`}
      >
        <div className="commentsHeader">
          <Button
            onClick={() =>
              setShownPanels((prev) => ({
                maxSlots: prev.maxSlots,
                panels: [1, 0].slice(0, prev.maxSlots),
              }))
            }
            variant="tertiary"
            icon={
              <ChevronLeft
                style={{
                  transform:
                    shownPanels.panels.length === 1
                      ? 'rotate(0)'
                      : 'rotate(180deg)',
                }}
              />
            }
          />
          <Text weight="medium" size={4} className="title" align="center">
            {internalCommentsShown ? 'Internal Comments' : 'Comments'}
          </Text>
          {userData.organization.id === 1 && (
            <Button
              onClick={() =>
                setInternalCommentsShown((prevState) => !prevState)
              }
              icon={
                <CoEfficientLabsIcon
                  className={`icon ${internalCommentsShown ? 'active' : ''}`}
                />
              }
              variant={internalCommentsShown ? 'secondary' : 'tertiary'}
              size="sm"
              className="internalCommentsToggle"
            />
          )}
        </div>
        <Divider />
        <div
          className={`commentsBody ${
            shownComments?.length === 0 ? 'message' : ''
          }`}
        >
          {shownComments?.length > 0 ? (
            shownComments?.map((comment) => {
              return (
                <CopyComment
                  key={comment.id}
                  editComment={editComment}
                  comment={comment}
                  likeComment={likeComment}
                  deleteComment={deleteComment}
                  onTextfieldCancel={() => changeCopyToComment(null)}
                  postComment={postComment}
                  commentAttachment={
                    <Pin
                      onClick={() => scrollIntoView(`copy-${comment.copy_id}`)}
                      className={comment?.is_internal ? 'internal' : 'colabs'}
                      number={comment?.copyOrderedIndex + 1}
                    />
                  }
                />
              );
            })
          ) : (
            <Text align="center">There are no Comments in this Copy Deck</Text>
          )}
        </div>
        <Divider />
        <div className="commentTextfield">
          <CommentTextfield
            postComment={postComment}
            onCancel={() => changeCopyToComment(null)}
            sendDisabled={sendDisabled}
            commentAttachments={
              copyToComment && commentAttachment ? (
                <div className="commentPin">{copyToComment?.orderedIndex}</div>
              ) : (
                ''
              )
            }
            placeholder={textfieldPlaceholder}
          />
        </div>
      </Card>
    </StyledCommentsPanel>
  );
};

CommentsPanel.propTypes = {
  isCommentPanelOpen: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape()),
  copyToComment: PropTypes.shape(),
  editComment: PropTypes.func.isRequired,
  likeComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  changeCopyToComment: PropTypes.func.isRequired,
  commentAttachment: PropTypes.shape().isRequired,
};

CommentsPanel.defaultProps = {
  comments: null,
  copyToComment: null,
};

export default CommentsPanel;
