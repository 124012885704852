import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'colabs-ui-react';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { camelToSentenceCase } from '../../utils/helper';

const StyledCopyCategory = styled.div`
  padding: 0.75rem 1.25rem 0.25rem 1.75rem;
  display: flex;
  justify-content: space-between;
  > .tooltip {
    margin-left: 0.5rem;
    line-height: 1.4rem;
  }
  > .grow {
    flex-grow: 1;
  }
  .platformIcon {
    height: 1rem;
    width: 4rem;
    object-fit: contain;
    /* flex-grow: 1; */
  }
`;

const CopyCategory = ({
  array,
  index,
  setCurrent,
  id,
  reviewedCount,
  totalCount,
  tooltip,
  isPlatform,
}) => {
  const categoryRef = useRef();

  let previousY = 0;
  let previousRatio = 0;

  useIntersectionObserver({
    refs: [categoryRef],
    callback: (entry) => {
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;
      const { isIntersecting } = entry;
      if (currentY < previousY) {
        if (currentRatio > previousRatio && isIntersecting) {
          // console.log('Scrolling down enter');
        } else {
          // console.log('Scrolling down leave');
          setCurrent(array[index]);
        }
      } else if (currentY > previousY && isIntersecting) {
        if (currentRatio < previousRatio) {
          // console.log('Scrolling up leave');
        } else {
          // console.log('Scrolling up enter');
          setCurrent(array[index && index - 1]);
        }
      }
      previousY = currentY;
      previousRatio = currentRatio;
    },
    options: {
      threshold: [0.1],
    },
  });

  return (
    <>
      <StyledCopyCategory
        ref={categoryRef}
        style={{ marginTop: isPlatform && index !== 0 ? '3rem' : null }}
      >
        {isPlatform ? (
          <img
            className="platformIcon"
            alt=""
            src={require(`../../assets/images/socialLogos/${array[index]}.png`)}
          />
        ) : (
          <Text weight="medium">{camelToSentenceCase(array[index])}</Text>
        )}
        {tooltip && (
          <Text
            size={2}
            style={{ color: '#84888A' }}
            weight="italic"
            className="tooltip"
          >
            {tooltip}
          </Text>
        )}
        <span className="grow" />
        {(reviewedCount !== null || totalCount !== null) && (
          <span>
            <Text size={2} tag="span">
              {reviewedCount}
            </Text>{' '}
            <Text size={2} tag="span" style={{ color: '#65676a' }}>
              / {totalCount} Reviewed
            </Text>
          </span>
        )}
      </StyledCopyCategory>
      <span id={`category-${id}`} />
    </>
  );
};

CopyCategory.propTypes = {
  array: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  reviewedCount: PropTypes.number,
  totalCount: PropTypes.number,
  tooltip: PropTypes.string,
  isPlatform: PropTypes.bool,
};

CopyCategory.defaultProps = {
  reviewedCount: null,
  totalCount: null,
  tooltip: null,
  isPlatform: false,
};

export default CopyCategory;
