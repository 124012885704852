import React from 'react';
import styled from 'styled-components';
import SettingsHeader from '../components/settings/SettingsHeader';
import SettingsNavPanel from '../components/settings/SettingsNavPanel';
import MainLayout from './Main';

const StyledSettingsLayout = styled.div`
  display: grid;
  padding: 10px;
  grid-gap: 10px;
  width: 100%;
  max-width: 1310px;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: min-content 1fr;
  > .main-panel {
    display: flex;
    flex-direction: column;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    border-radius: 12px;
    overflow: hidden;
  }
`;

const SettingsLayout = ({ children }) => {
  return (
    <MainLayout centered>
      <StyledSettingsLayout>
        <SettingsHeader />
        <SettingsNavPanel />
        <div className="main-panel">{children}</div>
      </StyledSettingsLayout>
    </MainLayout>
  );
};

export default SettingsLayout;
