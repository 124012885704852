// ? Use This to get geoLocation    :

// window.navigator.geolocation.getCurrentPosition(
//     successfulLookup,
//     console.log
//   );
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import FullPod from '../components/colabsAdmin/ProposalDocumentOptions/FullPod';

export const fullPodAndVideoPreProduction = [
  'Client communication (Pre-Production)',
  'Research (Pre-Production)',
  'Storyboarding (Pre-Production)',
  'Shot Designing (Pre-Production)',
  'Planning (Pre-Production)',
  'Location Scouting (Pre-Production)',
  'Light Set-up Design (Pre-Production)',
  'Casting Direction (Pre-Production)',
  'Script Writing (Pre-Production)',
];

export const fullPodAndVideoProduction = [
  'Talent Auditions (Production)',
  'Test Runs (Production)',
  'Rehearsal (Production)',
  'Set Production (audio / lighting) (Production)',
  'Filming (on set & off set) (Production)',
  'Set Wrapping (Production)',
];

export const fullPodAndVideoPostProduction = [
  'Digitizing (Post-Production)',
  'Rough Cutting (Editing) (Post-Production)',
  'Graphic & Motion Graphic Production (Post-Production)',
  'Fine Cutting (Editing) (Post-Production)',
  'Color Grading (Post-Production)',
  'Audio Design (Post-Production)',
  'Post-Production Supervision (Post-Production)',
  'Team & Client Collaboration (Post-Production)',
  'Final Cut & Micro Cut Exports (Post-Production)',
];

export function getFormattedDate(date) {
  const year = new Date(date)?.getFullYear();
  const month = (1 + new Date(date)?.getMonth()).toString().padStart(2, '0');
  const day = new Date(date)?.getDate().toString().padStart(2, '0');
  return `${month}/${day}/${String(year).slice(2)}`;
}

export const fullPodWeeks = (
  num = 12,
  weekTitle = ['Week 1 & 2', 'Week 3 & 4'],
  breakPage,
  sectionLength,
  additionalArrays = null,
  ExtraArrays = null
) => {
  const weekTemplateOne = [
    `Launch Round ${num} Creative Decks`,
    `Creative Deck for Round ${num + 1} Ads Submitted`,
    `Copy Deck for Round ${num + 1} Ads Submitted`,
    `Round ${num} Executive Report`,
    `Launch Round ${num + 1} Creative Decks`,
    `Creative Deck for Round ${num + 2} Ads Submitted`,
    `Copy Deck for Round ${num + 2} Ads Submitted`,
    `Round ${num + 1} Executive Report`,
    `Bidding Adjustments + Daily Optimizations`,
  ];

  const weekTemplateTwo = [
    `Launch Round ${num + 2} Creative Decks`,
    `Creative Deck for Round ${num + 3} Ads Submitted`,
    `Copy Deck for Round ${num + 3} Ads Submitted`,
    `Round ${num + 2} Executive Report`,
    `Launch Round ${num + 3} Creative Decks`,
    `Creative Deck for Round ${num + 4} Ads Submitted`,
    `Copy Deck for Round ${num + 4} Ads Submitted`,
    `Round ${num + 3} Executive Report`,
    `Bidding Adjustments + Daily Optimizations`,
  ];

  const lastWeekTemplate = [
    `Launch Round ${num + 2} Creative Decks`,
    `Creative Deck for Round ${num + 3} Ads Submitted`,
    `Copy Deck for Round ${num + 3} Ads Submitted`,
    `Round ${num + 2} Executive Report`,
    `Launch Round ${num + 3} Creative Decks`,
    `Round ${num + 3} Executive Report`,
    `Bidding Adjustments + Daily Optimizations`,
  ];

  if (additionalArrays) {
    if (additionalArrays[0] && additionalArrays[0] !== null) {
      for (let index = 0; index < additionalArrays[0].length; index += 1) {
        weekTemplateOne.push(additionalArrays[0][index]);
      }
    }
    if (additionalArrays[1] && additionalArrays[1] !== null) {
      for (let index = 0; index < additionalArrays[1].length; index += 1) {
        weekTemplateTwo.push(additionalArrays[1][index]);
      }
    }
    if (additionalArrays[2] && additionalArrays[2] !== null) {
      for (let index = 0; index < additionalArrays[2].length; index += 1) {
        lastWeekTemplate.push(additionalArrays[2][index]);
      }
    }
  }

  if (ExtraArrays) {
    if (ExtraArrays[0] && ExtraArrays[0] !== null) {
      for (let index = 0; index < ExtraArrays[0].length; index += 1) {
        weekTemplateOne.push(ExtraArrays[0][index]);
      }
    }
    if (ExtraArrays[1] && ExtraArrays[1] !== null) {
      for (let index = 0; index < ExtraArrays[1].length; index += 1) {
        weekTemplateTwo.push(ExtraArrays[1][index]);
      }
    }
    if (ExtraArrays[2] && ExtraArrays[2] !== null) {
      for (let index = 0; index < ExtraArrays[2].length; index += 1) {
        lastWeekTemplate.push(ExtraArrays[2][index]);
      }
    }
  }

  return {
    title:
      sectionLength && sectionLength === 2
        ? [weekTitle[0], weekTitle[1]]
        : [`${weekTitle[0] || 'Week'}`],
    bulletPoints:
      sectionLength && sectionLength === 2
        ? [weekTemplateOne, num === 9 ? lastWeekTemplate : weekTemplateTwo]
        : [weekTemplateOne],
    pageBreak: breakPage || false,
    num,
    additionalArrays,
    ExtraArrays,
  };
};
export const fullVideoWeeks = (
  weekTitle = ['Week 1 & 2', 'Week 3 & 4'],
  breakPage,
  sectionLength = 1,
  sectionTitle,
  template
) => {
  return {
    title:
      sectionLength && sectionLength === 2
        ? [weekTitle[0], weekTitle[1]]
        : [`${weekTitle[0] || 'Week'}`],
    bulletPoints: [template],
    pageBreak: breakPage || false,
    sectionTitle,
  };
};
export const fullLandingPageWeeks = (
  weekTitle = ['Week 1 & 2', 'Week 3 & 4'],
  breakPage,
  sectionLength = 1,
  template,
  sectionTitle
) => {
  return {
    title:
      sectionLength && sectionLength === 2
        ? [weekTitle[0], weekTitle[1]]
        : [`${weekTitle[0] || 'Week'}`],
    bulletPoints: [template],
    pageBreak: breakPage || false,
    sectionTitle,
  };
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const camelToTitle = (str) =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const successfulLookup = (position) => {
  const { latitude, longitude } = position.coords;
  fetch(
    `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=705f4c84922a42288795a13e3790f5db`
  ).then((response) => console.log(response.json()));
};

export const formatDate = (string) => {
  return format(
    new Date(
      string.substring(0, 4),
      string.substring(4, 6) - 1,
      string.substring(6, 8)
    ),
    'MMM. d, yyyy'
  );
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function validatePackages(arrToBeMapped, arrayToBeValidated, arrLength) {
  if (!arrToBeMapped.length) {
    return false;
  }
  return arrToBeMapped.every((val, _, arr) => {
    if (arrayToBeValidated.includes(val.name) && arr.length === arrLength) {
      return true;
    }
    return false;
  });
}
export const transformToDate = (string) => {
  return new Date(
    string.substring(0, 4),
    string.substring(4, 6) - 1,
    string.substring(6, 8)
  );
};

export const formatUsd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const limitString = (string, length) => {
  if (string.length > length) return `${string.substring(0, length)}...`;
  return string;
};

export const scrollIntoView = (selector) => {
  const element = document.getElementById(selector);
  element.scrollIntoView();
};

export const camelToSentenceCase = (text) => {
  const result = text.replace(/([A-Z0-9])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getDisplayName = (
  { first_name: firstName, last_name: lastName, email },
  maxLength = 100
) => {
  if (firstName && lastName && firstName.length + lastName.length < maxLength)
    return `${firstName} ${lastName}`;
  if (firstName && firstName.length < maxLength) return firstName;
  const emailUsername = email?.split('@')[0];
  if (emailUsername && emailUsername.length < maxLength) return emailUsername;
  if (firstName) return `${firstName.substring(0, maxLength - 3)}...`;
  if (emailUsername) return `${emailUsername.substring(0, maxLength - 3)}...`;
  return '';
};

export function formatNameOrEmail(firstName, lastName, email) {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  return email || 'Unknown';
}

export default (fileData, fileName) => FileSaver.saveAs(fileData, fileName);

export const pdfLines = (inputs) => {
  const discount = inputs.payment_discount ?? 20;
  const paymentDiscount = (100 - discount) * 0.01;
  const lpPageIndex = inputs.packages.findIndex(
    (e) => e.name === 'Landing Page'
  );
  const packages = inputs.packages.map((e) => e.name);
  let payments = [];
  if (inputs.payments.length) {
    payments = inputs.payments.map((e) => e);
  }
  const preProduction = [
    'Client communication',
    'Research',
    'Storyboarding',
    'Shot Designing',
    'Planning',
    'Location Scouting',
    'Light Set-up Design',
    'Casting Direction',
    'Script Writing',
  ];
  const production = [
    'Talent Auditions',
    'Test Runs',
    'Rehearsal',
    'Set Production (audio / lighting)',
    'Filming (on set & off set)',
    'Set Wrapping',
  ];
  const postProduction = [
    'Digitizing',
    'Rough Cutting (Editing)',
    'Graphic & Motion Graphic Production',
    'Fine Cutting (Editing)',
    'Color Grading',
    'Audio Design',
    'Post-Production Supervision',
    'Team & Client Collaboration',
    'Final Cut & Micro Cut Exports',
  ];

  return {
    setUpFee: 3000,
    pageBreaks: [],
    line1: 'Statement of Work',
    line2: 'Between',
    line3: 'CoEfficient Labs and',
    line4: '',
    line5: 'CoEfficient Labs Phase',
    line6: '(SOW)',
    line7:
      '“Agency” represents CoEfficient Labs to deliver the services defined in this SOW. “Client” represents the organization that will engage with the Agency for the delivery of services defined herein. “Project” represents the full scope of the work that the Agency will perform for the Client under the terms of SOW.',
    phase: '',
    contract_date: '',
    project_start_date: '',
    project_end_date: '',
    service_length_desc: !packages.includes('Full Pod')
      ? ''
      : `The sprint will begin after the aforementioned pre-launch. Upon the end of the sprint, the client will have the option to renew for another sprint. Any renewal by Client will be subject to a new SOW.`,
    fees_for_growth_sprint_desc:
      'Payment is conducted through the CoEfficient Labs payment system on the scheduled dates listed below.',
    payment_discount: inputs.payment_discount ?? 20,
    payments,
    line8:
      'CoEfficient Labs has two (2) opportunities to receive performance bonus ($) in Phase I.',
    line9: ` Bonus #1: Hitting the agreed upon ${
      inputs.mediaGoals.length
        ? inputs.mediaGoals.map(
            (e) =>
              `${
                e === 'Marketing Qualified Leads'
                  ? 'Monthly Marketing Qualified Lead (MQLs)'
                  : e
              }${
                e === inputs.mediaGoals[inputs.mediaGoals.length - 1]
                  ? ''
                  : ', '
              }`
          )
        : 'Monthly Marketing Qualified Leads (MQLs)'
    } Targets with the agreed upon Advertising Spend.`,
    line10: `If CoEfficient Labs hits the ${
      inputs.payments.length
    }-month cumulative lead targets stated in the Expected ${
      inputs.payments.length
    }-Month Media-Plan (see below), then CoEfficient Labs will receive their full-flat fee of ${numberWithCommas(
      Math.round(inputs?.payments[1]?.payment_amount)
    )}/month for${
      inputs.social_platforms.length
        ? inputs.social_platforms.map((e) => {
            console.log(e, '<=bonus');
            if (e === 'Google Ads') {
              return ' Google Ads';
            }
            return ` ${e} Ads`;
          })
        : ''
    }, and Creative -- instead of the current discounted fee $${numberWithCommas(
      Math.round(
        (inputs?.payments[1]?.payment_amount * paymentDiscount) / 1000
      ) * 1000
    )}/month.`,
    line11: `Bonus #2: Exceeding the agreed upon ${
      inputs.mediaGoals.length
        ? inputs.mediaGoals.map(
            (e) =>
              `${
                e === 'Marketing Qualified Leads'
                  ? 'Monthly Marketing Qualified Lead (MQLs)'
                  : e
              }${
                e === inputs.mediaGoals[inputs.mediaGoals.length - 1]
                  ? ''
                  : ', '
              }`
          )
        : 'Monthly Marketing Qualified Leads (MQLs)'
    } Targets with the agreed upon Advertising Spend.`,
    line12: `If CoEfficient Labs exceeds the ${
      inputs?.payments?.length
    }-month total leads and total meetings scheduled targets in the Expected ${
      inputs?.payments?.length
    }-Month Media Plan (see below), then CoEfficient Labs will receive their full-flat fee of $${numberWithCommas(
      Math.round(inputs?.payments[1]?.payment_amount)
    )}/month, plus an additional $${numberWithCommas(
      (inputs?.payments[1]?.payment_amount -
        Math.round(
          (inputs?.payments[1]?.payment_amount * paymentDiscount) / 1000
        ) *
          1000) *
        inputs?.payments.length
    )} for achieving the performance bonus -- instead of the current discounted fee of $${numberWithCommas(
      Math.round(
        (inputs?.payments[1]?.payment_amount * paymentDiscount) / 1000
      ) * 1000
    )}/month.`,
    line13: `While data will be tracked on a day-to-day basis, measurement for the performance bonus shall be conducted no later than ${
      inputs?.performance_measurement_choice
    }-days after the conclusion of the sprint (${
      inputs && inputs.performance_measurement_date
        ? `${
            monthNames[new Date(inputs.performance_measurement_date).getMonth()]
          } ${new Date(
            inputs.performance_measurement_date
          ).getDate()}, ${new Date(
            inputs.performance_measurement_date
          ).getFullYear()}`
        : 'March, 1st 2021'
    }).`,
    line14: `*Should CoEfficient Labs hit either of the two Bonus opportunities, payment of Performance Bonus is due ${
      inputs.performance_measurement_choice
    }-days after the goal has been hit; end date of this Sprint (${
      inputs.performance_measurement_date
        ? `${
            monthNames[new Date(inputs.performance_measurement_date).getMonth()]
          } ${new Date(
            inputs.performance_measurement_date
          ).getDate()}, ${new Date(
            inputs.performance_measurement_date
          ).getFullYear()}`
        : 'March, 1st 2021'
    }); or ${
      inputs.performance_measurement_choice
    }-Days after Termination notice has been provided.`,
    fullPod: packages.every((e) => e.includes('Full Pod'))
      ? {
          descriptionOfServices: `The objective of this project is to attain specific ${
            inputs.mediaGoals.length
              ? inputs.mediaGoals.map(
                  (e) =>
                    `${
                      e === 'Marketing Qualified Leads'
                        ? 'Monthly Marketing Qualified Lead (MQLs)'
                        : e
                    }${
                      e === inputs.mediaGoals[inputs.mediaGoals.length - 1]
                        ? ''
                        : ', '
                    }`
                )
              : 'Monthly Marketing Qualified Leads (MQLs)'
          } goals by developing and implementing paid acquisition strategies and a Media-Plan (for${
            inputs.social_platforms.length
              ? inputs.social_platforms.map((e, index, arr) => {
                  if (e === 'Google Ads') {
                    return ' Google Ads';
                  }
                  if (index === arr.length - 1) {
                    return ` and ${e}`;
                  }
                  return ` ${e}`;
                })
              : ''
          }).`,
          descNote:
            '*All Services Include Creative, Data Science, and Media Planning / Growth Hacking',
          descSynopsisIntro:
            'This document covers the synopsis of what the client should expect from this engagement:',
          descSynopsis: [
            `Extract & analyze existing data sets & campaigns (i.e.${
              inputs.social_platforms.includes('Facebook')
                ? ` Facebook Ad Manager /`
                : ''
            } ${
              inputs.social_platforms.includes('Google Ads')
                ? `Google Ads / Google Analytics /`
                : ''
            } etc.)`,
            'Develop & implement on-going audience-building strategies',
            `Design Graphical Ads (still image + animation) on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Copywriting & taglines to accompany the ads on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Data Analytics and Bi-Weekly Executive Reporting`,
            `A/B Testing & On-Going Media Plan Optimizations`,
          ],
          projectStartDate: `Project start date is suggested to be ${
            inputs.project_start_date
              ? `${
                  monthNames[new Date(inputs.project_start_date).getMonth()]
                } ${new Date(inputs.project_start_date).getDate()}, ${new Date(
                  inputs.project_start_date
                ).getFullYear()}`
              : 'March, 1st 2021'
          }.`,
          deliverablesDescription:
            'The project will include the following deliverables.',
          preLaunchTitle: 'Pre-Launch (2-Week Sprint):',
          preLaunch: [
            [
              'Kick off Call',
              'Creative Team Sync',
              'Data Team Sync',
              'Creative Assets Received from Client',
              'Setup Tracking for all Key Events',
              'Onboard to Ad Approval Tool',
              'Build Executive Report Template',
              'Create Buyer Persona Positioning Document',
            ],
            [
              'Reference Deck Submitted',
              'Creative Deck for Round 1 Ads Submitted',
              'Copy Deck for Round 1 Ads Submitted',
              'Google Keyword Research',
              `Custom Targeting Stack for Each Persona`,
              'Re-Build Campaign Structure',
              'Schedule Executive Report',
              'Build Performance Tracker',
              'Build Data Flow',
            ],
          ],
          sprintLength: '12 Week Sprint:',
          podWeeks: [
            fullPodWeeks(1, ['Week 1 & 2', 'Week 3 & 4'], false, 2),
            fullPodWeeks(5, ['Week 5 & 6', 'Week 7 & 8'], true, 2),
            fullPodWeeks(9, ['Week 9 & 10', 'Week 11 & 12'], false, 2),
          ],
        } // end of full pod
      : null,
    video: packages.every((e) => e.includes('Video'))
      ? {
          descriptionOfServices:
            'The objective of this project is to deliver the completed videos outlined in the Proposal',
          descNote: `This document covers the synopsis of what ${inputs.organization_name} should expect from this engagement:`,
          preProductionTitle: 'Pre-Production:',
          preProduction,
          productionTitle: 'Production:',
          production,
          postProductionTitle: 'Post-Production:',
          postProduction,
          projectStartDate: `Project start date is suggested to be ${
            inputs.project_start_date
              ? `${
                  monthNames[new Date(inputs.project_start_date).getMonth()]
                } ${new Date(inputs.project_start_date).getDate()}, ${new Date(
                  inputs.project_start_date
                ).getFullYear()}`
              : 'March, 1st 2021'
          }.`,
          deliverablesDescription:
            'The project will include the following deliverables.',
          podWeeks: [
            fullVideoWeeks(
              ['Week 1 & 2'],
              true,
              1,
              'Pre-Production',
              preProduction
            ),
            fullVideoWeeks(['Week 3 & 4'], false, 1, 'Production', production),
            fullVideoWeeks(
              ['Week 5 & 6'],
              false,
              1,
              'Post-Production',
              postProduction
            ),
          ],
        } // end of Video Packages
      : null,

    landingPage: packages.every((e) => e.includes('Landing Page'))
      ? {
          descriptionOfServices: `This document covers the synopsis of what the client could expect from this engagement for (${inputs?.packages[lpPageIndex]?.number}) Landing Pages.`,
          descSynopsis: [
            `Competitive Landing Page Research `,
            'Build Wireframe Architecture',
            'UX and Low Fidelity Designs in Figma',
            `1 High-Fidelity Landing Page Funnel (4-9 Panels and Lead Capture Flow) `,
            `Full-Stack Development`,
            `QA Testing`,
            'On-Going Conversion Rate Optimization',
          ],
          projectStartDate: `Project start date is suggested to be ${
            inputs.project_start_date
              ? `${
                  monthNames[new Date(inputs.project_start_date).getMonth()]
                } ${new Date(inputs.project_start_date).getDate()}, ${new Date(
                  inputs.project_start_date
                ).getFullYear()}`
              : 'March, 1st 2021'
          }.`,
          deliverablesDescription:
            'The project will include the following deliverables.',
          podWeeks: [
            fullLandingPageWeeks(
              ['Week 1 & 2'],
              false,
              1,
              [
                'Primary Research for Landing Pages (Competitor Analysis)',
                'Sitemap is Submitted',
                'Low-Fidelity Wireframe Submitted',
              ],
              null
            ),
            fullLandingPageWeeks(
              ['Week 3 & 4'],
              false,
              1,
              [
                'V1 of UX for Landing Page (Desktop)',
                'Low Fidelity Color Designs in Figma (Desktop)',
                'V1 of Copywriting is Approved by Client',
                'Client Review, Notes, and Approvals',
              ],
              null
            ),
            fullLandingPageWeeks(
              ['Week 5 & 6'],
              false,
              1,
              [
                'High-Fidelity Color Designs for Desktop ("HomePage" 4-9 Panels, Lead Capture Flow)',
                'High-Fidelity Color Designs for Mobile ("HomePage" 4-9 Panels, Lead Capture Flow)',
                'V2 of Copywriting is Approved by Client',
                'Client Review',
              ],
              null
            ),
            fullLandingPageWeeks(
              ['Week 7 & 8'],
              false,
              1,
              [
                'Pixel Implementation, Analytics, and Custom Conversion Set-Up',
                'Converting Photoshop/Figma layers into html ',
                'Front-end / Back-end development and implementation for Desktop ',
                'Front-end / Back-end development and implementation for Mobile',
                'Integrate Lead Flow into CMS',
                'Integrate with Client DNS (for Sub-Domain)',
                'QA Testing',
                'Launch',
              ],
              null
            ),
            fullLandingPageWeeks(
              ['Week 9 - 12'],
              true,
              1,
              [
                'Copy Testing',
                'Call to Action (CTA) Testing',
                'Panel Adjustments/Creation',
                'Form Testing',
                'Color & Button Testing',
                'Video & Image Testing',
                'Dev Maintenance & QA Testing',
              ],
              'On-Going Landing Page A/B Testing'
            ),
          ],
        } // end of Landing Page Package
      : null,

    Website: packages.every((e) => e.includes('Website'))
      ? {
          descriptionOfServices: `This document covers the synopsis of what the client could expect from this engagement for (${inputs?.packages[lpPageIndex]?.number}) Landing Pages.`,
          descSynopsis: [
            `Competitive Landing Page Research `,
            'Build Wireframe Architecture',
            'UX and Low Fidelity Designs in Figma',
            `1 High-Fidelity Website`,
            `Full-Stack Development`,
            `QA Testing`,
            'On-Going Conversion Rate Optimization',
          ],
          projectStartDate: `Project start date is suggested to be ${
            inputs.project_start_date
              ? `${
                  monthNames[new Date(inputs.project_start_date).getMonth()]
                } ${new Date(inputs.project_start_date).getDate()}, ${new Date(
                  inputs.project_start_date
                ).getFullYear()}`
              : 'March, 1st 2021'
          }.`,
          deliverablesDescription:
            'The project will include the following deliverables.',
          podWeeks: [
            fullLandingPageWeeks(
              ['Week 1 & 2'],
              false,
              1,
              [
                'Primary Research for Website (Competitor Analysis)',
                'Sitemap is Submitted',
                'Low-Fidelity Wireframe Submitted',
              ],
              null
            ),
            fullLandingPageWeeks(
              ['Week 3 & 4'],
              false,
              1,
              [
                'V1 of UX for Website (Desktop)',
                'Low Fidelity Color Designs in Figma (Desktop)',
                'V1 of Copywriting is Approved by Client',
                'Client Review, Notes, and Approvals',
              ],
              null
            ),
            fullLandingPageWeeks(
              ['Week 5 & 6'],
              false,
              1,
              [
                'High-Fidelity Color Designs for Desktop',
                'High-Fidelity Color Designs for Mobile',
                'V2 of Copywriting is Approved by Client',
                'Client Review',
              ],
              null
            ),
            fullLandingPageWeeks(
              ['Week 7 & 8'],
              false,
              1,
              [
                'Pixel Implementation, Analytics, and Custom Conversion Set-Up',
                'Converting Photoshop/Figma layers into html ',
                'Front-end / Back-end development and implementation for Desktop ',
                'Front-end / Back-end development and implementation for Mobile',
                'Integrate Lead Flow into CMS',
                'Integrate with Client DNS (for Sub-Domain)',
                'QA Testing',
                'Launch',
              ],
              null
            ),
            fullLandingPageWeeks(
              ['Week 9 - 12'],
              true,
              1,
              [
                'Copy Testing',
                'Call to Action (CTA) Testing',
                'Panel Adjustments/Creation',
                'Form Testing',
                'Color & Button Testing',
                'Video & Image Testing',
                'Dev Maintenance & QA Testing',
              ],
              'On-Going Website A/B Testing'
            ),
          ],
        } // end of Website Package
      : null,

    fullPodVideo: validatePackages(inputs.packages, ['Full Pod', 'Video'], 2)
      ? {
          descriptionOfServices: `The objective of this project is to attain specific ${
            inputs.mediaGoals.length
              ? inputs.mediaGoals.map(
                  (e) =>
                    `${
                      e === 'Marketing Qualified Leads'
                        ? 'Monthly Marketing Qualified Lead (MQLs)'
                        : e
                    }${
                      e === inputs.mediaGoals[inputs.mediaGoals.length - 1]
                        ? ''
                        : ', '
                    }`
                )
              : 'Monthly Marketing Qualified Leads (MQLs)'
          } goals by developing and implementing paid acquisition strategies and a Media-Plan (for ${
            inputs.social_platforms.length
              ? inputs.social_platforms.map((e) => {
                  if (e === 'Google Ads') {
                    return ' Google Ads';
                  }
                  return ` ${e}`;
                })
              : ''
          }) as well as delivering the completed videos outlined in the Proposal.`,
          descNote:
            '*All Services Include Creative, Data Science, and Media Planning / Growth Hacking',
          descSynopsisIntro:
            'This document covers the synopsis of what the client should expect from this engagement:',
          descSynopsis: [
            `Extract & analyze existing data sets & campaigns (i.e. ${
              inputs.social_platforms.includes('Facebook')
                ? `Facebook Ad Manager /`
                : null
            } ${
              inputs.social_platforms.includes('Google Ads')
                ? `Google Ads / Google Analytics /`
                : null
            } etc.)`,
            'Develop & implement on-going audience-building strategies',
            `Design Graphical Ads (still image + animation) on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Copywriting & taglines to accompany the ads on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Data Analytics and Bi-Weekly Executive Reporting`,
            `A/B Testing & On-Going Media Plan Optimizations`,
          ],
          preProductionTitle: 'Pre-Production:',
          preProduction,
          productionTitle: 'Production:',
          production,
          postProductionTitle: 'Post-Production:',
          postProduction,
          projectStartDate: `Project start date is suggested to be ${
            inputs.project_start_date
              ? `${
                  monthNames[new Date(inputs.project_start_date).getMonth()]
                } ${new Date(inputs.project_start_date).getDate()}, ${new Date(
                  inputs.project_start_date
                ).getFullYear()}`
              : 'March, 1st 2021'
          }.`,
          deliverablesDescription:
            'The project will include the following deliverables.',
          preLaunchTitle: 'Pre-Launch (2-Week Sprint):',
          preLaunch: [
            [
              'Kick off Call',
              'Creative Team Sync',
              'Data Team Sync',
              'Creative Assets Received from Client',
              'Setup Tracking for all Key Events',
              'Onboard to Ad Approval Tool',
              'Build Executive Report Template',
              'Create Buyer Persona Positioning Document',
            ],
            [
              'Reference Deck Submitted',
              'Creative Deck for Round 1 Ads Submitted',
              'Copy Deck for Round 1 Ads Submitted',
              'Google Keyword Research',
              `Custom Targeting Stack for Each Persona`,
              'Re-Build Campaign Structure',
              'Schedule Executive Report',
              'Build Performance Tracker',
              'Build Data Flow',
            ],
          ],
          sprintLength: '12 Week Sprint:',
          podWeeks: [
            fullPodWeeks(1, ['Week 1 & 2', 'Week 3 & 4'], false, 2),
            fullPodWeeks(5, ['Week 5 & 6', 'Week 7 & 8'], false, 2),
            fullPodWeeks(9, ['Week 9 & 10', 'Week 11 & 12'], false, 2),
          ],
        } // end of full pod Video
      : null,
    fullPodLanding: validatePackages(
      inputs.packages,
      ['Full Pod', 'Landing Page'],
      2
    )
      ? {
          descriptionOfServices: `The objective of this project is to attain specific ${
            inputs.mediaGoals.length
              ? inputs.mediaGoals.map(
                  (e) =>
                    `${
                      e === 'Marketing Qualified Leads'
                        ? 'Monthly Marketing Qualified Lead (MQLs)'
                        : e
                    }${
                      e === inputs.mediaGoals[inputs.mediaGoals.length - 1]
                        ? ''
                        : ', '
                    }`
                )
              : 'Monthly Marketing Qualified Leads (MQLs)'
          } goals by developing and implementing paid acquisition strategies and a Media-Plan (for${
            inputs.social_platforms.length
              ? inputs.social_platforms.map((e, index, arr) => {
                  if (e === 'Google Ads') {
                    return ' Google Ads';
                  }
                  if (index === arr.length - 1) {
                    return ` and ${e}`;
                  }
                  return ` ${e}`;
                })
              : ''
          }).`,
          descNote:
            '*All Services Include Creative, Data Science, and Media Planning / Growth Hacking',
          descSynopsisIntro:
            'This document covers the synopsis of what the client should expect from this engagement:',
          descSynopsis: [
            `Extract & analyze existing data sets & campaigns (i.e.${
              inputs.social_platforms.includes('Facebook')
                ? ` Facebook Ad Manager /`
                : ''
            } ${
              inputs.social_platforms.includes('Google Ads')
                ? `Google Ads / Google Analytics /`
                : ''
            } etc.)`,
            'Develop & implement on-going audience-building strategies',
            `Design Graphical Ads (still image + animation) on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Copywriting & taglines to accompany the ads on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Data Analytics and Bi-Weekly Executive Reporting`,
            `A/B Testing & On-Going Media Plan Optimizations`,
            `Competitive Landing Page Research `,
            'Build Wireframe Architecture',
            'UX and Low Fidelity Designs in Figma',
            `1 High-Fidelity Landing Page Funnel (4-9 Panels and Lead Capture Flow) `,
            `Full-Stack Development`,
            `QA Testing`,
            'On-Going Conversion Rate Optimization',
          ],
          projectStartDate: `Project start date is suggested to be ${
            inputs.project_start_date
              ? `${
                  monthNames[new Date(inputs.project_start_date).getMonth()]
                } ${new Date(inputs.project_start_date).getDate()}, ${new Date(
                  inputs.project_start_date
                ).getFullYear()}`
              : 'March, 1st 2021'
          }.`,
          deliverablesDescription:
            'The project will include the following deliverables.',
          preLaunchTitle: 'Pre-Launch (2-Week Sprint):',
          preLaunch: [
            [
              'Kick off Call',
              'Creative Team Sync',
              'Data Team Sync',
              'Creative Assets Received from Client',
              'Setup Tracking for all Key Events',
              'Onboard to Ad Approval Tool',
              'Build Executive Report Template',
              'Create Buyer Persona Positioning Document',
            ],
            [
              'Reference Deck Submitted',
              'Creative Deck for Round 1 Ads Submitted',
              'Copy Deck for Round 1 Ads Submitted',
              'Google Keyword Research',
              `Custom Targeting Stack for Each Persona`,
              'Re-Build Campaign Structure',
              'Schedule Executive Report',
              'Build Performance Tracker',
              'Build Data Flow',
            ],
          ],
          sprintLength: '12 Week Sprint:',
          podWeeks: [
            fullPodWeeks(1, ['Week 1 & 2', 'Week 3 & 4'], false, 2, [
              [
                'Primary Research for Landing Pages (Competitor Analysis)',
                'Sitemap is Submitted',
                'Low-Fidelity Wireframe Submitted',
              ],
              [
                'V1 of UX for Landing Page (Desktop)',
                'Low Fidelity Color Designs in Figma (Desktop)',
                'V1 of Copywriting is Approved by Client',
                'Client Review, Notes, and Approvals',
              ],
            ]),
            fullPodWeeks(5, ['Week 5 & 6', 'Week 7 & 8'], false, 2, [
              [
                'High-Fidelity Color Designs for Desktop ("HomePage" 4-9 Panels, Lead Capture Flow)',
                'High-Fidelity Color Designs for Mobile ("HomePage" 4-9 Panels, Lead Capture Flow)',
                'V2 of Copywriting is Approved by Client',
                'Client Review',
              ],
              [
                'Pixel Implementation, Analytics, and Custom Conversion Set-Up',
                'Converting Photoshop/Figma layers into html ',
                'Front-end / Back-end development and implementation for Desktop ',
                'Front-end / Back-end development and implementation for Mobile',
                'Integrate Lead Flow into CMS',
                'Integrate with Client DNS (for Sub-Domain)',
                'QA Testing',
                'Launch',
              ],
            ]),
            fullPodWeeks(9, ['Week 9 & 10', 'Week 11 & 12'], false, 2, [
              [
                'Copy Testing',
                'Call to Action (CTA) Testing',
                'Panel Adjustments/Creation',
                'Form Testing',
                'Color & Button Testing',
                'Video & Image Testing',
                'Dev Maintenance & QA Testing',
              ],
              null,
              [
                'Copy Testing',
                'Call to Action (CTA) Testing',
                'Panel Adjustments/Creation',
                'Form Testing',
                'Color & Button Testing',
                'Video & Image Testing',
                'Dev Maintenance & QA Testing',
              ],
            ]),
          ],
        } // end of full pod Landing Page
      : null,

    fullPodWebsite: validatePackages(
      inputs.packages,
      ['Full Pod', 'Website'],
      2
    )
      ? {
          descriptionOfServices: `The objective of this project is to attain specific ${
            inputs.mediaGoals.length
              ? inputs.mediaGoals.map(
                  (e) =>
                    `${
                      e === 'Marketing Qualified Leads'
                        ? 'Monthly Marketing Qualified Lead (MQLs)'
                        : e
                    }${
                      e === inputs.mediaGoals[inputs.mediaGoals.length - 1]
                        ? ''
                        : ', '
                    }`
                )
              : 'Monthly Marketing Qualified Leads (MQLs)'
          } goals by developing and implementing paid acquisition strategies and a Media-Plan (for${
            inputs.social_platforms.length
              ? inputs.social_platforms.map((e, index, arr) => {
                  if (e === 'Google Ads') {
                    return ' Google Ads';
                  }
                  if (index === arr.length - 1) {
                    return ` and ${e}`;
                  }
                  return ` ${e}`;
                })
              : ''
          }).`,
          descNote:
            '*All Services Include Creative, Data Science, and Media Planning / Growth Hacking',
          descSynopsisIntro:
            'This document covers the synopsis of what the client should expect from this engagement:',
          descSynopsis: [
            `Extract & analyze existing data sets & campaigns (i.e.${
              inputs.social_platforms.includes('Facebook')
                ? ` Facebook Ad Manager /`
                : ''
            } ${
              inputs.social_platforms.includes('Google Ads')
                ? `Google Ads / Google Analytics /`
                : ''
            } etc.)`,
            'Develop & implement on-going audience-building strategies',
            `Design Graphical Ads (still image + animation) on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Copywriting & taglines to accompany the ads on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Data Analytics and Bi-Weekly Executive Reporting`,
            `A/B Testing & On-Going Media Plan Optimizations`,
            `Competitive Website Research `,
            'Build Wireframe Architecture',
            'UX and Low Fidelity Designs in Figma',
            `1 High-Fidelity Website`,
            `Full-Stack Development`,
            `QA Testing`,
            'On-Going Conversion Rate Optimization',
          ],
          projectStartDate: `Project start date is suggested to be ${
            inputs.project_start_date
              ? `${
                  monthNames[new Date(inputs.project_start_date).getMonth()]
                } ${new Date(inputs.project_start_date).getDate()}, ${new Date(
                  inputs.project_start_date
                ).getFullYear()}`
              : 'March, 1st 2021'
          }.`,
          deliverablesDescription:
            'The project will include the following deliverables.',
          preLaunchTitle: 'Pre-Launch (2-Week Sprint):',
          preLaunch: [
            [
              'Kick off Call',
              'Creative Team Sync',
              'Data Team Sync',
              'Creative Assets Received from Client',
              'Setup Tracking for all Key Events',
              'Onboard to Ad Approval Tool',
              'Build Executive Report Template',
              'Create Buyer Persona Positioning Document',
            ],
            [
              'Reference Deck Submitted',
              'Creative Deck for Round 1 Ads Submitted',
              'Copy Deck for Round 1 Ads Submitted',
              'Google Keyword Research',
              `Custom Targeting Stack for Each Persona`,
              'Re-Build Campaign Structure',
              'Schedule Executive Report',
              'Build Performance Tracker',
              'Build Data Flow',
            ],
          ],
          sprintLength: '12 Week Sprint:',
          podWeeks: [
            fullPodWeeks(1, ['Week 1 & 2', 'Week 3 & 4'], false, 2, [
              [
                'Primary Research for Website',
                'Sitemap is Submitted',
                'Low-Fidelity Wireframe Submitted',
              ],
              [
                'V1 of UX for Website (Desktop)',
                'Low Fidelity Color Designs in Figma (Desktop)',
                'V1 of Copywriting is Approved by Client',
                'Client Review, Notes, and Approvals',
              ],
            ]),
            fullPodWeeks(5, ['Week 5 & 6', 'Week 7 & 8'], false, 2, [
              [
                'High-Fidelity Color Designs for Desktop',
                'High-Fidelity Color Designs for Mobile',
                'V2 of Copywriting is Approved by Client',
                'Client Review',
              ],
              [
                'Pixel Implementation, Analytics, and Custom Conversion Set-Up',
                'Converting Photoshop/Figma layers into html ',
                'Front-end / Back-end development and implementation for Desktop ',
                'Front-end / Back-end development and implementation for Mobile',
                'Integrate Lead Flow into CMS',
                'Integrate with Client DNS (for Sub-Domain)',
                'QA Testing',
                'Launch',
              ],
            ]),
            fullPodWeeks(9, ['Week 9 & 10', 'Week 11 & 12'], false, 2, [
              [
                'Copy Testing',
                'Call to Action (CTA) Testing',
                'Panel Adjustments/Creation',
                'Form Testing',
                'Color & Button Testing',
                'Video & Image Testing',
                'Dev Maintenance & QA Testing',
              ],
              null,
              [
                'Copy Testing',
                'Call to Action (CTA) Testing',
                'Panel Adjustments/Creation',
                'Form Testing',
                'Color & Button Testing',
                'Video & Image Testing',
                'Dev Maintenance & QA Testing',
              ],
            ]),
          ],
        } // end of full pod Website Page
      : null,

    fullPodLpAndVideo: validatePackages(
      inputs.packages,
      ['Full Pod', 'Landing Page', 'Video'],
      3
    )
      ? {
          descriptionOfServices: `The objective of this project is to attain specific ${
            inputs.mediaGoals.length
              ? inputs.mediaGoals.map(
                  (e) =>
                    `${
                      e === 'Marketing Qualified Leads'
                        ? 'Monthly Marketing Qualified Lead (MQLs)'
                        : e
                    }${
                      e === inputs.mediaGoals[inputs.mediaGoals.length - 1]
                        ? ''
                        : ', '
                    }`
                )
              : 'Monthly Marketing Qualified Leads (MQLs)'
          } goals by developing and implementing paid acquisition strategies and a Media-Plan (for ${
            inputs.social_platforms.length
              ? inputs.social_platforms.map((e) => {
                  if (e === 'Google Ads') {
                    return ' Google Ads';
                  }
                  return ` ${e}`;
                })
              : ''
          }) as well as delivering the completed videos outlined in the Proposal.`,
          descNote:
            '*All Services Include Creative, Data Science, and Media Planning / Growth Hacking',
          descSynopsisIntro:
            'This document covers the synopsis of what the client should expect from this engagement:',
          descSynopsis: [
            `Extract & analyze existing data sets & campaigns (i.e. ${
              inputs.social_platforms.includes('Facebook')
                ? `Facebook Ad Manager /`
                : null
            } ${
              inputs.social_platforms.includes('Google Ads')
                ? `Google Ads / Google Analytics /`
                : null
            } etc.)`,
            'Develop & implement on-going audience-building strategies',
            `Design Graphical Ads (still image + animation) on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Copywriting & taglines to accompany the ads on ${
              inputs.social_platforms.length
                ? inputs.social_platforms.map(
                    (e) =>
                      `${e}${
                        e ===
                        inputs.social_platforms[
                          inputs.social_platforms.length - 1
                        ]
                          ? ''
                          : ' + '
                      }`
                  )
                : 'Facebook + Instagram + Google Ads + LinkedIn'
            }`,
            `Data Analytics and Bi-Weekly Executive Reporting`,
            `A/B Testing & On-Going Media Plan Optimizations`,
            `Competitive Landing Page Research `,
            'Build Wireframe Architecture',
            'UX and Low Fidelity Designs in Figma',
            `1 High-Fidelity Landing Page Funnel (4-9 Panels and Lead Capture Flow) `,
            `Full-Stack Development`,
            `QA Testing`,
            'On-Going Conversion Rate Optimization',
          ],
          preProductionTitle: 'Pre-Production:',
          preProduction,
          productionTitle: 'Production:',
          production,
          postProductionTitle: 'Post-Production:',
          postProduction,
          projectStartDate: `Project start date is suggested to be ${
            inputs.project_start_date
              ? `${
                  monthNames[new Date(inputs.project_start_date).getMonth()]
                } ${new Date(inputs.project_start_date).getDate()}, ${new Date(
                  inputs.project_start_date
                ).getFullYear()}`
              : 'March, 1st 2021'
          }.`,
          deliverablesDescription:
            'The project will include the following deliverables.',
          preLaunchTitle: 'Pre-Launch (2-Week Sprint):',
          preLaunch: [
            [
              'Kick off Call',
              'Creative Team Sync',
              'Data Team Sync',
              'Creative Assets Received from Client',
              'Setup Tracking for all Key Events',
              'Onboard to Ad Approval Tool',
              'Build Executive Report Template',
              'Create Buyer Persona Positioning Document',
            ],
            [
              'Reference Deck Submitted',
              'Creative Deck for Round 1 Ads Submitted',
              'Copy Deck for Round 1 Ads Submitted',
              'Google Keyword Research',
              `Custom Targeting Stack for Each Persona`,
              'Re-Build Campaign Structure',
              'Schedule Executive Report',
              'Build Performance Tracker',
              'Build Data Flow',
            ],
          ],
          sprintLength: '12 Week Sprint:',
          podWeeks: [
            fullPodWeeks(1, ['Week 1 & 2', 'Week 3 & 4'], false, 2, [
              [
                'Primary Research for Landing Pages (Competitor Analysis)',
                'Sitemap is Submitted',
                'Low-Fidelity Wireframe Submitted',
              ],
              [
                'V1 of UX for Landing Page (Desktop)',
                'Low Fidelity Color Designs in Figma (Desktop)',
                'V1 of Copywriting is Approved by Client',
                'Client Review, Notes, and Approvals',
              ],
            ]),
            fullPodWeeks(5, ['Week 5 & 6', 'Week 7 & 8'], false, 2, [
              [
                'High-Fidelity Color Designs for Desktop ("HomePage" 4-9 Panels, Lead Capture Flow)',
                'High-Fidelity Color Designs for Mobile ("HomePage" 4-9 Panels, Lead Capture Flow)',
                'V2 of Copywriting is Approved by Client',
                'Client Review',
              ],
              [
                'Pixel Implementation, Analytics, and Custom Conversion Set-Up',
                'Converting Photoshop/Figma layers into html ',
                'Front-end / Back-end development and implementation for Desktop ',
                'Front-end / Back-end development and implementation for Mobile',
                'Integrate Lead Flow into CMS',
                'Integrate with Client DNS (for Sub-Domain)',
                'QA Testing',
                'Launch',
              ],
            ]),
            fullPodWeeks(9, ['Week 9 & 10', 'Week 11 & 12'], false, 2, [
              [
                'Copy Testing',
                'Call to Action (CTA) Testing',
                'Panel Adjustments/Creation',
                'Form Testing',
                'Color & Button Testing',
                'Video & Image Testing',
                'Dev Maintenance & QA Testing',
              ],
              null,
              [
                'Copy Testing',
                'Call to Action (CTA) Testing',
                'Panel Adjustments/Creation',
                'Form Testing',
                'Color & Button Testing',
                'Video & Image Testing',
                'Dev Maintenance & QA Testing',
              ],
            ]),
          ],
        } // end of full pod Video
      : null,
  };
};
