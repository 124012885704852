import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone-uploader';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { useMultipleFileUploader } from '../hooks/useMultipleFileUploader';

const StyledInputDiv = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  .dzu-dropzone {
    max-height: ${({ smallDropZone }) => (smallDropZone ? '180px' : '400px')};
  }
`;

const FileUploader = ({
  s3UploadURL,
  dbPostURL,
  dbOrgID,
  fileTypes,
  cb,
  getLinks,
  smallDropZone,
}) => {
  const {
    getUploadParams,
    handleChangeStatus,
    handleSubmit,
    fileUploaded,
    loadingSpinner,
  } = useMultipleFileUploader(
    s3UploadURL,
    dbPostURL,
    dbOrgID,
    fileTypes,
    cb,
    getLinks
  );

  return (
    <StyledInputDiv smallDropZone={smallDropZone}>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
      />
      {/* <p style={{ marginBottom: '10px' }}>Upload Document:</p>
      <span>
        Note: When File is uploaded, Folder for Org will be created if it does
        not exist <br />
      </span> */}
      <div
        style={{
          display: 'flex',
          marginTop: '10px',
          justifyContent: 'center',
        }}
      >
        &nbsp;
        {(() => {
          if (fileUploaded === true)
            return `\u{2705} Files uploaded Successfully`;
          if (fileUploaded === 'error') return `❌ Error uploading files`;
          return null;
        })()}
        {loadingSpinner && (
          <Loader
            // style={{ left: '44%', position: 'absolute' }}
            type="ThreeDots"
            color="#00BFFF"
            height={30}
            width={30}
          />
        )}
      </div>
    </StyledInputDiv>
  );
};

FileUploader.propTypes = {
  s3UploadURL: PropTypes.string.isRequired,
  dbPostURL: PropTypes.string.isRequired,
  dbOrgID: PropTypes.number,
  fileTypes: PropTypes.string.isRequired,
  cb: PropTypes.func,
  getLinks: PropTypes.func,
  smallDropZone: PropTypes.bool,
};

FileUploader.defaultProps = {
  cb: null,
  getLinks: null,
  dbOrgID: null,
  smallDropZone: false,
};

export default FileUploader;
