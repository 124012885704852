import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { Text } from 'colabs-ui-react';
import Overlay from '../presentational/Overlay';

const StyledLeadsGraph = styled.div`
  > .section {
    padding: 1rem;
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    > .summary {
      display: flex;
      justify-content: space-around;
      > .metric {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    > .graphTitle {
      padding-bottom: 1rem;
      font-family: ${({ theme }) => theme.font.main.medium};
    }
    > #leadsBarGraph {
      padding: 0.5rem 2rem 1rem 3.5rem;
      width: 100%;
      > svg {
        width: 100%;
        overflow: visible;
        .xAxis,
        .yAxis {
          color: black;
          .domain,
          line {
            display: none;
          }
          g.tick {
            font-size: 0.875rem;
            color: #939393;
            &.roundGoal {
              color: #00e16e !important;
              margin-left: 10px;
            }
          }
        }
        .barsBackground {
          > * {
            fill: #e5e5e5;
          }
        }
        .bars {
          > * {
            fill: #00e16e;
            :hover {
              fill: #00ff59;
            }
          }
        }
      }
    }
  }
`;

const StyledOverlayCard = styled.div`
  background: #303030;
  color: #00e16e;
  padding: 0.5rem;
  border-radius: 0.25rem;
`;

const LeadsGraph = ({ goal, actual, currentLeadsProjected }) => {
  const height = 200;
  const actualTotal = actual?.reduce((a, b) => a + b);
  const barChartRef = useRef();
  const roundGoal = currentLeadsProjected;
  const barChartWidth = 300;
  const [overlayValue, setOverlayValue] = useState(0);
  const [hoverBarRef, setHandleBarRef] = useState();
  const max = (() => {
    if (!actual) return 0;
    const maxFromActual = Math.max(...actual) * 1.2;
    if (currentLeadsProjected && currentLeadsProjected > maxFromActual) {
      return Math.max(...actual) * 2.5;
    }
    return maxFromActual;
  })();
  const maxTick = Math.ceil(max / 100) * 100;
  const updatedTicks = [
    ...[0, 0.2, 0.4, 0.6, 0.8, 1].map((f) => f * maxTick),
    roundGoal,
  ];

  const handleBarMouseEnter = (e, d) => {
    setOverlayValue(d);
    setHandleBarRef(e.target);
  };

  const handleBarMouseLeave = () => {
    setOverlayValue(0);
    setHandleBarRef(null);
  };

  const generateBarChart = () => {
    const svg = d3
      .select('#leadsBarGraph')
      .append('svg')
      .attr('viewBox', '0 0 300 200');
    // .attr('width', barChartWidth)
    // .attr('height', (barChartWidth / 3) * 2);
    if ((actual, goal)) {
      // xAxis
      svg
        .append('g')
        .attr('class', 'xAxis')
        .call(
          d3.axisBottom().scale(
            d3
              .scaleLinear()
              .domain([1, actual.length])
              .range([0, barChartWidth - barChartWidth / actual.length])
          )
        )
        .attr('transform', `translate(5,${height})`);

      // yAxis
      svg
        .append('g')
        .attr('class', 'yAxis')
        .call(
          d3
            .axisLeft()
            .scale(d3.scaleLinear().domain([0, maxTick]).range([height, 0]))
            .tickValues(updatedTicks.filter((d) => d <= maxTick))
        )
        .selectAll('g.tick')
        .filter((d) => d === roundGoal)
        .attr('class', 'tick roundGoal')
        .attr(
          'transform',
          `translate(330,${(height / maxTick) * (maxTick - roundGoal)})`
        );

      // barsBackground
      svg
        .append('g')
        .attr('class', 'barsBackground')
        .selectAll('rect')
        .data(actual)
        .enter()
        .append('rect')
        .attr('x', (d, i) => (barChartWidth / actual.length) * i) // distance from left of each bar
        .attr('y', 0)
        .attr('rx', 4)
        .attr('rx', 4)
        .attr('width', 8)
        .attr('height', height);

      // bars
      svg
        .append('g')
        .attr('class', 'bars')
        .selectAll('rect')
        .data(actual)
        .enter()
        .append('rect')
        .attr('x', (d, i) => (barChartWidth / actual.length) * i) // distance from left of each bar
        .attr('y', (d) => (height / maxTick) * (maxTick - d)) // distance from top of each bar
        .attr('rx', 4)
        .attr('rx', 4)
        .attr('width', 8)
        .attr('height', (d) => (height / maxTick) * d)
        .attr('title', (d, i) => `round number ${i + 1}: ${d}`)
        .on('mouseenter', handleBarMouseEnter)
        .on('mouseleave', handleBarMouseLeave);

      // target line
      svg
        .append('line')
        .attr('class', 'target')
        .attr(
          'transform',
          `translate(0, ${(height / maxTick) * (maxTick - roundGoal)})`
        )
        .attr('x1', 0)
        .attr('x2', barChartWidth - 5)
        .style('stroke', '#44dc7a')
        .attr('stroke-dasharray', '4 4')
        .attr('stroke-width', 1);
      return;
    }

    // empty bars when no data is passed
    svg
      .append('g')
      .attr('class', 'barsBackground')
      .selectAll('rect')
      .data([10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10])
      .enter()
      .append('rect')
      .attr('x', (d, i) => (barChartWidth / 12) * i) // distance from left of each bar
      .attr('y', 0)
      .attr('rx', 4)
      .attr('rx', 4)
      .attr('width', 8)
      .attr('height', height);
  };

  useEffect(() => {
    generateBarChart();
  }, []);

  return (
    <StyledLeadsGraph>
      <Overlay
        triggerRef={hoverBarRef}
        placement="top"
        show={!!overlayValue}
        arrowColor="#303030"
      >
        <StyledOverlayCard>{overlayValue}</StyledOverlayCard>
      </Overlay>
      <div className="section">
        <div className="header">
          <Text size={3}>Total Amount of Leads</Text>
          <Text size={3}>SPRINT</Text>
        </div>
        <div className="summary">
          <div className="metric">
            <Text size={5} weight="medium">
              {goal ? goal.toLocaleString('en') : '—'}
            </Text>
            <Text size={1} style={{ color: '#b0b0b0' }}>
              GOAL
            </Text>
          </div>
          <div className="metric">
            <Text size={5} weight="medium" style={{ color: '#44dc7a' }}>
              {actualTotal ? actualTotal.toLocaleString('en') : '—'}
            </Text>
            <Text size={1} style={{ color: '#b0b0b0' }}>
              AS OF TODAY
            </Text>
          </div>
          <div className="metric">
            <Text size={5} weight="medium">
              {actualTotal ? ((actualTotal * 100) / goal).toFixed(0) : '—'}%
            </Text>
            <Text size={1} style={{ color: '#b0b0b0' }}>
              OF GOAL
            </Text>
          </div>
        </div>
      </div>
      {/* <Divider /> */}
      <div className="section">
        <Text gutterBottom weight="medium" size={2}>
          Leads By Round
        </Text>
        <div id="leadsBarGraph" ref={barChartRef} />
      </div>
    </StyledLeadsGraph>
  );
};

LeadsGraph.propTypes = {
  goal: PropTypes.number,
  actual: PropTypes.arrayOf(PropTypes.number),
};

LeadsGraph.defaultProps = {
  goal: null,
  actual: null,
};

export default LeadsGraph;
