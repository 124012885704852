import { Media, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { SkipForward } from 'react-feather';
import styled from 'styled-components';
import blueImage from '../../../assets/images/BlueImage.png';
import tiktokTop from '../../../assets/images/tiktok_top.png';
import Pin from '../../Pin';
// import Image from '../presentational/Image';

const StyledYoutube = styled.div`
  background-color: #c9ccd1;
  width: 100%;
  height: 100%;
  .cui-text {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  > .appTop,
  > .appBottom {
    background: #fafafa;
    object-fit: contain;
    width: 100%;
    margin-bottom: -0.4rem;
  }
  > .appTop {
    z-index: 10;
  }
  .yellowBar {
    position: absolute;
    background-color: #ecc740;
    height: 1.2%;
    width: 30%;
    top: 29.6%;
  }
  .creativeWrapper {
    position: relative;
    height: 26%;
    width: 100%;
    > .pin {
      position: absolute;
    }
    .creative {
      object-fit: cover;
      margin-bottom: -0.3rem;
    }
  }
  .copyContent {
    background-color: #fff;
    padding: 0.5rem;
    display: flex;
    .avatar {
      height: 2.3rem;
      width: 2.3rem;
      margin-right: 0.5rem;
    }
    .centerBlock {
      flex-grow: 1;
      .cui-text {
        font-size: 0.8rem;
      }
      .headline {
        font-weight: bold;
      }
      .tag {
        background-color: #ecc740;
        .cui-text {
          font-size: 0.7rem;
        }
      }
    }
    .cta {
      background-color: #2893e6;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .cui-text {
        font-size: 0.9rem;
      }
    }
  }
  .videoInfo {
  }
  .similarVideos {
  }
  .skipAd {
    opacity: 0.8;
    background-color: #000000;
    padding: 0.3rem 1.25rem;
    border: 1px solid #ffffff;
    position: absolute;
    right: 0;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    .cui-text {
      font-size: 0.75rem;
      margin-right: 0.5rem;
    }
  }
`;

const Youtube = ({
  selectedCopys,
  cta,
  creative,
  socialProfilePicture,
  socialUsername,
  adTextIsExpanded,
  setAdTextIsExpanded,
  trimCopy,
  isLongerThanLimit,
  pins,
  isInteractive,
  pinsEnabled,
  newPinIndex,
}) => {
  return (
    <StyledYoutube>
      <img className="appTop" alt="" src={tiktokTop} />
      <div id="creativeWrapper" className="creativeWrapper">
        {pins?.map((pin) => (
          <Pin
            style={{ top: `${pin.top}%`, left: `${pin.left}%` }}
            className={(() => {
              if (pin.is_internal) return 'internal';
              if (pin.user_organization_id === 1) return 'colabs';
              return '';
            })()}
            number={pin?.number}
          />
        ))}
        {pinsEnabled && (
          <Pin className="newPin" number={newPinIndex} id="newPin" />
        )}
        <Media
          onError={(e) => {
            e.target.src = blueImage;
          }}
          videoProps={
            isInteractive
              ? {
                  autoPlay: true,
                  loop: true,
                }
              : {}
          }
          alt=""
          id="creativeMedia"
          className="creative"
          src={creative}
        />
      </div>
      <div className="yellowBar" />
      <div className="copyContent">
        <Media
          className="avatar"
          src={socialProfilePicture || blueImage}
          onError={(e) => {
            e.target.src = blueImage;
          }}
          alt=""
        />
        <div className="centerBlock">
          <Text className="headline">{selectedCopys?.headline?.content}</Text>
          <span>
            <Text color="white" tag="span" className="tag">
              Ad
            </Text>{' '}
            <Text style={{ color: '#8f9294' }} tag="span">
              www.hellosaurus.com/...
            </Text>
          </span>
        </div>
        <div className="cta">
          <Text noWrap color="white">
            {cta?.toUpperCase()}
          </Text>
        </div>
      </div>
      <div className="videoInfo" />
      <div className="similarVideos" />
      <div className="skipAd">
        <Text color="white">Skip Ad</Text>
        <SkipForward fill="white" color="white" size="0.9rem" />
      </div>
    </StyledYoutube>
  );
};

Youtube.propTypes = {
  selectedCopys: PropTypes.shape().isRequired,
  cta: PropTypes.string.isRequired,
  creative: PropTypes.string.isRequired,
  socialProfilePicture: PropTypes.string.isRequired,
  socialUsername: PropTypes.string.isRequired,
  adTextIsExpanded: PropTypes.bool.isRequired,
  setAdTextIsExpanded: PropTypes.func.isRequired,
  trimCopy: PropTypes.func.isRequired,
  isLongerThanLimit: PropTypes.func.isRequired,
};

Youtube.defaultProps = {};

export default Youtube;
