import React from 'react';
import styled from 'styled-components';

const StyledBottomBarMessage = styled.div`
  border-radius: 9999px;
  padding: 0.25rem 0.75rem;
  &.green {
    border: 1px solid #44dc7a;
    color: #44dc7a;
    background-color: #c7ffdb;
  }
  &.red {
    border: 1px solid #f55050;
    color: #f55050;
    background-color: #ffdbdb;
  }
  &.orange {
    border: 1px solid #ffa311;
    color: #ffa311;
    background-color: #ffe9c7;
  }
`;

const BottomBarMessage = ({ selectedTemplatesIds }) => {
  if (selectedTemplatesIds.length >= 2)
    return (
      <StyledBottomBarMessage className="green">
        {selectedTemplatesIds.length} Trends Selected
      </StyledBottomBarMessage>
    );
  if (selectedTemplatesIds.length === 1)
    return (
      <StyledBottomBarMessage className="green">
        {selectedTemplatesIds.length} Trend Selected
      </StyledBottomBarMessage>
    );
  return (
    <StyledBottomBarMessage className="red">
      {selectedTemplatesIds.length} Trends Selected
    </StyledBottomBarMessage>
  );
};

export default BottomBarMessage;
