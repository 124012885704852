import {
  DATA_FAILURE,
  DATA_START,
  DATA_SUCCESS,
  SET_USER,
  UPDATE_DYNAMIC_REDUCER,
} from '../reducers';
import fileReader from '../utils/file';
import fileSaver from '../utils/helper';
import { axiosUsingAuth } from '../utils/useAxioswithAuth';

export const FetchBlob =
  (cb, url, filename, loader = null) =>
  async (dispatch) => {
    if (loader) {
      loader(true);
    }
    try {
      dispatch({ type: DATA_START });
      const { data } = await cb().get(url, {
        responseType: 'blob',
        timeout: 30000,
      });
      console.log('DATA!!!', data);
      await fileSaver(data, filename);
      dispatch({ type: DATA_SUCCESS, currentFile: data });
      if (loader) {
        loader(false);
      }
    } catch (err) {
      dispatch({ type: DATA_FAILURE, error: err.response });
      if (loader) {
        loader(false);
      }
      console.log(err, 'DATA!!!');
      if (err.message === 'Network Error' || err.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      if (err.response) {
        try {
          const { data } = err.response;
          // Read file
          const file = await fileReader(data);
          // Parse content and retrieve 'message'
          const { message } = JSON.parse(file);
          alert(message);
        } catch (readError) {
          // Show a dummy error if sth goes wrong while retrieving 'message'
          alert('Something went wrong while downloading this file');
        }
      } else {
        alert('Something went wrong while downloading this file');
      }
    }
  };

export const FetchData = (cb, url, reducer) => (dispatch) => {
  dispatch({ type: DATA_START });
  cb()
    .get(url)
    .then((res) => {
      // console.log(res, 'res data');
      switch (reducer) {
        case 'documents':
          return dispatch({ type: DATA_SUCCESS, documents: res.data });
        case 'creatives':
          return dispatch({ type: DATA_SUCCESS, creatives: res.data });
        case 'executiveReports':
          return dispatch({ type: DATA_SUCCESS, executiveReports: res.data });
        case 'refreshtkn':
          return dispatch({ type: DATA_SUCCESS, refreshtkn: res.data });
        default:
          break;
      }
      dispatch({ type: DATA_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: DATA_FAILURE, error: err.response });
      if (err.message === 'Network Error' || err.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
    });
};

export const FetchRefresh = (cb, url) => (dispatch) => {
  dispatch({ type: DATA_START });
  cb()
    .get(url)
    .then((res) => dispatch({ type: DATA_SUCCESS, refreshtkn: res.data }))
    .catch((err) =>
      dispatch({ type: DATA_FAILURE, error: err.response, refreshtkn: null })
    );
};

export const CreateError = (msg) => (dispatch) => {
  dispatch({ type: DATA_FAILURE, error: msg });
};
export const FetchLocalData = (data) => (dispatch) => {
  dispatch({ type: DATA_START });
  try {
    dispatch({ type: DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DATA_FAILURE, error: error.response });
  }
};

export const fetchUserData = () => (dispatch) => {
  dispatch({ type: SET_USER, authenticationStatus: 'loading' });
  axiosUsingAuth()
    .get('/users/user')
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data,
        authenticationStatus: 'success',
      });
    })
    .catch((err) => {
      localStorage.clear();
      sessionStorage.clear();
      dispatch({
        type: SET_USER,
        payload: null,
        authenticationStatus: 'failure',
      });
    });
};

export const logoutUser = () => (dispatch) => {
  axiosUsingAuth()
    .get('/auth/logout')
    .finally(() => {
      localStorage.clear();
      sessionStorage.clear();
      dispatch({
        type: SET_USER,
        payload: null,
        authenticationStatus: 'start',
      });
    });
};

export const PostData = (cb, url, post_data, reducer) => (dispatch) => {
  dispatch({ type: DATA_START });
  cb()
    .post(url, post_data)
    .then((res) => {
      console.log('res data', res);
      switch (reducer) {
        case 'documents':
          return dispatch({ type: DATA_SUCCESS, documents: res.data });
        case 'creatives':
          return dispatch({ type: DATA_SUCCESS, creatives: res.data });
        case 'executiveReports':
          return dispatch({ type: DATA_SUCCESS, executiveReports: res.data });
        case 'refreshtkn':
          return dispatch({ type: DATA_SUCCESS, refreshtkn: res.data });
        case 'copyDeck':
          return dispatch({ type: DATA_SUCCESS, payload: [] });

        default:
          break;
      }
      dispatch({ type: DATA_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: DATA_FAILURE, error: err.response });
      if (err.message === 'Network Error' || err.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
    });
};

export const PutData = (cb, url, payload, reducer) => (dispatch) => {
  dispatch({ type: DATA_START });
  cb()
    .put(url, payload)
    .then((res) => {
      // console.log(res, 'res data');
      switch (reducer) {
        case 'documents':
          return dispatch({ type: DATA_SUCCESS, documents: res.data });
        case 'creatives':
          return dispatch({ type: DATA_SUCCESS, creatives: res.data });
        case 'executiveReports':
          return dispatch({ type: DATA_SUCCESS, executiveReports: res.data });
        case 'refreshtkn':
          return dispatch({ type: DATA_SUCCESS, refreshtkn: res.data });
        case 'copyDeck':
          return dispatch({ type: DATA_SUCCESS, payload: [] });

        case 'user':
          return dispatch(fetchUserData());
        default:
          break;
      }
      dispatch({ type: DATA_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: DATA_FAILURE, error: err.response });
      if (err.message === 'Network Error' || err.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
    });
};

export const DeleteData = (cb, url, reducer) => (dispatch) => {
  dispatch({ type: DATA_START });
  cb()
    .delete(url)
    .then((res) => {
      switch (reducer) {
        case 'documents':
          return dispatch({ type: DATA_SUCCESS, documents: res.data });
        case 'creatives':
          return dispatch({ type: DATA_SUCCESS, creatives: res.data });
        case 'executiveReports':
          return dispatch({ type: DATA_SUCCESS, executiveReports: res.data });
        case 'refreshtkn':
          return dispatch({ type: DATA_SUCCESS, refreshtkn: res.data });
        case 'user':
          return dispatch(fetchUserData());
        default:
          break;
      }
      dispatch({ type: DATA_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: DATA_FAILURE, error: err.response });
      if (err.message === 'Network Error' || err.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
    });
};

export const RefToolUpdate =
  (param, key, isArr = false) =>
  (dispatch, getState) => {
    try {
      if (isArr) {
        dispatch({
          type: UPDATE_DYNAMIC_REDUCER,
          name: 'refTool',
          payload: {
            ...getState().refTool,
            [key]: [...getState().refTool[key], param],
          },
        });
      } else {
        dispatch({
          type: UPDATE_DYNAMIC_REDUCER,
          name: 'refTool',
          payload: { ...getState().refTool, [key]: param },
        });
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };
