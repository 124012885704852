import { Button, Chip, Media, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { approvalStatus } from '../../staticData';

const StyledCopyDeckRow = styled.div`
  padding: 0.75rem;
  display: grid;
  grid-template-columns: min-content 2fr min-content min-content;
  align-items: center;
  cursor: pointer;
  grid-gap: 1rem;
  border: 1px solid #ffffffff;
  border-radius: 0.675rem;
  /* &:focus {
            outline: none;
          } */
  @media ${({ theme }) => theme.breakpoint.sm} {
    grid-template-columns: min-content 1fr min-content;
  }
  &.selected {
    border: 1px solid #2980ff;
  }
  > .deckThumbnail {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  .circle {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 0.25rem;
  }
  > :last-child {
    margin-right: 0;
  }
`;

const CopyDeckRow = ({
  selectedDeck,
  index,
  handleDeckKeyDown,
  changeDeckPreview,
  deck,
  isColabsMember,
}) => {
  const { width } = useWindowDimensions();
  const { push } = useHistory();
  return (
    <StyledCopyDeckRow
      className={`deck${
        selectedDeck?.type === 'copy' && deck.id === selectedDeck?.deck.id
          ? ' selected'
          : ''
      }`}
      role="button"
      tabIndex={index}
      onKeyDown={handleDeckKeyDown}
      onClick={() => changeDeckPreview(deck, 'copy')}
    >
      <Media
        className="deckThumbnail"
        src={deck?.creative_image}
        alt=""
        onError={(e) => {
          e.target.src = blueImage;
        }}
      />
      <Text>
        {isColabsMember && `${deck?.organization_name} • `}
        {deck?.round}
      </Text>
      {(() => {
        const approvalState = approvalStatus.find(
          ({ text }) => text === deck?.approval_state
        );
        if (width < 1279) return approvalState?.icon;
        return <Chip icon={approvalState?.icon} label={approvalState?.text} />;
      })()}
      <Button
        variant="secondary"
        text="Review"
        className="reviewDeck"
        onClick={() => push(`ad-approval/copy/${deck?.id}`)}
      />
    </StyledCopyDeckRow>
  );
};

CopyDeckRow.propTypes = {};

CopyDeckRow.defaultProps = {};

export default CopyDeckRow;
