import React from 'react';

export default function ApprovedIcon(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="22" cy="22" r="22" fill="white" />
      <path
        d="M38.6666 20.4666V21.9999C38.6645 25.594 37.5008 29.0911 35.3488 31.9696C33.1969 34.8482 30.1721 36.9541 26.7255 37.9731C23.279 38.9921 19.5954 38.8698 16.224 37.6242C12.8527 36.3787 9.97434 34.0768 8.01819 31.0617C6.06203 28.0467 5.1329 24.48 5.36938 20.8938C5.60586 17.3075 6.99526 13.8938 9.33039 11.1617C11.6655 8.42964 14.8212 6.52557 18.3269 5.73351C21.8326 4.94145 25.5004 5.30383 28.7833 6.7666"
        stroke="#44DC7A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.6667 8.66675L22 25.3501L17 20.3501"
        stroke="#44DC7A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
