import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Media } from 'colabs-ui-react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Pin from '../Pin';

const StyledAdDisplayContainer = styled.div`
  grid-column: 1/2;
  cursor: pointer;
  justify-self: center;
  position: relative;
  height: 100%;
  user-select: none;
  > .pins {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    &.hidden {
      opacity: 0;
    }
    > .pin {
      position: absolute;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 1.25rem;
      backdrop-filter: blur(10px);
      z-index: 1;
      transform: translate(-50%, -50%);
      @media ${({ theme }) => theme.breakpoint.sm} {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  :focus {
    outline: none;
  }
  > .cui-media {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const AdDisplayContainer = ({
  adContainerRef,
  processedPins,
  newPinRef,
  adPinnedNotes,
  url,
  handleTimeUpdate,
  internalCommentsShown,
  lastUpdate,
  isShowingPins,
  ...props
}) => {
  const { width } = useWindowDimensions();

  return (
    <StyledAdDisplayContainer
      ref={adContainerRef}
      onKeyDown={() => false}
      role="button"
      tabIndex={0}
      className="adContainer"
      {...props}
    >
      <div className={`pins ${!isShowingPins ? 'hidden' : ''}`}>
        {processedPins?.map((note) => (
          <Pin
            number={note.index + 1}
            className={(() => {
              if (internalCommentsShown) return ' internal';
              if (note.user_organization_id === 1) return ' colabs';
              if (new Date(note.created_at) < lastUpdate) return ' old';
              return '';
            })()}
            style={{ top: `${note.top}%`, left: `${note.left}%` }}
          />
        ))}
        <Pin
          ref={newPinRef}
          className="newPin"
          number={adPinnedNotes.length + 1}
          id="newPin"
        />
      </div>
      <Media
        src={url}
        alt={url}
        videoProps={{
          loop: true,
          muted: true,
          controls: true,
          onTimeUpdate: handleTimeUpdate,
          id: 'adDisplay',
        }}
      />
    </StyledAdDisplayContainer>
  );
};

AdDisplayContainer.propTypes = {
  adContainerRef: PropTypes.shape().isRequired,
  processedPins: PropTypes.arrayOf(PropTypes.shape()),
  newPinRef: PropTypes.shape().isRequired,
  adPinnedNotes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  url: PropTypes.string.isRequired,
  handleTimeUpdate: PropTypes.func.isRequired,
  internalCommentsShown: PropTypes.bool.isRequired,
  lastUpdate: PropTypes.instanceOf(Date).isRequired,
  isShowingPins: PropTypes.bool.isRequired,
};

AdDisplayContainer.defaultProps = {
  processedPins: null,
};

export default AdDisplayContainer;
