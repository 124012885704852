import React from 'react';
import notfoundimg from '../assets/images/404.png';

const GenericNotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '90vh',
        alignItems: 'center',
      }}
    >
      <div className="w-section content-wrapper">
        <div className="content">
          <img alt="404 page" width="206" src={notfoundimg} className="icon" />
          <h1 className="title">Page not found</h1>
          <p className="description">
            The page you are looking for doesn't exist or has been moved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GenericNotFound;
